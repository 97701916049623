import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";
import store from "@/store/store";

export default class BaseLogger {
  constructor(name, urlPart) {
    this.name = name;
    this.url = `logs/${urlPart}`;
    this.stateCommon = store.state.common;
  }

  get available() {
    return this.stateCommon.loggers[this.name];
  }

  async sendLog(payload, forcibly = false) {
    if (!this.available && !forcibly) return false;
    return AdminApi2HttpService.post(this.url, payload);
  }
}
