class PresentationSlide {
  constructor(slide, presentation) {
    this.presentation = presentation;
    this.id = slide.id;
    this.isTestAnswered = slide.isTestAnswered;
    this.likes = slide.likes;
    this.ord = slide.ord;
    this.index = slide.ord;
    this.path = slide.path;
    this.pathFullSize = slide.pathFullSize;
    this.test_id = slide.test_id || 0;
    this.test_info = slide.test_info || null;
    this.timer = slide.timer;
    this.time_started = slide.time_started;
    this.timer_start = slide.timer_start;
    this.timerDelta = slide.timerDelta || 0;
    this.type = slide.type;
    this.video_slide_preview = slide.video_slide_preview;
    this.video_url = slide.video_url;
    this.audio_url = slide.audio_url;
  }
}

class Presentation {
  constructor(presentation) {
    this.id = presentation.id;
    this.name = presentation.name;
    this.type = presentation.type;
    this.leader = { ...presentation.leader };
    this.video = presentation.video;
    this.countdownTimer = presentation.countdownTimer;
    this.slides = {};
    this.slidesArray = [];
    this.setSlides(presentation.slides);
  }

  setSlides(slides) {
    this.slidesArray = Object.values(slides)
      .sort((a, b) => a.ord - b.ord)
      .map(s => new PresentationSlide(s, this));
    this.slidesArray.forEach(slide => {
      this.slides[slide.index] = slide;
    });
  }
}

export default {
  addToPresentationsSlidesAsArray(presentations) {
    return presentations.map(pres => new Presentation(pres));
  },
};
