/**
 * @namespace RouterConsts
 */

/**
 * Перечесление имен для роутов внутри комнаты
 *
 * @memberof RouterConsts
 * @type {{Chat: string, Questions: string, Profile: string}}
 */
export const RoomRouteName = {
  Chat: "messenger",
  Questions: "questions",
  Profile: "profile",
};

/**
 * Перечисление имен для роутов внутри интерфейса спикера
 *
 * @memberof RouterConsts
 * @type {{Rooms: string, Presentations: string}}
 */
export const SpeakerRouteName = {
  Rooms: "speaker-rooms",
  Room: "speaker-room",
  Presentations: "speaker-presentations",
  Presentation: "speaker-presentation",
};

export const IGNORED_ROUTES = [
  "interactive",
  "test",
  "testCommonResult",
  "testResult",
  "mountain",
  "material",
  "listener",
  RoomRouteName.Profile,
];
