export const messenger = "messenger";
export const chats = "chats";
export const polls = "polls";
export const pollsTest = "polls/test";
export const presentation = "presentation";
export const landing = "landing";
export const questions = "questions";
export const brainstorm = "brainstorm";
export const schedule = "schedule";
export const room = "common";
export const speakers = "speakers";
export const photowall = "photowall";
export const materials = "materials";
export const livewall = "livewall";
export const listeners = "listeners";
export const chat = "chat";
export const auth = "auth";
export const games = "games";
export const dashboard = "dashboard";
export const speaker = "speaker";
export const speakerPolls = "speaker/polls";
export const speakerBrainstorm = "speaker/brainstorm";
export const speakerQuestions = "speaker/questions";
export const speakerPresentation = "speaker/presentation";
export const webex = "webex";
export const user = "user";
export const notifications = "notifications";
export const socket = "socket";
export const information = "information";
export const socketEvent = "socket/event";
export const socketSpeaker = "socket/speaker";
export const socketScreen = "socket/screen";
export const pollsInteractive = "polls/interactive";
export const systemTest = "systemTest";
export const broadcast = "broadcast";
export const miro = "miro";
