export const SET_AVAILABLE_GAMES = "SET_AVAILABLE_GAMES";
export const SET_KING_LEADERS_LIST = "SET_KING_LEADERS_LIST";
export const SET_CURRENT_ACTIVE_GAME = "SET_CURRENT_ACTIVE_GAME";
export const INCREMENT_CURRENT_QUESTION_INDEX = "INCREMENT_CURRENT_QUESTION_INDEX";
export const SET_CURRENT_QUESTION_INDEX = "SET_CURRENT_QUESTION_INDEX";
export const SET_POST_GAME_SCREEN_VISIBLE = "SET_POST_GAME_SCREEN_VISIBLE";
export const SET_LAST_GAME_QUESTION_TIME = "SET_LAST_GAME_QUESTION_TIME";
export const SET_KING_POINTS = "SET_KING_POINTS";
export const ADD_ANSWERED_QUESTION = "ADD_ANSWERED_QUESTION";
export const ADD_SKIPPED_QUESTION = "ADD_SKIPPED_QUESTION";
export const REMOVE_SKIPPED_QUESTION = "REMOVE_SKIPPED_QUESTION";
export const CLEAR_CURRENT_ACTIVE_GAME = "CLEAR_CURRENT_ACTIVE_GAME";
export const SET_KING_RESULT = "SET_KING_RESULT";
