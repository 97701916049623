export const CURRENT_PRESENTATION = "CURRENT_PRESENTATION";
export const CURRENT_PRESENTATION_LEADER = "CURRENT_PRESENTATION_LEADER";
export const CURRENT_PRESENTATION_SLIDES = "CURRENT_PRESENTATION_SLIDES";
export const CURRENT_PRESENTATION_SLIDE = "CURRENT_PRESENTATION_SLIDE";
export const CURRENT_PRESENTATION_SLIDE_ID = "CURRENT_PRESENTATION_SLIDE_ID";
export const SELECTED_SLIDE_ORD = "SELECTED_SLIDE_ORD";
export const CURRENT_PRESENTATION_SLIDE_SRC = "CURRENT_PRESENTATION_SLIDE_SRC";
export const CURRENT_PRESENTATION_INDEX = "CURRENT_PRESENTATION_INDEX";
export const CURRENT_PRESENTATION_NAME = "CURRENT_PRESENTATION_NAME";
export const CURRENT_PRESENTATION_SELECTED_SLIDE_POS = "CURRENT_PRESENTATION_SELECTED_SLIDE_POS";
export const CURRENT_PRESENTATION_SLIDES_COUNT = "CURRENT_PRESENTATION_SLIDES_COUNT";
