import {
  broadcast,
  notifications,
  polls,
  speakerPolls,
  presentation,
  socket,
  speakers,
  room,
} from "@/store/modules/store.namespaces";
import { GET_SPEAKERS } from "@/store/modules/speakers/action-types";
import { CLEAR_POLLCHAIN, SET_INTERACTIVE_TYPE } from "@/store/modules/polls/mutation-types";
import { interactive } from "@/constants/polls/polls-module-types";
import { GET_TESTS, RUN_POLL, RUN_TEST_POLL } from "@/store/modules/polls/action-types";
import {
  TOGGLE_QUIZ,
  START_SPEAKER_TEST,
} from "@/store/modules/speaker/modules/polls/action-types";
import {
  SET_LAST_NOTIFICATION,
  SET_LAST_SYSTEM_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATIONS,
} from "@/store/modules/notifications/mutation-types";
import {
  BRAINSTORM_IDEA,
  BRAINSTORM_IDEA_LIKE,
  CLOSE_QUIZ,
  CURRENT_TEST,
  NOTIFICATION,
  PRESENTATION,
  RUN_QUIZ,
  SLIDE,
  STREAM,
  TOGGLE_PREMODERATION,
  UPDATE_LEADING,
  RESET_STATE,
  CLEAR_LAST_NOTIFICATION,
} from "@/store/modules/socket/action-types";
import { GET_PRESENTATIONS, SET_SLIDE } from "@/store/modules/presentation/action-types";
import { SET_BROADCAST_STATE } from "@/store/modules/broadcast/action-types";
import {
  BROADCAST_RESET_STATE,
  BROADCAST_SET_STATE_PROP,
} from "@/store/modules/broadcast/mutation-types";
import { SET_PREMODERATION } from "@/store/modules/common/mutation-types";
import SentryLogger from "@/services/logger/SentryLogger";
import router from "@/routes/router";

const sentryLogger = new SentryLogger();

/** link on timeout timer for close video on client when stream is off */
let streamTimeoutTimer = null;
/** timeout in ms for close video on client when stream is off */
const STREAM_TIMEOUT = {
  typical: 181000,
  webRTC: 180000,
};

export default {
  actions: {
    async [SLIDE]({ rootState, dispatch }, data) {
      const { Slide = 1, Presentation = null } = data.obj;

      if (!Presentation) return false;
      if (!rootState.common.roomInfo.Online_room) return false;

      if (rootState.common.roomInfo.Presentation !== Presentation) {
        const hasCurrentPresentation = rootState.presentation.presentations.some(
          pres => pres.id === Presentation,
        );

        if (!hasCurrentPresentation) {
          await dispatch(`${presentation}/${GET_PRESENTATIONS}`, null, {
            root: true,
          });
        }
      }

      const currentPresentation = rootState.presentation.presentations.find(
        el => el.id === Presentation,
      );

      if (currentPresentation?.id === data.obj.Presentation) {
        const { slides } = currentPresentation;
        const slideArray = Object.values(slides);
        if (slideArray[data.obj.Slide - 1].type === "timer") {
          return false;
        }
        dispatch(
          `${presentation}/${SET_SLIDE}`,
          {
            presentationId: Presentation,
            slideIndex: Slide,
          },
          { root: true },
        );
      }
      return false;
    },
    async [PRESENTATION]({ commit, dispatch, rootState }, data) {
      const { Slide = 1, Presentation = null } = data.obj;

      if (!Presentation) return false;
      if (!rootState.common.roomInfo.Online_room) return false;

      if (rootState.common.roomInfo.Presentation !== Presentation) {
        const hasCurrentPresentation = rootState.presentation.presentations.some(
          pres => pres.id === Presentation,
        );

        if (!hasCurrentPresentation) {
          await dispatch(`${presentation}/${GET_PRESENTATIONS}`, null, {
            root: true,
          });
        }
      }

      await dispatch(`${speakers}/${GET_SPEAKERS}`, null, { root: true });
      dispatch(
        `${presentation}/${SET_SLIDE}`,
        {
          presentationId: Presentation,
          slideIndex: Slide,
        },
        { root: true },
      );
      if (rootState.route.name !== "test" && rootState.route.name !== "interactive") {
        commit(
          `${polls}/${CLEAR_POLLCHAIN}`,
          {
            type: interactive,
          },
          { root: true },
        );
        commit(`${polls}/${interactive}/${SET_INTERACTIVE_TYPE}`, null, {
          root: true,
        });
      }
    },
    /**
     * Запуск опроса по вебсокету.
     *
     * @param {*} param0
     * @param {*} data
     */
    [RUN_QUIZ]({ dispatch }, data) {
      dispatch(`${polls}/${RUN_POLL}`, { type: interactive, payload: data }, { root: true });
    },

    /**
     * Событие сокета прихода уведомлений.
     *
     * @param {object} message - объект данных, содержащий информацию об уведомлении
     */
    [NOTIFICATION]({ rootState, commit }, message) {
      // Выносим данные о пользователе в общий объект
      const preparedMessage = {
        ...message.obj,
        ...message.obj?.user,
      };

      // Получаем открытую страницу на странице notifs
      const { page, unreadNotifs } = rootState.notifications;

      // Если выбрана первая страница уведомлений, то вносим изменения в стор
      if (page === 1) {
        // Получаем уведомления из стора
        const arrNotifications = rootState.notifications?.notifications;
        // Добавляем в начало новое уведомление
        arrNotifications?.unshift(preparedMessage);
        // Если уведомлений больше 20 (выводимое число уведомлений), то удаляем последнее
        arrNotifications?.length > 20 && arrNotifications.splice(-1, 1);
        // Вносим в стор новый список уведомлений
        commit(`${notifications}/${SET_NOTIFICATIONS}`, arrNotifications, { root: true });
      }

      // Вносим в необходимое состояние новое уведомление
      preparedMessage.type === 1
        ? commit(`${notifications}/${SET_LAST_SYSTEM_NOTIFICATION}`, preparedMessage, {
            root: true,
          })
        : commit(`${notifications}/${SET_LAST_NOTIFICATION}`, preparedMessage, { root: true });

      // Увеличиваем число непрочитанных уведомлений на 1
      commit(`${notifications}/${SET_UNREAD_NOTIFICATIONS}`, unreadNotifs + 1, { root: true });
    },
    [BRAINSTORM_IDEA_LIKE]({ dispatch }, data) {
      dispatch(`${socket}/${BRAINSTORM_IDEA}`, data, { root: true });
    },

    /**
     * Метод запуска тестов у пользователей по пришедшему вебсокету.
     * Вызывается после метода START_SPEAKER_TEST (src/store/modules/speaker/modules/polls/actions.js)
     *
     * @param {*} param0
     * @param {object} data
     */

    // Данные завязываются на roomInfo и от туда берется id активного теста/опроса
    // В нем данные не обновляются
    [CURRENT_TEST]({ rootState, dispatch }, data) {
      // Запущенный спикером тест и параметр конца теста
      const { currentTest, isStarted } = data.obj;
      if (isStarted) {
        dispatch(`${polls}/${GET_TESTS}`, null, { root: true }).then(() => {
          dispatch(
            `${polls}/${RUN_TEST_POLL}`,
            {
              type: interactive,
              payload: currentTest,
            },
            { root: true },
          );
        });
      } else {
        dispatch(`${socket}/${CLOSE_QUIZ}`, null, { root: true });
        rootState.route.params.testId === currentTest && router.go(-1);
      }
    },

    [STREAM]({ dispatch, commit, rootState }, data) {
      if (data.stream_data.name && !data.stream_data.name.includes(rootState.common.roomNumber))
        return;
      dispatch(`${broadcast}/${SET_BROADCAST_STATE}`, data, { root: true });
      const timeout = STREAM_TIMEOUT[data.stream_data.webRTC ? "webRTC" : "typical"];
      /** timeout for close video on client when stream is off */
      clearTimeout(streamTimeoutTimer);
      if (data.launched) {
        streamTimeoutTimer = setTimeout(() => {
          commit(`${broadcast}/${BROADCAST_RESET_STATE}`, null, {
            root: true,
          });
          sentryLogger.sendException(
            new Error(`There was no pulse from the presenter for ${timeout}`),
          );
        }, timeout);
      } else {
        commit(`${broadcast}/${BROADCAST_RESET_STATE}`, null, {
          root: true,
        });
      }
    },
    [TOGGLE_PREMODERATION]({ commit }, data) {
      commit(`${room}/${SET_PREMODERATION}`, data.state, { root: true });
    },
    [UPDATE_LEADING]({ commit, rootState }, data) {
      const userId = data.obj.user_id;
      if (userId === rootState.auth.user.id) {
        if (!rootState.broadcast.permissions.canPresent && data.obj.permissions.canPresent) {
          localStorage.removeItem("handsUpBtnDisabled");
        }
        commit(
          `${broadcast}/${BROADCAST_SET_STATE_PROP}`,
          {
            handsUpBtnDisabled: !data.obj.permissions.canPresent,
            permissions: data.obj.permissions,
          },
          { root: true },
        );
      }
    },
    [RESET_STATE]({ commit }) {
      commit(`${broadcast}/${BROADCAST_RESET_STATE}`, null, { root: true });
      commit(
        `${broadcast}/${BROADCAST_SET_STATE_PROP}`,
        {
          selectedVideo: {},
          selectedAudio: {},
        },
        { root: true },
      );
    },

    /**
     * Метод очистки обычного уведомления у пользователей.
     *
     * @param {*} root0
     */
    [CLEAR_LAST_NOTIFICATION]({ rootState, commit }) {
      const lastNotification = rootState?.notifications?.lastNotification;

      commit(`${notifications}/${SET_LAST_NOTIFICATION}`, {}, { root: true });
      localStorage.setItem("lastClosedNotif", lastNotification?.id);
      localStorage.setItem("lastClosedNotifPopup", lastNotification?.id);
    },

    /**
     * Метод экстренного завершения теста/опроса.
     *
     * TODO: Есть похожие методы CLOSE_QUIZ и START_SPEAKER_TEST, и
     * методы, которые весят на кнопках остановки тестов/опросов.
     * Мб стоит разобраться и объединить их все.
     *
     * TODO: Была еще проблема с обновлением id Quiz и Test в store roomInfo.
     * Надо проверить
     *
     * @param {*} param
     * @param {object} data - содержит параметр id теста/опроса
     */
    EMERGENCY_TERMINATION({ rootState, dispatch }, data) {
      const { polls } = rootState;
      const voting = polls?.interactive?.chain?.id || polls?.test?.chain?.id;
      const type = polls?.interactive?.chain?.type || polls?.test?.chain?.type;
      const { id: room } = rootState?.route?.params;

      if (+voting === +data?.obj?.id) {
        dispatch(`${socket}/${CLOSE_QUIZ}`, null, { root: true });

        // TODO: Временный костыль, после объединения методов START_SPEAKER_TEST и TOGGLE_QUIZ необходимо
        // заменить на один общий метод.
        if (type === "test")
          dispatch(`${speakerPolls}/${START_SPEAKER_TEST}`, voting, { root: true });
        else dispatch(`${speakerPolls}/${TOGGLE_QUIZ}`, { room, voting }, { root: true });
      }
    },
  },
};
