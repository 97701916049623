import { RESET_PHOTO_WALL, SET_IMAGES } from "@/store/modules/photowall/mutation-types";
import { GET_PHOTO_WALL_IMAGES } from "@/store/modules/photowall/action-types";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import PhotoOnWall from "@/models/PhotoOnWall";

const initialState = () => ({
  photos: [],
  lastImageKey: null,
});

export default {
  state: initialState(),
  mutations: {
    [SET_IMAGES](state, data) {
      const lastItemKey = data[data.length - 1].id;
      if (lastItemKey !== state.lastImageKey) {
        state.lastImageKey = lastItemKey;
        state.photos = [...state.photos, ...data];
      }
    },
    [RESET_PHOTO_WALL](state) {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
  },
  actions: {
    async [GET_PHOTO_WALL_IMAGES]({ rootState, state, commit }) {
      try {
        const { data } = await AdminWebHttpService.get(
          `getPhotowall/${rootState.common.roomNumber}/${state.lastImageKey}`,
        );
        if (data.error) {
          return "complete";
        }
        const photos = data.map(photo => new PhotoOnWall(photo));
        commit(SET_IMAGES, photos);
        return null;
      } catch (e) {
        throw e;
      }
    },
  },
};
