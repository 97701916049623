import {
  TRANSLATION_SET_PROP,
  TRANSLATION_RESET_STATE,
  TOGGLE_STREAM_IS_ACTIVE,
  SET_STREAM_SAFEMODE,
  SET_STREAM_AIR,
  SET_STREAM_IS_ACTIVE,
  SET_STREAM_LOW_LATENCY,
  SET_STREAM_RESOLUTION,
} from "./mutation-types";
import { SET_AVAILABLE_DEVICES } from "@/store/modules/translation/mutation-types";

const defaultState = {
  streamType: null,
  isLowLatency: false,
  isActive: false,
  isMuteSound: false,
  isMuteVideo: false,
  inputVideo: null,
  inputAudio: null,
  streamAir: false,
  streamLowLatency: false,
};

export default {
  [TRANSLATION_SET_PROP](state, { prop, value }) {
    state[prop] = value;
  },
  [TRANSLATION_RESET_STATE](state) {
    Object.keys(defaultState).forEach(key => {
      state[key] = defaultState[key];
    });
  },
  [TOGGLE_STREAM_IS_ACTIVE](state) {
    state.streamIsActive = !state.streamIsActive;
  },
  [SET_STREAM_SAFEMODE](state, status) {
    state.streamSafeMode = status;
  },
  [SET_STREAM_AIR](state, status) {
    state.streamAir = status;
  },
  [SET_STREAM_IS_ACTIVE](state, status) {
    state.streamIsActive = status;
  },
  [SET_STREAM_LOW_LATENCY](state, status) {
    state.streamLowLatency = status;
  },
  [SET_AVAILABLE_DEVICES](state, payload) {
    state.availableDevices = payload;
  },
  [SET_STREAM_RESOLUTION](state, resolution) {
    state.resolution = resolution;
  },
};
