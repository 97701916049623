import { socket } from "@/store/modules/store.namespaces";
import {
  ADD_LEADING,
  CLEAR_LIST_OF_LEADING,
  DELETE_LEADING,
  GET_LIST_OF_LEADING,
  RAISE_HAND,
  SET_BROADCAST_STATE,
  UPDATE_LEADING,
} from "@/store/modules/broadcast/action-types";
import { BROADCAST_SET_STATE_PROP } from "@/store/modules/broadcast/mutation-types";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";

const SOCKET_OBJ_MUTATION_NAME = `${socket}/${SET_SOCKET_OBJ}`;

export default {
  [SET_BROADCAST_STATE]({ commit, state }, { launched, stream_data }) {
    commit(BROADCAST_SET_STATE_PROP, {
      onAir: launched,
      buffering: stream_data.buffering,
      streamName: stream_data.name,
      streamCaptureName: stream_data.captureName,
      streamServer: stream_data.server,
      displayScreenSaver: stream_data.displayScreenSaver,
      speakerState: stream_data.speaker ?? state.speakerState,
      userPlayerType: stream_data?.playerType,
    });
  },
  async [ADD_LEADING]({ commit, rootState, state }) {
    const { user } = rootState.auth;
    const leadingState = {
      user_id: user.id,
      name: user.fio,
      photo: user.photoWithUrl,
      permissions: state.permissions,
    };
    const response = await AdminWebHttpService.post(
      `stream/addLeading/${rootState.common.roomNumber}`,
      leadingState,
    );
    commit(
      SOCKET_OBJ_MUTATION_NAME,
      {
        type: "addLeading",
        obj: leadingState,
      },
      { root: true },
    );
    commit(BROADCAST_SET_STATE_PROP, {
      participants: [...state.participants, leadingState],
    });
    return response;
  },
  async [UPDATE_LEADING]({ commit, rootState }, leadingState) {
    const response = await AdminWebHttpService.post(
      `stream/updateLeading/${rootState.common.roomNumber}`,
      { 
        ...leadingState,
        permissions: leadingState.permissions,
      },
    );
    commit(
      SOCKET_OBJ_MUTATION_NAME,
      {
        type: "updateLeading",
        obj: leadingState,
      },
      { root: true },
    );
    return response;
  },
  async [GET_LIST_OF_LEADING]({ commit, rootState }) {
    const response = await AdminWebHttpService.get(
      `stream/getLeadingList/${rootState.common.roomNumber}`,
    );
    commit(BROADCAST_SET_STATE_PROP, {
      participants: response.data,
    });
    return response.data;
  },
  async [CLEAR_LIST_OF_LEADING]({ rootState }) {
    return AdminWebHttpService.get(`stream/clearAllLeading/${rootState.common.roomNumber}`);
  },
  [RAISE_HAND]({ commit, rootState }) {
    const userId = rootState.auth.user.id;
    commit(
      SOCKET_OBJ_MUTATION_NAME,
      {
        type: "userRaiseHand",
        obj: {
          id: userId,
        },
      },
      { root: true },
    );
    commit(BROADCAST_SET_STATE_PROP, {
      handsUpBtnDisabled: true,
    });
    localStorage.setItem("handsUpBtnDisabled", "true");
  },
  async [DELETE_LEADING]({ commit, rootState, state }, id = null) {
    const userId = id || rootState.auth.user.id;
    if (state.participants.some(p => +p.user_id === +userId)) {
      commit(
        SOCKET_OBJ_MUTATION_NAME,
        {
          type: "deleteLeading",
          obj: {
            id: userId,
          },
        },
        { root: true },
      );
      await AdminWebHttpService.delete(
        `stream/deleteLeading/${rootState.common.roomNumber}/${userId}`,
      );
    }
  },
};
