import {
  SET_ABOUT_WS_ID,
  SET_POLLS,
  SET_TESTS,
} from "@/store/modules/speaker/modules/polls/mutation-types";

export default {
  [SET_POLLS](state, payload) {
    state.polls = payload;
  },
  [SET_TESTS](state, payload) {
    state.tests = payload;
  },
  /**
   * Заполняем aboutWsId для определения является ли запущенный тест опросом о whenspeak
   * @param {Object} state
   * @param {Number} id
   */
  [SET_ABOUT_WS_ID](state, id) {
    state.aboutWsTestId = id;
  },
};
