import {
  RESET_STATE,
  SET_CHAT_ID,
  SET_MESSAGES,
  SET_NEW_MESSAGE,
  SET_OLD_MESSAGES,
  SET_TEAM_CHAT_ID,
  SET_UNDREAD_MESSAGES,
  SET_USER,
} from "@/store/modules/chat/mutation-types";

import Vue from "vue";

export default {
  [SET_USER](state, data) {
    state.user = data;
  },
  [SET_UNDREAD_MESSAGES](state, data) {
    state.unreadMessages = data;
  },
  [SET_OLD_MESSAGES](state, { data, group_id }) {
    const messages = state.messages[group_id];
    const lastOldIDs = messages.slice(0, 21).map(v => v.id);
    const filtered = data.filter(item => !lastOldIDs.includes(item.id));
    if (filtered.length) {
      messages.unshift(...filtered);
    }
  },
  [SET_MESSAGES](state, data) {
    Vue.set(state.messages, data.group, data.data);
  },
  [SET_NEW_MESSAGE](state, data) {
    if (state.messages[data.group_id]) {
      const newMessageID = data.id;
      const messages = state.messages[data.group_id];
      const lastMessagesID = messages.slice(-5).map(v => v.id);
      if (lastMessagesID.includes(newMessageID)) {
        return;
      }

      messages.push(data);
    }
  },
  [SET_CHAT_ID](state, data) {
    state.roomChatId = data;
  },
  [SET_TEAM_CHAT_ID](state, data) {
    state.teamChatId = data;
  },
  [RESET_STATE](state) {
    Object.keys(state.user).forEach(k => delete state.user[k]);
    Object.keys(state.messages).forEach(k => delete state.messages[k]);
    state.unreadMessages = 0;
    state.roomChatId = false;
    state.teamChatId = false;
  }
};
