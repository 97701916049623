import {
  SET_AVERAGE_TEAM_SCORE,
  SET_BU_TEAMS_SCORE,
  SET_DASHBOARD_LOADER,
  SET_GOD_TOP_MANAGERS,
  SET_GOD_TOP_TEAMS,
  SET_TEAM_SCORE,
  SET_USER_DASHBOARD_INFO,
  SET_USER_LINE_ACTIVE,
  SET_USER_SCORE,
} from "@/store/modules/dashboard/mutation-types";

export default {
  [SET_GOD_TOP_MANAGERS](state, data) {
    state.godDashBoardBUTopManagers = data;
  },
  [SET_USER_DASHBOARD_INFO](state, data) {
    state.userDashboardInfo = data;
  },
  [SET_USER_SCORE](state, data) {
    state.userDashBoardMyScore = data;
  },
  [SET_AVERAGE_TEAM_SCORE](state, data) {
    state.userDashBoardAvgTeamScore = data;
  },
  [SET_TEAM_SCORE](state, data) {
    state.userDashBoardMyTeamScore = data;
  },
  [SET_USER_LINE_ACTIVE](state, data) {
    state.userDashBoardUserLineActive = data;
  },
  [SET_BU_TEAMS_SCORE](state, data) {
    if (!Array.isArray(data)) {
      state.userDashBoardBUTeamsScore = data;
    }
  },
  [SET_GOD_TOP_TEAMS](state, data) {
    state.godDashboardBUTopTeams = data;
  },
  [SET_DASHBOARD_LOADER](state, data) {
    state.loading = data;
  },
};
