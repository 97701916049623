import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    presentations: [],
    currentPresentationInfo: {
      id: null,
      slideIndex: null,
      userSelectedSlideIndex: null,
      start: 0,
    },
    playYoutubeVideo: false,
    slideVideoTime: 0,
    changeSlideIsBusy: false,
  },
  getters,
  actions,
  mutations,
};
