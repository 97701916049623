import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
// todo rename me after refactoring state
const currentLanguage = localStorage.getItem("interfaceLang") || "rus";

export default {
  state: {
    roomPending: false,
    faq: [],
    sidebar: {
      expanded: true,
      fullscreen: false,
    },
    userPopupVisible: false,
    timer: {
      seconds: 0,
    },
    clock: {
      timestamp: 0,
    },
    interfaceLanguage: currentLanguage,
    // Параметр, содержащий основную информацию о комнате
    roomInfo: {
      miro: {
        boardId: "",
      },
    },
    roomNumber: "",
    roomId: "",
    activeResultId: 0,
    styleColors: {
      defaultColor: "#f5f5f5",
      baseColor: "#f79843",
      extraColor: "#f9ac44",
      baseAccentColor: "#f05735",
      secondaryColor: "#B5E4F5",
      positiveColor: "#7CDE10",
      negativeColor: "#e90e42",
      baseTextColor: "#353535",
      muteTextColor: "#898989",
      baseBorderColor: "#D5D5D5",
      scrollBar: "#ebebeb",
      scrollThumb: "#a4a4a4",
      buttonTextColor: "#373737",
      buttonActiveTextColor: "#fff",
      buttonDefaultTextColor: "#acacac",
      baseBgColor: "#ffe4bf",
      systemBgNotify: "#0670b8",
    },
    styleUrls: {
      roomTopLogo: null,
    },
    neuroStatus: false,
    modules: {
      pdfReportModule: false,
      downloadPhotoWallModule: false,
      sortPresentationModule: false,
      downloadMaterialsModule: false,
    },
    loggers: {
      materialsLogger: false,
      streamLogger: false,
      userPresenceLogger: false,
      videoLogger: false,
    },
    streamLang: "RUS",
  },
  getters,
  actions,
  mutations,
};
