import {
  SET_INTERACTIVE_TYPE,
  SET_PREVIOUS_ROUTE,
} from "@/store/modules/polls/mutation-types";

export default {
  state: {
    chain: null,
    activePoll: null,
    sendingStatus: false,
    chainScore: null,
    interactiveType: null,
    previousRoute: null,
    showResultAsCommon: false,
    results: {},
    currentResultId: "",
  },
  mutations: {
    [SET_INTERACTIVE_TYPE](state, payload) {
      state.interactiveType = payload;
    },
    [SET_PREVIOUS_ROUTE](state, payload) {
      state.previousRoute = payload;
    },
  },
};
