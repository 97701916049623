<script>
import Color from "color";
import { mapActions, mapGetters, mapState } from "vuex";
import { landing, room } from "@/store/modules/store.namespaces";
import {
  BASE_FADE_COLOR,
  BASE_SHADOW_COLOR,
  HAS_CORP_LANDING,
  LIGHTER_BASE_COLOR,
  LOGIN_FORM_COLOR,
  MANAGER_LOGIN_AVAILABLE,
  SYSTEM_FADE_COLOR,
} from "@/store/modules/landing/getter-types";
import { GET_TEMPLATE } from "@/store/modules/landing/action-types";
import { hidePreloader } from "@/utils";

export default {
  name: "ThemeProvider",
  data() {
    return {
      pending: true,
    };
  },
  computed: {
    ...mapState(room, ["styleColors"]),
    ...mapGetters(landing, {
      baseShadowColor: BASE_SHADOW_COLOR,
      baseFadeColor: BASE_FADE_COLOR,
      systemFadeColor: SYSTEM_FADE_COLOR,
      lighterBaseColor: LIGHTER_BASE_COLOR,
      loginForm: LOGIN_FORM_COLOR,
      hasCorpLanding: HAS_CORP_LANDING,
      managerLoginAvailable: MANAGER_LOGIN_AVAILABLE,
    }),
    ...mapState(landing, {
      landingTemplate: "template",
    }),
    styles() {
      const baseColorDarken = Color(this.styleColors.baseColor).darken(0.2);
      return {
        "--default-color": this.styleColors.defaultColor,
        "--base-color": this.styleColors.baseColor,
        "--base-color_drkn": `${baseColorDarken}`,
        "--base-accent-color": this.styleColors.baseAccentColor,
        "--base-bg-color": this.styleColors.extraColor,
        "--base-text-color": this.styleColors.baseTextColor,
        "--positive-color": this.styleColors.positiveColor,
        "--negative-color": this.styleColors.negativeColor,
        "--mute-text-color": this.styleColors.muteTextColor,
        "--secondary-color": this.styleColors.secondaryColor,
        "--base-shadow-color": this.baseShadowColor,
        "--base-fade-color": this.baseFadeColor,
        "--system-fade-color": this.systemFadeColor,
        "--button-text-color": this.styleColors.buttonTextColor,
        "--base-button-active-color": this.styleColors.buttonActiveTextColor,
        "--default-button-color": this.styleColors.buttonDefaultTextColor,
        "--base-border-color": this.styleColors.baseBorderColor,
        "--base-color-lighter": this.lighterBaseColor,
        "--scroll-bar-color": this.styleColors.scrollBar,
        "--scroll-thumb-color": this.styleColors.scrollThumb,
        ...this.buttons,
      };
    },
    buttons() {
      const defaultColorHSL = Color(this.styleColors.defaultColor).hsl();
      const baseColorHSL = Color(this.styleColors.baseColor).hsl();
      const accentColorHSL = Color(this.styleColors.baseAccentColor).hsl();

      return {
        "--ws-color-default": defaultColorHSL.string(),
        "--ws-color-default--active": defaultColorHSL.darken(0.06).string(),
        "--ws-color-primary": baseColorHSL.string(),
        "--ws-color-primary--active": baseColorHSL.darken(0.06).string(),
        "--ws-color-primary-light": baseColorHSL.lighten(0.3).string(),
        "--ws-color-primary-light--active": baseColorHSL
          .lighten(0.3)
          .darken(0.06)
          .string(),
        "--ws-color-accent": accentColorHSL.string(),
        "--ws-color-accent--active": accentColorHSL.darken(0.06),
      };
    },
  },
  async created() {
    await this.getLandingByDomain();
    await this.handleCorporateLogin();
    await this.$nextTick();
    this.pending = false;
    await this.$nextTick();
    hidePreloader();
  },
  methods: {
    ...mapActions(landing, {
      getLandingByDomain: GET_TEMPLATE,
    }),
    async handleCorporateLogin() {
      const routeNames = ["Login", "Root"];
      const isOwner = this.$route.query.owner && this.managerLoginAvailable;
      const isGuest =
        this.$route.query.guest || this.$route.query.token || this.$route.meta.isGuest;
      if (this.hasCorpLanding && routeNames.includes(this.$route.name) && !isGuest && !isOwner) {
        await this.$router.push({ name: "corpLogin" });
      }
    },
  },
  render(h) {
    if (!this.pending) {
      const [root] = this.$slots.default;
      return h(root.tag, { ...root.data, style: this.styles }, root.children);
    }
    return h();
  },
};
</script>
