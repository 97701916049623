export const GET_GAMES = "GET_GAMES";
export const RUN_MOUNTAIN_KING = "RUN_MOUNTAIN_KING";
export const GET_TOP_USERS = "GET_TOP_USERS";
export const GET_SCORES = "GET_SCORES";
export const GAME_POLL_STEP_FORWARD = "GAME_POLL_STEP_FORWARD";
export const SKIP_GAME_QUESTION = "SKIP_GAME_QUESTION";
export const GO_TO_SKIPPED_QUESTION = "GO_TO_SKIPPED_QUESTION";
export const GAME_OVER = "GAME_OVER";
export const GAME_OVER_BY_TIME = "GAME_OVER_BY_TIME";
export const SEND_MOUNTAIN_KING_ANSWER = "SEND_MOUNTAIN_KING_ANSWER";
export const GET_KING_RESULT = "GET_KING_RESULT";
