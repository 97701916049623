import {
  AVAILABLE_SORT_PRESENTATIONS,
  PRESENTATIONS,
} from "@/store/modules/speaker/modules/presentation/getter-types";
import { presentation, room } from "@/store/modules/store.namespaces";
import { makeUrl } from "@/utils";
import {
  SLIDE_PREVIEW_IMG_SIZE,
  SLIDE_TYPE,
  TYPES_WITHOUT_SRC,
} from "@/constants/presentation/slide-const";
import YoutubeUrlService from "@/Utils/VideoServicesUrlSevice";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";

/**
 * Подготовливает слайд для компонента представления страницы презентаций
 *
 * @param {object} slide - исходный объект слайда
 * @returns {{src: string, id, type}} - готовый объект слайда
 */
export function prepareSlide(slide) {
  let src = makeUrl(slide.path);
  if (slide.type === SLIDE_TYPE.video) {
    src =
      slide.video_url.includes("youtu") && !slide.video_slide_preview
        ? YoutubeUrlService.getYoutubeImgPreviewUrl(slide.video_url)
        : `${makeUrl(slide.video_slide_preview)}`;
  }
  if (TYPES_WITHOUT_SRC.includes(slide.type)) {
    src = "";
  }
  return {
    id: slide.id,
    type: slide.type,
    src,
  };
}

export default {
  /**
   * Подготовленные презентации для компонента представления страницы презентаций
   *
   * @param {object} state
   * @param {object} getters
   * @param {object} rootState
   * @returns {Array} - список презентаций готовый для представления
   */
  [PRESENTATIONS](state, getters, rootState) {
    return rootState[presentation].presentations.map(presentation => ({
      id: presentation.id,
      name: presentation.name,
      slides: presentation.slidesArray.map(prepareSlide),
    }));
  },
  /**
   * Доступен ли модуль сортировки презентаций на спикере
   *
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns {boolean|*}
   */
  [AVAILABLE_SORT_PRESENTATIONS](state, getters, rootState, rootGetters) {
    return rootGetters[`${room}/${AVAILABLE_ROOM_MODULES}`].sortPresentationModule;
  },
};
