<template>
  <theme-provider>
    <main id="root" class="app">
      <router-view />

      <simple-modal
        v-if="showSupportModal"
        :custom-width="$mqMobile ? undefined : '600px'"
        @close="closeSupportModal()"
      >
        <div slot="header">{{ $t("techSupport.getSupport") }}</div>
        <div slot="body" class="sup-modal-body">
          <support-form :user-name="userFio" :user-mail="userEmail" />
        </div>
      </simple-modal>

      <system-notifications group="support" position="top center" />
    </main>
  </theme-provider>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import { CLOSE_SUPPORT_MODAL, SET_CURRENT_MEDIA_QUERY_KEY } from "@/store/mutations/mutation-types";
import { auth } from "@/store/modules/store.namespaces";
import ThemeProvider from "@/components/common/theme/ThemeProvider";
import SystemNotifications from "@/components/common/notifications/SystemNotifications";

const SimpleModal = () =>
  import(/* webpackChunkName="AppSimpleModal" */ "@/components/event/layouts/modals/SimpleModal");
const SupportForm = () =>
  import(/* webpackChunkName="AppSupportForm" */ "@/components/event/SupportForm");

export default {
  name: "RootApp",
  components: {
    ThemeProvider,
    SystemNotifications,
    SimpleModal,
    SupportForm,
  },
  data() {
    return {
      $pageLinks: [],
    };
  },

  test: 1,

  computed: {
    ...mapState(["showSupportModal"]),
    ...mapState(auth, ["user"]),
    userFio() {
      return this.user.fio;
    },
    userEmail() {
      return this.user.email;
    },
  },
  watch: {
    $mq(val) {
      this.setCurrentMediaQuery(val);
    },
  },
  created() {
    this.setCurrentMediaQuery(this.$mq);
  },
  mounted() {
    if (this.$isCordova) {
      setInterval(() => {
        this.handleLinkForCordova();
      }, 2000);
    }
  },
  methods: {
    ...mapMutations({
      closeSupportModal: CLOSE_SUPPORT_MODAL,
      setCurrentMediaQuery: SET_CURRENT_MEDIA_QUERY_KEY,
    }),
    handleLinkForCordova() {
      this.removeLinksListeners();
      this.$pageLinks = document.querySelectorAll("a[target='_blank']");
      this.$pageLinks.forEach(el => {
        el.setAttribute("download", "download");
        el.addEventListener("click", this.linkClickHandler);
      });
    },
    linkClickHandler(e) {
      let link = e.target;
      if (link.tagName !== "A") {
        link = e.target.closest("a");
      }
      if (link && link.tagName === "A") {
        const hrefIsPDA = link.href.includes("#/personal-data-agreement");
        if (hrefIsPDA) {
          const hrefParts = link.href.split("/");
          link.href = `https://${process.env.VUE_APP_LANDING_DOMAIN}/${
            hrefParts[hrefParts.length - 1]
          }`;
          return;
        }
        e.preventDefault();
        window.open(link.href, "_system");
      }
    },
    removeLinksListeners() {
      if (this.$pageLinks && this.$pageLinks.length) {
        this.$pageLinks.forEach(el => {
          el.removeEventListener("click", this.linkClickHandler);
        });
      }
    },
  },
};
</script>

<style lang="scss">
.app {
  position: relative;
  z-index: 1;
  height: 100%;
}

.white-text {
  color: var(--base-button-active-color);
}

.login-button_border {
  color: #353535;
  border: 1px solid var(--base-color);
  border-radius: 8px;
  transition: all ease 0.3s;

  &:not(.filled) {
    &:hover {
      color: var(--base-color);
    }
  }
}

.button.filled {
  color: var(--base-button-active-color);
  background: var(--base-color);
}

.main-border {
  border-color: var(--base-color);
}

.vjs-error-display {
  &::before {
    display: none;
  }
}

.vjs-modal-dialog-content {
  color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.app .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);

  .vjs-icon-placeholder::after {
    position: absolute;
    top: -89px;
    left: -109px;
    box-sizing: border-box;
    display: block;
    width: 304px;
    padding: 14px;
    font-family: "ProximaNova", sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    content: attr(data-content);
    border-radius: 13px;
  }
}

.sup-modal-body {
  padding: 10px;
}

.nav-arrow {
  &--back {
    background-image: linear-gradient(to left, var(--base-color-lighter), var(--base-color));
  }

  &--forward {
    background-image: linear-gradient(to right, var(--base-color-lighter), var(--base-color));
  }
}

.button {
  color: var(--base-color);
  border: 1px solid var(--base-border-color);

  &-active {
    border: 1px solid var(--base-color);
  }

  &[disabled="disabled"]:hover {
    box-shadow: none;
  }

  &:hover {
    box-shadow: 0 0 4px #999;
  }
}
</style>
