import { BUSINESS_UNIT } from "@/store/modules/dashboard/getter-types.js";

export default {
  [BUSINESS_UNIT](state) {
    if (!state.userDashboardInfo.business_unit) {
      return [];
    }
    return state.userDashboardInfo.business_unit.replace(/\s/g, "").split(",");
  },
};
