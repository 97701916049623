import {
  CLEAR_POLLCHAIN,
  SET_ACTIVE_POLL,
  SET_POLL_CHAIN,
  SET_POLLCHAIN_SCORE,
  SET_POLL_SCORE,
  SET_SENDING_STATUS,
  SET_IS_BLOCK_CLOSE_POLL,
  CHAIN_STEP_FORWARD,
  SET_SHOW_RESULT_AS_COMMON,
  SKIP_TEST_POLL,
  SET_POLLCHAIN_DATA_TIMER,
  SET_TEST_RESULTS,
  SET_CURRENT_RESULT_ID,
  SET_WS_POLL_AVAILABLE,
  SET_WS_POLL_COMPLETE,
  SET_WS_POLL_MODAL_OPEN,
  SET_TESTS,
  SET_INTERACTIVE_THEME,
  RESET_INTERACTIVE_THEME,
  SET_INTERACTIVE_BACKGROUND,
  RESET_INTERACTIVE_BACKGROUND,
  SET_CHEAT_POINTS,
} from "@/store/modules/polls/mutation-types";

export default {
  /**
   * @param state {object} - current module state
   * @param type {string} - must be presentation, interactive, test
   * @param payload {object} - object from backend API
   */
  [SET_POLL_CHAIN](state, { type, payload }) {
    state[type].chain = {
      timerOut: payload.timer,
      ...payload,
    };
  },
  [SET_ACTIVE_POLL](state, { type, payload }) {
    state[type].activePoll = payload;
  },
  [SET_POLLCHAIN_SCORE](state, { type, payload }) {
    state[type].chainScore = payload;
  },
  [SET_POLL_SCORE](state, { type, payload }) {
    state[type].score = payload;
  },
  [SET_SENDING_STATUS](state, { type, payload }) {
    state[type].sendingStatus = payload;
  },
  [CLEAR_POLLCHAIN](state, { type, force = false }) {
    if (!state[type].chain) return;
    state[type].chain =
      state[type].chain.testIsEndless && !force
        ? {
            testIsEndless: state[type].chain.testIsEndless,
          }
        : {};
  },
  [SET_IS_BLOCK_CLOSE_POLL](state, payload) {
    state.isBlockClosePoll = payload;
  },
  [CHAIN_STEP_FORWARD](state, { type, skipQuestion }) {
    if (!skipQuestion) {
      state[type].chain.step += 1;
    } else {
      state[type].activePoll = state[type].chain.polls[state[type].chain.step + 1];
      state[type].chain.polls.splice(state[type].chain.step, 1);
    }
  },
  [SET_SHOW_RESULT_AS_COMMON](state, { type, payload }) {
    state[type].showResultAsCommon = payload;
  },
  [SKIP_TEST_POLL](state, type) {
    state[type].activePoll = state[type].chain.polls[state[type].chain.step + 1];
    // баг. так как вопросы приходят по одному то хранение пропущеных вопросов приводит к дублированию
    // а если убрать дублирование то на бэке кончаются вопросы и завершается тест, хотя еще есть вопросы на фронте
    state[type].chain.polls.splice(state[type].chain.step, 1);
  },
  [SET_POLLCHAIN_DATA_TIMER](state, type) {
    const timerTest = state[type].chain.timer;
    if (state[type].chain.time_started) {
      state[type].chain.timerOut =
        timerTest - (Math.round(Date.now() / 1000) - state[type].chain.time_started);
    }
  },
  [SET_TEST_RESULTS](state, { type, payload }) {
    state[type].results = {
      [payload.id]: payload.data,
    };
  },
  [SET_CURRENT_RESULT_ID](state, { type, payload }) {
    state[type].currentResultId = payload;
  },
  [SET_WS_POLL_AVAILABLE](state, available) {
    state.wsPollAvailable = available;
  },
  [SET_WS_POLL_COMPLETE](state, complete) {
    state.wsPollComplete = complete;
  },
  [SET_WS_POLL_MODAL_OPEN](state, open) {
    state.wsPollModalOpen = open;
  },
  [SET_TESTS](state, payload) {
    state.tests = payload;
  },
  [SET_INTERACTIVE_THEME](state, theme) {
    state.interactiveTheme = theme;
  },
  [RESET_INTERACTIVE_THEME](state) {
    state.interactiveTheme = null;
  },
  [SET_INTERACTIVE_BACKGROUND](state, bgImgUrl) {
    state.interactiveThemeBg = bgImgUrl;
  },
  [RESET_INTERACTIVE_BACKGROUND](state) {
    state.interactiveThemeBg = null;
  },
  [SET_CHEAT_POINTS](state, payload) {
    state.isCheatPoints = payload;
  },
  /**
   * Изменение состояния ограничения на отправку новых ответов на вопрос.
   *
   * @param {object} state
   * @param {boolean} payload - состояние отправки ответа на вопрос.
   */
  changeSendingStatus(state, payload) {
    state.sendNewAnswerStatus = payload;
  },
};
