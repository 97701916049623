import ControlHttpService from "@/services/http/ControlHttpService";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import {
  GET_ROOMS,
  GET_SPEAKER_STATE,
  SEND_NOTIFICATION,
  SET_TIMER,
} from "@/store/modules/speaker/action-types";
import { SET_ROOMS } from "@/store/modules/speaker/mutation-types";
import { pushNotification } from "@/services/OnesignalService";

export default {
  /**
   * Сохранение в памяти контрола состояния комнаты для дальнейшей работы с комнатой
   *
   * @param _context
   * @param {number} room - номер комнаты
   * @returns {Promise<*>} - выполнена комнанда или нет
   */
  [GET_SPEAKER_STATE](_context, room) {
    return ControlHttpService.get(`state/${room}`);
  },
  /**
   * Запрос комнат
   *
   * @param {object} context
   * @param {Function} context.commit
   * @returns {Promise<[]>} - список комнат
   */
  async [GET_ROOMS]({ commit }) {
    const { data } = await AdminWebHttpService.get(`${AdminWebHttpService.token}/getRooms`);
    const rooms = data.map(room => ({
      name: room.name.replace(/#/, "\n"),
      number: room.number,
      id: room.id,
      roomNumber: `#${room.number}`,
    }));
    commit(SET_ROOMS, rooms);
    return rooms;
  },
  /**
   * Запуск таймера в комнате
   *
   * @param _context
   * @param data
   * @param {number} data.room - номер комнаты
   * @param {number} data.timer - значение для таймера в минутах
   */
  [SET_TIMER](_context, { room, timer }) {
    ControlHttpService.post(`setTimer/${room}`, {
      timer: parseInt(timer, 10),
    }).catch(() => {
      console.error("Не удалось запустить таймер");
    });
  },
  /**
   * Отправка уведомления в комнату
   *
   * @param _context
   * @param {object} data
   * @param {number} data.room - номер комнаты
   * @param {string} data.notification - сообщение
   * @param {number} data.type - тип уведомления(важное|обычное)
   * @returns {Promise<unknown>}
   */
  async [SEND_NOTIFICATION](_context, { room, notification, type, user }) {
    try {
      const res = await ControlHttpService.post(`setNotifications/${room}`, {
        notification,
        user,
        type: type || 0,
      });
      return res;
    } finally {
      if (type === 1) {
        pushNotification({
          message: notification,
          user,
          roomId: room,
        });
      }
    }
  },
};
