export default {
  browser: {
    greetings:
      "Your system does not meet the technical requirements. WhenSpeak functions work incorrectly!",
    error_slow_connect: "Connection too slow",
    error_old_version: "Outdated browser version",
    error_not_support: "Browser not supported",
    error_high_ping: "High ping",
    understand: "I understand",
  },
  messages: {
    common: {
      please_wait: "Please, wait...",
      list_empty: "Presentation list is empty",
    },
  },
  common: {
    version: "Version: 1.17",
    guest: "Guest",
    back: "Back",
    online: "Online",
    today: "today",
    allDay: "all days",
    myPoints: "My points",
    myTeam: "My team",
    teamRate: "Team rating",
    participantRate: "Participant rate",
    send: "Send",
    search: "Search",
    button: "Button",
    of: "of",
    for: "for",
    videoText: "To play the video, click on Play",
    links: "Links",
    files: "Files",
    delete: "Delete",
    msgSendError: "an error occurred while sending the message",
    msgSendSucc: "message sent",
    chat: "Chat",
    questions: "Questions",
    sorting: "Sorting",
    close: "Close",
    averageValue: "Average value",
    tapeActivity: "Activity tape",
    total: "Total",
    cancel: "Cancel",
    division: "Select division",
    error: "Error",
    filesLimit: "You can upload no more than 10 photos.",
    okText: "Ok!",
    visitWebsite: "Visit the website ",
    enterTheNumber: " and enter the number",
    update: "Update",
    notFound: "Oops...",
    allPresentation: "All presentation",
    sortByName: "By name",
    sortByDate: "By date",
    downloadAll: "Download all",
    downloadAllShort: "all",
    downloadSelected: "Download selected",
    downloadSelectedShort: "selected",
    timeInDate: "in",
    accept: "Accept",
    hide: "Hide",
    confirm: "Confirm",
    required: "Please, fill the field",
    download: "Download",
    login: "Login",
  },
  App: {
    thxQuestion: "Thank you for your question!",
    descQuestion: "After being moderated, <br> it will appear in the general list.",
  },
  Login: {
    login: "Log in",
    loginAgain: "Log in again",
    register: "Register",
    guestLogin: "Guest log in",
    password: "Password",
    yourLogin: "Your login",
    email: "E-mail",
    loginEmail: "Login / E-mail",
    name: "Name",
    secondName: "Second name",
    surname: "Surname",
    emailError: "enter a valid email",
    passwordError: "enter password",
    nameError: "enter name",
    restorePassword: "Restore password",
    processingPersonData: "I accept the terms of",
    processingPersonDataLink: "Personal Data Processing Policy",
    processingPersonDataERR: "check the box",
    controlPanel: "Control panel",
    socialEnter: "Enter via Social network",
    authDeclinedError:
      "User authorization failed.<br>Verify that the information provided is correct.",
    socialVkontakte: "VK",
    socialFaceBook: "FaceBook",
    socialOdnoklassniki: "OK.ru",
    socialGmail: "Google mail",
    restorePasswordRestriction: "You can only change the password after {0} minutes",
    emptyValue: "value not set",
  },
  Registration: {
    email: "E-mail",
    fio: "Full name",
    password: "Password",
    repeatPassword: "Repeat password",
    registration: "Register",
    login: "I already have an account",
    emailFormat: "Incorrect format",
    emailError: "value is incorrect",
    emailExistError: "mail is already registered",
    fioExistError: "Such name and surname are already in use",
    fioError: "value less than 4 characters",
    processingPersonData: "Consent to the processing of personal data",
    processingPersonDataERR: "check the box",
    passwordError: "value less than 4 characters",
    rePasswordError: "Passwords do not match",
  },
  Breed: {
    title: "Choose your room",
    listNoRooms: "Rooms not listed",
  },
  RestorePassword: {
    sendNewPass: "Send new password",
    cancel: "Cancel",
    changePassword: "Change password",
  },
  Lobby: {
    title: "Enter the room number",
    roomNumber: "room number",
    logout: "Log out",
    errorRoom: "Room is not available",
    unavailableToGuest: "Room unavailable to guests",
    errorUnexpected: "Something went wrong",
  },
  Presentation: {
    back: "Back",
    empty: "Slides not uploaded",
    startError: "Failed to start presentation",
    videoUnavailable: "Video unavailable",
  },
  PresentationButton: {
    externalVideo: "Click to open backup video in new window",
    like: "like",
    tightened: "tightened",
    unclear: "unclear",
    dislike: "dislike",
  },
  Room: {
    videoNotifError: "No Broadcast",
    backupTranslMode: "Click to switch to backup broadcast in case of problems with video",
    mainTranslMode: "Switch to main stream",
    mainChatList: "Chat",
    teamChatList: "Team",
    speakerQuestions: "Questions",
    noActivePresentations: "Speaker doesn't activate presentation or it's loading",
    rotateYourMobileVertically: "Rotate the phone vertically",
    andWorkAgain: "to continue working",
    title: {
      menu: "Menu",
      closeSidePanel: "Hide side panel",
      openSidePanel: "Open side panel",
    },
  },
  ChartList: {
    guest: "Guest",
    writeFirst: "Write your message first!",
    anonym: "Anonym",
  },
  SpeakerQuestions: {
    errorQuestions: "Error loading questions",
    noQuestions: "No questions yet",
    askFirst: "Be the first to ask your question!",
    askAQuestion: "Ask a Question",
    askQuestion: "Write a message",
    autoUpdate: "Real-time sorting",
    moderate: "Pre-moderate",
    answered: "Answered",
    answeredVerbally: "Answered verbally",
    answeredInWriting: "Written answer",
    hiddenQuestions: "Hidden question",
    sortByLikes: "by likes",
    sortByTime: "by time",
    displayAllQuestions: "All questions",
    displayAnsweredQuestions: "Answered questions",
    dialogTitle: "Question status",
    dialogBody: 'Cancel the status "Answered verbally"?',
    dialogOkText: "Yes, cancel",
    dialogCancelText: "No",
    thanksForQuestion: "Thanks for your question!",
    questionModerate: "Your question will appear after approval by the moderator",
    fullScreenQuestions: "Full screen questions",
    title: {
      enterFullscreen: "Enter full screen mode",
      exitFullscreen: "Exit full screen mode",
      speakerAnswer: "Answer:",
      managerAnswer: "Manager answer:",
    },
    error: {
      fetchQuestions: "There was an error loading questions",
    },
    answerModal: {
      title: "Answer to the question",
      question: "Question:",
      questionAnswer: "Question answer:",
      submitButton: "Answer",
    },
    questionItem: {
      speakerAnswer: "Speaker answer:",
      managerAnswer: "Manager answer:",
    },
    notifs: {
      answer: {
        success: {
          title: "Success",
          text: "The answer was saved.",
        },
        error: {
          title: "Error",
          text: `An error occurred while saving the question. Please try to answer the question later or contact the administrator.`,
        },
      },
    },
  },
  Messenger: {
    startTyping: "Start typing...",
    textWrite: "Enter the text ...",
    anonymous: "Anonymous",
  },
  Agenda: {
    mowTime: "ATTENTION: the Moscow time is indicated in the schedule",
    rateTeam: "Rate tream",
    ratingLimit: "Your rating limit",
    allWerePresent: "All were present",
    clearFormTitle: "The form of clearing the team's points.",
    managerLoginPlaceholder: "Login of the regional manager",
    managerClearButton: "Clear the manager's ratings",
    clearFormConfirmation: "Are you sure want to clear the team's points?",
    clearFormApprove: "Clear",
  },
  Tests: {
    testGroup: "Test",
    gameGroup: "Game",
    voting: "Voting",
    not_started: "Active",
    started: "Launched",
    finished: "Archive",
    startPoll: "Start",
    stopPoll: "Stop",
    hideResults: "Hide",
    showResults: "Show results",
    backToList: "Back",
    defaultBar: "Select a POLL from the list on the left to see its parameters.",
    errorStopPrevPoll: "To launch a new vote, stop running",
    errorStopPoll: "Now the user sees the vote",
    stopped: "stopped",
    polls: "Polls and tests",
    votes: "Votes",
    progress: "Started",
    notSelect: "Nothing is selected",
    downloadResult: "Download result",
    timerRepeatNote: "Repeated passage will be available through",
    results: "Results",
    stopTest: "Stop test",
    startTest: "Start test",
    emptyVoting: "No polls have been created for this presentation yet.",
    emptyTests: "No tests have been created for this presentation yet.",
    noAnswer: "You missed this question.",
    noQuestion: "There is no question",
    testTimerNotification: "When you click on the test, the timer turns on automatically.",
    testIsLoading: "Please wait while the test is loading",
    notAnswered: "You have not answered this question",
    maxLength: "Maximum number of characters:",
    notFilled: "Name not specified",
    testsHeading: "Tests / Polls",
    testWithTimer: "Timer test",
    pressToShowResults: "To view the results, click the button.",
    timeExpired: "Time is up",
    repeatedTest: "The question has been reissued.",
    repeatedTestDescription: "The question was reissued as it was changed after your answer.",
    yourAnswer: "Your Answer:",
    postponeQuestion: "Postpone the question",
    hasNoOneAnswer: "You did not answer the question",
    PollTestResults: "Overall test results",
    PollResults: "Overall voting results",
    activityType: "Activity type:",
    youPassed: "You have already completed this survey",
  },
  Photowall: {
    errorImages: "Error loading photos",
    noImages: "The organizer has not yet added photos",
    loadImgError: "Photo upload failed",
  },
  Notifs: {
    noNotifs: "No new notifications",
    at: "at",
    attention: "ATTENTION! NOTIFICATION!",
    notification: "Notification",
  },
  AverageTeamPoints: {
    avg: "Team average score",
  },
  TeamRating: {
    place: "place of",
  },
  Practice: {
    title: "Choose a regional manager to go to his virtual room",
    noVirtualRooms: "The list of virtual rooms is empty",
  },
  TeamTable: {
    atotal: "Total",
    avg: "Average value",
    presence: "Presence",
    polls: "Polls",
    questions: "Questions",
    testing: "Testing",
    practice: "Practice",
    photoGame: "Photo / Game",
    total: "Total",
    additional: "Additional",
    rewards: "Rewards",
  },
  ManagerPoints: {
    presence: "Presence",
    polls: "Polls",
    questions: "Questions",
    testing: "Testing",
    practice: "Practice",
    photoGame: "Photo / Game",
  },
  ManagerTopManager: {
    place: "place",
    points: "p",
    topManagers: "TOP executives",
  },
  Materials: {
    emptyFolderFile: "No files or folders",
    back: "Back",
    name: "name",
    dateUpdate: "update date",
  },
  About: {
    name: "Your name",
    phone: "your phone",
    message: "Your message",
    text:
      // eslint-disable-next-line max-len
      "<p><b>web-based collaboration platform for events and corporate education.</b></p><p>Meetings, lectures, forums, trainings, seminars, conferences are held on the platform.\nLearn how to make your event interactive!\nLeave your contacts and we will call you back within 1 working day.</p>",
  },
  userPopup: {
    changePhoto: "Change photo",
    loggedAsGuest: "You are logged in as guest",
    moreInfoAt: "More info at",
    logout: "Log out",
    feedback: "Feedback",
    addPhoto: "Add photo",
    chooseFromLibrary: "Choose from library",
    makePhoto: "Make photo",
    skip: "Skip",
    loggedAsSpeaker: "You enter as speaker",
    toProfile: "To your personal account",
  },
  techSupport: {
    writeToSupportInline: "Write to support",
    techSupport: "Support",
    techSupportShort: "Support",
    writeToSupport: "Write to<br>support service",
    fullName: "Full name",
    email: "Email",
    subject: "Subject",
    content: "Content",
    send: "Send",
    getSupport: "Get support",
    allFieldsShouldBeForfilled: "All fields should be forfilled",
    addFiles: "add files",
    appealAccepted: "Your appeal has been accepted!",
    appealNotAccepted: "The appeal was not sent! Please try again in a few minutes.",
  },
  navigation: {
    rooms: "Change room",
    presentation: "Presentation",
    allPresentations: "All presentations",
    photowall: "Photowall",
    questions: "Questions",
    roundedTable: "Rounded table",
    messenger: "Chat",
    video: "Video",
    schedule: "Agenda",
    testsAndGames: "Polls",
    materials: "Materials",
    participants: "Participants",
    partners: "Partners",
    notifications: "Notifications",
    results: "Results",
    aboutUs: "About us",
    faq: "FAQ",
    brainstorm: "Brainstorm",
    liveWall: "Live-wall",
    information: "Information",
    practice: "Practice",
    broadcast: "Broadcast",
    endPresentation: "Poll about WhenSpeak",
    hello: "Hello",
    goToRoom: "Go to room",
    sendPush: "Send notification",
    importantPush: "Important",
    commonPush: "Normal",
    send: "Send",
    timer: "Timer",
    minutes: "minutes",
    timerIsSet: "Timer is set",
    minutesQuantity: "q-ty of minutes",
    exit: "Exit",
    start: "Start",
    translation: "Go on the air",
    pin: "Display room PIN",
    presentations: "Presentations",
    commonPushMaxLengthWarning: "* The maximum length of a normal notification is 250 characters.",
    clearLastPush: "Clear last notification",
    miro: "Miro",
  },
  testNGames: {
    correctAnswers: "Correct answers",
    incorrectAnswers: "Incorrect answers",
    persentsCorrectAnswers: "Correct answers",
    testing: "Testing",
    test: "Testing:",
    survey: "Survey:",
    question: "Question",
    youAnswered: "You answered",
    point: "Point",
    points: "Points",
    close: "Close",
    skip: "Skip",
    answer: "Answer",
    yourResult: "Your result",
    result: "Result",
    of: "of",
    startGame: "Start game",
    gameRules: "Game rules",
    place: "Place",
    kingOfTheMountain: "King of the mountain",
    mountainGameRulesText:
      "You will be asked a series of questions. There is a certain amount of time for answers. " +
      "Your task is to go through the game as quickly as possible and give the maximum number of correct answers.",
    passed: "passed",
    active: "active",
    youHaveToChooseAnswer: "you have to choose answer",
    writeYourAnswerHere: "write your answer here",
    theAnswerCantBeEmpty: "the answer can't be empty",
    connectFieldsAtRightAndTheLeft: "connect the fields at the right and the left",
    youHaveToChooseAtLeastOneAnswer: "you have to choose at least one answer",
    thankForYourAnswers: "Thank for your answers!",
    quizResult: "Quiz result",
    ratingThanks: "Thank you for answer!",
    ratingThanksLabel: "The window closes after 3 seconds.",
    updateResult: "Update",
    testRepeatAfter: "Repass of the test will be available after",
    testRepeatDisabled: "Test repeat is disabled",
    wordCloudPlaceholder: "In one word",
    allFieldsAreRequired: "All fields are required",
    yourRating: "Your rating",
    overallRating: "Overall rating",
    averageRating: "Average rating",
    postpone: "Postpone",
  },
  Brainstorm: {
    suggestiIdea: "Suggest your idea",
    table: "Table",
    allIdeas: "All ideas",
    groupIdeas: "Group ideas",
    brainstormIsntStarted: "Brainstorm isn't started yet",
    chooseTheDesk: "Choose the desk",
    suggestFirst: "Share your idea first!",
    yourIdeasTopics: "Topics for your ideas",
    topicLaunched: "Topic launched",
    controlsNotification: "Click on the title to stop the topic",
    dialogStartTitle: "Start a new topic?",
    dialogStartBody: "Stop current and start new topic?",
    dialogOkText: "Ok",
    dialogCancelText: "Cancel",
    dialogStopTitle: "Stop current topic?",
    dialogStopBody: "Users will not see the topic on their device.",
    showIdeas: "Show ideas",
    hideIdeas: "Hide ideas",
    noTopicsYet: "No topics yet",
    noTopicsYetDesc: "They can be added to the admin. panel",
    topicsList: "List of topics",
    dialogUnderstandText: "Understandably!",
    dialogLostControlTitle: "Someone has taken over content management.",
  },
  Pin: {
    title: "Enter event code",
    error: "Invalid pin",
  },
  Speaker: {
    chooseRoom: "Сhoose a room",
    roomsListEmpty:
      "The list of rooms is empty. Create an active room, a speaker and a slide presentation in the admin panel.",
    sidebarBroadcastTabTitle: "Broadcast settings",
    sidebarParticipantsTabTitle: "Controlling participants",
    sidebarPresentationTabTitle: "Stream statistics",
  },
  systemTest: {
    isConnectionProblems: "Connection errors detected",
    internetSpeed: "The speed of your internet",
    networkConnectionQuality: "Network Connection Quality (PING)",
    browserAndVersion: "Browser and version",
    systemChecking: "System check in progress...",
    lowConnectionQuality: "Poor network connection quality. Possible delays in syncing content",
    useOtherNetwork: "Connect to another network with less connection delay.",
    browserLowQuality: "Your browser does not meet the requirements",
    updateBrowser: "Update your browser or download the latest version of Google Chrome",
    systemIsReady: "Your system is ready to go",
    runSystemTest: "Run system test",
    testSystemInfoTitle:
      "The system test will determine if your device is suitable for use with the WhenSpeak platform.",
    yourEmail: "Your corporate e-mail",
    systemTest: "System test",
    pleaseEnterYourEmail: "Please enter your corporate email",
    yourBrowser: "Your browser",
    portsAndProtocols: "Ports and protocols",
    calculation: "calculation",
    download: "Download",
    upload: "Upload",
    jitter: "Jitter",
    latency: "Latency",
    broadcastAvailable: "Broadcast available",
    testSuccessMessage: "System test passed successfully. Broadcast video is available for you.",
    warning: "Warning",
    broadcastErrorsPossibleBecause: "There may be errors in the broadcast video, reason: ",
    yourBrowserIsOutOfDate: "Your browser is out of date.",
    lowSpeed: "low speed of your internet",
    requiredPortsNotAvailable: "Required ports or protocols are not available:",
    useAlternativeNetwork: "We recommend using an alternative network to connect to the Internet.",
    installChrome: "Install Chrome",
    technicalInformation: "Technical information",
    browser: "browser",
    city: "city",
    countryCode: "country code",
    countryName: "country name",
    regionCode: "region code",
    regionName: "region name",
    timeZone: "time zone",
    zipCode: "zip code",
    browserInformation: "Browser information",
    isMobile: "is mobile",
    userAgent: "user agent",
    cookieEnabled: "cookie enabled",
    vendor: "vendor",
    product: "product",
    localStorageSupport: "local storage support",
    unknown: "unknown",
    fail: "fail",
    MbS: "Mbit/s",
    ms: "ms",
    suitable: "suitable",
    problemsDetected: "problems detected",
    await: "Await...",
    undefined: "Indefined",
  },
  Dashboards: {
    all: "All",
    ball: "ball",
    balls: "balls",
    game: "Photos / games",
    presence: "Presence",
    question: "Question",
    quiz: "Quiz",
    test: "Tests",
    training: "Training",
    placeBalls: "{0} place /{1} b.",
    topExecutives: "TOP executives",
    topTeams: "TOP teams",
    tableColumnDate: "Date",
    tableColumnTime: "Time",
    tableColumnActivityType: "Type of activity",
    tableColumnName: "Name",
    tableColumnBalls: "Balls",
  },
  ResultTests: {
    average: "Average value",
  },
  RoomErrors: {
    roomIsInaccessible: "The room is not available now",
    wrongToken: "Wrong token",
    roomIsNotAvailableNow: "Please try again later",
    eventEnds: "The event ends",
    roomIsNotExisted: "The room is not existed",
    roomIsNotAttachedToEvent: "The room is not attached to event",
    eventIsNotExisted: "The event is not existed",
    roomOvercrowded: "Room overcrowded, lets login later",
    eventIsRemoved: "The event is removed",
    roomIsRemoved: "Room is removed",
    connectionLimitForCompany:
      "The limit of instant connections for your company has been reached!",
  },
  LiveWall: {
    at: "at",
    placeholder: "Enter your message",
  },
  SpeakerAgenda: {
    scheduleNotSetYet: "Schedule not set yet",
    allHalls: "All halls",
  },
  SpeakerPresentation: {
    show: "Show",
    run: "Broadcast",
    control: "Control",
    right: "Forward",
    left: "Backward",
    pin_message: "The PIN code is now displayed to all viewers",
    pin_show_label: "Show connection code",
    from: "from",
    isActive: "The presentation started",
    chooseAnother: "Choose another presentation",
  },
  Translation: {
    resolution: "Resolution",
    capture_video: "Start capture",
    stop_capture_video: "Stop capture",
    capture_video_on_air: "Broadcast is already running",
    start_players: "Run players",
    close_players: "Close players",
    close_stream_for_me: "Close stream for me",
    close_stream_for_all: "Close stream for all",
    no_signal: "No translation signal",
    video: "Video",
    audio: "Audio",
    settings: "Streaming settings",
    initializing: "Stream system initializing...",
    translation_url_missing: "No translation URL given",
    options: "Options",
    media_error: "To start the broadcast you need to connect the camera and microphone",
    reload_page: "Broadcast stopped. Are you sure you want to leave the page?",
    media_buzy: "Failed to allocate videosource",
    warningMsgHaveNoSpeaker: "You can't join the broadcast until the speaker starts it.",
    warningMsgUserNotStreamer:
      "You can't join a broadcast until the speaker give permissions for you to stream",
    unsupportedBrowserIos:
      "Broadcast is not available in this browser. Please go to browser Safari!",
    unsupportedBrowser: "Broadcast is not available on this device.",
    startBroadcast: "Start broadcast",
    sessionUsers: "Session users",
    manageUsers: "Manage users",
    userList: "User list",
  },
  Slides: {
    quizSlideAnswers: "Answers",
  },
  Chat: {
    createNewChat: "Create new chat",
    allChats: "All chats",
    newChat: "New chat",
    createGroupChat: "Create group chat",
    writeToParticipant: "Write to participant",
    newGroupChat: "New group chat",
    groupChatName: "Group chat name",
    selectParticipants: "Select participants",
    participantsWithCount: "Participants: {participants} ",
    participants: "Participants",
    participantsInfo: "Participants: {participantsCount} | Online: {usersOnlineCount}",
    enterChatName: "Enter chat name",
    createChat: "Create chat",
    exitFromChat: "Exit from chat",
    deleteChatAndExit: "Delete chat and exit",
    removeParticipant: "Remove participant",
    ownUserName: "I",
    startWriteMessage: "Start write message...",
    viewImage: "Preview",
    loadImage: "Select image",
    removeImage: "Remove",
    invintation: "invited you to this chat",
    acceptIvite: "Accept",
    declineIvite: "Decline",
    created: "Chat created.",
    hello: "Write your first message!",
    commonTitle: "Shared chat",
    groupTitle: "Group chat",
    guest: "Guest",
    anonymous: "Anonymous",
    updateNameChatBtn: "Ok",
    nameTooLong: "Name is too long",
    errorChatList: "Error loading the chat list",
  },
  PollRunning: {
    pollIsRunning: "Poll is running:",
    divider: "or",
    scanQrcode: "Open the camera on your smartphone and point to the QR code",
    visitWebsite: "Visit the site",
    enterTheNumber: "Enter the number",
    nothingSelected: "Nothing selected",
    selectPollToSeeParams: "Select a poll from the list on the left to see its parameters",
  },
  Abbott: {
    entry: "Entry",
    welcome: "Welcome to the 2019 Remote Cycle Conference!",
    acceptTerms: "I accept the terms",
    pdpPolicies: "Personal Data Processing Policies",
    faq: "Frequently Asked Questions",
    needAcceptTerms: "It is necessary to accept the terms of the personal data processing policy",
  },
  broadcast: {
    reconnect: "Reconnection",
    speakerLaunchedNewBroadcast: "Speaker launched new broadcast",
    participantsOnAir: "On air",
    participantsHandUp: "Raised a hand",
    participantsViewers: "Viewers",
    andMoreGuests: "And more {0}...",
    leading: "Leading",
    leadings: "Leadings",
    noBroadcast: "No Broadcast",
    safeModeToggleOn: "Click to switch to backup broadcast in case of problems with video",
    safeModeToggleOff: "Switch to main stream",
    cameraIsOff: "Camera is off",
    microphoneIsOff: "Microphone is off",
    publishFailed: "Failed to start broadcast",
    speaker: "Speaker",
    takeOffAirQuestionStart: "Take off",
    takeOffAirQuestionEnd: "air?",
    readyToJoin: "ready to join",
    joinRequestDescription: "Allow participant join to air or hide request",
    connectionFailed: "Connection failed",
    allParticipants: "All participants",
    onAir: "On on air",
    notOnAir: "Off air",
    participants: "Participants",
    goOffAir: "Exit from on air",
    preparing: "Initializing...",
    unknownError: "Unexpected error. Stream is not available.",
    preview: "Preview",
    goOnAir: "Go on air",
    goOnAirShort: "On air",
    devicesSearchForAvailable: "Searching for devices...",
    devicesNotAvailable: "No enough available devices",
    camera: "Camera",
    microphone: "Microphone",
    externalStream: "External stream",
    repeatConnect: "Try again",
    restart: "Restart broadcast",
    servers: "Server for connect",
    rtmpServers: "Server for re-publish",
    title: {
      play: "Watch",
      pause: "Pause",
      video: "Disable video",
      "video-slash": "Turn on video",
      microphone: "Mute microphone",
      "microphone-slash": "Turn on microphone",
      "expand-alt": "Enter full screen mode",
      "compress-alt": "Exit full screen mode",
      plus: "Expand the list of participants",
      times: "Hide the list of participants",
      "rectangle-landscape": "Screen demonstration",
    },
    errors: {
      publishFailed: "Failed to start broadcast",
      connectionFailed: "Connection failed",
      error: "Error!",
      streamErrorTitle: "Broadcast down!",
      streamErrorText: "Please reconnect.",
      streamNotSupport:
        "For stable PC streaming, please use the latest version of Google Chrome." +
        "For mobile devices, use the Safari browser.",
      deviceNotFound: "Device not detected, please change device to start",
    },
    streamConnectedTitle: "Broadcast connected.",
    streamNotRunning: "Broadcast is not running",
    streamConnectedText: "You can go on air.",
    stopThrough: "Stop through",
    failedToPlay: "Failed to play",
    quality: "Quality",
    captureVideo: "Start player",
    stopCaptureVideo: "Stop",
    notAvailableOnYourDevice:
      "Broadcast from your device is not available in the current version of the platform",
    streamingSettings: "Streaming settings",
    enterName: "Enter your name for continue work",
    youNotCanGoOnAir: "You cannot go on air until the speaker allows you to connect",
    raiseHand: "Ask to join",
    participantControls: "Lead management",
    streamCapturePublishedTitle: "Screen sharing started.",
    streamCaptureStoppedTitle: "Screen sharing stopped.",
    streamCaptureFailedTitle: "Screen sharing failed.",
    recordType: "Record type",
    recordTypes: {
      off: "Off",
      stream: "Record from camera",
      event: "Record event",
    },
    recordEventTitle: "Record started",
    recordEventErrorTitle: "An error occurred while recording",
    recordEventEndTitle: "Record stopped",
    recordDownloadTitle: "Record ended",
    recordStart: "Start recording",
    recordStarted: "Recording already started",
    recordDownloadText: "Record can be downloaded here: ",
    recordDownload: "Download record",
    openInBrowser: "Open in browser",
    copyInBuffer: "Copy link to clipboard",
    streamIsBlocked:
      "Video conferencing is not supported in the application. Please use the Safari browser",
    streamIsBlockedInBrowser:
      "Broadcast is not supported in this browser. Copy the link and use the Safari browser",
    requestGoOnAirTitle: "Request to go on air sent",
    requestGoOnAirText: "You can connect when it is approved",
    screenSaver: "Start and end the broadcast with a screensaver",
    screenSaverDescription:
      "Participants will see the screen saver in the broadcast window before and after the broadcast",
    useBuffer: "Use buffering",
    useBufferDescription: "Increases broadcast stability but adds latency",
    noConnection: "No connection",
    youOnAir: "You on air",
    youStillOnAir: "You still on air!",
    endYourStream: "Want to end your stream?",
    stop: "Stop",
    streamConnectionWarning: `<p>The broadcast is unstable: it will disappear from the audience via {time}.<p> 
      Please complete the broadcast and contact WhenSpeak technical support.`,
    attention: "Attention!",
    exit: "Exit",
  },
  listeners: {
    notEnough: "List is empty",
    phone: "Phone number",
  },
  profile: {
    uploadFailed: "Avatar upload failed",
  },
  plurals: {
    guest: "no guests | {n} guest | {n} guests",
    slide: "No slides | {n} slide | {n} slide | {n} slides",
    point: "points | point | points",
  },
  // Перевод компонента Miro
  Miro: {
    buttons: {
      create: "Create",
    },
    createForm: {
      title: "Form for creating a new board:",
      name: "Board name:",
      description: "Board description:",
      sendButton: "Send",
    },
    plugText: "The speaker has not started the activity yet. Contact the speaker or support.",
  },
};
