import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import messages from "@/languages";
import parseUrlQueryString from "@/utils/parseUrlQueryString";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "eng", // set locale
  fallbackLocale: "eng",
  messages, // set locale messages
  pluralizationRules: {
    /**
     * @param {number} choice - индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param {number} choicesLength - общее количество доступных вариантов
     * @returns {number} финальный индекс для выбора соответственного варианта слова
     */
    rus(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

const loadedLanguages = [];

const DEFAULT_LOCALE = "rus";
const MOMENT_LOCALES_MAP = {
  eng: "en",
  [DEFAULT_LOCALE]: "ru",
};

/**
 * Метод установки языка.
 *
 * @param {string} lang - устанавливаемый язык
 * @returns {string} установленный язык
 */
function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  const rightLangHtmlCode = { rus: "ru", eng: "en-us" }[lang] ?? lang;
  document.querySelector("html").setAttribute("lang", rightLangHtmlCode);
  return lang;
}

/**
 * Метод загрузки переводов компонентов.
 *
 * @param {string} lang - устанавливаемый язык
 * @returns {void}
 */
export function loadLanguageAsync(lang) {
  if (!loadedLanguages.includes(lang)) {
    return import(/* webpackChunkName: "lang-[request]" */ `@/languages/${lang}`).then(msgs => {
      i18n.setLocaleMessage(lang, msgs.default);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    });
  }
  return Promise.resolve(setI18nLanguage(lang));
}

export const LS_KEY_INTERFACE_LANG = "interfaceLang";
export const LAST_CHOOSED_LANGUAGE = "lastChoosedLanguage";
export const getLocale = (() => {
  return (currentLocale = "", useQueryString = false) => {
    currentLocale = currentLocale || localStorage.getItem(LS_KEY_INTERFACE_LANG);

    if (useQueryString) {
      const queryParams = parseUrlQueryString(location.search);
      if (queryParams && queryParams.lang) {
        currentLocale = queryParams.lang;
      }
    }

    if (!currentLocale) {
      currentLocale = DEFAULT_LOCALE;
    }

    localStorage.setItem(LS_KEY_INTERFACE_LANG, currentLocale);
    return {
      localeApp: currentLocale,
      localeMoment: MOMENT_LOCALES_MAP[currentLocale],
    };
  };
})();
