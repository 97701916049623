<template>
  <button type="button" v-bind="$attrs" :class="viewClasses" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
import { BUTTON_SIZES, BUTTON_COLORS } from "@/constants/themes/themes-const";

export default {
  name: "WsButton",
  props: {
    /**
     * Makes the button small size.
     */
    sm: {
      type: Boolean,
      default: false,
    },
    /**
     * Makes the button large size.
     */
    lg: {
      type: Boolean,
      default: false,
    },
    /**
     * Makes the button extra large size.
     */
    xl: {
      type: Boolean,
      default: false,
    },
    /**
     * Display as full width block.
     */
    block: {
      type: Boolean,
      default: false,
    },
    /**
     * Specifies to use one of the predefined colors.
     * Otherwise, it simply inherits the color from the parent block.
     */
    color: {
      type: String,
      default: "",
      validator(value) {
        return !value || BUTTON_COLORS.includes(value);
      },
    },
    /**
     * Makes the background transparent and applies a thin border.
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Applies a large border radius on the button.
     */
    rounded: {
      type: Boolean,
      default: false,
    },
    /**
     * Designates the button as icon.
     */
    icon: {
      type: Boolean,
      default: false,
    },
    /**
     * Makes the background transparent.
     */
    text: {
      type: Boolean,
      default: false,
    },
    /**
     * Designates the button as a floating-action-button.
     */
    fab: {
      type: Boolean,
      default: false,
    },
    /**
     * Additional property for combining button size with other text size.
     */
    fontSize: {
      type: String,
      default: "",
      validator(value) {
        return !value || BUTTON_SIZES.includes(value);
      },
    },
  },
  computed: {
    viewClasses() {
      const size =
        (this.sm && this.$style.sm) ||
        (this.lg && this.$style.lg) ||
        (this.xl && this.$style.xl) ||
        this.$style.md;
      const allowedColor = BUTTON_COLORS.includes(this.color) ? this.$style[this.color] : "";
      const allowedFontSize = BUTTON_SIZES.includes(this.fontSize)
        ? this.$style[`font-${this.fontSize}`]
        : "";
      const view =
        (this.fab && this.$style.fab) ||
        (this.icon && this.$style.icon) ||
        (this.text && this.$style.text) ||
        this.$style.button;

      return [
        this.$style.wsButton,
        {
          [this.$style.inlineFlex]: this.$isSafari || this.$isCordova,
        },
        view,
        size,
        allowedColor,
        allowedFontSize,
        [this.$style.button, this.$style.icon].includes(view) &&
          this.rounded &&
          this.$style.rounded,
        view !== this.$style.fab && this.block && this.$style.block,
        [this.$style.fab, this.$style.icon, this.$style.button].includes(view) &&
          this.outlined &&
          this.$style.outlined,
        [this.$style.fab, this.$style.button].includes(view) &&
          !this.outlined &&
          this.$style.filled,
      ].filter(className => className);
    },
  },
  mounted() {
    if (this.$isSafari || this.$isCordova) {
      this.setGaps();
    }
  },
  updated() {
    if (this.$isSafari || this.$isCordova) {
      this.setGaps();
    }
  },
  methods: {
    // Workaround for Safari, button not support inline-grid
    setGaps() {
      const nodes = Array.from(this.$el.childNodes).filter(
        node => node.nodeType !== window.HTMLElement.COMMENT_NODE,
      );
      nodes.forEach(node => {
        if (node.nodeType === window.HTMLElement.ELEMENT_NODE && node.hasAttribute("style")) {
          node.removeAttribute("style");
        }
      });
      if (nodes.length === 1) return;
      nodes.forEach((node, idx, list) => {
        if (node.nodeType === window.HTMLElement.ELEMENT_NODE && idx !== list.length - 1) {
          node.style.marginRight = "10px";
        }
        if (idx > 0 && list[idx - 1].nodeType === window.HTMLElement.TEXT_NODE) {
          node.style.marginLeft = "10px";
        }
      });
    },
  },
};
</script>

<style lang="scss" module>
/* stylelint-disable selector-max-specificity, declaration-empty-line-before */
.wsButton {
  --ws-btn-color-default-text: var(--default-button-color, #acacac);
  --ws-btn-color-default-border: var(--ws-color-default--active);
  --ws-btn-color-default-bg: var(--ws-color-default);
  --ws-btn-color-default-bg--active: var(--ws-color-default--active);
  --ws-btn-color-primary-text: var(--base-button-active-color, #fff);
  --ws-btn-color-primary-border: var(--ws-color-primary);
  --ws-btn-color-primary-bg: var(--ws-color-primary);
  --ws-btn-color-primary-bg--active: var(--ws-color-primary--active);
  --ws-btn-color-primary-light-text: var(--ws-color-primary);
  --ws-btn-color-primary-light-border: var(--ws-color-primary-light);
  --ws-btn-color-primary-light-bg: var(--ws-color-primary-light);
  --ws-btn-color-primary-light-bg--active: var(--ws-color-primary-light--active);
  --ws-btn-color-accent-text: var(--base-button-active-color, #fff);
  --ws-btn-color-accent-border: var(--ws-color-accent);
  --ws-btn-color-accent-bg: var(--ws-color-accent);
  --ws-btn-color-accent-bg--active: var(--ws-color-accent--active);
  --ws-btn-color-danger-text: var(--base-button-active-color, #fff);
  --ws-btn-color-danger-border: var(--ws-color-danger);
  --ws-btn-color-danger-bg: var(--ws-color-danger);
  --ws-btn-color-danger-bg--active: var(--ws-color-danger--active);
  --button-width: auto;
  --button-height: auto;
  --button-padding: 0;
  --button-font: inherit;
  --button-text-color: inherit;
  --button-border-color: transparent;
  --button-bg-color: transparent;
  --button-bg-active-color: var(--button-bg-color);

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  position: relative;
  display: inline-grid;
  flex: 0 0 auto;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: var(--button-width);
  max-width: 100%;
  height: var(--button-height);
  padding: 0;
  margin: 0;
  overflow: hidden;
  font: var(--button-font);
  color: var(--button-text-color);
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: solid 1px transparent;
  border-radius: 5px;
  outline: none;
  box-shadow: none;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: inherit;
    pointer-events: none;
    content: "";
    background-color: currentColor;
    border: solid 1px transparent;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
}

.inlineFlex {
  display: inline-flex;
  grid-gap: 0;
}

.icon,
.text {
  &:not(:disabled):hover::before {
    opacity: 0.07;
  }
}

.block {
  flex: 1 0 auto;
  width: 100%;
  min-width: 0;
}

.rounded {
  border-radius: var(--button-height);
}

/* By styleguide in base state for all colors border's color is "default" */
.outlined {
  --button-border-color: var(--ws-btn-color-default-border);
  border-color: var(--button-border-color);
}

.filled {
  background-color: var(--button-bg-color);
}

/* sizes */
.sm {
  --button-width: var(--ws-button-size-sm-width);
  --button-height: var(--ws-button-size-sm-height);
  --button-padding: var(--ws-button-size-sm-paddings);
  --button-font: var(--ws-button-size-sm-font);
}

.md {
  --button-width: var(--ws-button-size-md-width);
  --button-height: var(--ws-button-size-md-height);
  --button-padding: var(--ws-button-size-md-paddings);
  --button-font: var(--ws-button-size-md-font);
}

.lg {
  --button-width: var(--ws-button-size-lg-width);
  --button-height: var(--ws-button-size-lg-height);
  --button-padding: var(--ws-button-size-lg-paddings);
  --button-font: var(--ws-button-size-lg-font);
}

.xl {
  --button-width: var(--ws-button-size-lg-width);
  --button-height: var(--ws-button-size-lg-height);
  --button-padding: var(--ws-button-size-lg-paddings);
  --button-font: var(--ws-button-size-lg-font);
}

/* aditional text sizes */
.font-sm {
  --button-font: var(--ws-button-size-sm-font);
}

.font-md {
  --button-font: var(--ws-button-size-md-font);
}

.font-lg {
  --button-font: var(--ws-button-size-lg-font);
}

.font-xl {
  --button-font: var(--ws-button-size-xl-font);
}

.filled:not(:disabled):hover,
.filled:not(:disabled):active {
  background-color: var(--button-bg-active-color);
}

/* colors */
.default {
  --button-text-color: var(--ws-btn-color-default-text);
  --button-border-color: var(--ws-btn-color-default-border);
  --button-bg-color: var(--ws-btn-color-default-bg);
  --button-bg-active-color: var(--ws-btn-color-default-bg--active);
}

.default.outlined:not(:disabled):hover,
.default.outlined:not(:disabled):active {
  --button-border-color: var(--ws-btn-color-default-bg);
  background-color: var(--ws-btn-color-default-bg);
}

.primary {
  --button-text-color: var(--ws-btn-color-primary-text);
  --button-bg-color: var(--ws-btn-color-primary-bg);
  --button-bg-active-color: var(--ws-btn-color-primary-bg--active);
}

.primary.outlined {
  color: var(--ws-btn-color-primary-bg);

  &:not(:disabled):hover,
  &:not(:disabled):active {
    --button-border-color: var(--ws-btn-color-primary-border);
  }
}

.primary-light {
  --button-text-color: var(--ws-btn-color-primary-light-text);
  --button-bg-color: var(--ws-btn-color-primary-light-bg);
  --button-bg-active-color: var(--ws-btn-color-primary-light-bg--active);
}

.primary-light.outlined {
  color: var(--ws-btn-color-primary-light-bg);

  &:not(:disabled):hover,
  &:not(:disabled):active {
    --button-border-color: var(--ws-btn-color-primary-light-border);
  }
}

.accent {
  --button-text-color: var(--ws-btn-color-accent-text);
  --button-bg-color: var(--ws-btn-color-accent-bg);
  --button-bg-active-color: var(--ws-btn-color-accent-bg--active);
}

.accent.outlined {
  color: var(--ws-btn-color-accent-bg);

  &:not(:disabled):hover,
  &:not(:disabled):active {
    --button-border-color: var(--ws-btn-color-accent-border);
  }
}

.danger {
  --button-text-color: var(--ws-btn-color-danger-text);
  --button-bg-color: var(--ws-btn-color-danger-bg);
  --button-bg-active-color: var(--ws-btn-color-danger-bg--active);
}

.danger.outlined {
  color: var(--ws-btn-color-danger-bg);

  &:not(:disabled):hover,
  &:not(:disabled):active {
    --button-border-color: var(--ws-btn-color-danger-border);
  }
}

.primary.text,
.primary.icon,
.primary-light.text,
.primary-light.icon {
  --button-text-color: var(--ws-btn-color-primary-bg);
}

.accent.icon,
.accent.text {
  --button-text-color: var(--ws-btn-color-accent-bg);
}

/* views */
.button,
.text {
  padding: var(--button-padding);
}

.icon {
  --button-width: var(--button-height);
}

.fab {
  min-width: calc(var(--button-height) * 1.4);
  height: calc(var(--button-height) * 1.4);
  border-radius: 50%;
}

.wsButton:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.inlineFlex {
  display: inline-flex;
}
</style>
