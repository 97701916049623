export const GET_FAQ_ANSWERS = "GET_FAQ_ANSWERS";
export const GET_ROOM_TIMER = "GET_ROOM_TIMER";
export const GET_MOSCOW_TIME = "GET_MOSCOW_TIME";
export const GET_ROOM_INFO = "GET_ROOM_INFO";
export const SWITCH_ROOM_LANGUAGE = "SWITCH_ROOM_LANGUAGE";
export const CHECK_ROOM_ACCESSIBILITY = "CHECK_ROOM_ACCESSIBILITY";
export const LOAD_ROOM = "LOAD_ROOM";
export const CHANGE_ROOM = "CHANGE_ROOM";
export const ENTER_TO_ROOM = "ENTER_TO_ROOM";
export const GET_CUSTOMER_MODULES = "GET_CUSTOMER_MODULES";
export const CHANGE_USER_ROOM = "CHANGE_USER_ROOM";
export const CHECK_ROOM_AUTH_STATUS = "CHECK_ROOM_AUTH_STATUS";
export const CHECK_ROOM_INFO = "CHECK_ROOM_INFO";
export const CHECK_STARTED_POLLS = "CHECK_STARTED_POLLS";
