import {
  BRAINSTORM_ID,
  BRAINSTORM_TITLE,
} from "@/store/modules/brainstorm/getter-types";
import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    idBrainstorm: null,
    userTableId: null,
    roundedTables: [],
    roomBrainstorms: [],
    roundedTableIdeas: [],
    brainstormIdeasIsLoading: false,
    currentBrainstormTab: "group",
    brainstormFilter: "",
  },
  getters: {
    [BRAINSTORM_ID](state, getters, rootState) {
      return rootState.common.roomInfo.theme_show
        ? state.idBrainstorm
        : rootState.common.roomInfo.Brainstorm?.id ?? null;
    },
    [BRAINSTORM_TITLE](state, getters, rootState) {
      let id = rootState.common.roomInfo.Brainstorm?.id;
      if (id) {
        let bsObject =
          state.roomBrainstorms &&
          state.roomBrainstorms.filter(item => {
            return item.id == id;
          });
        return (bsObject[0] && bsObject[0].name) || null;
      } else {
        return null;
      }
    },
  },
  actions,
  mutations,
};
