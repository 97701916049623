import { GET_LISTENERS, GET_LISTENER_PROFILE } from "@/store/modules/listeners/action-types";
import {
  SET_ERROR,
  SET_LISTENER_PROFILE,
  SET_LISTENERS,
} from "@/store/modules/listeners/mutation-types";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";
import { room } from "@/store/modules/store.namespaces";
import { makeUrl, stringToHtmlLink } from "@/utils";

export default {
  /**
   * Получаем список участников
   *
   * @param commit
   * @param rootState
   * @returns {Promise<boolean>}
   */
  async [GET_LISTENERS]({ commit, rootState }) {
    try {
      const { data } = await AdminWebHttpService.get(
        `rooms/${rootState[room].roomNumber}/getListeners/0`,
      );
      if (data && data.length > 0) {
        const mappedData = data.map(listener => {
          return {
            ...listener,
            avatar: makeUrl(listener.photo),
            name: listener.name ? listener.name.split("/") : [],
            position: listener.position ? listener.position.split("/") : [],
          };
        });
        commit(SET_LISTENERS, mappedData);
        return false;
      }
      return false;
    } catch (e) {
      console.log("error", e);
      return false;
    }
  },
  /**
   * Получаем профиль конкретного юзера по id и мапим его в формат для отображение на странице
   *
   * @param {Function} commit
   * @param {object} state
   * @param {Function} dispatch
   * @param {number} id - ID конкретного юзера
   * @returns {Promise<boolean>} - возвращает false: запрос выполнен
   */
  async [GET_LISTENER_PROFILE]({ state, commit, dispatch }, id) {
    try {
      if (!state.listeners.some(listener => listener.id === id)) {
        await dispatch(GET_LISTENERS);
      }
      const { data } = await AdminApi2HttpService.get(`user/${id}/profile`);
      if (data) {
        const description =
          (data.user_description && stringToHtmlLink(data.user_description)) || "";
        const mappedData = {
          id,
          description,
          phone: data.user_phone,
          email: data.user_email,
          location: [data.user_city, data.user_region].filter(Boolean).join(", "),
        };
        commit(SET_LISTENER_PROFILE, mappedData);
      }
      return false;
    } catch (e) {
      commit(SET_ERROR, e);
      return false;
    }
  },
};
