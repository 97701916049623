import axios from "axios";

export default {
  notificationId: "198a52a2-8395-4316-ab13-f341e47d4f56",
  fallbackHlsUrl: async room => {
    // "this" is not works here, window.conf is source of settings
    const conf = window.conf;

    try {
      let response = await axios.get(`${conf.wcsLoadBalancerUrl}/hls/get-cdn-server?roomId=${room}`);

      if (response.data.serverUrl) {
        return `${response.data.serverUrl}/${room}/${room}.m3u8`
      }
    } catch (e) {
      console.log(e);
    }

    return `${conf.flashphonerHlsUrl}/${room}/${room}.m3u8`
  },
  abbottServerUrl: "https://abbott.whenspeak.ru",
  abbottControlPanelUrl: "https://cp.abbott.whenspeak.ru",
  FREEGEOIP_KEY: "45e44273813124d8cfea35e864174e62",
  systemTestAvailable: process.env.NODE_ENV === "development",
  reportOnErrors: false,
  controllUrls: [
    "state",
    "setPublicAnswer",
    "setAnswersPollSequence",
    "setMark",
    "setDislike",
    "changed",
    "postPoll",
    "postAnswer",
    "setMultiAnswer",
    "setComment",
    "setCommentMark",
    "startQuiz",
    "stopQuiz",
    "blockRoom",
    "setTestState",
    "setCoffeeBreak",
    "switchRoom",
    "startStopTeva",
    "addIdea",
    "removeIdea",
    "toggleLikeIdea",
    "setNotifications",
    "setTimer",
    "changed",
    "startAboutWSQuiz",
    "stopPolling",
  ],
  controllApiUrlsVerTwo: ["setQuestion"],
  adminUrlsVerTwo: [
    "brainstormAjax",
    "brainstormTables",
    "brainstormIdea",
    "brainstormTables",
    "brainstorm",
  ],
};
