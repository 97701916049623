import { RoomRouteName } from "@/constants/router/router-const";

const About = () => import(/* webpackChunkName:"EventAbout" */ "@/components/event/room/About.vue");
const Materials = () =>
  import(/* webpackChunkName:"EventMaterials" */ "@/components/event/room/Materials/Materials.vue");
const ViewListeners = () =>
  import(/* webpackChunkName:"ViewListeners" */ "@/views/room/ViewListeners");

const Chat = () => import(/* webpackChunkName:"EventChat" */ "@/components/chat/Chat.vue");
const Photowall = () =>
  import(/* webpackChunkName:"EventPhotowall" */ "@/views/room/ViewPhotoWall.vue");
const Presentation = () =>
  import(
    /* webpackChunkName:"EventPresentation" */ "@/components/event/room/Presentation/Presentation.vue"
  );
const Breed = () => import(/* webpackChunkName:"EventBreed" */ "@/components/event/room/Breed.vue");
const Agenda = () =>
  import(/* webpackChunkName:"EventAgenda" */ "@/components/event/room/Agenda/Agenda.vue");
const FAQ = () => import(/* webpackChunkName:"EventFAQ" */ "@/components/event/room/FAQ.vue");
const Tests = () =>
  import(/* webpackChunkName:"EventTests" */ "@/components/event/room/Tests/Tests.vue");
const MountainGame = () =>
  import(/* webpackChunkName:"EventMountainGame" */ "@/components/event/polls/MountainGame");
const KingMountainResult = () =>
  import(
    /* webpackChunkName:"EventKingMountainResult" */ "@/components/event/room/KingMountainResult.vue"
  );
const Information = () =>
  import(
    /* webpackChunkName:"EventInformation" */ "@/components/event/room/Information/Information"
  );

const Notifs = () =>
  import(/* webpackChunkName:"EventNotifs" */ "@/components/event/room/Notifs/Notifs");
const Dashboard = () =>
  import(/* webpackChunkName:"EventDashboard" */ "@/components/event/room/Dashboard/Dashboard");
const Practice = () =>
  import(/* webpackChunkName:"EventPractice" */ "@/components/event/room/Practice");

const Brainstorm = () =>
  import(/* webpackChunkName:"EventBrainstorm" */ "@/components/event/room/Brainstorm/Brainstorm");
const LiveWall = () =>
  import(/* webpackChunkName:"EventLiveWall" */ "@/components/event/room/LiveWall/LiveWall");
const PollTestResultCommon = () =>
  import(
    /* webpackChunkName:"EventPollTestResultCommon" */ "@/components/common/polls/PollTestResultCommon"
  );
const PollTestResultContainer = () =>
  import(
    /* webpackChunkName:"EventPollTestResultContainer" */ "@/components/common/polls/PollTestResultContainer"
  );
const PollTestContainer = () =>
  import(
    /* webpackChunkName:"EventPollTestContainer" */ "@/components/common/polls/PollTestContainer"
  );
const ViewRoomMobileQuestions = () =>
  import(
    /* webpackChunkName:"ViewRoomMobileQuestions" */ "@/views/room/mobile/ViewRoomMobileQuestions"
  );

const Room = () => import(/* webpackChunkName:"Room" */ "@/views/room/ViewRoom");
const ViewEventRecording = () =>
  import(/* webpackChunkName="EventRecording" */ "@/views/room/ViewEventRecording");

const ViewListenerProfile = () =>
  import(/* webpackChunkName:"ViewListenerProfile" */ "@/views/room/ViewListenerProfile");
const ViewProfile = () =>
  import(/* webpackChunkName:"ViewListenerProfile" */ "@/views/room/ViewProfile");

const Miro = () =>
  import(/* webpackChunkName:"Miro" */ "@/components/event/room/Presentation/components/miro/Miro");

export default [
  {
    path: "/room/:id",
    name: "room",
    component: Room,
    meta: {
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: "breed",
        name: "breed",
        component: Breed,
      },
      {
        path: "presentation",
        name: "presentation",
        component: Presentation,
      },
      {
        path: "materials",
        name: "materials",
        component: Materials,
      },
      {
        path: "material",
        name: "material",
        component: Materials,
        meta: {
          hasBack: true,
        },
      },
      {
        path: "listeners",
        name: "listeners",
        component: ViewListeners,
      },
      {
        path: "listeners/:listenerId",
        name: "listener",
        component: ViewListenerProfile,
      },
      {
        path: "brainstorm",
        name: "brainstorm",
        component: Brainstorm,
      },
      {
        path: "about",
        name: "about",
        component: About,
      },
      {
        path: RoomRouteName.Chat,
        name: RoomRouteName.Chat,
        component: Chat,
      },
      {
        path: "photowall",
        name: "photowall",
        component: Photowall,
      },
      {
        path: "miro-board",
        name: "miro-board",
        component: Miro,
      },
      {
        path: RoomRouteName.Questions,
        name: RoomRouteName.Questions,
        component: ViewRoomMobileQuestions,
      },
      {
        path: "agenda",
        name: "agenda",
        component: Agenda,
      },
      {
        path: "faq",
        name: "faq",
        component: FAQ,
      },
      {
        path: "tests",
        name: "tests",
        component: Tests,
      },
      {
        path: "test/:testId",
        name: "test",
        component: PollTestContainer,
        props: true,
      },
      {
        path: "testResult/:testId",
        name: "testResult",
        component: PollTestResultContainer,
        props: true,
      },
      {
        path: "test-common-result/:testId",
        name: "testCommonResult",
        component: PollTestResultCommon,
        props: true,
      },
      {
        path: "notifs",
        name: "notifs",
        component: Notifs,
      },
      {
        path: "practice",
        name: "practice",
        component: Practice,
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "mountain",
        name: "mountain",
        component: MountainGame,
      },
      {
        path: "kingMountainResult",
        name: "kingMountainResult",
        component: KingMountainResult,
      },
      {
        path: "live-wall",
        name: "live-wall",
        component: LiveWall,
      },
      {
        path: "information/:sectionId",
        name: "information",
        component: Information,
      },
      {
        path: RoomRouteName.Profile,
        name: RoomRouteName.Profile,
        component: ViewProfile,
      },
    ],
  },
  {
    name: "eventRecord",
    path: "/room/:id/event-recording",
    component: ViewEventRecording,
    meta: {
      allowEventRecord: true,
      requiresAuth: true,
    },
  },
];
