import {
  RESET_TEST_STATE,
  SET_PING,
  SET_TEST_ERRORS,
  SET_TEST_ERRORS_COUNTER,
} from "@/store/modules/systemTest/mutation-types";

const defaultState = () => ({
  testErrorsCounter: 0,
  testErrors: [],
  ping: 0,
});

export default {
  state: {
    testErrorsCounter: 0,
    testErrors: [],
    ping: 0,
  },
  mutations: {
    [SET_TEST_ERRORS_COUNTER](state, count) {
      state.testErrorsCounter = count;
    },
    [SET_TEST_ERRORS](state, errors) {
      state.testErrors = errors;
    },
    [SET_PING](state, ping) {
      state.ping = ping;
    },
    [RESET_TEST_STATE](state) {
      const newState = defaultState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
  },
};
