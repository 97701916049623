import moment from "moment";
import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";
import AdminWsHttpService from "@/services/http/AdminWsHttpService";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import {
  GET_FAQ_ANSWERS,
  GET_MOSCOW_TIME,
  GET_ROOM_INFO,
  GET_ROOM_TIMER,
  LOAD_ROOM,
  ENTER_TO_ROOM,
  CHECK_ROOM_AUTH_STATUS,
  CHECK_ROOM_ACCESSIBILITY,
  CHECK_ROOM_INFO,
  CHECK_STARTED_POLLS,
  CHANGE_ROOM,
  CHANGE_USER_ROOM,
  SWITCH_ROOM_LANGUAGE,
  GET_CUSTOMER_MODULES,
} from "@/store/modules/common/action-types";
import {
  SET_CLOCK_TIMESTAMP,
  SET_FAQ_ANSWERS,
  SET_INTERFACE_LANGUAGE,
  SET_ROOM_ID,
  SET_ROOM_INFO,
  SET_ROOM_NUMBER,
  SET_ROOM_THEME,
  SET_ROOM_TIMER,
  SET_ROOM_MODULES_STATE,
  SET_ROOM_PENDING,
} from "@/store/modules/common/mutation-types";
import { LOGOUT } from "@/store/modules/auth/action-types";
import {
  auth,
  broadcast,
  information,
  livewall,
  polls,
  presentation,
  schedule,
} from "@/store/modules/store.namespaces";
import router from "@/routes/router";
import { SET_PRESENTATION_INFO } from "@/store/modules/presentation/mutation-types";
import {
  CHECK_IS_AVAILABLE_WS_POLL,
  RUN_POLL,
  RUN_TEST_POLL,
  SHOW_POLL_SCORE,
} from "@/store/modules/polls/action-types";
import { interactive } from "@/constants/polls/polls-module-types";
import {
  LAST_CHOOSED_LANGUAGE,
  loadLanguageAsync,
  LS_KEY_INTERFACE_LANG,
} from "@/utils/i18n-setup";
import UserRoles from "@/constants/user/roles-const";
import { GET_PRESENTATIONS } from "@/store/modules/presentation/action-types";
import { GET_SCHEDULE } from "@/store/modules/schedule/action-types";
import { SET_INTERACTIVE_TYPE } from "@/store/modules/polls/mutation-types";
import * as type from "@/store/mutations/mutation-types";
import { CLEAR_POSTS } from "@/store/modules/livewall/mutation-types";
import { SET_INFORMATION } from "@/store/modules/information/mutation-types";
import { BROADCAST_RESET_STATE } from "@/store/modules/broadcast/mutation-types";

import { wsErrorType, WSCustomError } from "@/utils/errors";
import { SpeakerRouteName } from "@/constants/router/router-const";
import { USER_IS_SPEAKER } from "@/store/modules/auth/getter-types";

import axios from "axios";
import config from "@/settings/config";
import AuthService from "@/services/AuthService";
import { NAVIGATION_ELEMENTS } from "./getter-types";

/**
 * Устанавливает корректный роут при загрузке комнаты
 *
 * @param {Array} navList - список для навигации
 * @param {object} rootState - стейт
 * @param {boolean} changeRoom - вызвано сменной комнаты или нет
 * @returns {string} - Возвращает роут на который надо перейти
 */
function getCorrectRoute(navList, rootState, changeRoom = false) {
  const checkMq = ({ mq }) => !mq || mq.includes(rootState.currentMediaQuery);
  let route = rootState.route.name;
  const isCurrentRouteCorrect = navList.some(item => item.route === route);
  if (isCurrentRouteCorrect && !changeRoom) return route;
  route = navList.find(item => checkMq(item));

  if (changeRoom) {
    const firstNavItem = navList.find(item => item.route !== "breed" && checkMq(item));
    route = firstNavItem || route;
  }

  // Если есть sectionId в любом случае подставляем его
  route = route.routeParams?.sectionId
    ? `${route.route}/${route.routeParams?.sectionId}`
    : route.route;

  return route || "presentation";
}

export default {
  async [CHECK_ROOM_AUTH_STATUS]({ rootState }, roomNumber) {
    const { is_guest } = rootState.auth.user;
    if (!is_guest) return;

    try {
      const response = await AdminApi2HttpService.get(`rooms/${roomNumber}/login`);
      if (response.data === "auth") {
        throw new WSCustomError(wsErrorType.ROOM_ENTER_ERROR_NO_GUEST_ALLOWED);
      }
    } catch (error) {
      if (error?.type === wsErrorType.ROOM_ENTER_ERROR_NO_GUEST_ALLOWED) {
        throw error;
      }
      throw new WSCustomError(wsErrorType.ROOM_ENTER_ERROR_NO_GUEST_ALLOWED, {
        ...error,
        subType: wsErrorType.NETWORK_ERROR,
      });
    }
  },
  [GET_FAQ_ANSWERS]({ commit }) {
    return new Promise(resolve => {
      AdminWebHttpService.get(`readCsvFile/abbott_faq`)
        .then(response => {
          if (response && response.data && response.data.length > 0) {
            let data = response.data.reduce((agregator, item) => {
              if (item["Заголовок"] && +item["На главную"] !== 1) {
                if (!agregator[item["Заголовок"]]) {
                  agregator[item["Заголовок"]] = [item];
                } else {
                  agregator[item["Заголовок"]].push(item);
                }
              }
              return agregator;
            }, {});
            data = Object.keys(data).map(item => {
              return data[item];
            });

            commit(SET_FAQ_ANSWERS, data);
            resolve();
          } else {
            console.log("empty response");
            resolve("complete");
          }
        })
        .catch(err => {
          console.log("error", err);
          resolve("complete");
        });
    });
  },
  async [GET_ROOM_TIMER]({ commit, state }) {
    try {
      const res = await AdminWsHttpService.get(`getRoomTimer/${state.roomNumber}`);
      commit(SET_ROOM_TIMER, res.data);
    } catch (error) {
      throw new WSCustomError(wsErrorType.ROOM_INFO_ERROR_FAIL_GET_ROOM_TIMER, {
        ...error,
        subType: wsErrorType.NETWORK_ERROR,
      });
    }
  },
  async [GET_MOSCOW_TIME]({ commit }) {
    try {
      const res = await AdminWebHttpService.get("getMoscowTime");
      commit(SET_CLOCK_TIMESTAMP, res.data);
    } catch (error) {
      throw new WSCustomError(wsErrorType.ROOM_INFO_ERROR_FAIL_GET_MOSCOW_TIME, {
        ...error,
        subType: wsErrorType.NETWORK_ERROR,
      });
    }
  },
  [CHECK_STARTED_POLLS]({ state: { roomInfo }, dispatch, rootState }) {
    const makePayload = payload => ({
      type: interactive,
      isScreen: rootState.route.meta.isScreen,
      payload,
    });

    if (roomInfo.Show_result) {
      const payload = makePayload(JSON.parse(roomInfo.Show_result).obj);
      dispatch(`${polls}/${SHOW_POLL_SCORE}`, payload, { root: true });
    }

    if (roomInfo.Test) {
      const payload = makePayload(roomInfo.Test);
      dispatch(`${polls}/${RUN_TEST_POLL}`, payload, { root: true });
    }

    if (roomInfo.Quiz) {
      const payload = makePayload({
        obj: {
          id: roomInfo.Quiz,
          title: "",
          timer: "",
        },
      });
      dispatch(`${polls}/${RUN_POLL}`, payload, { root: true });
    }
  },
  async [CHECK_ROOM_INFO]({ commit, state: { roomInfo }, dispatch }) {
    if (roomInfo.Online_room) {
      const payload = {
        presentationId: roomInfo.Presentation,
        slideIndex: roomInfo.Slide,
      };

      commit(`${presentation}/${SET_PRESENTATION_INFO}`, payload, {
        root: true,
      });
    }

    // Если у нас не включен переключатель языка то включаем дефолтный язык
    // в противном случае оставляем ранее выбранный язык
    const lastLang = localStorage.getItem(LAST_CHOOSED_LANGUAGE);
    if (!lastLang || !roomInfo?.show_language_panel)
      await dispatch(SWITCH_ROOM_LANGUAGE, roomInfo.default_language);

    /* Timeout для того что бы выполнить проверки запущенных голосованний уже после редиректа в комнату
     * Связанно с тем что логика жестко завязанна на экшены, а не на вью
     */
    setTimeout(() => {
      dispatch(CHECK_STARTED_POLLS);
    }, 2000);
  },

  /**
   * Получаем информацию о комнате и модули из настроек компании к которой привязанна комната
   *
   * @param {Function} commit
   * @param {object} state
   * @param {Function} dispatch
   * @param {object} rootState
   * @param {string} room - номер комнаты
   * @returns {Promise<void>}
   */
  async [GET_ROOM_INFO]({ commit, state, dispatch, rootState }, room) {
    try {
      if (!(state.roomNumber || room)) {
        throw new WSCustomError(wsErrorType.ROOM_INFO_ERROR_NO_NUMBER);
      }

      const { data: roomInfo } = await AdminWsHttpService.get(
        `getRoomInfo/${room || state.roomNumber}`,
      );

      if (!roomInfo) {
        throw new WSCustomError(wsErrorType.ROOM_INFO_ERROR_NO_DATA);
      }

      await dispatch(GET_CUSTOMER_MODULES, roomInfo.room_id);
      commit(SET_ROOM_ID, roomInfo.room_id);
      commit(SET_ROOM_INFO, roomInfo);
      if (roomInfo.styles && rootState.auth.user.role !== UserRoles.Admin) {
        commit(SET_ROOM_THEME, roomInfo.styles);
      }
      await dispatch(CHECK_ROOM_INFO);
    } catch (error) {
      if (error instanceof WSCustomError) {
        error.setParentType(wsErrorType.ROOM_INFO_ERROR);
        throw error;
      }

      throw new WSCustomError(wsErrorType.ROOM_INFO_ERROR, {
        ...error,
        subType: wsErrorType.UNKNOWN_ERROR,
      });
    }
  },

  /**
   * Метод изменения языка.
   *
   * @param {*} param0
   * @param {string} language - устанавливаемый язык
   */
  async [SWITCH_ROOM_LANGUAGE]({ state, commit, dispatch }, language) {
    localStorage.setItem(LS_KEY_INTERFACE_LANG, language);
    localStorage.setItem(LAST_CHOOSED_LANGUAGE, language);
    commit(SET_INTERFACE_LANGUAGE, language);
    moment.locale(language.slice(0, 2));
    await loadLanguageAsync(language);
    if (state.roomNumber) {
      dispatch(`${presentation}/${GET_PRESENTATIONS}`, undefined, {
        root: true,
      });
      dispatch(`${schedule}/${GET_SCHEDULE}`, null, { root: true });
    }
  },

  async [CHECK_ROOM_ACCESSIBILITY](_context, room) {
    try {
      const response = await AdminWebHttpService.get(`checkRoomWS/${room}`);
      if (!response?.data?.result) {
        const error = response?.data
          ? { server: response?.data }
          : {
              name: "Check room accessibility data error",
              message: "No data in response",
              stack: `${JSON.stringify(response)}`,
            };
        throw new WSCustomError(wsErrorType.ROOM_ENTER_ERROR_NO_ACCESSIBILITY, error);
      }
    } catch (error) {
      if (error?.type === wsErrorType.ROOM_ENTER_ERROR_NO_ACCESSIBILITY) {
        throw error;
      }
      throw new WSCustomError(wsErrorType.ROOM_ENTER_ERROR_NO_ACCESSIBILITY, {
        ...error,
        subType: wsErrorType.NETWORK_ERROR,
      });
    }
  },
  async [CHANGE_USER_ROOM](_context, room) {
    try {
      await AdminWebHttpService.post("ChangeUserRoom", { room });
    } catch (error) {
      throw new WSCustomError(wsErrorType.ROOM_ENTER_ERROR_NO_CHANGED_FOR_USER, {
        ...error,
        subType: wsErrorType.NETWORK_ERROR,
      });
    }
  },
  /**
   * Получаем модули из настроек компании к которой привязанна комната
   *
   * @param {Function} commit
   * @param {number} roomId - ид комнаты
   * @returns {Promise<void>}
   */
  async [GET_CUSTOMER_MODULES]({ commit }, roomId) {
    try {
      if (roomId) {
        const response = await AdminApi2HttpService.get(`configs/module/${roomId}`);
        commit(SET_ROOM_MODULES_STATE, response.data);
      }
    } catch (e) {
      console.warn(`${e} - Can not request customer modules`);
    }
  },
  async [LOAD_ROOM]({ dispatch }) {
    dispatch(GET_ROOM_TIMER);
    await dispatch(`${presentation}/${GET_PRESENTATIONS}`, null, { root: true });
  },
  async [ENTER_TO_ROOM](
    { commit, dispatch, getters, rootState, rootGetters },
    { room, onlyData, changeRoom },
  ) {
    try {
      commit(SET_ROOM_PENDING, true);
      /**
       * room === "default" means the user’s entry into the room from the login page
       * without additional routing parameters (such as guestRoom, etc.)
       */
      let targetRoom = room;
      const userScreen = [UserRoles.Manager, UserRoles.Screen].includes(+rootState.auth.user.role);
      const userSpeaker = +rootState.auth.user.role === UserRoles.Admin;
      const roomLanding = rootState.landing.template.room_number_default;
      const roomUser = rootState.auth.user.defaultRoom;

      // determine the default room
      if (targetRoom === "default" && !userSpeaker) {
        if (roomLanding) {
          targetRoom = roomLanding;
        } else if (roomUser) {
          targetRoom = roomUser;
        }
      }
      // load|prepare all data when there is an exact value of the room
      if (targetRoom !== "default") {
        await dispatch(CHECK_ROOM_ACCESSIBILITY, targetRoom);
        await dispatch(CHECK_ROOM_AUTH_STATUS, targetRoom);
        commit(SET_ROOM_NUMBER, targetRoom);
        await dispatch(GET_ROOM_INFO, targetRoom);
        await dispatch(CHANGE_USER_ROOM, targetRoom);
        dispatch(`${polls}/${CHECK_IS_AVAILABLE_WS_POLL}`, null, {
          root: true,
        });
        await dispatch(LOAD_ROOM);
      }

      // direct the user to the desired page
      if (!onlyData) {
        if (targetRoom === "default") {
          return router.replace({
            name: userSpeaker ? SpeakerRouteName.Rooms : "lobby",
          });
        }

        if (userSpeaker) {
          return router.replace({
            name: SpeakerRouteName.Presentations,
            params: { id: targetRoom },
          });
        }

        if (userScreen) {
          return router.replace({
            name: "screenRoom",
            params: { id: targetRoom },
          });
        }

        const navList = getters[NAVIGATION_ELEMENTS];
        const route = getCorrectRoute(navList, rootState, changeRoom);
        return router.replace(`/room/${targetRoom}/${route}`);
      }
    } catch (error) {
      if (error instanceof WSCustomError) {
        /** Срабатывает в случае если комната не доступна,
            редирект на страницу недоступности комнаты */
        if (error.hasType(wsErrorType.ROOM_ENTER_ERROR_NO_ACCESSIBILITY)) {
          const route = rootGetters[`${auth}/${USER_IS_SPEAKER}`]
            ? {
                name: SpeakerRouteName.Rooms,
                query: {
                  status: error.serverCode,
                },
              }
            : {
                name: "roomLimit",
                params: { status: error.serverCode },
              };
          await router.push(route);
        }
        /** В комнате в которую попытались войти запрещен вход гостям,
            редирект на лоби где можно выбрать другую комнату */
        if (error.hasType(wsErrorType.ROOM_ENTER_ERROR_NO_GUEST_ALLOWED)) {
          if (router.currentRoute.name !== "lobby") {
            await router.push({ name: "lobby" });
          }
        }
        error.setParentType(wsErrorType.ROOM_ENTER_ERROR);
        throw error;
      } else {
        throw new WSCustomError(wsErrorType.ROOM_ENTER_ERROR, {
          ...error,
          subType: wsErrorType.UNKNOWN_ERROR,
        });
      }
    } finally {
      commit(SET_ROOM_PENDING, false);
    }
  },
  async [CHANGE_ROOM]({ commit, dispatch, rootGetters }, room) {
    try {
      commit(SET_ROOM_PENDING, true);
      commit(`${polls}/${interactive}/${SET_INTERACTIVE_TYPE}`, null, {
        root: true,
      });
      commit(type.SET_BASE_ROUTE, false, { root: true });
      commit(`${broadcast}/${BROADCAST_RESET_STATE}`, null, { root: true });
      commit(`${livewall}/${CLEAR_POSTS}`, null, { root: true });
      commit(`${information}/${SET_INFORMATION}`, null, { root: true });
      commit(
        `${presentation}/${SET_PRESENTATION_INFO}`,
        {
          presentationId: false,
          slideIndex: 1,
        },
        { root: true },
      );
      await dispatch(ENTER_TO_ROOM, { room, changeRoom: true });
    } catch (error) {
      /**
       * This action is called already inside the system,
       * i.e. the user has already logged in and is now moving to another room.
       * But since the rooms have different parameters,
       * we duplicate the checks here as during authorization (via login page or login by socket).
       */
      if (error._isRouter) return;

      if (error.hasType && error.hasType(wsErrorType.ROOM_ENTER_ERROR_NO_ACCESSIBILITY)) {
        if (!rootGetters[`${auth}/${USER_IS_SPEAKER}`]) {
          await router.replace({
            name: "roomLimit",
            params: { status: error.serverCode },
          });
        }
      } else if (error.hasType && error.hasType(wsErrorType.ROOM_ENTER_ERROR_NO_GUEST_ALLOWED)) {
        await router.replace("/lobby");
      } else {
        await dispatch(`${auth}/${LOGOUT}`, undefined, { root: true });
      }
    }
  },
};
