<template>
  <div :class="$style.auth" :style="theme">
    <div :class="$style.auth__container">
      <auth-header />
      <div :class="$style.auth__content">
        <div v-if="welcomeText" :class="$style.promoText">
          {{ welcomeText }}
        </div>
        <div :class="$style.form">
          <landing-additional-buttons
            :faq-url="landingFaqUrl"
            :lang-selector="landingLangSelector"
          />
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthHeader from "@/components/common/auth/AuthHeader";
import LandingAdditionalButtons from "@/components/common/landing/LandingAdditionalButtons";
import useLanding from "@/components/common/landing/mixins/useLanding";
import useLandingTheme from "@/components/common/landing/mixins/useLandingTheme";
/**
 * Реализует сетку для роутов которые отвечают за аутентификацию, регистрация и вход в комнату,
 * устанавливает тему из лендинга для них
 *
 * @vue-computed {Object} themeColor - Цвета лендинга переменные css
 * @vue-computed {Object} themeBackground - Стили для основного фона страницы
 * @vue-computed {Object} themeHeader - Стили для хедера и логотипа страницы
 * @vue-computed {Object} themeForm - Центрирование формы входа для лендинга
 * @vue-computed {Object} themePromo - Стили для текста приветствия
 * @vue-computed {String} welcomeText - Текст приветствия в зависимоести от языка
 * @vue-computed {String} landingFaqUrl - Url для факью
 * @vue-computed {Boolean} landingLangSelector - Показывать ли смену языков
 */
export default {
  name: "LayoutAuth",
  components: { LandingAdditionalButtons, AuthHeader },
  mixins: [useLanding, useLandingTheme],
};
</script>

<style lang="scss" module>
.auth {
  height: 100%;
  max-height: 100%;
  overflow: auto;

  &__container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: var(--login-content-color);
    background-image: var(--login-background-image);
    background-repeat: no-repeat;
    background-position-x: var(--login-background-position);
    background-size: var(--login-background-size);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: var(--login-form-align);
    padding: 20px;
  }
}

.promoText {
  width: 100%;
  max-width: 800px;
  padding: 0 0 20px;
  font-size: 20px;
  color: var(--login-text-color);
  text-align: var(--login-promo-align);
  white-space: pre-line;
}

.form {
  display: grid;
  flex: 1 0 auto;
  grid-gap: 10px;
  width: 310px;
  padding: 20px 20px 5px;
  color: var(--login-input-color);
  background: var(--login-form-color);
  border-radius: 5px;
}
</style>