import { QUESTIONS } from "@/store/modules/questions/getter-types";
import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    questions: [],
    sort: true,
  },
  getters: {
    /**
     * Берет вопросы из стейта, между не отвечеными и отвечеными добавляет линию,
     * и также формирует строчки для вопроса
     *
     * @param {object} state - стейт стора
     * @returns {*[]|*} - возвращает массив вопросов
     */
    [QUESTIONS](state) {
      if (!state.questions?.length) return [];
      return state.questions.reduce((accum, q, i, arr) => {
        const isLine = accum.findIndex(question => question.id.toString() === "line");

        if (!arr[i - 1]?.isAnswered && arr[i]?.isAnswered && isLine === -1) {
          accum.push({ id: "line", isLine: true });
        }

        q.value && accum.push({ ...q, value: q.value.toString().split("\n") });
        return accum;
      }, []);
    },
  },
  mutations,
  actions,
};
