import {
  DELETE_IDEA,
  GET_BRAINSTORMS,
  GET_ROUNDED_TABLE_IDEAS,
  GET_ROUNDED_TABLES,
  SEND_IDEA,
  SET_TABLE_FOR_USER,
  SWITCH_IDEA_LIKE,
  UPDATE_IDEA,
} from "@/store/modules/brainstorm/action-types";
import { BRAINSTORM_ID } from "@/store/modules/brainstorm/getter-types";
import {
  REMOVE_BRAINSTORM_IDEA,
  SET_BRAINSTORM_IDEAS,
  SET_BRAINSTORM_IDEAS_IS_LOADING,
  SET_ROOM_BRAINSTORMS,
  SET_ROUNDED_TABLES,
  UPDATE_BRAINSTORM_IDEA,
} from "@/store/modules/brainstorm/mutation-types";
import { room } from "@/store/modules/store.namespaces";
import { SET_ROOM_INFO_BRAINSTORM } from "@/store/modules/common/mutation-types";

import { wsErrorType, WSCustomError } from "@/utils/errors";
import UserRoles from "@/constants/user/roles-const";
import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";

export default {
  async [GET_ROUNDED_TABLES]({ commit, rootState }) {
    try {
      const response = await AdminApi2HttpService.get(
        `rooms/${rootState.common.roomId}/brainstorms/tables`,
      );

      commit(SET_ROUNDED_TABLES, response.data);
    } catch (error) {
      throw new WSCustomError(
        wsErrorType.BRAINSTORM_ERROR_FAIL_GET_ROUNDED_TABLES,
        {
          ...error,
          subType: wsErrorType.NETWORK_ERROR,
        },
      );
    }
  },
  async [SET_TABLE_FOR_USER](context, tableId) {
    try {
      await AdminApi2HttpService.put(`users/brainstorms/tables/${tableId}`);
    } catch (error) {
      throw new WSCustomError(
        wsErrorType.BRAINSTORM_ERROR_FAIL_SET_TABLE_FOR_USER,
        {
          ...error,
          subType: wsErrorType.NETWORK_ERROR,
        },
      );
    }
  },
  async [GET_ROUNDED_TABLE_IDEAS]({ commit, getters, state, rootState }) {
    if (!getters[BRAINSTORM_ID]) return;

    try {
      commit(SET_BRAINSTORM_IDEAS_IS_LOADING, true);
      const allIdeas =
        state.currentBrainstormTab === "all" ||
        rootState.auth.user.role === UserRoles.Screen ||
        rootState.common.roomInfo.theme_show;
      const { data } = await AdminApi2HttpService.get(
        `brainstorms/${getters[BRAINSTORM_ID]}/ideas${allIdeas ? "" : "?table=true"}`,
      );
      commit(SET_BRAINSTORM_IDEAS, data);
    } catch (error) {
      commit(SET_BRAINSTORM_IDEAS, []);
    } finally {
      commit(SET_BRAINSTORM_IDEAS_IS_LOADING, false);
    }
  },
  async [GET_BRAINSTORMS]({ commit, rootState }) {
    try {
      const { room_id } = rootState.common.roomInfo;
      const { data } = await AdminApi2HttpService.get(
        `rooms/${room_id}/brainstormsWithIdeasCount`,
      );
      commit(SET_ROOM_BRAINSTORMS, data);
      const activeBrainstorm = data.find(item => item.status !== 0) || null;
      commit(`${room}/${SET_ROOM_INFO_BRAINSTORM}`, activeBrainstorm, {
        root: true,
      });
    } catch (error) {
      throw new WSCustomError(
        wsErrorType.BRAINSTORM_ERROR_FAIL_GET_BRAINSTORMS,
        {
          ...error,
          subType: wsErrorType.NETWORK_ERROR,
        },
      );
    }
  },
  async [UPDATE_IDEA]({ commit, getters }, ideaId) {
    try {
      const { data } = await AdminApi2HttpService.get(
        `brainstorms/${getters[BRAINSTORM_ID]}/ideas/${ideaId}`,
      );

      if (data.exists) {
        commit(UPDATE_BRAINSTORM_IDEA, data.idea);
      } else {
        commit(REMOVE_BRAINSTORM_IDEA, ideaId);
      }
    } catch (error) {
      throw new WSCustomError(wsErrorType.BRAINSTORM_ERROR_FAIL_UPDATE_IDEA, {
        ...error,
        subType: wsErrorType.NETWORK_ERROR,
      });
    }
  },
  async [SWITCH_IDEA_LIKE]({ dispatch, getters }, ideaId) {
    try {
      await AdminApi2HttpService.put(
        `brainstorms/${getters[BRAINSTORM_ID]}/ideas/${ideaId}/likes`,
      );
      await dispatch(GET_ROUNDED_TABLE_IDEAS);
    } catch (error) {
      if (error instanceof WSCustomError) {
        error.setParentType(wsErrorType.BRAINSTORM_ERROR_FAIL_SWITCH_IDEA_LIKE);
        throw error;
      }

      throw new WSCustomError(
        wsErrorType.BRAINSTORM_ERROR_FAIL_SWITCH_IDEA_LIKE,
        {
          ...error,
          subType: wsErrorType.UNKNOWN_ERROR,
        },
      );
    }
  },
  async [SEND_IDEA]({ getters }, idea) {
    try {
      await AdminApi2HttpService.post(`brainstorms/${getters[BRAINSTORM_ID]}/ideas`, idea);
    } catch (error) {
      throw new WSCustomError(wsErrorType.BRAINSTORM_ERROR_FAIL_SEND_IDEA, {
        ...error,
        subType: wsErrorType.NETWORK_ERROR,
      });
    }
  },
  async [DELETE_IDEA]({ getters }, ideaId) {
    try {
      await AdminApi2HttpService.delete(
        `brainstorms/${getters[BRAINSTORM_ID]}/ideas/${ideaId}`,
      );
    } catch (error) {
      throw new WSCustomError(wsErrorType.BRAINSTORM_ERROR_FAIL_DELETE_IDEA, {
        ...error,
        subType: wsErrorType.NETWORK_ERROR,
      });
    }
  },
};
