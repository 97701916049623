import Cookies from "js-cookie";
import { wsErrorType, ErrorWS } from "@/utils/errors";
import { USER_TOKEN_KEY, GUEST_TOKEN_KEY } from "@/constants/user/auth-const";
import AdminApiHttpService from "@/services/http/AdminApiHttpService";
import { setTokenGetterToService } from "@/services/http/helpers/HttpUtils";
import { isCordova } from "@/utils/browser-check";

export default {
  async reg({ fio, email, password, repassword }) {
    try {
      const { data } = await AdminApiHttpService.register({
        fio,
        email,
        password,
        repassword,
      });
      return { data, email, password };
    } catch (e) {
      throw new ErrorWS(wsErrorType.EXISTS_ERROR, e);
    }
  },
  async login(email, pass, lang = "rus") {
    try {
      const { data } = await AdminApiHttpService.login({ email, pass, lang });
      this.setToken(data.sessionToken);
      return data;
    } catch (e) {
      console.error(e);
    }
  },
  async loginBySurname(login, pass, fio) {
    const { data } = await AdminApiHttpService.loginBySurname({
      login,
      pass,
      fio,
    });
    this.setToken(data.sessionToken);
    return data;
  },
  restorePassword(login) {
    return AdminApiHttpService.restorePassword({ login });
  },
  checkToken() {
    return !!localStorage.getItem(USER_TOKEN_KEY) || !!Cookies.get(USER_TOKEN_KEY);
  },
  getToken() {
    return localStorage.getItem(USER_TOKEN_KEY) || Cookies.get(USER_TOKEN_KEY);
  },
  setToken(token, cookieParams) {
    setTokenGetterToService(this.getToken);
    if (cookieParams) {
      Cookies.set(USER_TOKEN_KEY, token, cookieParams);
    } else {
      Cookies.set(USER_TOKEN_KEY, token, { SameSite: "Lax" });
    }
    if (isCordova()) {
      localStorage.setItem(USER_TOKEN_KEY, token);
    }
  },
  removeToken() {
    if (isCordova()) {
      localStorage.removeItem(USER_TOKEN_KEY);
    }
    Cookies.remove(USER_TOKEN_KEY);
  },
  setGuestToken(token) {
    localStorage.setItem(GUEST_TOKEN_KEY, token);
  },
  removeGuestToken() {
    localStorage.removeItem(GUEST_TOKEN_KEY);
  },
  logout(cb) {
    this.removeToken();
    Cookies.remove(USER_TOKEN_KEY, { domain: ".abbott.whenspeak.ru" });
    if (cb) cb();
  },
};
