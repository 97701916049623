import {
  HIDE_AGENDA_EVENT_DETAILS,
  HIDE_SCHEDULE_SPEAKER,
  SET_AGENDA_EVENT,
  SET_AGENDA_EVENT_DETAILS,
  SET_AGENDA_PILLS_ID,
  SET_LOADING_STATUS,
  SET_REWARDS,
  SET_REWARDS_LIST,
  SET_SCHEDULE,
  SET_SELECTED_SCHEDULE_SPEAKER,
  SET_SHOW_REWARD_BUTTON,
} from "@/store/modules/schedule/mutation-types";

export default {
  [SET_AGENDA_EVENT](state, payload) {
    state.scheduleSpeaker = payload;
  },
  [SET_AGENDA_EVENT_DETAILS](state, eventInfo) {
    state.shownAgendaEvent = Object.assign({}, eventInfo.event);
    state.shownAgendaEventId = eventInfo.id;
  },
  [SET_REWARDS_LIST](state, payload) {
    state.scheduleRewardList = payload;
  },
  [SET_REWARDS](state, status) {
    state.isRewardSet = status;
  },
  [SET_SHOW_REWARD_BUTTON](state, payload) {
    let newState = Object.assign({}, state.showRewardButton);
    newState[payload.id] = payload.status;

    state.showRewardButton = newState;
  },
  [SET_AGENDA_PILLS_ID](state, id) {
    state.agendaPillsId = id;
  },
  [SET_SCHEDULE](state, schedule) {
    state.schedule = schedule;
  },
  [SET_LOADING_STATUS](state, status) {
    state.agendaLoadingStatus = status;
  },
  [HIDE_AGENDA_EVENT_DETAILS](state) {
    state.shownAgendaEvent = null;
    state.shownAgendaEventId = null;
  },
  [HIDE_SCHEDULE_SPEAKER](state) {
    state.selectedScheduleSpeaker = null;
  },
  [SET_SELECTED_SCHEDULE_SPEAKER](state, speakerInfo) {
    state.selectedScheduleSpeaker = Object.assign({}, speakerInfo);
  },
};
