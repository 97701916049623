<template>
  <!--    TODO: расширить функционал при необходимости -->
  <notifications
    ref="notifications"
    v-bind="$attrs"
    :width="$mq | mq({ ['mobile-s']: 320, ['mobile-m']: 400 })"
  >
    <template #body="props">
      <div :class="['system-notifications', `system-notifications--${props.item.type}`]">
        <div
          class="system-notifications__icon"
          :class="`system-notifications__icon--${props.item.type}`"
        >
          <ws-icon>{{ getIcon(props.item.type) }}</ws-icon>
        </div>
        <div>
          <div class="system-notifications__title">
            {{ props.item.title }}
          </div>
          <div class="system-notifications__content">
            {{ props.item.text }}
            <ws-timer
              v-if="props.item.data && props.item.data.timer"
              :append="props.item.data.timer.append"
              :seconds="props.item.data.timer.value"
              :dict-key="props.item.data.timer.dictKey"
            />
          </div>
        </div>
        <ws-button
          v-show="$refs.notifications.closeOnClick"
          icon
          class="system-notifications__close"
          @click="closeNotification(props)"
        >
          <ws-icon lg light>times</ws-icon>
        </ws-button>
      </div>
    </template>
  </notifications>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import WsTimer from "@/components/base/WsTimer";

export default {
  name: "SystemNotifications",
  components: {
    WsTimer,
    WsButton,
    WsIcon,
  },
  methods: {
    getIcon(type) {
      const types = {
        warn: "bell",
        success: "check",
        error: "exclamation",
        info: "check",
      };
      return types[type];
    },
    /**
     * Метод очистки всех уведомлений.
     */
    closeAllNotifications() {
      const { notifications } = this.$refs;
      notifications?.destroyAll();
    },
    /**
     * Метод очистки уведомления.
     *
     * @param {object} notification - удаляемое уведомление
     */
    closeNotification(notification) {
      notification?.close();
      this.$emit("close", notification);
    },
  },
};
</script>

<style lang="less">
@warn-main-clr: #f79843;

@warn-background-clr: #fef0e3;

@warn-content-clr: #2c2723;

@error-main-clr: #f05735;

@error-background-clr: #fff5f3;

@error-content-clr: #be9595;

@success-main-clr: #4bcb34;

@success-background-clr: #e6fee2;

@success-content-clr: #9ec797;

@info-main-clr: #00aeef;

@info-background-clr: #effaff;

@info-content-clr: #9cb0b8;

.system-notifications {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 5px 20px 5px;
  border: 1px solid;
  border-radius: 5px;

  &__title {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    color: #503e3e;

    @media (min-width: 414px) {
      font-size: 16px;
    }

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  &__content {
    font-size: 12px;
    line-height: 1.3;

    @media (min-width: 414px) {
      font-size: 14px;
    }

    @media (min-width: 768px) {
      font-size: 15px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 50%;
  }

  &--warn {
    background: @warn-background-clr;
    border-color: @warn-main-clr;
  }

  &--warn &__content {
    color: @warn-content-clr;
  }

  &--warn &__icon {
    color: @warn-background-clr;
    background: @warn-main-clr;
  }

  &--error {
    background: @error-background-clr;
    border-color: @error-main-clr;
  }

  &--error &__content {
    color: @error-content-clr;
  }

  &--error &__icon {
    color: @error-background-clr;
    background: @error-main-clr;
  }

  &--success {
    background: @success-background-clr;
    border-color: @success-main-clr;
  }

  &--success &__content {
    color: @success-content-clr;
  }

  &--success &__icon {
    color: @success-background-clr;
    background: @success-main-clr;
  }

  &--info {
    background: @info-background-clr;
    border-color: @info-main-clr;
  }

  &--info &__content {
    color: @info-content-clr;
  }

  &--info &__icon {
    color: @info-background-clr;
    background: @info-main-clr;
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;

    @media (min-width: 768px) {
      top: 9px;
      right: 6px;
    }
  }

  &--error &__close {
    color: @error-content-clr;
  }

  &--warn &__close {
    color: @warn-content-clr;
  }

  &--success &__close {
    color: @success-content-clr;
  }

  &--info &__close {
    color: @info-content-clr;
  }
}
</style>
