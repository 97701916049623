import {
  ADD_LIVEWALL_POST,
  GET_LIVEWALL_POSTS,
  SWITCH_LIVEWALL_POST_LIKE,
} from "@/store/modules/livewall/action-types";
import {
  ADD_POST,
  CLEAR_POSTS,
  SET_POSTS,
  UPDATE_POSTS,
} from "@/store/modules/livewall/mutation-types";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";

export default {
  async [GET_LIVEWALL_POSTS]({ commit, rootState }, offset = 0) {
    const roomNumber = rootState.common.roomNumber || rootState.route.params.id;
    if (!roomNumber) return null;
    try {
      const response = await AdminWebHttpService.get(`getLiveWalls/${roomNumber}?offset=${offset}`);
      if (response) {
        if (response.data.length > 0) {
          commit(SET_POSTS, response.data);
          return null;
        }
        if (!offset && response.data.length === 0) {
          commit(CLEAR_POSTS);
        }
        return "complete";
      }
    } catch (e) {
      console.error(e);
      return "complete";
    }
  },
  async [SWITCH_LIVEWALL_POST_LIKE]({ commit }, postId) {
    const response = await AdminWebHttpService.post(`addLiveWallLike/${postId}`);
    commit(UPDATE_POSTS, response.data);
    return Promise.resolve();
  },
  async [ADD_LIVEWALL_POST]({ state, commit }, formData) {
    try {
      const { data } = await AdminWebHttpService.post(`createLiveWall`, formData);
      if (state.isComplete) {
        commit(ADD_POST, data);
      }
    } catch (e) {
      console.error(e);
    }
  },
};
