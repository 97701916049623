import mutations from "./mutations";
import actions from "./actions";

export default {
  state: {
    folders: [],
    files: [],
    isFolderVisible: true,
    lastLoadedFileKey: {
      listLength: 0,
      lastId: null,
    },
  },
  mutations,
  actions,
};
