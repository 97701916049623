import { socket } from "@/store/modules/store.namespaces";
import { SET_SOCKET_OBJ, SET_SOCKET_CONNECTED } from "@/store/modules/socket/mutation-types";
import { registerModules } from "@/store/modules/registerModules";
import actions from "./actions";

const modules = registerModules(true, socket);

export default {
  state: {
    isConnected: false,
    message: "",
    reconnectError: false,
    reconnected: false,
    socketObj: {},
  },
  actions,
  mutations: {
    [SET_SOCKET_OBJ](state, payload) {
      state.socketObj = payload;
    },

    /**
     * Мутация изменения состояния подключения к сокету
     * @param {Object} state  - состояние
     * @param {Boolean} payload - параметры
     */
    [SET_SOCKET_CONNECTED](state, payload) {
      state.isConnected = payload;
    },
  },
  modules,
};
