import { MqName } from "@/constants/media-query/media-query";

const pinStatus = localStorage.getItem("currentPinStatus") === "true";

export default {
  appMode: "",
  pinbar: {
    visible: false,
  },
  neuroStatus: false,
  baseRoute: false,
  speakerIsActive: null,
  showSupportModal: false,
  modalView: false,
  wsPollModalOpen: false,
  logoRoute: "presentation",
  currentMediaQuery: MqName.Laptop,
  // todo жесткий костыль, убрать когда отрефакторим авторизацию.
  fromGuestRoute: false,
  pinStatus,
};
