export const SET_AGENDA_EVENT = "SET_AGENDA_EVENT";
export const SET_AGENDA_EVENT_DETAILS = "SET_AGENDA_EVENT_DETAILS";
export const SET_REWARDS_LIST = "SET_REWARDS_LIST";
export const SET_REWARDS = "SET_REWARDS";
export const SET_SHOW_REWARD_BUTTON = "SET_SHOW_REWARD_BUTTON";
export const SET_AGENDA_PILLS_ID = "SET_AGENDA_PILLS_ID";
export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
export const SET_SELECTED_SCHEDULE_SPEAKER = "SET_SELECTED_SCHEDULE_SPEAKER";
export const HIDE_AGENDA_EVENT_DETAILS = "HIDE_AGENDA_EVENT_DETAILS";
export const HIDE_SCHEDULE_SPEAKER = "HIDE_SCHEDULE_SPEAKER";
