import {
  GET_USER_QUESTIONS,
  SEND_QUESTION,
  SEND_QUESTION_DISLIKE,
  SEND_QUESTION_LIKE,
} from "@/store/modules/questions/action-types";
import AuthService from "@/services/AuthService";
import {
  SET_QUESTION_DISLIKE,
  SET_QUESTION_LIKE,
  SET_USER_QUESTIONS,
} from "@/store/modules/questions/mutation-types";
import { presentation, room as roomStoreNamespace, socket } from "@/store/modules/store.namespaces";
import * as roomGetterKeys from "@/store/modules/common/getter-types";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import ControlHttpService from "@/services/http/ControlHttpService";
import { CURRENT_PRESENTATION_LEADER } from "@/store/modules/presentation/getter-types";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";

export default {
  async [GET_USER_QUESTIONS]({ commit, rootState }) {
    try {
      const params = {
        speaker: rootState.speakers.currentSpeaker?.id ?? null,
      };
      const { data } = await AdminWebHttpService.get(
        `getUserQuestions/${rootState.common.roomNumber}/${AuthService.getToken()}`,
        { params },
      );
      commit(SET_USER_QUESTIONS, data || []);
    } catch (e) {
      commit(SET_USER_QUESTIONS, []);
      console.error(e);
    }
  },

  /**
   * Метод отправки вопроса спикеру.
   *
   * @param {*} param0
   * @param {object} payload - объект с информацией о вопросе
   */
  async [SEND_QUESTION]({ rootGetters, rootState }, payload) {
    try {
      if (!payload.speakerID) {
        payload.speakerID = rootGetters[`${presentation}/${CURRENT_PRESENTATION_LEADER}`].id;
      }
      const { speakerID, text, anonymous } = payload;
      const { allowAnonymity } = rootGetters[
        `${roomStoreNamespace}/${roomGetterKeys.AVAILABLE_ROOM_MODULES}`
      ];
      await ControlHttpService.post(
        `v2/setQuestion/${rootState.common.roomNumber}/${AuthService.getToken()}/${speakerID}`,
        {
          question: text,
          anonymity: anonymous && +allowAnonymity,
        },
      );
    } catch (e) {
      console.error(e);
    }
  },

  [SEND_QUESTION_LIKE]({ commit, rootState }, question) {
    if (!question) {
      return;
    }
    const { id } = question;
    const markStatus = question.isMarked;
    const newMarkStatus = !markStatus;
    const markDelta = newMarkStatus ? 1 : -1;

    ControlHttpService.get(`setMark/${rootState.common.roomNumber}/${id}/${AuthService.getToken()}`)
      .then(() => {
        /* TODO: Временное решение после необходимо поправить */
        commit(SET_QUESTION_LIKE, {
          id,
          markDelta: 0,
          markStatus: newMarkStatus,
        });

        /* TODO: Временное решение после необходимо поправить */
        commit(
          `${socket}/${SET_SOCKET_OBJ}`,
          {
            type: "refreshQuestionsLikes",
            obj: {
              id,
              markDelta,
              markStatus: newMarkStatus,
            },
          },
          { root: true },
        );
      })
      .catch(err => console.error(err));
  },
  [SEND_QUESTION_DISLIKE]({ commit, rootState }, question) {
    if (!question) {
      return;
    }
    const { id } = question;
    const markStatus = question.isDisliked;
    const newMarkStatus = !markStatus;
    const markDelta = newMarkStatus ? 1 : -1;

    ControlHttpService.get(
      `setDislike/${rootState.common.roomNumber}/${id}/${AuthService.getToken()}`,
    )
      .then(() => {
        /* TODO: Временное решение после необходимо поправить */
        commit(SET_QUESTION_DISLIKE, {
          id,
          markDelta: 0,
          markStatus: newMarkStatus,
        });

        /* TODO: Временное решение после необходимо поправить */
        commit(
          `${socket}/${SET_SOCKET_OBJ}`,
          {
            type: "refreshQuestionsDislikes",
            obj: {
              id,
              markDelta,
              markStatus: newMarkStatus,
            },
          },
          { root: true },
        );
      })
      .catch(err => console.error(err));
  },
};
