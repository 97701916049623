import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    godDashBoardBUTopManagers: {
      groups: [],
      groups_count: 0,
      summary: {
        online: 0,
        in_team: 0,
        presence: 0,
        question: 0,
        quiz: 0,
        test: 0,
        game_king: 0,
        sum: 0,
      },
      avg: {
        online: 0,
        in_team: 0,
        presence: 0,
        question: 0,
        quiz: 0,
        test: 0,
        game_king: 0,
        sum: 0,
      },
    },
    userDashboardInfo: {
      fio: "",
      photo: "",
      img_background_color: "",
      position: "",
      business_unit: "",
      leader_name: "",
      achieves: [],
    },
    userDashBoardMyScore: {
      my_score: {
        login_points: 0,
        quiz_points: 0,
        question_points: 0,
        test_points: 0,
        training_points: 0,
        additional_points: 0,
        summary_points: 0,
      },
      my_score_today: {
        login_points: 0,
        quiz_points: 0,
        question_points: 0,
        test_points: 0,
        training_points: 0,
        additional_points: 0,
        summary_points: 0,
      },
      place: 0,
      place_today: 0,
      out_of: 0,
    },
    userDashBoardAvgTeamScore: {
      avg: {
        game_king: 0,
        presence: 0,
        question: 0,
        quiz: 0,
        sum: 0,
        test: 0,
      },
      avg_today: {
        game_king: 0,
        presence: 0,
        question: 0,
        quiz: 0,
        sum: 0,
        test: 0,
      },
      out_of: 0,
      place: 0,
      place_today: 0,
    },
    userDashBoardMyTeamScore: {
      users: [],
      in_team: 0,
      is_online: 0,
      online_color: "",
      summary: {
        presence: 0,
        question: 0,
        quiz: 0,
        test: 0,
        game_king: 0,
        sum: 0,
      },
      avg: {
        presence: 0,
        question: 0,
        quiz: 0,
        test: 0,
        game_king: 0,
        sum: 0,
      },
      columns: [],
    },
    userDashBoardUserLineActive: {
      activity: [],
      activity_types: [],
      columns: {},
      sum: 0,
      avg: 0,
    },
    godDashboardBUTopTeams: {
      groups: [],
      groups_count: 0,
      summary: {
        online: 0,
        in_team: 0,
        presence: 0,
        question: 0,
        quiz: 0,
        test: 0,
        game_king: 0,
        sum: 0,
      },
      avg: {
        online: 0,
        in_team: 0,
        presence: 0,
        question: 0,
        quiz: 0,
        test: 0,
        game_king: 0,
        sum: 0,
      },
    },
    userDashBoardBUTeamsScore: {
      groups: [],
      groups_count: 0,
      summary: [],
      avg: [],
    },
    loading: false,
  },
  actions,
  mutations,
  getters,
};
