const UserRoles = {
  SuperAdmin: 0,
  Admin: 1,
  Manager: 2,
  Screen: 3,
  Leading: 4,
  Guest: 5,
  Photowall: 6,
};

export default UserRoles;
