export default {
  browser: {
    greetings:
      "Ваша система не соответствует техническим требованиям, возможна некорректная работа функций WhenSpeak!",
    error_slow_connect: "Низкая скорость соединения",
    error_old_version: "Устаревшая версия браузера",
    error_not_support: "Не поддерживаемый браузер",
    error_high_ping: "Высокая задержка",
    understand: "Понятно",
  },
  messages: {
    common: {
      please_wait: "Ожидайте...",
      list_empty: "Список презентаций пуст",
    },
  },
  common: {
    version: "Версия: 1.17",
    guest: "Гость",
    back: "Назад",
    online: "Онлайн",
    today: "сегодня",
    allDay: "все дни",
    myPoints: "Мои баллы",
    myTeam: "Моя команда",
    teamRate: "Рейтинг команды",
    participantRate: "Рейтинг участника",
    send: "Отправить",
    search: "Поиск",
    button: "Кнопка",
    of: "из",
    for: "для",
    videoText: "Для воспроизведения видео нажмите на Play",
    delete: "Удалить",
    msgSendError: "произошла ошибка при отправке сообщения",
    msgSendSucc: "сообщение отправлено",
    chat: "Чат",
    questions: "Вопросы",
    sorting: "Сортировка",
    close: "Закрыть",
    links: "Ссылки",
    files: "Файлы",
    averageValue: "Среднее значение",
    cancel: "Отменить",
    total: "Всего",
    tapeActivity: "Лента активности",
    division: "Выберите подразделение",
    error: "Ошибка",
    filesLimit: "Вы можете загрузить не более 10 фотографий.",
    okText: "Понятно!",
    visitWebsite: "Зайдите на сайт ",
    enterTheNumber: " и введите номер",
    update: "Обновить",
    notFound: "Упс, упс...",
    allPresentation: "Все презентации",
    sortByName: "По названию",
    sortByDate: "По дате",
    downloadAll: "Скачать все",
    downloadAllShort: "все",
    downloadSelected: "Скачать выбранные",
    downloadSelectedShort: "выбранные",
    timeInDate: "в",
    accept: "Принять",
    hide: "Скрыть",
    confirm: "Подтвердить",
    required: "Заполните поле",
    download: "Скачать",
    login: "Вход",
  },
  App: {
    thxQuestion: "Спасибо за ваш вопрос!",
    descQuestion: "После проверки модератором,<br>он появится в общем списке",
  },
  Login: {
    login: "Войти",
    loginAgain: "Повторить вход",
    register: "Регистрация",
    processingPersonData: "Я принимаю условия",
    processingPersonDataLink: "Политики об обработке персональных данных",
    guestLogin: "Войти как гость",
    password: "Пароль",
    yourLogin: "Ваш логин",
    email: "E-mail",
    loginEmail: "Логин / E-mail",
    name: "Имя",
    secondName: "Фамилия",
    surname: "Фамилия",
    restorePassword: "Восстановить пароль",
    emailError: "Введите корректный email",
    passwordError: "введите пароль",
    nameError: "введите имя",
    processingPersonDataERR: "установите флажок",
    controlPanel: "Панель управления",
    socialEnter: "Войти через соцсети",
    authDeclinedError:
      "Ошибка авторизации пользователя.<br>Проверьте правильность указанных данных.",
    socialVkontakte: "В контакте",
    socialFaceBook: "FaceBook",
    socialOdnoklassniki: "Одноклассники",
    socialGmail: "Google mail",
    restorePasswordRestriction: "Вы можете изменить пароль только через {0} минут",
    emptyValue: "значение не заданно",
  },
  Registration: {
    email: "E-mail",
    fio: "ФИО",
    password: "Пароль",
    repeatPassword: "Повторить пароль",
    registration: "Зарегистрироваться",
    login: "У меня уже есть аккаунт",
    emailFormat: "Не верный формат почты",
    emailError: "значение задано не верно",
    emailExistError: "почта уже зарегистрирована",
    fioExistError: "Такие имя и фамилия уже используются",
    processingPersonData: "Согласие на обработку персональных данных",
    processingPersonDataERR: "установите флажок",
    fioError: "значение меньше 4 символов",
    passwordError: "значение меньше 4 символов",
    rePasswordError: "пароли не совпадают",
  },
  Breed: {
    title: "Выберите нужную комнату",
    listNoRooms: "Комнаты нет в списке",
  },
  RestorePassword: {
    sendNewPass: "Выслать новый пароль",
    cancel: "Отмена",
    changePassword: "Сменить пароль",
  },
  Lobby: {
    title: "Введите номер комнаты",
    roomNumber: "номер комнаты",
    logout: "Выйти",
    errorRoom: "Комната недоступна",
    unavailableToGuest: "Комната недоступна для гостей",
    errorUnexpected: "Что-то пошло не так",
  },
  Presentation: {
    back: "Назад",
    empty: "Слайды не загружены",
    startError: "Неудалось запустить презентацию",
    videoUnavailable: "Видео недоступно",
  },
  PresentationButton: {
    externalVideo: "Нажмите для открытия резервного видео в новом окне",
    like: "нравится",
    tightened: "затянуто",
    dislike: "не нравится",
    unclear: "не понятно",
  },
  Room: {
    videoNotifError: "Нет трансляции",
    backupTranslMode: "Нажмите для переключения на резервную трансляцию в случае проблем с видео",
    mainTranslMode: "Переключиться на основную трансляцию",
    mainChatList: "Общий чат",
    teamChatList: "Чат команды",
    speakerQuestions: "Вопросы",
    noActivePresentations: "Спикер не запустил презентацию или она еще не загрузилась",
    rotateYourMobileVertically: "Поверните телефон вертикально",
    andWorkAgain: "чтобы продолжить работу",
    title: {
      menu: "Меню",
      closeSidePanel: "Скрыть боковую панель",
      openSidePanel: "Раскрыть боковую панель",
    },
  },
  ChartList: {
    guest: "Гость",
    writeFirst: "Напишите ваше сообщение первым!",
    anonym: "Аноним",
  },
  SpeakerQuestions: {
    errorQuestions: "Ошибка загрузки вопросов",
    noQuestions: "Вопросов еще нет",
    askFirst: "Задайте свой вопрос первым!",
    askAQuestion: "Задать вопрос",
    askQuestion: "Напишите сообщение",
    autoUpdate: "Сортировка в реальном времени",
    moderate: "Премодерация",
    answered: "Отвеченные",
    answeredVerbally: "Ответил устно",
    answeredInWriting: "Письменный ответ на вопрос",
    hiddenQuestions: "Скрытые вопросы",
    sortByLikes: "по лайкам",
    sortByTime: "по времени",
    displayAllQuestions: "Все вопросы",
    displayAnsweredQuestions: "Отвеченные вопросы",
    dialogTitle: "Статус вопроса",
    dialogBody: 'Отменить статус "Ответил устно"?',
    dialogOkText: "Да, отменить",
    dialogCancelText: "Нет",
    thanksForQuestion: "Спасибо за ваш вопрос!",
    questionModerate: "Ваш вопрос появится после одобрения модератором",
    fullScreenQuestions: "Вопросы в режиме полного экрана",
    title: {
      enterFullscreen: "Войти в полноэкранный режим",
      exitFullscreen: "Выйти из полноэкранного режима",
      speakerAnswer: "Ответ:",
      managerAnswer: "Ответ организатора:",
    },
    error: {
      fetchQuestions: "Произошла ошибка загрузки вопросов",
    },
    answerModal: {
      title: "Ответ на вопрос",
      question: "Вопрос:",
      questionAnswer: "Ответ на вопрос:",
      submitButton: "Ответить",
    },
    questionItem: {
      speakerAnswer: "Ответ спикера:",
      managerAnswer: "Ответ организатора:",
    },
    notifs: {
      answer: {
        success: {
          title: "Успех",
          text: "Ответ был сохранен.",
        },
        error: {
          title: "Ошибка",
          text: `Во время сохранения вопроса произошла ошибка.
            Пожалуйста, попробуйте ответить на вопрос позднее или обратитесь к администратору.`,
        },
      },
    },
  },
  Messenger: {
    startTyping: "Начните печатать...",
    textWrite: "Введите текст...",
    anonymous: "Анонимно",
  },
  Agenda: {
    mowTime: "ВНИМАНИЕ: в расписании указано Московское время",
    rateTeam: "Оценить команду",
    ratingLimit: "Ваш лимит оценок",
    allWerePresent: "Все присутствовали",
    clearFormTitle: "Форма очистки баллов команды.",
    managerLoginPlaceholder: "Логин регионального менеджера",
    managerClearButton: "Очистить оценки менеджера",
    clearFormConfirmation: "Вы уверены что хотите очистить баллы команды?",
    clearFormApprove: "Очистить",
  },
  Tests: {
    testGroup: "Тесты",
    gameGroup: "Игры",
    voting: "Голосование",
    not_started: "Активные",
    started: "Запущенные",
    finished: "Архив",
    startPoll: "Запустить",
    stopPoll: "Остановить",
    hideResults: "Скрыть результаты",
    showResults: "Показать результаты",
    backToList: "К списку",
    defaultBar: "Выберите опрос из списка слева, чтобы увидеть его параметры",
    errorStopPrevPoll: "Чтобы запустить новое голосование, остановите запущенное",
    errorStopPoll: "Сейчас пользователь видит голосование",
    stopped: "Завершен",
    polls: "Тесты и опросы",
    votes: "Голосования",
    progress: "В процессе...",
    notSelect: "Ничего не выбрано",
    downloadResult: "Скачать результат",
    timerRepeatNote: "Повторное прохождение будет доступно через",
    results: "Результаты",
    stopTest: "Остановить тест",
    startTest: "Запустить тест",
    emptyVoting: "Для этой презентации пока не созданы опросы и голосования.",
    emptyTests: "Для этой презентации пока не созданы тестирования.",
    testTimerNotification: "При нажатии на тест, таймер включается автоматически.",
    testIsLoading: "Подождите, в данный момент происходит загрузка теста",
    notAnswered: "Вы не ответили на этот вопрос",
    maxLength: "Максимальное количество символов:",
    noAnswer: "Вы пропустили данный вопрос.",
    noQuestion: "Вопрос отсутствует",
    notFilled: "Название не указано",
    testsHeading: "Тесты / Опросы",
    testWithTimer: "Тест с таймером",
    pressToShowResults: "Для просмотра результатов нажмите на кнопку.",
    timeExpired: "Время истекло",
    repeatedTest: "Вопрос выдан повторно.",
    repeatedTestDescription: "Вопрос выдан повторно, так как он был изменен после вашего ответа.",
    yourAnswer: "Ваш Ответ:",
    postponeQuestion: "Отложить вопрос",
    hasNoOneAnswer: "Вы не ответили на вопрос",
    PollTestResults: "Общие результаты тестирования",
    PollResults: "Общие результаты голосования",
    activityType: "Тип активности:",
    youPassed: "Вы уже прошли данный опрос",
  },
  Photowall: {
    errorImages: "Ошибка при загрузке фотографий",
    noImages: "Организатор еще не добавил фотографии",
    loadImgError: "Неудалось загрузить фотографию",
  },
  Notifs: {
    noNotifs: "Нет новых уведомлений",
    at: "в",
    attention: "ВНИМАНИЕ! УВЕДОМЛЕНИЕ!",
    notification: "Уведомление",
  },
  AverageTeamPoints: {
    avg: "Средний балл команды",
  },
  TeamRating: {
    place: "место из",
  },
  Practice: {
    title: "Выберите регионального менеджера, чтобы перейти в его виртуальную комнату",
    noVirtualRooms: "Список виртуальных комнат пуст",
  },
  TeamTable: {
    atotal: "Итого",
    avg: "Среднее значение",
    presence: "Присутствие",
    polls: "Опросы",
    questions: "Вопросы",
    testing: "Тестирование",
    practice: "Практика",
    photoGame: "Фото / игры",
    total: "Всего",
    additional: "Дополнительно",
    rewards: "Награда",
  },
  ManagerPoints: {
    presence: "Присутствие",
    polls: "Опросы",
    questions: "Вопросы",
    testing: "Тестирование",
    practice: "Практика",
    photoGame: "Фото / игры",
  },
  ManagerTopManager: {
    place: "место",
    points: "б.",
    topManagers: "ТОП руководителей",
  },
  Materials: {
    emptyFolderFile: "Нет файлов и папок",
    back: "Назад",
    name: "название",
    dateUpdate: "дата обновления",
  },
  About: {
    name: "Ваше имя",
    phone: "Ваш телефон",
    message: "Ваше сообщение",
    text:
      // eslint-disable-next-line max-len
      "<p><b>веб-платформа для взаимодействия с аудиторией на внутренних, деловых и образовательных мероприятиях.</b></p><p>На платформе проводят совещания, лекции, форумы, тренинги, семинары, конференции.\nУзнайте, как сделать ваше мероприятие интерактивным.\nОставьте контакты и мы перезвоним вам в течение 1 рабочего дня.</p>",
  },
  userPopup: {
    changePhoto: "Сменить фото",
    loggedAsGuest: "Вы вошли как гость",
    moreInfoAt: "Больше информации на",
    logout: "Выход",
    feedback: "Обратная связь",
    addPhoto: "Добавить фото",
    chooseFromLibrary: "Выбрать из библиотеки",
    makePhoto: "Сделать фото",
    skip: "Пропустить",
    loggedAsSpeaker: "Вы вошли как спикер",
    toProfile: "В личный кабинет",
  },
  techSupport: {
    writeToSupportInline: "Написать в техподдержку",
    techSupport: "Техническая поддержка",
    techSupportShort: "Тех. поддержка",
    writeToSupport: "Написать в службу<br>технической поддержки",
    fullName: "Полное имя",
    email: "Email-адрес",
    subject: "Тема",
    content: "Содержание",
    send: "Отправить",
    getSupport: "Обратиться за помощью",
    allFieldsShouldBeForfilled: "Все поля должны быть заполнены",
    addFiles: "прикрепить файлы",
    appealAccepted: "Ваше обращение принято!",
    appealNotAccepted: "Обращение не отправлено! Повторите попытку через несколько минут.",
  },
  navigation: {
    rooms: "Смена комнаты",
    presentation: "Презентация",
    allPresentations: "Все презентации",
    photowall: "Фотостена",
    questions: "Вопросы",
    roundedTable: "Круглый стол",
    messenger: "Чат",
    video: "Стрим",
    schedule: "Расписание",
    testsAndGames: "Опросы",
    materials: "Материалы",
    participants: "Участники",
    partners: "Партнеры",
    results: "Результаты",
    notifications: "Уведомления",
    aboutUs: "О нас",
    faq: "FAQ",
    brainstorm: "Брейншторм",
    liveWall: "Live-стена",
    information: "Инфо-раздел",
    practice: "Практика",
    broadcast: "Трансляция",
    endPresentation: "Опрос о WhenSpeak",
    hello: "Здравствуйте",
    goToRoom: "Перейти в комнату",
    sendPush: "Отправить уведомление",
    importantPush: "Важное",
    commonPush: "Обычное",
    send: "Отправить",
    timer: "Таймер",
    minutes: "минуты",
    timerIsSet: "Таймер установлен",
    minutesQuantity: "кол-во минут",
    exit: "Выход",
    start: "Старт",
    translation: "Выйти в эфир",
    pin: "Отображать PIN комнаты",
    presentations: "Презентации",
    commonPushMaxLengthWarning: "* Максимальная длина обычного уведомления 250 символов.",
    clearLastPush: "Очистить последнее уведомление",
    miro: "Миро",
  },
  testNGames: {
    correctAnswers: "Правильные ответы",
    incorrectAnswers: "Неправильные ответы",
    persentsCorrectAnswers: "Правильных ответов",
    testing: "Тестирование",
    test: "Тестирование",
    survey: "Опрос",
    question: "Вопрос",
    youAnswered: "Вы ответили",
    point: "Балл",
    points: "Баллы",
    skip: "Пропустить",
    answer: "Ответить",
    close: "Завершить",
    yourResult: "Ваш результат",
    result: "Результат",
    of: "из",
    startGame: "Начать игру",
    gameRules: "Правила игры",
    place: "место",
    kingOfTheMountain: "Царь горы",
    mountainGameRulesText:
      "Вам будет задана серия вопросов. На ответы отведено определенное количество времени. " +
      "Ваша задача как можно быстрее пройти игру и дать максимальное количество правильных ответов",
    passed: "пройден",
    active: "в процессе",
    youHaveToChooseAnswer: "необходимо выборать ответ",
    writeYourAnswerHere: "Напишите ответ здесь",
    theAnswerCantBeEmpty: "Ответ не может быть пустым",
    connectFieldsAtRightAndTheLeft: "Сопоставьте поля слева и справа",
    youHaveToChooseAtLeastOneAnswer: "Вы должны выбрать хотя бы один вариант",
    thankForYourAnswers: "Спасибо за ваши ответы!",
    quizResult: "Результат опроса",
    ratingThanks: "Спасибо за ответ!",
    ratingThanksLabel: "Окно закроется через 3 сек.",
    updateResult: "Обновить",
    testRepeatAfter: "Повторное прохождение будет доступно через",
    testRepeatDisabled: "Повторное прохождение еще недоступно",
    wordCloudPlaceholder: "Одним словом",
    allFieldsAreRequired: "Все поля обязательны для заполнения",
    yourRating: "Ваша оценка",
    overallRating: "Общая оценка",
    averageRating: "Средняя оценка",
    postpone: "Отложить",
  },
  Brainstorm: {
    suggestiIdea: "Предложите свою идею",
    table: "Стол",
    allIdeas: "Все идеи",
    groupIdeas: "Идеи группы",
    brainstormIsntStarted: "Брейншторм еще не запущен",
    chooseTheDesk: "Выберите стол",
    suggestFirst: "Поделитесь своей идеей первым!",
    yourIdeasTopics: "Темы для ваших идей",
    topicLaunched: "Тема запущена",
    controlsNotification: "Нажмите на название, чтобы остановить тему",
    dialogStartTitle: "Включить новую тему?",
    dialogStartBody: "Остановить текущую и включить новую тему?",
    dialogOkText: "Да",
    dialogCancelText: "Нет",
    dialogStopTitle: "Остановить текущую тему?",
    dialogStopBody: "Пользователи не увидят тему на своем устройстве.",
    showIdeas: "Показать идеи",
    hideIdeas: "Скрыть идеи",
    noTopicsYet: "Пока нет тем",
    noTopicsYetDesc: "Их можно добавить в админ. панели",
    topicsList: "Список тем",
    dialogUnderstandText: "Понятно!",
    dialogLostControlTitle: "Кто-то перехватил управление контентом.",
  },
  Pin: {
    title: "Введите код мероприятия",
    error: "Не верный пин",
  },
  Speaker: {
    chooseRoom: "Выберите комнату",
    roomsListEmpty:
      "Список комнат пуст. Создайте в панели администратора активную комнату, спикера и презентацию со слайдом.",
    sidebarBroadcastTabTitle: "Настройка трансляции",
    sidebarParticipantsTabTitle: "Управление участниками",
    sidebarPresentationTabTitle: "Статистика стрима",
  },
  systemTest: {
    isConnectionProblems: "Обнаружены ошибки подключения",
    internetSpeed: "Скорость вашего интернета",
    networkConnectionQuality: "Качество соединения сети (PING)",
    browserAndVersion: "Браузер и версия",
    systemChecking: "Идет проверка системы...",
    lowConnectionQuality:
      "Низкое качество соединения сети. Возможны задержки синхронизации контента",
    useOtherNetwork: "Подключитесь к другой сети с меньшей задержкой соединения.",
    browserLowQuality: "Ваш браузер не соответствует требованиям",
    updateBrowser: "Обновите браузер или скачайте Google Chrome последней версии",
    systemIsReady: "Ваша система готова к работе",
    runSystemTest: "Запустить тест системы",
    testSystemInfoTitle:
      "Тест системы определит, подходит ли ваше устройство для работы с платформой WhenSpeak",
    yourEmail: "Ваш корпоративный e-mail",
    systemTest: "Тест системы",
    pleaseEnterYourEmail: "Пожалуйста, введите ваш корпоративный email",
    yourBrowser: "Ваш браузер",
    portsAndProtocols: "Порты и протоколы",
    download: "Загрузка",
    calculation: "вычисление",
    upload: "Выгрузка",
    jitter: "Джитттер",
    latency: "Задержка",
    broadcastAvailable: "Трансляция доступна",
    testSuccessMessage: "Тест системы пройден успешно. Трансляция видео доступна для Вас.",
    warning: "Внимание",
    broadcastErrorsPossibleBecause: "Возможны ошибки при трансляции видео, причина:",
    yourBrowserIsOutOfDate: "Ваш браузер устарел.",
    lowSpeed: "низкая скорость Вашего интернета",
    requiredPortsNotAvailable: "требуемые порты или протоколы не доступны:",
    useAlternativeNetwork:
      "рекомендуем использовать альтернативную сеть для подключения к сети Интернет.",
    installChrome: "Установить Chrome",
    technicalInformation: "Техническая информация",
    browser: "браузер",
    city: "город",
    countryCode: "код страны",
    countryName: "название страны",
    regionCode: "код региона",
    regionName: "название региона",
    timeZone: "временная зона",
    zipCode: "индекс",
    browserInformation: "Информация о браузере",
    isMobile: "мобильный",
    userAgent: "версия",
    cookieEnabled: "cookie доступны",
    vendor: "производитель",
    product: "техническое имя",
    localStorageSupport: "локальное хранилище поддерживается",
    unknown: "неизв.",
    fail: "неудачно",
    MbS: "Мбит/с",
    ms: "мс",
    suitable: "совместимо",
    problemsDetected: "обнаружены проблемы",
    await: "Ожидайте...",
    undefined: "Не определен",
  },
  Dashboards: {
    all: "Все",
    ball: "балл",
    balls: "балла",
    game: "Фото / игры",
    presence: "Присутствие",
    question: "Вопросы",
    quiz: "Опросы",
    test: "Вопросы",
    training: "Практика",
    placeBalls: "{0} место /{1} б.",
    topExecutives: "ТОП руководителей",
    topTeams: "ТОП команд",
    tableColumnDate: "Дата",
    tableColumnTime: "Время",
    tableColumnActivityType: "Тип активности",
    tableColumnName: "Название",
    tableColumnBalls: "Баллы",
  },
  ResultTests: {
    average: "Среднее значение",
  },
  RoomErrors: {
    roomIsInaccessible: "Комната недоступна в данный момент",
    wrongToken: "Некорректный токен",
    roomIsNotAvailableNow: "Пожалуйста попробуйте снова позднее",
    eventEnds: "Мероприятие завершено",
    roomIsNotExisted: "Комната не существует",
    roomIsNotAttachedToEvent: "Комната не пренадлежит мероприятию",
    eventIsNotExisted: "Мероприятие не существует",
    roomOvercrowded: "Превышен лимит пользователей в комнате. Попробуйте зайти позднее",
    eventIsRemoved: "Мероприятие удалено",
    roomIsRemoved: "Комната удалена",
    connectionLimitForCompany: "Достигнут лимит одномоментных подключений для вашей компании!",
  },
  LiveWall: {
    at: "в",
    placeholder: "Введите сообщение",
  },
  SpeakerAgenda: {
    scheduleNotSetYet: "Расписание еще не задано",
    allHalls: "Все залы",
  },
  SpeakerPresentation: {
    show: "Показать",
    run: "Запустить",
    control: "Управлять",
    right: "Вперед",
    left: "Назад",
    pin_message: "PIN-код сейчас отображается всем зрителям",
    pin_show_label: "Показать код подключения",
    from: "из",
    isActive: "Презентация запущена",
    chooseAnother: "Выбрать другую презентацию",
  },
  Translation: {
    resolution: "Разрешение",
    capture_video: "Запустить плеер",
    stop_capture_video: "Остановить плеер",
    capture_video_on_air: "Трансляция уже запущена",
    start_players: "Транслировать",
    close_players: "Остановить",
    close_stream_for_me: "Остановить меня",
    close_stream_for_all: "Закончить работу для всех",
    no_signal: "Нет сигнала трансляции",
    video: "Видео",
    audio: "Аудио",
    settings: "Настройки стриминга",
    initializing: "Идет подготовка системы видеострима, ожидайте...",
    translation_url_missing: "Не задан URL трансляции",
    options: "Доп. опции",
    media_error: "Для начала трансляции необходимо подключить камеру и микрофон",
    reload_page: "Трансляция остановлена. Вы уверены что хотите покинуть страницу?",
    media_buzy: "Не удалось получить доступ к видео устройству",
    warningMsgHaveNoSpeaker: "Вы не можете присоединиться к трансляции, пока спикер не начнёт её",
    warningMsgUserNotStreamer:
      "Вы не можете присоединиться к трансляции, пока спикер не назначит Вас ведущим",
    unsupportedBrowserIos:
      "Трансляция недоступна в этом браузере. Пожалуйста, перейдите в браузер Safari!",
    unsupportedBrowser: "Трансляция недоступна на этом устройстве.",
    startBroadcast: "Начать трансляцию",
    sessionUsers: "Участники сессии",
    manageUsers: "Управлять участниками",
    userList: "Список участников",
  },
  Slides: {
    quizSlideAnswers: "Ответов",
  },
  Chat: {
    createNewChat: "Создать новый чат",
    allChats: "Все чаты",
    newChat: "Новый чат",
    createGroupChat: "Создать групповой чат",
    writeToParticipant: "Написать участнику",
    newGroupChat: "Новый групповой чат",
    groupChatName: "Название группого чата",
    selectParticipants: "Выберите участников",
    participantsWithCount: "Участники: {participants} ",
    participants: "Участники",
    participantsInfo: "Участников: {participantsCount} | Онлайн: {usersOnlineCount}",
    enterChatName: "Введите название для чата",
    createChat: "Создать чат",
    exitFromChat: "Выйти из чата",
    deleteChatAndExit: "Удалить чат и выйти",
    removeParticipant: "Удалить участника",
    ownUserName: "Я",
    startWriteMessage: "Начните сообщение...",
    viewImage: "Посмотреть",
    loadImage: "Выбрать из галереи",
    removeImage: "Удалить",
    invintation: "пригласил(а) вас в этот чат",
    acceptIvite: "Принять",
    declineIvite: "Отклонить",
    created: "Чат создан.",
    hello: "Напишите ваше первое сообщение!",
    commonTitle: "Общий чат",
    groupTitle: "Командный чат",
    guest: "Гость",
    anonymous: "Аноним",
    updateNameChatBtn: "Ок",
    nameTooLong: "Имя чата слишком длинное",
    errorChatList: "Ошибка при загрузке списка чатов",
  },
  PollRunning: {
    pollIsRunning: "Идёт голосование:",
    divider: "или",
    scanQrcode: "Откройте камеру на смартфоне и наведите на QR-код",
    visitWebsite: "Зайдите на сайт",
    enterTheNumber: "Введите номер",
    nothingSelected: "Ничего не выбрано",
    selectPollToSeeParams: "Выберите опрос из списка слева, чтобы\nувидеть его параметры",
  },
  Abbott: {
    entry: "Вход",
    welcome: "Добро пожаловать на Дистанционную Цикловую Конференцию 2019!",
    acceptTerms: "Я принимаю условия",
    pdpPolicies: "Политики об обработке персональных данных",
    faq: "Ответы на частые вопросы",
    needAcceptTerms: "Необходимо принять условия политики обработки персональных данных",
  },
  broadcast: {
    reconnect: "Переподключение",
    speakerLaunchedNewBroadcast: "Спикер запустил новую трансляцию",
    participantsOnAir: "В эфире",
    participantsHandUp: "Подняли руку",
    participantsViewers: "Зрители",
    andMoreGuests: "И еще {0}...",
    leading: "Ведущий",
    leadings: "Ведущие",
    noBroadcast: "Нет трансляции",
    safeModeToggleOn: "Нажмите для переключения на резервную трансляцию в случае проблем с видео",
    safeModeToggleOff: "Переключиться на основную трансляцию",
    cameraIsOff: "Камера выключена",
    microphoneIsOff: "Микрофон выключен",
    publishFailed: "Не удалось начать трансляцию",
    speaker: "Спикер",
    takeOffAirQuestionStart: "Отключить",
    takeOffAirQuestionEnd: "от эфира?",
    readyToJoin: "готов присоединиться",
    joinRequestDescription: "Позвольте участнику выйти в эфир или скройте запрос",
    connectionFailed: "Не удалось подключиться",
    allParticipants: "Все участники",
    onAir: "В эфире",
    notOnAir: "Не в эфире",
    participants: "Участники",
    goOnAir: "Выйти в эфир",
    goOnAirShort: "В эфир",
    goOffAir: "Выйти из эфира",
    preparing: "Ожидайте...",
    unknownError: "Непредвиденная ошибка. Стрим не доступен.",
    preview: "Предварительный просмотр",
    devicesSearchForAvailable: "Поиск доступных устройств...",
    devicesNotAvailable: "Нет доступных устройств",
    camera: "Камера",
    microphone: "Микрофон",
    externalStream: "Внешний поток",
    repeatConnect: "Попробуйте еще раз",
    restart: "Перезапустить трансляцию",
    servers: "Сервер для подключения",
    rtmpServers: "Сервер для ре-публикации",
    title: {
      play: "Смотреть",
      pause: "Пауза",
      video: "Отключить видео",
      "video-slash": "Включить видео",
      microphone: "Отключить микрофон",
      "microphone-slash": "Включить микрофон",
      "expand-alt": "Войти в полноэкранный режим",
      "compress-alt": "Выйти из полноэкранного режима",
      plus: "Раскрыть список участников",
      times: "Скрыть список участников",
      "rectangle-landscape": "Демонстрация экрана",
    },
    errors: {
      publishFailed: "Не удалось начать трансляцию",
      connectionFailed: "Не удалось подключиться",
      error: "Ошибка!",
      streamErrorTitle: "Трансляция прервана!",
      streamErrorText: "Пожалуйста переподключитесь.",
      streamNotSupport:
        "Для стабильной работы стрима для ПК используйте последнюю версию Google Chrome. " +
        "При работе c мобильных устройств, используйте браузер Safari.",
      deviceNotFound: "Устройство не обнаруженно, пожалуйста смените устройство чтобы начать",
    },
    streamConnectedTitle: "Трансляция подключена.",
    streamNotRunning: "Трансляция еще не запущена",
    streamConnectedText: "Вы можете выйти в эфир.",
    stopThrough: "Завершение через",
    failedToPlay: "Не удалось воспроизвести",
    quality: "Качество",
    captureVideo: "Запустить плеер",
    stopCaptureVideo: "Остановить",
    notAvailableOnYourDevice:
      "Выход в эфир с вашего устройства недоступен в текущей версии платформы",
    streamingSettings: "Настройки стриминга",
    enterName: "Укажите ваше имя,<br>чтобы продолжить работу",
    youNotCanGoOnAir: "Вы не можете выйти в эфир, пока спикер не разрешит вам подключится",
    raiseHand: "Поднять руку",
    participantControls: "Управление ведущим",
    streamCapturePublishedTitle: "Трансляция экрана начата.",
    streamCaptureStoppedTitle: "Трансляция экрана остановлена.",
    streamCaptureFailedTitle: "Ошибка трансляции экрана.",
    recordType: "Тип записи",
    recordTypes: {
      off: "Отключено",
      stream: "Запись с камеры",
      event: "Запись мероприятия",
    },
    recordEventTitle: "Запись началась",
    recordEventErrorTitle: "Произошла ошибка при попытке записи",
    recordEventEndTitle: "Запись остановлена",
    recordDownloadTitle: "Запись завершенна",
    recordStart: "Начать запись",
    recordStarted: "Запись уже запущенна",
    recordDownloadText: "Запись можно скачать по ссылке: ",
    recordDownload: "Скачать запись",
    openInBrowser: "Открыть в браузере",
    copyInBuffer: "Скопировать ссылку в буфер обмена",
    streamIsBlocked:
      "Видеоконференцсвязь не поддерживается в приложении. Воспользуйтесь браузером Safari",
    streamIsBlockedInBrowser:
      "Трансляция не поддерживается в этом браузере. Скопируйте ссылку и воспользуйтесь браузером Safari",
    requestGoOnAirTitle: "Запрос на выход в эфир отправлен",
    requestGoOnAirText: "Вы сможете подключиться когда его одобрят",
    screenSaver: "Начинать и завершать эфир заставкой",
    screenSaverDescription:
      "Участники будут видеть заставку в окне трансляции до начала и по окончании эфира",
    useBuffer: "Использовать буферизацию",
    useBufferDescription: "Повышает стабильность эфира, но добавляет задержку",
    noConnection: "Нет соединения",
    youOnAir: "Вы в эфире",
    youStillOnAir: "Вы всё ещё в эфире!",
    endYourStream: "Хотите завершить трансляцию?",
    stop: "Завершить",
    streamConnectionWarning: `<p>Возникли проблемы с трансляцией: отключение у зрителей через {time}.</p> 
        Пожалуйста, завершите эфир и обратитесь в техподдержку WhenSpeak.`,
    attention: "Внимание!",
    exit: "Выйти",
  },
  listeners: {
    notEnough: "Список пуст",
    phone: "Телефон",
  },
  profile: {
    uploadFailed: "Не удалось загрузить изображение",
  },
  plurals: {
    guest: "0 гостей | {n} гость | {n} гостя | {n} гостей",
    slide: "нет слайдов | {n} слайд | {n} слайда | {n} слайдов",
    point: "баллов | балл | балла | баллов",
  },
  // Перевод компонента Miro
  Miro: {
    buttons: {
      create: "Создать",
    },
    createForm: {
      title: "Форма создания новой доски:",
      name: "Имя доски:",
      description: "Описание доски:",
      sendButton: "Отправить",
    },
    plugText: "Спикер еще не запустил активность. Обратитесь к спикеру или поддержке.",
  },
};
