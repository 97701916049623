import Auth from "@/services/AuthService";
import { auth } from "@/store/modules/store.namespaces";
import { USER_IS_LOGGED_IN } from "@/store/modules/auth/getter-types";
import { LOGIN, LOGIN_BY_TOKEN, LOGOUT } from "@/store/modules/auth/action-types";
import { FROM_GUEST } from "@/constants/user/auth-const";
import { wsErrorType } from "@/utils/errors";
import { ROOM_NUMBER } from "@/constants/room/room-const";

export default async function checkAuth({ to, next, store, nextMiddleware }) {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = to.query.token || Auth.getToken();
  const isLoggedIn = store.getters[`${auth}/${USER_IS_LOGGED_IN}`];

  if (!token) {
    if (localStorage.getItem(ROOM_NUMBER)) {
      localStorage.removeItem(ROOM_NUMBER);
    }
  }
  if (requiresAuth || token) {
    if (!token) {
      next(false);
      return store.dispatch(`${auth}/${LOGOUT}`, { redirect: to.fullPath });
    }

    if (!isLoggedIn) {
      try {
        await store.dispatch(`${auth}/${LOGIN_BY_TOKEN}`, token);
        if (to.query.redirect) {
          return next(to.query.redirect);
        }
      } catch (error) {
        next(false);
        let redirect = !to.query.token && to.fullPath;
        if (error.hasType && error.hasType(wsErrorType.AUTH_ERROR_EXPIRED_TOKEN)) {
          redirect = undefined;
        }
        return store.dispatch(`${auth}/${LOGOUT}`, { redirect });
      }
    }
  }

  if (to.name === "Guest") {
    localStorage.setItem(FROM_GUEST, "true");
    try {
      if (token) {
        await store.dispatch(`${auth}/${LOGIN_BY_TOKEN}`, token);
      } else {
        await store.dispatch(`${auth}/${LOGIN}`, { login: "guest" });
      }
    } finally {
      next({ name: "room", params: { id: to.params.guestRoom } });
    }
  }

  return nextMiddleware();
}
