import { landing } from "@/store/modules/store.namespaces";
import { HAS_CORP_LANDING, MANAGER_LOGIN_AVAILABLE } from "@/store/modules/landing/getter-types";

export default function checkCorpLanding({ to, next, store, nextMiddleware }) {
  const isManagerAvailable =
    to.query.owner && store.getters[`${landing}/${MANAGER_LOGIN_AVAILABLE}`];
  const isGuest = to.query.guest || to.query.token || to.meta.isGuest;
  const routeNames = ["Login"];

  if (!store.getters[`${landing}/${HAS_CORP_LANDING}`] && to.name === "corpLogin") {
    return next({ name: "Login", replace: true });
  }

  if (
    routeNames.includes(to.name) &&
    !isManagerAvailable &&
    !isGuest &&
    store.getters[`${landing}/${HAS_CORP_LANDING}`]
  ) {
    return next({ name: "corpLogin", replace: true });
  }

  return nextMiddleware();
}
