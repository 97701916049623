import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    scheduleSpeaker: {},
    scheduleRewardList: {},
    isRewardSet: false,
    showRewardButton: {},
    shownAgendaEvent: null,
    shownAgendaEventId: null,
    agendaPillsId: 0,
    schedule: {},
    agendaLoadingStatus: false,
    selectedScheduleSpeaker: null,
  },
  getters,
  actions,
  mutations,
};
