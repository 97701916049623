import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    availableGames: {},
    king: {
      leadersList: [],
      points: null,
    },
    gameKingResult: {},
    currentActiveGame: {
      name: "",
      pollsList: [],
      currentQuestionIndex: 0,
      pollType: "",
      postGameScreen: false,
      postGameScreenVisible: false,
      postGameScreenAction: "",
      answeredQuestions: [],
      skippedQuestions: [],
      gameTime: 0,
      lastQuestionTime: null,
    },
  },
  actions,
  mutations,
};
