export const SLIDE_TYPE = {
  extimg: "extimg",
  img: "img",
  video: "video",
  test: "test",
  timer: "timer",
  results: "results",
};

export const SLIDE_PREVIEW_IMG_SIZE = "/resize_274_200_75";
export const TYPES_WITHOUT_SRC = [SLIDE_TYPE.test, SLIDE_TYPE.timer, SLIDE_TYPE.results];
