import Vue from "vue";

/**
 * Функция осуществляет плавный переход скрола в указзанное положение
 *
 * @param {HTMLElement} element - Элемент к которому стоит применить анимацию
 * @param {object} options - Обьект опций
 * @param {number} [options.top] - Место назначения скрола по координате Y в px
 * @param {number} [options.left] - Место назначения скрола по координате X в px
 * @param {number} [options.duration] - Время за которое скрол дойдет в милисекундах
 */
export default function scrollTo(element, { top, left, duration }) {
  let durationInner = duration;
  if (duration === 0) {
    const scrollLeft = left ?? 0;
    const scrollTop = top ?? 0;
    try {
      element.scrollTo(scrollLeft, scrollTop);
    } catch (e) {
      element.scrollLeft = scrollLeft;
      element.scrollTop = scrollTop;
    }
    return;
  }
  const { scrollLeft, scrollTop } = element;
  const diffLeft = left === undefined ? 0 : left - scrollLeft;
  const diffTop = top === undefined ? 0 : top - scrollTop;
  // По умолчанию задержка 1px в милисекунду
  if (duration === undefined) {
    const maxDiff = Math.max(Math.abs(diffLeft), Math.abs(diffTop));
    durationInner = Math.round(maxDiff);
  }
  let start;
  // Используем Апи для плавных анимаций
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Расчитываем на сколько должен сдвинуться скрол в данный момент
    const time = timestamp - start;
    const percent = Math.min(time / durationInner, 1);
    // Переход к скролу
    try {
      element.scrollTo(
        Math.round(scrollLeft + diffLeft * percent),
        Math.round(scrollTop + diffTop * percent),
      );
    } catch (e) {
      // IE, EDGE <= 18
      element.scrollLeft = Math.round(scrollLeft + diffLeft * percent);
      element.scrollTop = Math.round(scrollTop + diffTop * percent);
    }

    if (time < durationInner) {
      window.requestAnimationFrame(step);
    }
  });
}

const scrollToPlugin = {
  install(instance) {
    instance.mixin({
      methods: {
        $scrollTo: scrollTo,
      },
    });
  },
};

Vue.use(scrollToPlugin);
