<template>
  <div class="form-lang">
    <transition name="slide" mode="out-in">
      <ws-button
        v-if="currentLanguage === 'rus'"
        :key="'eng'"
        color="primary"
        outlined
        class="form-lang__btn"
        :class="{ 'form-lang__btn--landing': !inRoom }"
        :style="inRoom && buttonStyles"
        @click="selectLanguage('eng')"
      >
        eng
      </ws-button>
      <ws-button
        v-if="currentLanguage === 'eng'"
        :key="'rus'"
        color="primary"
        outlined
        class="form-lang__btn"
        :class="{ 'form-lang__btn--landing': !inRoom }"
        :style="inRoom && buttonStyles"
        @click="selectLanguage('rus')"
      >
        rus
      </ws-button>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { LAST_CHOOSED_LANGUAGE } from "@/utils/i18n-setup";
import { LAST_QUERY_PARAMS } from "@/constants/user/auth-const";
import { room } from "@/store/modules/store.namespaces";
import { SWITCH_ROOM_LANGUAGE } from "@/store/modules/common/action-types";
import WsButton from "@/components/base/WsButton";

export default {
  name: "LandingLangSelector",
  components: {
    WsButton,
  },
  computed: {
    ...mapState(room, {
      currentLanguage: "interfaceLanguage",
      roomTheme: "styleColors",
    }),
    inRoom() {
      const notLanding = ["room", "speaker"];
      return this.$route.matched.some(match => notLanding.includes(match.name));
    },
    buttonStyles() {
      return this.roomTheme
        ? {
            color: this.roomTheme.baseColor,
            borderColor: this.roomTheme.baseColor,
          }
        : {};
    },
  },
  methods: {
    ...mapActions(room, {
      switchLanguage: SWITCH_ROOM_LANGUAGE,
    }),
    selectLanguage(language) {
      if (language !== this.currentLanguage) {
        localStorage.setItem(LAST_CHOOSED_LANGUAGE, language);
        localStorage.removeItem(LAST_QUERY_PARAMS);
        this.switchLanguage(language).then(() => {
          const v = document.querySelector(".vjs-icon-placeholder");
          if (v) {
            v.dataset.content = this.$t("common.videoText");
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.slide-enter-active {
  transition: all 0.2s ease;
}
.slide-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-enter,
.slide-leave-to {
  transform: translateX(5px);
  opacity: 0;
}

.form-lang {
  display: inline-block;
  margin: 5px 0 10px;

  /* todo make base-button for it*/
  &__btn {
    display: block;
    position: relative;
    min-width: 55px;
    padding: 4px 5px 6px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    background-color: transparent;
    background-size: cover;
    background-position: center center;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      color: var(--mute-text-color);
      border-color: var(--mute-text-color);
      opacity: 0.8;
    }

    &--landing {
      color: var(--login-button-color);
      border-color: var(--login-button-color);

      &:hover {
        color: var(--login-button-color);
        border-color: var(--login-button-color);
        opacity: 0.8;
      }
    }
  }
}
</style>
