export const GET_AGENDA_EVENT = "GET_AGENDA_EVENT";
export const SHOW_AGENDA_EVENT = "SHOW_AGENDA_EVENT";
export const GET_REWARDS_LIST = "GET_REWARDS_LIST";
export const SEND_SETTED_REWARDS = "SEND_SETTED_REWARDS";
export const GET_REWARDS = "GET_REWARDS";
export const GET_SCHEDULE = "GET_SCHEDULE";





