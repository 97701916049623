import BaseHttpService from "@/services/http/BaseHttpService";
import config from "@/settings/config";

const baseURL = `${config.serverUrl}/api/`;

class AdminApiHttpService extends BaseHttpService {
  constructor() {
    super({ baseURL });
  }

  register({ fio, email, password, repassword }) {
    const payload = `fio=${fio}&email=${email}&password=${password}&password_confirm=${repassword}&region=нет`;
    return this.post("registerUser", payload);
  }

  login({ email, pass, lang }) {
    const params = { lang };
    const payload = `email=${email}&password=${pass}`;
    return this.post("sessions", payload, { params });
  }

  loginBySurname({ login, pass, fio }) {
    const params = {
      email: login,
      nestle_auth_password: pass,
      fio,
    };
    return this.get("nestleAuth", { params });
  }

  restorePassword({ login }) {
    return this.post("restorePass", `login=${login}`);
  }
}

export default new AdminApiHttpService();
