<template>
  <div :class="$style.container">
    <landing-faq-button v-if="!!faqUrl" :class="$style.button" :faq-url="faqUrl" />
    <div />
    <landing-lang-selector v-if="langSelector" :class="$style.button" />
  </div>
</template>

<script>
import LandingLangSelector from "@/components/common/landing/LandingLangSelector";
import LandingFaqButton from "@/components/common/landing/LandingFaqButton";

/**
 * Дополнительные кнопки для выбора языка и перехода на FAQ по URL
 *
 * @vue-prop {string} [faqUrl=""] - Сыллка на FAQ (если пустая то кнопки не видно)
 * @vue-prop {boolean} [langSelector=false] - Показывать ли выбор языка
 */
export default {
  name: "LandingAdditionalButtons",
  components: {
    LandingLangSelector,
    LandingFaqButton,
  },
  props: {
    faqUrl: {
      type: String,
      default: "",
    },
    langSelector: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: -5px 0 -10px;
}

.button {
  flex: 0 0 auto;
  min-height: 1px;
}
</style>
