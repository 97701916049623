import "vuejs-dialog/dist/vuejs-dialog.min.css";
import "@whenspeak/ui-kit/dist/ui-kit.css";
import "./styles/main.less";
import "./styles/styles.scss";
import "./components/_globals";
import "jodit/build/jodit.min.css";

import Vue from "vue";
import VueApollo from "vue-apollo";
import VuejsDialog from "vuejs-dialog";
import VClipboard from "v-clipboard";
import moment from "moment";
import Notifications from "vue-notification";
import objectFitImages from "object-fit-images";
import { sync } from "vuex-router-sync";

import "./plugins";
import { setStoreToService } from "@/services/http/helpers/HttpUtils";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import router from "./routes/router";
import store from "./store/store";
import { getLocale, i18n, loadLanguageAsync } from "./utils/i18n-setup";

import App from "./App.vue";
import Page from "./components/common/page/Page";
import PageTitle from "./components/common/page/PageTitle";
import PageMessage from "./components/common/page/PageMessage";
import apolloClient from "./components/chat/Connect";

// loads the Icon plugin
UIkit.use(Icons);

/* eslint-disable import/prefer-default-export */
/**
 *
 */
export function createAppInstance() {
  if (!window.globalThis) {
    // eslint-disable-next-line no-restricted-globals
    window.globalThis = self;
  }

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
  });

  Vue.prototype.$bus = new Vue();
  Vue.config.productionTip = false;

  // own components
  Vue.component(Page.name, Page);
  Vue.component(PageTitle.name, PageTitle);
  Vue.component(PageMessage.name, PageMessage);

  // external components
  Vue.use(VueApollo);
  Vue.use(Notifications);
  Vue.use(VuejsDialog);
  Vue.use(VClipboard);

  localStorage.setItem("documentReferrer", document.referrer);
  objectFitImages();
  sync(store, router);

  // configure services
  setStoreToService(store);

  const { localeApp, localeMoment } = getLocale(null, true);
  moment.locale(localeMoment);
  loadLanguageAsync(localeApp).then(() => {
    new Vue({
      router,
      store,
      apolloProvider,
      i18n,
      render: h => h(App),
    }).$mount("#app");
  });
}
