export const SET_UNREAD_NOTIFICATIONS = "SET_UNREAD_NOTIFICATIONS";
export const CLEAR_READ_NOTIFS = "CLEAR_READ_NOTIFS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_PAGE = "SET_PAGE";
export const SET_PAGES_COUNT = "SET_PAGES_COUNT";
export const SET_LAST_NOTIFICATION = "SET_LAST_NOTIFICATION";
export const SET_LAST_SYSTEM_NOTIFICATION = "SET_LAST_SYSTEM_NOTIFICATION";






