import BaseHttpService from "@/services/http/BaseHttpService";
import config from "@/settings/config";

const baseURL = config.serverUrl + "/ws/api/";

class AdminWsHttpService extends BaseHttpService {
  constructor() {
    super({ baseURL });
  }
}

export default new AdminWsHttpService();
