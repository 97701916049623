export const SET_POLL_CHAIN = "SET_POLL_CHAIN";
export const SET_ACTIVE_POLL = "SET_ACTIVE_POLL";
export const SET_POLLCHAIN_SCORE = "SET_POLLCHAIN_SCORE";
export const SET_POLL_SCORE = "SET_POLL_SCORE";
export const SET_SENDING_STATUS = "SET_SENDING_STATUS";
export const SET_INTERACTIVE_TYPE = "SET_INTERACTIVE_TYPE";
export const SET_PREVIOUS_ROUTE = "SET_PREVIOUS_ROUTE";
export const CLEAR_POLLCHAIN = "CLEAR_POLLCHAIN";
export const SET_IS_BLOCK_CLOSE_POLL = "SET_IS_BLOCK_CLOSE_POLL";
export const CHAIN_STEP_FORWARD = "CHAIN_STEP_FORWARD";
export const SET_SHOW_RESULT_AS_COMMON = "SET_SHOW_RESULT_AS_COMMON";
export const SKIP_TEST_POLL = "SKIP_TEST_POLL";
export const SET_POLLCHAIN_DATA_TIMER = "SET_POLLCHAIN_DATA_TIMER";
export const SET_CURRENT_RESULT_ID = "SET_CURRENT_RESULT_ID";
export const SET_TEST_RESULTS = "SET_TEST_RESULTS";
export const SET_WS_POLL_AVAILABLE = "SET_WS_POLL_AVAILABLE";
export const SET_WS_POLL_COMPLETE = "SET_WS_POLL_COMPLETE";
export const SET_WS_POLL_MODAL_OPEN = "SET_WS_POLL_MODAL_OPEN";
export const SET_TESTS = "SET_TESTS";
export const SET_INTERACTIVE_THEME = "SET_INTERACTIVE_THEME";
export const RESET_INTERACTIVE_THEME = "RESET_INTERACTIVE_THEME";
export const SET_INTERACTIVE_BACKGROUND = "SET_INTERACTIVE_BACKGROUND";
export const RESET_INTERACTIVE_BACKGROUND = "RESET_INTERACTIVE_BACKGROUND";
export const SET_CHEAT_POINTS = "SET_CHEAT_POINTS"