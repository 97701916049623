<template>
  <layout-auth-form :loading="isLoading" @submit.prevent="loginUser">
    <auth-input
      v-if="emailInputOptions.visible"
      v-model="email"
      data-testid="loginEmailInput"
      type="text"
      :disabled="isLoading"
      :placeholder="emailInputOptions.placeholder"
      :error="!!authDeclinedError"
      :error-message="authEmailStatus"
      @input="clearErrors"
    />
    <auth-input
      v-if="passwordInputOptions.visible"
      v-model="password"
      data-testid="loginPasswordInput"
      :type="passwordInputOptions.type"
      :disabled="isLoading"
      :error="!!authDeclinedError"
      :error-message="authPassStatus"
      :placeholder="passwordInputOptions.placeholder"
      @input="clearErrors"
    />
    <agreement v-if="isAgreement" v-model="processingPersonData" />
    <auth-button
      v-if="visibleButtons.login"
      data-testid="loginButtonLogin"
      type="submit"
      filled
      :disabled="disabledLoginButton"
    >
      {{ $t("Login.login") }}
    </auth-button>
    <auth-button
      v-if="visibleButtons.register"
      data-testid="loginButtonRegister"
      @click="$router.push({ name: 'Registration' })"
    >
      {{ $t("Login.register") }}
    </auth-button>
    <auth-button
      v-if="visibleButtons.loginGuest"
      data-testid="loginButtonLoginGuest"
      :filled="loginType === 'anonymous'"
      :disabled="disabledAuthButtons"
      @click="loginGuest"
    >
      {{ $t("Login.guestLogin") }}
    </auth-button>
    <auth-button
      v-if="visibleButtons.test"
      data-testid="loginButtonTest"
      @click="$router.push({ name: 'TestUserSystem' })"
    >
      {{ $t("systemTest.systemTest") }}
    </auth-button>
    <social-auth v-if="isSocialAuth" @auth="socialAuth" />
    <template v-if="isRestorePassword" #additions>
      <router-link data-testid="loginRestorePassword" :to="{ name: 'restorePassword' }">
        {{ $t("Login.restorePassword") }}
      </router-link>
    </template>
    <template v-if="authDeclinedError" #error>
      <div v-html="$t('Login.authDeclinedError')" />
    </template>
  </layout-auth-form>
</template>

<script>
import { mapActions } from "vuex";
import { auth, room } from "@/store/modules/store.namespaces";
import { LOGIN } from "@/store/modules/auth/action-types";
import { ENTER_TO_ROOM } from "@/store/modules/common/action-types";
import { LOGIN_FORM_TYPE } from "@/constants/user/auth-const";
import { VALID_MAIL_REGEXP } from "@/constants/utils/utils-const";
import useLanding from "@/components/common/landing/mixins/useLanding";

import Agreement from "@/components/common/elements/Agreement";
import SocialAuth from "@/components/common/social/SocialAuth";
import AuthInput from "@/components/common/auth/AuthInput";
import AuthButton from "@/components/common/auth/AuthButton";
import LayoutAuthForm from "@/components/layouts/LayoutAuthForm";

/**
 * Страница логина осуществляет осуществляет разные типы авторизации,
 * в зависимости от настроек лендига
 *
 * @vue-prop {string} redirect - Путь на который нужно редиректить после авторизации
 * @vue-prop {boolean} owner - Владелец, у него всегда стандартный тип авторизации
 * @vue-data {string} email - Поле ввода логина, емайл или имени
 * @vue-data {string} password - Поле ввода пароля или фамилии
 * @vue-data {boolean} processingPersonData - Соглашение с Условиями политики обработки данных
 * @vue-data {boolean} isLoading - Осуществление проверки и загрузки данных пользователя
 * @vue-data {string} authEmailStatus - Ошибка валидации ввода email
 * @vue-data {string} authPassStatus - Ошибка валидации ввода password
 * @vue-data {boolean} authDeclinedError - Ошибка с сервера после отправки данных
 * @vue-computed {object} emailInputOptions - Настройки для поля ввода email, видимость и плейсхолдер
 * @vue-computed {object} passwordInputOptions - Настройки для поля ввода password, видимость и плейсхолдер
 * @vue-computed {boolean} isAgreement - Есть ли на странице блок востановления пароля
 * @vue-computed {boolean} isSocialAuth - Есть ли на странице блок востановления пароля
 * @vue-computed {object} visibleButtons - Видимость кнопок на страниие
 * @vue-computed {object} isRestorePassword - Есть ли на странице блок востановления пароля
 * @vue-computed {boolean} disabledAuthButtons - Активность кнопок авторизации, логин и логин гостем
 * @vue-computed {boolean} disabledLoginButton - Активность кнопоки логина
 * @vue-computed {boolean} formNotValid - Валидна ли форма авторизации
 */
export default {
  name: "ViewAuthLogin",
  components: {
    LayoutAuthForm,
    AuthButton,
    AuthInput,
    SocialAuth,
    Agreement,
  },
  mixins: [useLanding],
  props: {
    redirect: {
      type: String,
      default: "",
    },
    owner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      processingPersonData: true,
      isLoading: false,
      authEmailStatus: "",
      authPassStatus: "",
      authDeclinedError: false,
    };
  },
  computed: {
    emailInputOptions() {
      const textByVisibleType = {
        [LOGIN_FORM_TYPE.EMAIL]: "email",
        [LOGIN_FORM_TYPE.STANDARD]: "loginEmail",
        [LOGIN_FORM_TYPE.SURNAME]: "name",
      };
      return {
        visible: Object.keys(textByVisibleType).includes(this.loginType),
        placeholder: this.$t(`Login.${textByVisibleType[this.loginType] || "email"}`),
      };
    },
    passwordInputOptions() {
      const textByVisibleType = {
        [LOGIN_FORM_TYPE.STANDARD]: "password",
        [LOGIN_FORM_TYPE.SURNAME]: "surname",
      };
      return {
        visible: Object.keys(textByVisibleType).includes(this.loginType),
        placeholder: this.$t(`Login.${textByVisibleType[this.loginType] || "password"}`),
        type: this.loginType === "surname" ? "text" : "password",
      };
    },
    isAgreement() {
      return this.template.options?.personal_data ?? true;
    },
    isSocialAuth() {
      return !this.owner && this.template.options?.networks;
    },
    visibleButtons() {
      return {
        login: !this.isAnonymousType,
        register:
          !this.owner &&
          this.template.options?.signup &&
          !this.isAnonymousType &&
          !this.isOAuth2Type,
        loginGuest: !this.owner && (this.template.options?.guest || this.isAnonymousType),
        test: this.template.options?.system_test,
      };
    },
    isRestorePassword() {
      return this.template.options?.signup && this.isStandardType;
    },
    disabledAuthButtons() {
      return !this.processingPersonData || this.isLoading;
    },
    disabledLoginButton() {
      return this.disabledAuthButtons || this.formNotValid;
    },
    formNotValid() {
      return !!this.authDeclinedError || !!this.authEmailStatus || !!this.authPassStatus;
    },
  },
  methods: {
    ...mapActions(auth, {
      login: LOGIN,
    }),
    ...mapActions(room, {
      enterToRoom: ENTER_TO_ROOM,
    }),
    /**
     * Распределение метода аунтификации по типу авторизации из настроек лендинга
     *
     * @returns {undefined|void} - Возвращает результат выполнения авторизации
     */
    loginUser() {
      switch (this.loginType) {
        case LOGIN_FORM_TYPE.OAUTH2:
          return this.oauth2Auth();
        case LOGIN_FORM_TYPE.EMAIL:
          return this.mailAuth();
        case LOGIN_FORM_TYPE.SURNAME:
          return this.surnameAuth();
        default:
          return this.standardAuth();
      }
    },
    /**
     * Метод для стандартной аунтификации, содержит в себе валидацию
     */
    standardAuth() {
      if (!this.email) {
        this.authEmailStatus = this.$t("Login.emailError");
        return;
      }
      if (!this.password) {
        this.authPassStatus = this.$t("Login.passwordError");
        return;
      }
      this.loginHandler({ login: this.email, pass: this.password });
    },
    /**
     * Метод для аунтификации по имени и фамилии, содержит в себе валидацию
     */
    surnameAuth() {
      if (!this.email) {
        this.authEmailStatus = this.$t("Login.nameError");
        return;
      }
      const domain = window.location.hostname.substring(0, window.location.hostname.indexOf("."));
      const email = this.email.toLocaleLowerCase().trim();
      const name = this.password.toLocaleLowerCase().trim();
      const login = `${domain}_${name}_${email}`;

      this.loginHandler({ login, pass: "111111" });
    },
    /**
     * Метод для аунтификации по емайл, содержит в себе валидацию
     */
    mailAuth() {
      if (!this.email) {
        this.authEmailStatus = this.$t("Login.emailError");
        return;
      }
      if (this.email.indexOf(this.template.mask) === -1) {
        this.authEmailStatus = this.$t("Registration.emailError");
        return;
      }
      if (!VALID_MAIL_REGEXP.test(this.email)) {
        this.authEmailStatus = this.$t("Login.emailError");
        return;
      }
      this.loginHandler({ login: this.email, pass: "111111" });
    },
    /**
     * Метод для аунтификации как гость
     */
    loginGuest() {
      this.loginHandler({ login: "guest" });
    },
    /**
     * Обработчик логина, редиректит в случае если есть куда редиректить а также осуществляет вход в комнату
     * после аунтификации
     *
     * @param {object} settings - Данные для аунтификации
     * @param {string} settings.login - Логин
     * @param {string|undefined} settings.pass - Пароль
     */
    async loginHandler({ login, pass }) {
      try {
        this.isLoading = true;
        await this.login({ login, pass });
        if (this.redirect) {
          await this.$router.replace(this.redirect);
        } else {
          await this.enterToRoom({ room: "default" });
        }
      } catch {
        this.authDeclinedError = true;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Очистка всех ошибок
     */
    clearErrors() {
      this.authPassStatus = "";
      this.authEmailStatus = "";
      this.authDeclinedError = false;
    },
  },
};
</script>
