import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import AuthService from "@/services/AuthService";
import config from "@/settings/config";
import "whatwg-fetch";

const httpLink = createHttpLink({
  uri: config.chatServerUrl,
});

const wsLink = new WebSocketLink({
  uri: config.chatSocketUrl,
  options: {
    reconnect: true,
    lazy: true,
    minTimeout: 10000,
    timeout: 10000,
    connectionParams() {
      return {
        "x-id": AuthService.getToken(),
      };
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const token = AuthService.getToken();
  return {
    headers: {
      ...headers,
      "x-id": token ? token : "",
    },
  };
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink),
);

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
});

export default apolloClient;
