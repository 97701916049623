import checkCorpLanding from "@/middlewares/router/checkCorpLanding";
import checkAuth from "./checkAuth";
import checkRole from "./checkRole";
import checkRoom from "./checkRoom";
import checkReset from "./checkReset";
import checkTest from "./checkTest";

// sorted by priority
const middlewares = [checkReset, checkAuth, checkRole, checkRoom, checkTest, checkCorpLanding];

export default middlewares;
