<template>
  <ws-button
    :class="[$style.button, { [$style.button_filled]: filled }]"
    block
    lg
    color="primary"
    :outlined="!filled"
    font-size="xl"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </ws-button>
</template>

<script>
import WsButton from "@/components/base/WsButton";

/**
 * Хранит в себе общую стилистику кнопок для аунтификации
 *
 * @vue-prop {boolean} filled - Является ли кнопка главной
 */

export default {
  name: "AuthButton",
  components: { WsButton },
  inheritAttrs: false,
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.button {
  font-weight: 400;
  border-radius: 14px;

  &:not(&_filled) {
    border: 1px solid var(--ws-color-primary);
  }

  &:hover:not(:disabled) {
    box-shadow: 0 0 4px $gray-dark;
  }

  &_filled {
    color: var(--login-button-text-color);
  }
}
</style>