import actions from "./actions";
import mutations from "./mutations";
import { RESOLUTIONS } from "@/constants/broadcast/broadcast-const";

export default {
  state: {
    streamType: null,
    isLowLatency: false,
    isActive: false,
    isMuteSound: false,
    isMuteVideo: false,
    resolution: RESOLUTIONS[640],
    inputVideo: null,
    inputAudio: null,
    playerInstance: null,
    constraints: null,
    streamIsActive: false,
    streamSafeMode: false,
    streamLowLatency: false,
    streamAir: false,
    availableDevices: {
      audio: [],
      video: [],
    },
    selectedVideo: null,
    selectedAudio: null,
  },
  actions,
  mutations,
};
