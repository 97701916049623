// Временно, пока разработчик vue-i18n не запилит
// нормальную плюрализацию (https://github.com/kazupon/vue-i18n/issues/78).
// @FIXME We might make plurals normally

export default {
  eng: {
    file: ["file", "files", "files"],
    point: ["point", "points", "points"],
    voted: ["Voted", "Voted", "Voted"],
    participant: ["participant", "participants"],
    minutesV: ["minute", "minutes", "minutes"],
    secondsV: ["second", "seconds", "seconds"],
  },
  rus: {
    file: ["файл", "файла", "файлов"],
    point: ["балл", "балла", "баллов"],
    voted: ["Проголосовал", "Проголосовали", "Проголосовали"],
    participant: ["участник", "участника", "участников"],
    minutesV: ["минуту", "минуты", "минут"],
    secondsV: ["секунду", "секунды", "секунд"],
  },
};
