<template>
  <div :class="className">
    <template v-if="$slots.header">
      <div class="Page__header">
        <slot name="header"></slot>
      </div>
    </template>
    <template v-if="$slots.default">
      <div class="Page__content">
        <slot v-if="isLoading" name="loading">
          <page-loader></page-loader>
        </slot>
        <slot v-else name="default"></slot>
      </div>
    </template>
    <template v-if="$slots.footer">
      <div class="Page__footer">
        <slot name="footer"></slot>
      </div>
    </template>
  </div>
</template>
<script>
import Loader from "../elements/Loader";

export default {
  name: "Page",
  components: {
    PageLoader: Loader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    className() {
      return {
        Page: true,
        Page_loading: this.isLoading,
      };
    },
  },
};
</script>
<style lang="less" scoped>
.Page {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 1140px;
  margin: auto;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  &__content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    text-overflow: ellipsis;
  }
  &_loading {
    cursor: wait;
    user-select: none;
    .Page__content {
      align-items: center;
      justify-content: center;
      @media (max-width: 640px) {
        height: 100%;
        margin: auto;
      }
    }
  }
}
</style>
