import screenfull from "screenfull";
import Vue from "vue";

/**
 * Обработка unbind для директивы
 *
 * @namespace $fullscreen
 * @param {object} reactorComponent - компонент
 * @param {string} group - группа
 * @param {object} modifiers - модификаторы директивы
 * @param {HTMLElement} el - элемент на котором использована директива
 */
function handleUnbind(reactorComponent, group, modifiers, el) {
  if (reactorComponent.groups.has(group)) {
    if (modifiers?.attach) {
      if (reactorComponent.parentEls.has(el)) {
        reactorComponent.returnEls(reactorComponent.parentEls.get(el), [el]);
        reactorComponent.parentEls.delete(el);
      }
      reactorComponent.groups.get(group).attach = reactorComponent.groups
        .get(group)
        .attach.filter(node => node !== el);
    } else {
      reactorComponent.groups.get(group).el = null;
    }
  }
}

/**
 * Обработка inserted для директивы и прикрепление новых элементов к текущему элементу фуллскрина
 *
 * @namespace $fullscreen
 * @param {object} reactorComponent - компонент
 * @param {string} group - группа
 * @param {object} modifiers - модификаторы директивы
 * @param {HTMLElement} el - элемент на котором использована директива
 */
function handleInserted(reactorComponent, group, modifiers, el) {
  if (!reactorComponent.groups.has(group)) {
    reactorComponent.groups.set(group, {
      el: null,
      attach: [],
    });
  }

  if (modifiers?.attach) {
    reactorComponent.groups.get(group).attach.push(el);
    if (reactorComponent.currentGroup === group && reactorComponent.isFullscreen) {
      reactorComponent.moveEls(reactorComponent.element, [el]);
    }
  } else {
    reactorComponent.groups.get(group).el = el;
  }
}

/**
 * Плагин для управления входом и выходом из режима fullscreen
 * Содержит:<br>
 *   1. Реактивный инстанс для контроля и управления<br>
 *   2. Директиву v-fullscreen
 *   3. Миксин примешивающий $fullscreen
 *
 * @namespace $fullscreen
 * @type {{install: void}}
 */
const fullscreen = {
  install(VueInstance) {
    /**
     * Компонент для создания реактивности плагина
     *
     * @memberof $fullscreen
     * @inner
     * @vue-data {WeakMap} parenEls - родители перенесенных элементов
     * @vue-data {boolean} [isFullscreen=false] - включен ли режим фуллскрин
     * @vue-data {boolean} [isEnabled=false] - доступен ли фуллскрин нативно
     * @vue-data {Map} groups - коллекция для группировки основного элемента и тех которые должны быть прикреплены к нему
     * @vue-data {Function | null} boundChangeHandler - обработчик изменения состояния фуллскрина
     * @vue-data {HTMLElement} element - элемент который на данный момент в режиме фуллскрин
     * @vue-data {string} currentGroup - текущая группа которая в режиме фуллскрин
     */
    const reactorComponent = new VueInstance({
      data() {
        return {
          parentEls: new WeakMap(),
          isFullscreen: false,
          isEnabled: false,
          groups: new Map(),
          boundChangeHandler: null,
          element: null,
          currentGroup: "",
        };
      },
      created() {
        this.isEnabled = screenfull.isEnabled;
      },
      methods: {
        /**
         * Перемещает элементы из их родителя в элемент который будет запущен в фуллскрине
         *
         * @param {HTMLElement} target - элемент для фуллскрина
         * @param {HTMLElement[]} nodes - элементы для перемещения
         */
        moveEls(target, nodes) {
          const fragment = document.createDocumentFragment();
          nodes.forEach(node => {
            this.parentEls.set(node, node.parentNode);
            fragment.appendChild(node);
          });
          target.appendChild(fragment);
        },
        /**
         * Возвращает перемещенные элементы в их родительские элементы
         *
         * @param {HTMLElement[]} nodes - элементы для перемещения
         * @param {HTMLElement} [parentEl] - родитель элементов
         */
        returnEls(nodes, parentEl) {
          nodes.forEach(node => {
            if (parentEl) {
              parentEl.appendChild(node);
            } else if (this.parentEls.has(node)) {
              const parent = this.parentEls.get(node);
              if (parent) {
                parent.appendChild(node);
              }
              this.parentEls.delete(node);
            }
          });
        },
        /**
         * Обрабатывает изменения состояния фуллскрина по ESC
         *
         * @param {HTMLElement} target - элемент для фуллскрина
         * @param {HTMLElement[]} nodes - элементы для перемещения
         */
        changeHandler(target, nodes) {
          if (!screenfull.isFullscreen) {
            this.returnEls(nodes);
            screenfull.off("change", this.boundChangeHandler);
            this.boundChangeHandler = null;
            this.isFullscreen = false;
            this.element = null;
          }
        },
        /**
         * Вход в режим фуллскрина или эмулирование входа
         *
         * @async
         * @param {string} group - имя группы
         * @returns {Promise<void>}
         */
        async enter(group) {
          const { el, attach } = this.groups.get(group);
          if (this.isEnabled) {
            try {
              if (attach.length) {
                this.moveEls(el, attach);
              }
              await screenfull.request(el, { navigationUI: "hide" });
              this.element = el;
              this.boundChangeHandler = this.changeHandler.bind(this, el, attach);
              screenfull.on("change", this.boundChangeHandler);
            } catch (e) {
              throw new Error(`Fullscreen error: ${e}`);
            }
          } else {
            this.element = document.querySelector("#root");
            this.emulateEnter(el, attach);
          }
          this.isFullscreen = true;
          this.currentGroup = group;
        },
        /**
         * Выход из режима фуллскрина или эмулирование выхода
         *
         * @async
         * @param {string} group - имя группы
         * @returns {Promise<void>}
         */
        async exit(group) {
          const { el, attach } = this.groups.get(group);
          if (this.isEnabled) {
            if (attach.length) {
              this.returnEls(attach);
            }
            screenfull.off("change", this.boundChangeHandler);
            this.boundChangeHandler = null;
            await screenfull.exit();
          } else {
            this.emulateExit(el, attach);
          }
          this.isFullscreen = false;
          this.element = null;
          this.currentGroup = "";
        },
        /**
         * Эмулирование входа в фуллскрин
         *
         * @param {HTMLElement} target - элемент для фуллскрина
         * @param {HTMLElement[]} nodes - элементы для перемещения
         */
        emulateEnter(target, nodes) {
          const root = document.querySelector("#root");
          this.moveEls(root, [target]);
          if (nodes.length) {
            this.moveEls(root, nodes);
          }
        },
        /**
         * Эмулирование выхода из фуллскрина
         *
         * @param {HTMLElement} target - элемент для фуллскрина
         * @param {HTMLElement[]} nodes - элементы для перемещения
         */
        emulateExit(target, nodes) {
          this.returnEls([target]);
          if (nodes.length) {
            this.returnEls(nodes);
          }
        },
      },
    });
    /**
     * Примесь для компонентов
     *
     * @typedef FullscreenMixin
     * @type {object}
     * @property {boolean} isEnabled - включен ли в браузере нативный фуллскрин
     * @property {boolean} isFullscreen - доступен ли фуллскрин нативно
     * @property {HTMLElement} element - элемент который на данный момент в режиме фуллскрин
     * @property {Function} enter - вход в режим фуллскрин
     * @property {Function} exit - выход из режима фуллскрин
     * @property {string} group - текущая группа которая в режиме фуллскрин
     *
     * @mixin $fullscreen
     * @memberof $fullscreen
     * @vue-computed {FullscreenMixin} $fullscreen
     */
    VueInstance.mixin({
      computed: {
        $fullscreen() {
          return {
            isEnabled: reactorComponent.isEnabled,
            isFullscreen: reactorComponent.isFullscreen,
            element: reactorComponent.element,
            enter: reactorComponent.enter,
            exit: reactorComponent.exit,
            group: reactorComponent.currentGroup,
          };
        },
      },
    });

    /**
     * Директива для обработки входа в режим фуллскрина<br>
     * На главном элементе мы используем директиву v-fullscreen="groupName"<br>
     * На дополнительных элемента мы используем директиву v-fullscreen.attach="groupName|[group1, group2]"<br>
     *
     * @memberof $fullscreen
     * @name $fullscreen.directive
     */
    VueInstance.directive("fullscreen", {
      inserted(el, { modifiers, value }) {
        if (!value) return;
        if (Array.isArray(value)) {
          value.forEach(group => {
            handleInserted(reactorComponent, group, modifiers, el);
          });
        } else {
          handleInserted(reactorComponent, value, modifiers, el);
        }
      },
      unbind(el, { modifiers, value }) {
        if (!value) return;
        if (Array.isArray(value)) {
          value.forEach(group => {
            handleUnbind(reactorComponent, group, modifiers, el);
          });
        } else {
          handleUnbind(reactorComponent, value, modifiers, el);
        }
      },
    });
    return reactorComponent;
  },
};

Vue.use(fullscreen);

export default fullscreen;
