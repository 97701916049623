import BaseLogger from "./BaseLogger";

class MaterialsLogger extends BaseLogger {
  constructor() {
    super("materialsLogger", "file-material-actions");
  }

  getPayload({ file, user, room }) {
    return {
      fileId: file.id,
      userId: user.id,
      roomNumber: room,
    };
  }
}

export default new MaterialsLogger();
