import { i18n } from "@/utils/i18n-setup";
import { TO_LAPTOP_MQ_KEYS } from "@/constants/media-query/media-query";
import { RoomRouteName } from "@/constants/router/router-const";

const navItemVisibilityFuncMap = {
  distributing_rooms: ({ common }) => common.roomInfo.distributingRooms.length > 1,
  test_results: ({ auth }, visibility) => auth.user.customRole && visibility,
};

/**
 * @param key
 * @param value
 * @param state
 */
function isVisible(key, value, state) {
  const itemFunc = navItemVisibilityFuncMap[key];
  return itemFunc ? itemFunc(state, value.visibility) : !!value.visibility;
}

/**
 * @param value
 * @param language
 * @param localeKey
 */
function getLabel(value, language, localeKey) {
  return value.label[language.slice(0, 2)] || i18n.t(`navigation.${localeKey}`);
}

/**
 * @param route
 * @param iconName
 * @param fallbackLocaleKey
 * @param routeKeys
 * @param mq
 * @param counter
 */
function createNavInfo(
  route,
  iconName,
  fallbackLocaleKey = route,
  routeKeys = [],
  mq = null,
  counter = null,
) {
  return {
    route,
    iconName,
    fallbackLocaleKey,
    mq,
    activeConditionFunc: routeKeys.length ? currentRoute => routeKeys.includes(currentRoute) : null,
    counter,
  };
}

const navigationItemsInfo = {
  distributing_rooms: createNavInfo("breed", "th-large", "rooms"),
  details: createNavInfo("presentation", "desktop", "presentations"),
  info_section: createNavInfo("information", "book-open", "information"),
  workbook: createNavInfo("tests", "clipboard-list-check", "testsAndGames", [
    "tests",
    "test",
    "testResult",
    "mountain",
  ]),
  live_wall: createNavInfo("live-wall", "fire-alt", "liveWall", ["live-wall"]),
  practice_web_ex: createNavInfo("practice", "graduation-cap"),
  calendar: createNavInfo("agenda", "calendar-alt", "schedule", "agenda"),
  resources: createNavInfo("materials", "folders", undefined, ["materials", "material"]),
  test_results: createNavInfo("dashboard", "trophy", "results"),
  listeners: createNavInfo("listeners", "user-friends", "participants"),
  faq: createNavInfo("faq", "info-circle"),
  questions: createNavInfo(
    RoomRouteName.Questions,
    "question-circle",
    undefined,
    undefined,
    TO_LAPTOP_MQ_KEYS,
  ),
  groupchat: createNavInfo(
    RoomRouteName.Chat,
    "comments",
    undefined,
    undefined,
    TO_LAPTOP_MQ_KEYS,
    state => state.chat.unreadMessages,
  ),
  brainstorm: createNavInfo("brainstorm", "brain"),
  photowall: createNavInfo("photowall", "camera"),
  notifications: createNavInfo(
    "notifs",
    "bell",
    "notifications",
    undefined,
    undefined,
    state => state.notifications.unreadNotifs,
  ),
  about_ws: createNavInfo("about", "info-circle", "aboutUs"),
  miro: createNavInfo("miro-board", "pencil-ruler"),
};

/**
 * Создаем элемент навигации
 *
 * @param {object} value
 * @param {string} key
 * @param {object} state
 */
function createNavItem(value, key, state) {
  const infoByKey = navigationItemsInfo[key];

  if (!infoByKey) return null;

  return {
    text: getLabel(value, state.common.interfaceLanguage, infoByKey.fallbackLocaleKey),
    icon: infoByKey.iconName,
    route: infoByKey.route,
    routeParams: value.routeParams || null,
    mq: infoByKey.mq,
    activeFunc: infoByKey.activeConditionFunc,
    counter: infoByKey.counter ? infoByKey.counter(state) : null,
  };
}

export default (elements, state) =>
  Object.entries(elements).reduce((accum, [key, value]) => {
    if (isVisible(key, value, state)) {
      if (Array.isArray(value.label)) {
        const navItems = value.label.map(item =>
          createNavItem({ ...value, label: item, routeParams: { sectionId: item.id } }, key, state),
        );
        accum.push(...navItems);
      } else {
        const navItem = createNavItem(value, key, state);
        if (navItem) {
          accum.push(navItem);
        }
      }
    }
    return accum;
  }, []);
