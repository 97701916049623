import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { polls } from "@/store/modules/store.namespaces";
import { SET_ACTIVE_POLL } from "@/store/modules/polls/mutation-types";
import { test } from "@/constants/polls/polls-module-types";
import {
  GAME_OVER,
  GAME_OVER_BY_TIME,
  GAME_POLL_STEP_FORWARD,
  GET_GAMES,
  GET_SCORES,
  GET_TOP_USERS,
  GO_TO_SKIPPED_QUESTION,
  RUN_MOUNTAIN_KING,
  SEND_MOUNTAIN_KING_ANSWER,
  SKIP_GAME_QUESTION,
  GET_KING_RESULT,
} from "@/store/modules/games/action-types";
import {
  ADD_ANSWERED_QUESTION,
  ADD_SKIPPED_QUESTION,
  CLEAR_CURRENT_ACTIVE_GAME,
  INCREMENT_CURRENT_QUESTION_INDEX,
  REMOVE_SKIPPED_QUESTION,
  SET_AVAILABLE_GAMES,
  SET_CURRENT_ACTIVE_GAME,
  SET_CURRENT_QUESTION_INDEX,
  SET_KING_LEADERS_LIST,
  SET_KING_POINTS,
  SET_POST_GAME_SCREEN_VISIBLE,
  SET_KING_RESULT,
} from "@/store/modules/games/mutation-types";

export default {
  async [GET_GAMES]({ commit, rootState }) {
    try {
      const { data } = await AdminWebHttpService.get(`room/${rootState.common.roomId}/getGames`);
      if (data.length > 0) {
        const games = data.reduce((summary, game) => {
          summary[game.alias] = game;
          return summary;
        }, {});
        commit(SET_AVAILABLE_GAMES, games);
      } else {
        commit(SET_AVAILABLE_GAMES, []);
      }
    } catch (e) {
      console.error(e);
    }
  },
  [RUN_MOUNTAIN_KING]({ commit, rootState }) {
    AdminWebHttpService.post(
      `room/${rootState.common.roomId}/game/king/getQuestionsAndResetGame`,
    ).then(response => {
      if (response) {
        commit(SET_CURRENT_ACTIVE_GAME, response.data);
        commit(
          `${polls}/${SET_ACTIVE_POLL}`,
          {
            type: test,
            payload: response.data[0],
          },
          { root: true },
        );
      }
    });
  },
  [GET_TOP_USERS]({ commit, rootState }) {
    AdminWebHttpService.post(`room/${rootState.common.roomId}/game/king/getTopUsers`).then(resp => {
      if (resp) {
        commit(SET_KING_LEADERS_LIST, resp.data);
      }
    });
  },
  async [GET_SCORES]({ commit, state, rootState }) {
    const resp = await AdminWebHttpService.post(
      `room/${rootState.common.roomId}/game/getUserPoints`,
      {
        time: state.currentActiveGame.lastQuestionTime,
      },
    );
    if (
      resp &&
      resp.data &&
      resp.data.king &&
      (resp.data.king.user_points || resp.data.king.user_points === 0)
    ) {
      commit(SET_KING_POINTS, resp.data.king.user_points);
    }
    commit(SET_POST_GAME_SCREEN_VISIBLE);
  },
  async [GAME_POLL_STEP_FORWARD]({ commit, dispatch, state }) {
    const pollsLength = state.currentActiveGame.pollsList.length;
    const index = state.currentActiveGame.currentQuestionIndex + 1;
    if (state.currentActiveGame.answeredQuestions.indexOf(index) > -1) {
      commit(INCREMENT_CURRENT_QUESTION_INDEX);
      dispatch(GAME_POLL_STEP_FORWARD);
    } else if (index === pollsLength) {
      if (state.currentActiveGame.skippedQuestions.length > 0) {
        dispatch(GO_TO_SKIPPED_QUESTION);
      } else if (state.currentActiveGame.postGameScreen) {
        if (state.currentActiveGame.postGameScreenAction) {
          await dispatch(GET_SCORES);
          await dispatch(GET_KING_RESULT);
        } else {
          commit(SET_POST_GAME_SCREEN_VISIBLE);
        }
      } else {
        dispatch(GAME_OVER);
      }
    } else {
      commit(INCREMENT_CURRENT_QUESTION_INDEX);
      commit(
        `${polls}/${SET_ACTIVE_POLL}`,
        {
          type: test,
          payload: state.currentActiveGame.pollsList[index],
        },
        {
          root: true,
        },
      );
    }
  },
  [SKIP_GAME_QUESTION]({ commit, dispatch, state }) {
    const skippedQuestionIndex = state.currentActiveGame.currentQuestionIndex;
    if (state.currentActiveGame.skippedQuestions.indexOf(skippedQuestionIndex) === -1) {
      commit(ADD_SKIPPED_QUESTION, skippedQuestionIndex);
    }
    dispatch(GAME_POLL_STEP_FORWARD);
  },
  [GO_TO_SKIPPED_QUESTION]({ commit, state }) {
    const index = state.currentActiveGame.skippedQuestions[0];
    commit(SET_CURRENT_QUESTION_INDEX, index);
    commit(
      `${polls}/${SET_ACTIVE_POLL}`,
      {
        type: test,
        payload: state.currentActiveGame.pollsList[index],
      },
      { root: true },
    );
  },
  [GAME_OVER]({ commit, dispatch }, needClearPoll = true) {
    dispatch(GET_TOP_USERS);
    dispatch(GET_KING_RESULT);
    commit(CLEAR_CURRENT_ACTIVE_GAME);
    if (needClearPoll) {
      commit(
        `${polls}/${SET_ACTIVE_POLL}`,
        {
          type: test,
          payload: null,
        },
        { root: true },
      );
    }
  },
  [GAME_OVER_BY_TIME]({ commit, dispatch, state }) {
    if (state.currentActiveGame.postGameScreen) {
      if (state.currentActiveGame.postGameScreenAction) {
        dispatch(state.currentActiveGame.postGameScreenAction);
      } else {
        commit(SET_POST_GAME_SCREEN_VISIBLE);
      }
    } else {
      dispatch(GAME_OVER);
    }
  },
  [SEND_MOUNTAIN_KING_ANSWER]({ commit, dispatch, state, rootState }, data) {
    // maybe move to poll as type of send answer
    return new Promise((resolve) => {
      AdminWebHttpService.post(
        `room/${rootState.common.roomId}/game/question/${rootState.polls.test.activePoll.id}/saveAnswer`,
        data,
      ).then(() => {
        commit(ADD_ANSWERED_QUESTION, state.currentActiveGame.currentQuestionIndex);
        commit(REMOVE_SKIPPED_QUESTION, state.currentActiveGame.currentQuestionIndex);
        dispatch(GAME_POLL_STEP_FORWARD);
        resolve();
      });
    });
  },
  async [GET_KING_RESULT]({ commit, rootState }) {
    if (!rootState.common.roomId) {
      return false;
    }

    const { data } = await AdminWebHttpService.get(
      `room/${rootState.common.roomId}/game/getKingResultByUser/${rootState.auth.user.id}`,
    );
    commit(SET_KING_RESULT, data);
    return data;
  },
};
