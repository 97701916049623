import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { auth, broadcast, presentation, room } from "@/store/modules/store.namespaces";
import {
  ADD_LEADING,
  CHANGE_SPEAKER_IS_MAIN,
  CLOSE_QUIZ,
  DELETE_LEADING,
  PRESENTATION,
  RUN_QUIZ,
  SLIDE,
  STREAM,
  UPDATE_LEADING,
  USER_RAISE_HAND,
} from "@/store/modules/socket/action-types";
import {
  SET_PRESENTATION_INFO,
  SET_START_PRESENTATION,
} from "@/store/modules/presentation/mutation-types";
import { SET_ROOMINFO_QUIZ, SET_ROOMINFO_SHOW_RESULT } from "@/store/modules/common/mutation-types";
import { SET_USER_IS_MAIN } from "@/store/modules/auth/mutation-types";
import { SHOW_QUIZ_RESULT } from "@/store/modules/speaker/modules/polls/action-types";
import { HIGH_LATENCY_BROADCAST_ON_AIR } from "@/store/modules/broadcast/getter-types";
import { BROADCAST_SET_STATE_PROP } from "@/store/modules/broadcast/mutation-types";

/**
 * 30 секунд до того как показать предупреждение спикеру
 *
 * @type {number}
 */
const WARNING_STREAM_TIMEOUT = 30000;
/**
 * Таймаут для определения живой
 *
 * @type {null|number}
 */
let warningStreamTimeoutId = null;

export default {
  actions: {
    [SLIDE]({ commit, rootState, rootGetters }, { obj }) {
      if (rootGetters[`${broadcast}/${HIGH_LATENCY_BROADCAST_ON_AIR}`]) return;
      if (rootState.presentation.currentPresentationInfo.slideIndex !== obj.Slide) {
        commit(
          `${presentation}/${SET_PRESENTATION_INFO}`,
          {
            presentationId: rootState.presentation.currentPresentationInfo.id,
            slideIndex: obj.Slide,
          },
          { root: true },
        );
      }
    },
    [PRESENTATION]({ commit, rootState }, data) {
      const { Presentation: presentationId = null, Slide: slideIndex = 1 } = data.obj;
      if (
        !presentationId ||
        rootState[presentation].currentPresentationInfo.id === presentationId
      ) {
        return;
      }
      commit(
        `${presentation}/${SET_PRESENTATION_INFO}`,
        {
          presentationId,
          slideIndex,
        },
        { root: true },
      );
    },
    [RUN_QUIZ]({ commit }, data) {
      commit(`${presentation}/${SET_START_PRESENTATION}`, 1, { root: true });
      commit(`${room}/${SET_ROOMINFO_QUIZ}`, data.obj.id, { root: true });
    },
    [CLOSE_QUIZ]({ commit }) {
      commit(`${presentation}/${SET_START_PRESENTATION}`, 0, { root: true });
      commit(`${room}/${SET_ROOMINFO_QUIZ}`, null, { root: true });
    },
    [SHOW_QUIZ_RESULT]({ commit, rootState }, data) {
      let query = {};
      if (!data.show) {
        query = {
          obj: data.obj,
          type: data.type,
        };
      }
      AdminWebHttpService.post(`showResult/${rootState.common.roomNumber}`, query).then(() => {
        commit(`${room}/${SET_ROOMINFO_SHOW_RESULT}`, JSON.stringify(data), {
          root: true,
        });
      });
    },
    [CHANGE_SPEAKER_IS_MAIN]({ commit }, data) {
      commit(`${auth}/${SET_USER_IS_MAIN}`, data.obj, { root: true });
    },
    [ADD_LEADING]({ commit, rootState }, data) {
      const { participants } = rootState.broadcast;
      if (participants.some(p => p.user_id === data.obj.user_id)) return;
      participants.push(data.obj);
      commit(
        `${broadcast}/${BROADCAST_SET_STATE_PROP}`,
        {
          participants,
        },
        { root: true },
      );
    },
    [UPDATE_LEADING]({ commit, rootState }, data) {
      const candidate = data.obj;
      const participants = rootState.broadcast.participants.map(p => {
        if (p.user_id === candidate.user_id) {
          return {
            ...p,
            permissions: candidate.permissions,
          };
        }
        return p;
      });
      commit(
        `${broadcast}/${BROADCAST_SET_STATE_PROP}`,
        {
          participants,
        },
        { root: true },
      );
    },
    [USER_RAISE_HAND]({ commit, rootState }, data) {
      const userId = data.obj.id;
      const raiseHandIds = [...rootState.broadcast.raiseHandIds];
      if (raiseHandIds.includes(userId)) return;
      raiseHandIds.unshift(userId);
      commit(`${broadcast}/${BROADCAST_SET_STATE_PROP}`, { raiseHandIds }, { root: true });
    },
    [DELETE_LEADING]({ commit, rootState }, data) {
      const userId = data.obj.id;
      const { participants } = rootState.broadcast;
      if (participants.some(p => p.user_id === userId)) {
        commit(
          `${broadcast}/${BROADCAST_SET_STATE_PROP}`,
          {
            participants: participants.filter(p => p.user_id !== userId),
          },
          { root: true },
        );
      }
    },
    [STREAM]({ commit, rootState }, data) {
      clearTimeout(warningStreamTimeoutId);
      if (data.launched) {
        if (rootState[broadcast].showWarning) {
          commit(
            `${broadcast}/${BROADCAST_SET_STATE_PROP}`,
            {
              showWarning: false,
            },
            {
              root: true,
            },
          );
        }

        warningStreamTimeoutId = setTimeout(() => {
          commit(
            `${broadcast}/${BROADCAST_SET_STATE_PROP}`,
            {
              showWarning: true,
            },
            {
              root: true,
            },
          );
        }, WARNING_STREAM_TIMEOUT);
      }
    },
  },
};
