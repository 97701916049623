import {
  GET_ADMIN_INFO,
  GET_AVERAGE_TEAM_SCORE,
  GET_AVERAGE_TEAM_SCORE_RM,
  GET_BUSINESS_UNIT_TEAM_SCORE,
  GET_GOD_TOP_MANAGERS,
  GET_GOD_TOP_TEAMS,
  GET_MANAGER_ADMIN_INFO,
  GET_MY_LINE_ACTIVE,
  GET_MY_TEAM_SCORE,
  GET_MY_TEAM_SCORE_RM,
  GET_PARTICIPANT_SCORE,
  GET_PARTICIPANTS_ADMIN_INFO,
  GET_USER_INFO,
} from "@/store/modules/dashboard/action-types";
import {
  SET_AVERAGE_TEAM_SCORE,
  SET_BU_TEAMS_SCORE,
  SET_DASHBOARD_LOADER,
  SET_GOD_TOP_MANAGERS,
  SET_GOD_TOP_TEAMS,
  SET_TEAM_SCORE,
  SET_USER_DASHBOARD_INFO,
  SET_USER_LINE_ACTIVE,
  SET_USER_SCORE,
} from "@/store/modules/dashboard/mutation-types";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";

function sortByOrder(list) {
  return Object.values(list).sort(function(a, b) {
    return a.order * b.order === 0 ? b.order - a.order : a.order - b.order;
  });
}

async function mapActionNameToDispatch(actionName, dispatch) {
  return await dispatch(actionName);
}

export default {
  async [GET_PARTICIPANTS_ADMIN_INFO]({ commit, dispatch }) {
    commit(SET_DASHBOARD_LOADER, true);
    await Promise.all(
      [
        GET_USER_INFO,
        GET_PARTICIPANT_SCORE,
        GET_AVERAGE_TEAM_SCORE,
        GET_MY_TEAM_SCORE,
      ].map(action => mapActionNameToDispatch(action, dispatch)),
    );
    commit(SET_DASHBOARD_LOADER, false);
  },
  async [GET_MANAGER_ADMIN_INFO]({ commit, dispatch }) {
    commit(SET_DASHBOARD_LOADER, true);
    await Promise.all(
      [
        GET_USER_INFO,
        GET_BUSINESS_UNIT_TEAM_SCORE,
        GET_PARTICIPANT_SCORE,
        GET_AVERAGE_TEAM_SCORE_RM,
        GET_MY_TEAM_SCORE_RM,
      ].map(action => mapActionNameToDispatch(action, dispatch)),
    );
    commit(SET_DASHBOARD_LOADER, false);
  },
  async [GET_ADMIN_INFO]({ commit, dispatch }) {
    commit(SET_DASHBOARD_LOADER, true);
    await Promise.all(
      [GET_USER_INFO, GET_MY_TEAM_SCORE_RM].map(action =>
        mapActionNameToDispatch(action, dispatch),
      ),
    );
    commit(SET_DASHBOARD_LOADER, false);
  },
  async [GET_GOD_TOP_MANAGERS]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      AdminWebHttpService
        .get(`BUTeamsScore/${payload}/${rootState.common.roomId}`)
        .then(res => {
          commit(SET_GOD_TOP_MANAGERS, res.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async [GET_GOD_TOP_TEAMS]({ commit, rootState }, payload) {
    AdminWebHttpService
      .get(`BUTeamsScore/${payload}/${rootState.common.roomId}`)
      .then(res => {
        commit(SET_GOD_TOP_TEAMS, res.data);
      })
  },
  async [GET_USER_INFO]({ commit, rootState }) {
    return AdminWebHttpService
      .post(`userInfo/${rootState.common.roomNumber}`)
      .then(res => {
        commit(SET_USER_DASHBOARD_INFO, res.data);
      })
      .catch(error => {});
  },
  async [GET_PARTICIPANT_SCORE]({ commit, rootState }) {
    return AdminWebHttpService
      .post(`myScore/${rootState.common.roomId}`)
      .then(res => {
        commit(SET_USER_SCORE, res.data);
      })
      .catch(error => {});
  },
  async [GET_AVERAGE_TEAM_SCORE]({ commit, rootState }) {
    return AdminWebHttpService
      .post(`getAvgTeam/${rootState.common.roomId}`)
      .then(res => {
        commit(SET_AVERAGE_TEAM_SCORE, res.data);
      })
      .catch(error => {});
  },
  async [GET_AVERAGE_TEAM_SCORE_RM]({ commit, rootState }) {
    return AdminWebHttpService
      .post(`getAvgTeamRM/${rootState.common.roomId}`)
      .then(res => {
        commit(SET_AVERAGE_TEAM_SCORE, res.data);
      })
      .catch(error => {
        console.warn(error);
      });
  },
  async [GET_MY_TEAM_SCORE]({ commit, rootState }) {
    return AdminWebHttpService
      .post(`myTeamDashboard/${rootState.common.roomId}`)
      .then(res => {
        let data = {
          ...res.data,
          columns: sortByOrder(res.data.columns),
        };
        commit(SET_TEAM_SCORE, data);
      })
      .catch(error => {
        console.warn(error);
      });
  },
  async [GET_MY_TEAM_SCORE_RM]({ commit, rootState }) {
    return AdminWebHttpService
      .post(`myTeamDashboardRM/${rootState.common.roomId}`)
      .then(res => {
        let data = {
          ...res.data,
          columns: sortByOrder(res.data.columns),
        };
        commit(SET_TEAM_SCORE, data);
      })
      .catch(error => {
        console.warn(error);
      });
  },
  async [GET_MY_LINE_ACTIVE]({ commit, rootState }, typeLine = "") {
    return AdminWebHttpService
      .get(`dashboardUserActivity/${rootState.common.roomId}?type=${typeLine}`)
      .then(res => {
        commit(SET_USER_LINE_ACTIVE, res.data);
      })
      .catch(error => {
        console.warn("dashboardUserActivity = ", error);
      });
  },
  //UserBUTeamsScore
  async [GET_BUSINESS_UNIT_TEAM_SCORE]({ commit, rootState }) {
    return AdminWebHttpService
      .get(`UserBUTeamsScore/${rootState.common.roomId}`)
      .then(res => {
        commit(SET_BU_TEAMS_SCORE, res.data);
      })
      .catch(error => {});
  },
};
