import { CHECK_LANGUAGE, GET_TEMPLATE } from "@/store/modules/landing/action-types";
import { RESET_STATE } from "@/store/mutations/mutation-types";
import { LAST_CHOOSED_LANGUAGE } from "@/utils/i18n-setup";
import { SET_TEMPLATE } from "@/store/modules/landing/mutation-types";
import { SWITCH_ROOM_LANGUAGE } from "@/store/modules/common/action-types";
import { room } from "@/store/modules/store.namespaces";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { checkImage } from "@/utils";
import { FROM_GUEST } from "@/constants/user/auth-const";

export default {
  async [GET_TEMPLATE]({ dispatch, commit }) {
    const domain = process.env.VUE_APP_CORDOVA_PLATFORM
      ? process.env.VUE_APP_LANDING_DOMAIN
      : window.location.hostname;

    const wsUserTokenExpiresDatetime = +localStorage.getItem("wsUserTokenExpiresDatetime");
    if (wsUserTokenExpiresDatetime < Date.now()) {
      localStorage.removeItem("wsUserTokenExpiresDatetime");
    }

    try {
      const { data } = await AdminWebHttpService.post(`getLandingByDomain`, `domain=${domain}`);
      commit(RESET_STATE, null, { root: true });
      commit(SET_TEMPLATE, data);
      await dispatch(CHECK_LANGUAGE);
      const images = [data.header_logo, data.logo, data.page_logo].filter(img => !!img);
      await Promise.all(images.map(img => checkImage(img)));
    } catch (e) {
      console.error(e);
    }
  },
  async [CHECK_LANGUAGE]({ state: { template }, dispatch }) {
    const fromGuestRoute = !!localStorage.getItem(FROM_GUEST);
    if (fromGuestRoute) return;

    const lastChoosedLanguage = localStorage.getItem(LAST_CHOOSED_LANGUAGE);

    const lang = lastChoosedLanguage || template.language;
    await dispatch(`${room}/${SWITCH_ROOM_LANGUAGE}`, lang, { root: true });
  },
};
