export const IS_CONFERENCE_TYPE = "IS_CONFERENCE_TYPE";
export const IS_ONE_2_MANY_TYPE = "IS_ONE_2_MANY_TYPE";
export const IS_EXTERNAL_STREAM = "IS_EXTERNAL_STREAM";
export const IS_TYPICAL_TYPE = "IS_TYPICAL_TYPE";
export const IS_YOUTUBE_TYPE = "IS_YOUTUBE_TYPE";
export const IS_VIMEO_TYPE = "IS_VIMEO_TYPE";
export const IS_FACECAST_TYPE = "IS_FACECAST_TYPE";
export const IS_MP4_TYPE = "IS_MP4_TYPE";
export const IS_BROADCAST_ENABLED = "IS_BROADCAST_ENABLED";
export const YT_BROADCAST_URL = "YT_BROADCAST_URL";
export const VIMEO_BROADCAST_URL = "VIMEO_BROADCAST_URL";
export const BROADCAST_URL = "BROADCAST_URL";
export const ROOM_BROADCAST_TYPE = "ROOM_BROADCAST_TYPE";
export const BROADCAST_PLAYER_TYPE = "BROADCAST_PLAYER_TYPE";
export const FACECAST_URL = "FACECAST_URL";
export const IS_BROADCAST_URL_MISSING = "IS_BROADCAST_URL_MISSING";
export const IS_NEED_MEDIA_DEVICES = "IS_NEED_MEDIA_DEVICES";
export const IS_VIDEO_TYPE = "IS_VIDEO_TYPE";
export const EXTERNAL_STREAM_URL = "EXTERNAL_STREAM_URL";
export const SPEAKER_PLAYER_CONSTRAINTS = "SPEAKER_PLAYER_CONSTRAINTS";
export const HIGH_LATENCY_BROADCAST_ON_AIR = "HIGH_LATENCY_BROADCAST_ON_AIR";
export const SCREEN_SAVER_SRC = "SCREEN_SAVER_SRC";
export const BROADCAST_SERVERS = "BROADCAST_SERVERS";
