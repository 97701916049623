import {
  SOCKET_CONNECT,
  ANSWER,
  BRAINSTORM_IDEA,
  BRAINSTORM_STATUS,
  CHANGE_BRAINSTORM_STATUS,
  CLOSE_QUIZ,
  QUESTION,
  SET_BRAINSTORM,
  SHOW_QUIZ_RESULT,
  TIMER,
  YOUTUBE_SLIDE_START_STOP,
  REFRESH_QUESTIONS_LIKES,
  REFRESH_QUESTIONS_DISLIKES,
} from "@/store/modules/socket/action-types";
import {
  brainstorm,
  polls,
  presentation,
  questions,
  room,
  speakers,
} from "@/store/modules/store.namespaces";
import { GET_ROOM_TIMER, GET_ROOM_INFO, LOAD_ROOM } from "@/store/modules/common/action-types";

import { GET_USER_QUESTIONS } from "@/store/modules/questions/action-types";
import {
  CLOSE_POLL,
  CLOSE_POLL_SCORE,
  SHOW_POLL_SCORE,
  CHECK_IS_AVAILABLE_WS_POLL,
} from "@/store/modules/polls/action-types";
import { interactive } from "@/constants/polls/polls-module-types";
import {
  GET_BRAINSTORMS,
  GET_ROUNDED_TABLE_IDEAS,
  UPDATE_IDEA,
} from "@/store/modules/brainstorm/action-types";
import { SET_BRAINSTORM_STATUS } from "@/store/modules/common/mutation-types";
import { SET_PLAY_YOUTUBE_VIDEO } from "@/store/modules/presentation/mutation-types";
import { SET_CURRENT_BRAINSTORM_TAB } from "@/store/modules/brainstorm/mutation-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { SET_USER_QUESTIONS } from "@/store/modules/questions/mutation-types";

export default {
  async [SOCKET_CONNECT]({ dispatch }, reconnect) {
    /**
     * On start LOAD_ROOM dispatching in entering|changing a room.
     * Refresh data on socket reconnect.
     */
    if (reconnect) {
      await dispatch(`${room}/${GET_ROOM_INFO}`, null, { root: true });
      dispatch(`${polls}/${CHECK_IS_AVAILABLE_WS_POLL}`, null, {
        root: true,
      });
      dispatch(`${room}/${LOAD_ROOM}`, null, { root: true });
    }
  },
  [TIMER]({ dispatch }) {
    dispatch(`${room}/${GET_ROOM_TIMER}`, null, { root: true });
  },
  [ANSWER]({ commit, rootGetters }, data) {
    const dataAnswer = data?.obj;
    const arrQuestions = rootGetters && rootGetters[`questions/QUESTIONS`];
    const questionIndex = arrQuestions?.findIndex(question => +question.id === +dataAnswer.id);

    // TODO: Поправить модель SpeakerQuestion и сделать единый формат данных с бэком
    if (arrQuestions && arrQuestions[questionIndex]) {
      arrQuestions[questionIndex].isAnswered =
        dataAnswer?.isAnswered === undefined ? !!dataAnswer?.is_answered : !dataAnswer?.isAnswered;
      arrQuestions[questionIndex].answer = dataAnswer?.answer;
      arrQuestions[questionIndex].admin_answer = dataAnswer?.admin_answer;

      commit(`${questions}/${SET_USER_QUESTIONS}`, arrQuestions, {
        root: true,
      });
    }

    // dispatch(`${questions}/${GET_USER_QUESTIONS}`, null, { root: true });
  },

  /**
   * Метод добавления вопроса в список.
   *
   * Срабатывает при приходящем сокете.
   *
   * @param {*} param0
   * @param {*} data
   */
  [QUESTION]({ commit, rootGetters, rootState }, data) {
    const { questionPreModeration } = rootGetters[`${room}/${AVAILABLE_ROOM_MODULES}`];
    const arrQuestions = rootGetters[`questions/QUESTIONS`];
    const dataQuestion = data.obj.questions || data.obj;
    const isArray = Array.isArray(dataQuestion);
    const { currentSpeaker } = rootState.speakers;
    // ID спикера из веб-сокета
    const speakerId = +dataQuestion?.speakerId || +dataQuestion?.leader_id;
    let removeIndex = null;

    if (+currentSpeaker?.id !== speakerId) return;

    // TODO: Привести данные data к единому формату
    // TODO: Сделать единую функцию добавления/удаления вопроса
    // сейчас есть различия в отправляемом вопросе и в получаемых данных по веб-сокету.

    if (dataQuestion) {
      if (isArray) {
        dataQuestion?.forEach(question => {
          switch (question.status) {
            case "remove":
              removeIndex = arrQuestions.findIndex(q => +q.id === +question.id);
              arrQuestions.splice(removeIndex, 1);
              break;
            case "regular":
              break;
            default:
              break;
          }
        });
      } else if (data.obj.questions && !dataQuestion.moderated) {
        removeIndex = arrQuestions.findIndex(question => +question.id === +dataQuestion.id);
        arrQuestions.splice(removeIndex, 1);
      } else {
        // Переустановка данных из другого объекта
        dataQuestion.isAnswered = !!dataQuestion.is_answered;
        dataQuestion.mark = dataQuestion.mark_count || 0;
        dataQuestion.dislike = dataQuestion.dislike_count || 0;
        (!questionPreModeration || (questionPreModeration && dataQuestion.moderated)) &&
          arrQuestions.push(dataQuestion);
      }

      commit(`${questions}/${SET_USER_QUESTIONS}`, arrQuestions, {
        root: true,
      });
    }
  },

  /**
   * Метод закрытия опросов у пользователей.
   *
   * @param {*} param0
   * @param {*} payload
   */
  [CLOSE_QUIZ]({ dispatch }, payload) {
    dispatch(
      `${polls}/${CLOSE_POLL}`,
      { type: interactive, isScreen: (payload && payload.isScreen) || false },
      { root: true },
    );
  },

  [BRAINSTORM_IDEA]({ dispatch }, data) {
    dispatch(`${brainstorm}/${UPDATE_IDEA}`, data.obj.id_idea || data.obj.idea_id, { root: true });
  },
  [BRAINSTORM_STATUS]({ commit }, data) {
    if (data.obj.status) {
      commit(`${room}/${SET_BRAINSTORM_STATUS}`, data.obj.status, {
        root: true,
      });
    }
  },
  [SET_BRAINSTORM]({ dispatch }) {
    dispatch(`${brainstorm}/${GET_BRAINSTORMS}`, null, { root: true });
    dispatch(`${brainstorm}/${GET_ROUNDED_TABLE_IDEAS}`, undefined, {
      root: true,
    });
  },
  [CHANGE_BRAINSTORM_STATUS]({ dispatch, commit }) {
    commit(`${brainstorm}/${SET_CURRENT_BRAINSTORM_TAB}`, "group", {
      root: true,
    });
    dispatch(SET_BRAINSTORM);
  },
  [YOUTUBE_SLIDE_START_STOP]({ commit }, data) {
    commit(`${presentation}/${SET_PLAY_YOUTUBE_VIDEO}`, data.obj.play, {
      root: true,
    });
  },
  [SHOW_QUIZ_RESULT]({ dispatch }, data) {
    if (data.obj.event) {
      dispatch(
        `${polls}/${CLOSE_POLL_SCORE}`,
        { type: interactive, isScreen: data.isScreen || false },
        { root: true },
      );
    } else {
      dispatch(
        `${polls}/${SHOW_POLL_SCORE}`,
        {
          type: interactive,
          payload: data.obj,
          isScreen: data.isScreen || false,
        },
        { root: true },
      );
    }
  },

  // TODO: Временное решение с отправкой сокета с фронка и обновлением
  // лайков на лету, необходимо исправить. Всю эту функцию, по возможности,
  // заменить на метод SET_QUESTION_LIKE.

  [REFRESH_QUESTIONS_LIKES]({ commit, rootGetters }, data) {
    const dataLike = data && data.obj;
    const arrQuestions = rootGetters && rootGetters[`questions/QUESTIONS`];
    const questionIndex =
      arrQuestions &&
      arrQuestions.findIndex(question => question.id.toString() === dataLike.id.toString());

    if (arrQuestions && arrQuestions[questionIndex]) {
      arrQuestions[questionIndex].mark += dataLike.markDelta;

      commit(`${questions}/${SET_USER_QUESTIONS}`, arrQuestions, {
        root: true,
      });
    }
  },

  // TODO: Временное решение с отправкой сокета с фронка и обновлением
  // дизлайков на лету, необходимо исправить. Всю эту функцию, по возможности,
  // заменить на метод SET_QUESTION_DISLIKE.

  [REFRESH_QUESTIONS_DISLIKES]({ commit, rootGetters }, data) {
    const dataDislike = data && data.obj;
    const arrQuestions = rootGetters && rootGetters[`questions/QUESTIONS`];
    const questionIndex =
      arrQuestions &&
      arrQuestions.findIndex(question => question.id.toString() === dataDislike.id.toString());

    if (arrQuestions && arrQuestions[questionIndex]) {
      arrQuestions[questionIndex].dislike += dataDislike.markDelta;

      commit(`${questions}/${SET_USER_QUESTIONS}`, arrQuestions, {
        root: true,
      });
    }
  },

  /**
   * Метод очистки всех вопросов.
   *
   * @param {*} param0
   *
   */
  CLEAR_QUESTIONS({ commit }) {
    commit("questions/RESET_USER_QUESTIONS", null, {
      root: true,
    });
  },
};
