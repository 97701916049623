<template>
  <layout-auth v-if="!pending">
    <router-view />
  </layout-auth>
</template>

<script>
import LayoutAuth from "@/components/layouts/LayoutAuth";
import useLanding from "@/components/common/landing/mixins/useLanding";
import { auth, room } from "@/store/modules/store.namespaces";
import { LOGIN, LOGOUT } from "@/store/modules/auth/action-types";
import { mapActions, mapGetters } from "vuex";
import { USER_IS_LOGGED_IN } from "@/store/modules/auth/getter-types";
import { ENTER_TO_ROOM } from "@/store/modules/common/action-types";
/**
 * Реализует общюю логику для страниц аутентификации, автоматическая авторизация
 *
 * @vue-data {boolean} pending - Ожидание авторизации
 * @vue-computed {boolean} userIsLoggedIn - Залогинен ли пользователь
 */
export default {
  name: "ViewAuth",
  components: { LayoutAuth },
  mixins: [useLanding],
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    ...mapGetters(auth, {
      userIsLoggedIn: USER_IS_LOGGED_IN,
    }),
  },
  created() {
    // Если ты не авторизван и скрыт лендинг и не владелец, то автоматом авторизуешься
    if (
      !this.userIsLoggedIn &&
      this.template.hide_landing &&
      !this.$route.query.owner
    ) {
      this.auth();
    }
  },
  methods: {
    ...mapActions(auth, {
      login: LOGIN,
      logout: LOGOUT,
    }),
    ...mapActions(room, {
      enterToRoom: ENTER_TO_ROOM,
    }),
    /**
     * Авторизация без действий пользователя
     * если включенна авторизация по "oauth2" то тебя перекинет на страницу авторизации
     * в ином случае тебя авторизует как гостя и бросает в дефолтную комнату лендинга
     *
     * @returns {Promise<void>}
     */
    async auth() {
      this.pending = true;
      if (this.isOAuth2Type) {
        this.oauth2Auth();
        return;
      }
      try {
        await this.login({ login: "guest" });
        await this.enterToRoom({ room: "default" });
      } catch {
        this.logout();
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>