import throttle from "lodash/throttle";
import ControlHttpService from "@/services/http/ControlHttpService";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import {
  CHANGE_PRESENTATION,
  CHANGE_SLIDE_BY_KEY,
  CHANGE_SLIDE,
  GET_PRESENTATIONS,
  RUN_NEXT_SLIDE,
  RUN_NEXT_PRESENTATION,
  SET_SLIDE,
  SET_SLIDE_TIME,
} from "@/store/modules/presentation/action-types";
import {
  SET_PRESENTATIONS,
  SET_PRESENTATION_INFO,
  SET_SLIDE_VIDEO_TIME,
  SET_USER_SLIDE_INDEX,
  SET_SLIDE_BUSY,
} from "@/store/modules/presentation/mutation-types";
import {
  auth,
  broadcast,
  room,
  speakerPolls,
} from "@/store/modules/store.namespaces";

import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import {
  CURRENT_PRESENTATION_INDEX,
  CURRENT_PRESENTATION_SLIDE,
  CURRENT_PRESENTATION_SLIDES,
} from "@/store/modules/presentation/getter-types";
import presentationsAdapter from "@/store/api/presentationsAdapter";
import { HIGH_LATENCY_BROADCAST_ON_AIR } from "@/store/modules/broadcast/getter-types";
import { HIGH_LATENCY_ACTIONS_DEFFERS_MS } from "@/constants/broadcast/broadcast-const";
import { USER_IS_SPEAKER } from "@/store/modules/auth/getter-types";
import { RESET_INTERACTIVE } from "@/store/modules/speaker/modules/polls/action-types";

const CHANGE_SLIDE_DEBOUNCE = 400;

const KEY_CODES_RIGHT = ["ArrowRight", "ArrowUp", "PageUp"];
const KEY_CODES_LEFT = ["ArrowLeft", "ArrowDown", "PageDown"];

function incrementByKey(key) {
  if (KEY_CODES_LEFT.includes(key)) return -1;
  if (KEY_CODES_RIGHT.includes(key)) return 1;
  return 0;
}

export default {
  [RUN_NEXT_SLIDE]: throttle(({ dispatch, getters }, increment = 1) => {
    const slides = getters[CURRENT_PRESENTATION_SLIDES];
    const slide = getters[CURRENT_PRESENTATION_SLIDE];
    const nextSlideIndex = slides.findIndex(s => s.id === slide.id) + increment;
    const nextSlide = slides[nextSlideIndex];
    if (!nextSlide) {
      dispatch(RUN_NEXT_PRESENTATION, increment);
    } else {
      dispatch(CHANGE_SLIDE, nextSlide);
    }
  }, CHANGE_SLIDE_DEBOUNCE),
  [RUN_NEXT_PRESENTATION]({ dispatch, getters, state, rootGetters }, increment = 1) {
    if (!rootGetters[`${room}/${AVAILABLE_ROOM_MODULES}`].allowScrollToNextPresentation) return;

    const nextPresIndex = getters[CURRENT_PRESENTATION_INDEX] + increment;
    const nextPresSlides = state.presentations[nextPresIndex]?.slidesArray;
    if (!nextPresSlides) return;
    const nextSlide =
      increment === 1 ? nextPresSlides[0] : nextPresSlides[nextPresSlides.length - 1];
    dispatch(CHANGE_SLIDE, nextSlide);
  },
  /**
   * Запуск презентации
   *
   * @param {object<any>} context
   * @param {number} id - Презентация для запуска
   * @returns {Promise<object>} - статус запроса
   */
  async [CHANGE_PRESENTATION]({ state, rootGetters, dispatch }, id) {
    try {
      if (rootGetters[`${auth}/${USER_IS_SPEAKER}`]) {
        await dispatch(`${speakerPolls}/${RESET_INTERACTIVE}`, undefined, { root: true });
      }
      const slide = state.presentations.find(p => p.id === id).slidesArray[0];
      await dispatch(CHANGE_SLIDE, slide);
    } catch (e) {
      throw e;
    }
  },
  async [GET_PRESENTATIONS]({ commit, rootState }) {
    try {
      const {
        common: { roomNumber, interfaceLanguage },
      } = rootState;
      const { data } = await AdminWebHttpService.get(`getRoomDetail/${roomNumber}`, {
        params: { lang: interfaceLanguage },
      });
      const presentations = presentationsAdapter.addToPresentationsSlidesAsArray(data);
      commit(SET_PRESENTATIONS, presentations);
    } catch (e) {
      throw e;
    }
  },
  [CHANGE_SLIDE_BY_KEY]: throttle(({ dispatch }, { key }) => {
    const increment = incrementByKey(key);
    if (!increment) return;
    dispatch(RUN_NEXT_SLIDE, increment);
  }, CHANGE_SLIDE_DEBOUNCE),
  /**
   * Смена слайда  с обработкой запуска следующей презентации<br>
   * Обработка задержки листания при стриме с задержкой
   *
   * @param {object<any>} context
   * @param {PresentationSlide} slide - номер следующего слайда
   * @returns {Promise<void>}
   */
  async [CHANGE_SLIDE]({ rootState, state, commit, rootGetters }, slide) {
    if (state.changeSlideIsBusy) return;

    try {
      commit(SET_SLIDE_BUSY, true);
      const changeSlideBind = ControlHttpService.changeSlide.bind(ControlHttpService, {
        roomNum: rootState.common.roomNumber,
        presId: slide.presentation.id,
        slideOrd: slide.ord,
      });
      if (rootGetters[`${broadcast}/${HIGH_LATENCY_BROADCAST_ON_AIR}`]) {
        // задержка на смену слайда для лучшего синхрона стрима с высокой задержкой и презы.
        setTimeout(changeSlideBind, HIGH_LATENCY_ACTIONS_DEFFERS_MS);
      } else {
        await changeSlideBind();
      }
      commit(SET_PRESENTATION_INFO, {
        presentationId: slide.presentation.id,
        slideIndex: slide.ord,
      });
    } finally {
      commit(SET_SLIDE_BUSY, false);
    }
  },
  [SET_SLIDE]({ commit }, data) {
    commit(SET_SLIDE_VIDEO_TIME, 0);
    commit(SET_USER_SLIDE_INDEX, null);
    commit(SET_PRESENTATION_INFO, data);
  },
  async [SET_SLIDE_TIME](_context, slideId) {
    await AdminWebHttpService.get(`setTimerSlideTime/${slideId}`);
  },
};
