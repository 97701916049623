import { auth, room } from "@/store/modules/store.namespaces";
import { LOGOUT } from "@/store/modules/auth/action-types";
import { ROOM_NUMBER } from "@/store/modules/common/getter-types";
import { ENTER_TO_ROOM } from "@/store/modules/common/action-types";
import { wsErrorType } from "@/utils/errors";
import { USER_IS_SPEAKER } from "@/store/modules/auth/getter-types";

export default async function checkRoom({ to, next, store, nextMiddleware }) {
  const targetRoom = to.params.id;
  const roomNumber = store.getters[`${room}/${ROOM_NUMBER}`];
  const userIsSpeaker = store.getters[`${auth}/${USER_IS_SPEAKER}`];

  try {
    if (targetRoom && !roomNumber) {
      await store.dispatch(
        `${room}/${ENTER_TO_ROOM}`,
        {
          room: targetRoom,
          onlyData: true,
        },
        { root: true },
      );
    }
  } catch (error) {
    /**
     * Redirect the user to a page with a description of the error
     * if the room is not accessible at all.
     */
    if (error.hasType && error.hasType(wsErrorType.ROOM_ENTER_ERROR_NO_ACCESSIBILITY)) {
      if (userIsSpeaker) {
        return nextMiddleware();
      }

      return next({
        name: "roomLimit",
        params: { status: error.serverCode },
      });
    }

    /**
     * Redirect the guest to the lobby when he trying
     * to enter a room which requiring registration.
     */
    if (error.hasType && error.hasType(wsErrorType.ROOM_ENTER_ERROR_NO_GUEST_ALLOWED)) {
      return next("/lobby");
    }

    /**
     * In other cases, just logout.
     */
    next(false);
    return store.dispatch(`${auth}/${LOGOUT}`);
  }

  return nextMiddleware();
}
