function middlewaresPipeline(context, middlewares, index) {
  const nextMiddleware = middlewares[index];
  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewaresPipeline(context, middlewares, index + 1);
    nextMiddleware({ ...context, nextMiddleware: nextPipeline });
  };
}

export default middlewaresPipeline;
