import {
  RESET_SPEAKERS,
  SET_CURRENT_SPEAKER,
  SET_SPEAKERS,
} from "@/store/modules/speakers/mutation-types";
import {
  CHANGE_CURRENT_SPEAKER,
  GET_SPEAKERS,
} from "@/store/modules/speakers/action-types";
import { wsErrorType, ErrorWS } from "@/utils/errors";
import { GET_USER_QUESTIONS } from "@/store/modules/questions/action-types";
import { questions } from "@/store/modules/store.namespaces";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { checkImage } from "@/utils";

export default {
  state: {
    speakers: [],
    currentSpeaker: null,
  },
  mutations: {
    [SET_SPEAKERS](state, payload) {
      payload.forEach(async speaker => {
        if (speaker.photo) {
          speaker.photo = await checkImage(speaker.photo);
        }
      });
      state.speakers = payload;
    },
    [SET_CURRENT_SPEAKER](state, speaker) {
      state.currentSpeaker = { ...speaker };
    },
    [RESET_SPEAKERS](state) {
      state.speakers = [];
      state.currentSpeaker = null;
    },
  },
  actions: {
    async [GET_SPEAKERS]({ commit, dispatch , rootState: { common } }) {
      try {
        const { data } = await AdminWebHttpService.get(`getSpeakers/${common.roomNumber}`);
        commit(SET_SPEAKERS, data);
        await dispatch(CHANGE_CURRENT_SPEAKER, data[0]);
        return data;
      } catch (e) {
        throw new Error(
          new ErrorWS(wsErrorType.SERVER_ERROR, e).getCommonUserMessage(),
        );
      }
    },
    async [CHANGE_CURRENT_SPEAKER](
      { commit, dispatch },
      speakerObj,
    ) {
      commit(SET_CURRENT_SPEAKER, speakerObj);
      await dispatch(`${questions}/${GET_USER_QUESTIONS}`, null, { root: true });
    },
  },
};
