import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";
import { SPEAKER_BRAINSTORMS } from "@/store/modules/speaker/modules/brainstorm/getter-types";
import {
  CHANGE_STATUS,
  GET_BRAINSTORMS,
  SET_BRAINSTORM,
} from "@/store/modules/speaker/modules/brainstorm/action-types";
import {
  CHANGE_BRAINSTORM_STATUS,
  SET_ACTIVE_BRAINSTORM,
  SET_BRAINSTORMS,
} from "@/store/modules/speaker/modules/brainstorm/mutation-types";

export default {
  state: {
    list: [],
    activeBrainstormId: null,
  },
  getters: {
    [SPEAKER_BRAINSTORMS](state) {
      return state.list || [];
    },
  },
  actions: {
    async [GET_BRAINSTORMS]({ commit, rootState }) {
      try {
        const { room_id } = rootState.common.roomInfo;
        const res = await AdminApi2HttpService.get(
          `rooms/${room_id}/brainstormsWithIdeasCount`,
        );
        commit(SET_BRAINSTORMS, res.data);
      } catch (e) {
        console.error(e);
      }
    },
    async [CHANGE_STATUS]({ commit }, { id, status }) {
      try {
        const res = await AdminApi2HttpService.put(`brainstorms/${id}`, { status });
        commit(CHANGE_BRAINSTORM_STATUS, res.data);
      } catch (e) {
        console.error(e);
      }
    },
    async [SET_BRAINSTORM]({ rootState }, id) {
      try {
        const { room_id } = rootState.common.roomInfo;
        await AdminApi2HttpService.put(`rooms/${room_id}/brainstorms/${id}`);
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    [SET_BRAINSTORMS](state, brainstorms) {
      state.list = brainstorms;

      let activeBrainstorm = null;
      brainstorms.some(function(item) {
        if (item.status === 0) {
          return false;
        } else {
          activeBrainstorm = item;
          return true;
        }
      });
      state.activeBrainstormId =
        activeBrainstorm !== null ? activeBrainstorm.id : null;
    },
    [SET_ACTIVE_BRAINSTORM](state, id) {
      state.activeBrainstormId = Number(id);
    },
    [CHANGE_BRAINSTORM_STATUS](state, data) {
      const index = state.list.findIndex(item => {
        return item.id.toString() === data.brainstorm_id.toString();
      });
      if (index > -1) {
        let brainstorm = state.list[index];
        brainstorm.status = data.status;
        let newBrainstormsList = [...state.list];
        newBrainstormsList.splice(index, 1, brainstorm);
        state.list = [...newBrainstormsList];
      }
    },
  },
};
