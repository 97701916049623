export const SET_USER = "SET_USER";
export const SET_UNDREAD_MESSAGES = "SET_UNDREAD_MESSAGES";
export const SET_OLD_MESSAGES = "SET_OLD_MESSAGES";
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";
export const SET_CHAT_ID = "SET_CHAT_ID";
export const SET_TEAM_CHAT_ID = "SET_TEAM_CHAT_ID";
export const RESET_STATE = "RESET_STATE";





