import { PlayerEvents } from "@/constants/logs/logs-const";
import BaseLogger from "./BaseLogger";

class VideoLogger extends BaseLogger {
  constructor() {
    super("videoLogger", "video-actions");
    this.Events = {
      ...PlayerEvents,
      Ping: 6,
    };
  }

  getPayload(payload) {
    return {
      userId: payload.user.id,
      personalId: payload.user.personalId,
      login: payload.user.login,
      roomNumber: payload.roomNumber,
      presentationId: payload.presentationId,
      event: payload.event,
    };
  }
}

export default new VideoLogger();
