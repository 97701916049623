import config from "@/settings/config";
import {
  AVAILABLE_ROOM_MODULES,
  MOSCOW_TIME,
  NAVIGATION_ELEMENTS,
  ROOM_LOGO,
  ROOM_TOP_LOGO_SRC,
  LAST_ROOM_NUMBER,
  ROOM_NUMBER,
  ACTIVE_POLL_ID,
  ACTIVE_RESULTS_ID,
  ACTIVE_TEST_ID,
} from "@/store/modules/common/getter-types";
import prepareNavigationElements from "@/utils/room/prepareNavigationElements";
import { ROOM_NUMBER as ROOM_NUMBER_CONST } from "@/constants/room/room-const";
import { makeUrl } from "@/utils";

export default {
  [AVAILABLE_ROOM_MODULES]({ roomInfo, modules }) {
    if (roomInfo && roomInfo.Room_nav) {
      const roomNav = roomInfo.Room_nav;
      const topNavObject = (roomNav.top_nav && roomNav.top_nav.elements) || {};
      const result = {};
      for (const navKey in topNavObject) {
        if (topNavObject.hasOwnProperty(navKey)) {
          result[navKey] = !!topNavObject[navKey].visibility;
        }
      }
      return {
        ...result,
        cheat_points: roomInfo.cheat_points,
        feedbackForm: !!roomNav.feedback_form,
        teamChatMode: roomNav.chat.mode === "private",
        isShowLanguagePanel: !!roomInfo.show_language_panel,
        defaultLanguage: roomInfo.default_language,
        listeners_profile: !!roomNav.listeners_profile,
        aboutws_quiz: !!roomNav.aboutws_quiz,
        pulse: !!roomNav.pulse,
        allowAnonymity: !!roomNav.anonymity_question_enabled,
        allowDislikes: !!roomNav.dislikes_enabled,
        questionPreModeration: !!roomInfo.Question_premoderation,
        allowScrollToNextPresentation: !!roomInfo.is_scroll_to_next_pres,
        disablePrivateChat: !!roomInfo.disable_private_chat,
        streamMultilingual: !!roomInfo.multilingual,
        ...modules,
      };
    }
    return {};
  },
  [MOSCOW_TIME]({ roomInfo }) {
    return roomInfo.Room_nav && Boolean(roomInfo.Room_nav.moscow_time);
  },
  [ROOM_TOP_LOGO_SRC]({ styleUrls }) {
    if (styleUrls.roomTopLogo) {
      return makeUrl(styleUrls.roomTopLogo);
    }
    return "";
  },
  [ROOM_LOGO]({ interfaceLanguage, roomInfo }) {
    if (interfaceLanguage === "rus") {
      return roomInfo.logo || roomInfo.logo_en;
    }
    return roomInfo.logo_en || roomInfo.logo;
  },
  [LAST_ROOM_NUMBER]() {
    return localStorage.getItem(ROOM_NUMBER_CONST);
  },
  [ROOM_NUMBER](state) {
    return state.roomNumber;
  },
  [NAVIGATION_ELEMENTS]({ roomInfo }, getters, rootState) {
    const topNavElements = roomInfo.Room_nav?.top_nav?.elements;
    if (!topNavElements) return [];
    const resNav = prepareNavigationElements(topNavElements, rootState);
    return resNav.sort((a, b) => a.order - b.order);
  },
  /**
   * Ид текущего запущеного опроса
   *
   * @param {object} state
   * @param {object} state.roomInfo
   * @returns {number} - ид опроса
   */
  [ACTIVE_POLL_ID]({ roomInfo }) {
    return +roomInfo.Quiz || 0;
  },
  /**
   * Ид текущего запущеного результата опроса
   *
   * @param {object} state
   * @param {object} state.roomInfo
   * @returns {number} - ид результатов
   */
  [ACTIVE_RESULTS_ID]({ roomInfo }) {
    if (roomInfo.Show_result) {
      const pollRes = JSON.parse(roomInfo.Show_result);
      if (pollRes.type) return +pollRes.obj.id || 0;
      return 0;
    }
    return 0;
  },
  /**
   * Ид текущего запущеного теста
   *
   * @param {object} state
   * @param {object} state.roomInfo
   * @returns {number} - ид теста
   */
  [ACTIVE_TEST_ID]({ roomInfo }) {
    return roomInfo.Test || 0;
  },
};
