import {
  CLEAR_MATERIALS,
  SET_MATERIALS,
  TOGGLE_FOLDER_VISIBLE,
} from "@/store/modules/materials/mutation-types";

export default {
  [SET_MATERIALS](state, payload) {
    let data = payload.data;
    let lastItemKey =
      data.files && data.files.length && data.files[data.files.length - 1].id;
    if (payload.isFolder) {
      state.files = [...state.files, ...data];
      state.lastLoadedFileKey = {
        listLength: state.files.length,
        lastId: lastItemKey,
      };
    } else {
      state.files = [...state.files, ...data.files];
      if (data.folders.length > 0) {
        state.folders = [...data.folders];
      }
      state.lastLoadedFileKey = {
        listLength: state.files.length,
        lastId: lastItemKey,
      };
    }
  },
  [CLEAR_MATERIALS](state) {
    state.files = [];
    state.folders = [];
    state.lastLoadedFileKey = {
      listLength: 0,
      lastId: null,
    };
  },
  [TOGGLE_FOLDER_VISIBLE](state) {
    state.isFolderVisible = !state.isFolderVisible;
  },
};
