import AdminApiHttpService from "@/services/http/AdminApiHttpService";
import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import AdminWsHttpService from "@/services/http/AdminWsHttpService";
import ControlHttpService from "@/services/http/ControlHttpService";
import StorageHttpService from "@/services/http/StorageHttpService";

const httpServices = [
  AdminApiHttpService,
  AdminApi2HttpService,
  AdminWebHttpService,
  AdminWsHttpService,
  ControlHttpService,
  StorageHttpService,
];

export function setTokenGetterToService(getToken) {
  httpServices.forEach(service => {
    service.config.getToken = getToken;
  });
}

export function setStoreToService(store) {
  httpServices.forEach(service => {
    service.setStore(store);
  });
}
