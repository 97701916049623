<template>
  <div v-if="$slots.default" class="PageTitle">
    <slot name="default"></slot>
  </div>
</template>
<script>
export default {
  name: "PageTitle",
};
</script>
<style lang="less" scoped>
.PageTitle {
  font-size: 24px;
  padding: 15px;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
}
</style>
