export const GET_PARTICIPANTS_ADMIN_INFO = "GET_PARTICIPANTS_ADMIN_INFO";
export const GET_MANAGER_ADMIN_INFO = "GET_MANAGER_ADMIN_INFO";
export const GET_ADMIN_INFO = "GET_ADMIN_INFO";
export const GET_GOD_TOP_MANAGERS = "GET_GOD_TOP_MANAGERS";
export const GET_GOD_TOP_TEAMS = "GET_GOD_TOP_TEAMS";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_PARTICIPANT_SCORE = "GET_PARTICIPANT_SCORE";
export const GET_AVERAGE_TEAM_SCORE = "GET_AVERAGE_TEAM_SCORE";
export const GET_AVERAGE_TEAM_SCORE_RM = "GET_AVERAGE_TEAM_SCORE_RM";
export const GET_MY_TEAM_SCORE = "GET_MY_TEAM_SCORE";
export const GET_MY_TEAM_SCORE_RM = "GET_MY_TEAM_SCORE_RM";
export const GET_MY_LINE_ACTIVE = "GET_MY_LINE_ACTIVE";
export const GET_BUSINESS_UNIT_TEAM_SCORE = "GET_BUSINESS_UNIT_TEAM_SCORE";
