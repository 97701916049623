import {
  FORMATTED_TESTS,
  ONLY_TESTS,
  INTERACTIVE_THEME,
  KING_LEADERS,
  KING_USER_SCORE,
  TESTS_BY_STATUS,
} from "@/store/modules/polls/getter-types";
import { i18nPlurals } from "@/utils";
import { CUSTOM_THEME } from "@/constants/themes/themes-const";

const TEST_STATUS_KEY_MAP = {
  started: "started",
  not_started: "notStarted",
  finished: "finished",
};

export default {
  /**
   * Метод получения отформатированных тестов,
   * для выдачи в список тестов у пользователя
   *
   * @param {object} tests - список групп с тестами и их статусами.
   * @returns {Array} - отформатированный массив групп со статусами и тестами внутри
   */
  [FORMATTED_TESTS]({ tests }) {
    const formattedTests = JSON.parse(JSON.stringify(tests));
    for (const group in formattedTests) {
      for (const status of Object.keys(TEST_STATUS_KEY_MAP)) {
        formattedTests[group][status] = formattedTests[group][status]?.map(test => ({
          id: test.id,
          status,
          took: test.users_passed,
          test_group: test.test_group,
          title: test.title,
          isRepeated: test.isRepeated,
          commonResult: test.all_users_points > 0,
          answers: test.answers,
          questions: test.questions,
          max_points: test.max_points,
          points: test.points,
          timer: test.timer,
          time_started: test.time_started,
          has_repeated: test.has_repeated,
          timerDelta: test.timerDelta,
          endless_passing: !!Number(test.endless_passing),
          repeat_pass_locked: test.repeat_pass_locked,
          repeat_pass_wait: test.repeat_pass_wait,
        }));
      }
    }
    return formattedTests;
  },
  /**
   * Метод получения тестов. Без разделения на группы и статусы.
   *
   * @param {object} tests - список групп с тестами и их статусами.
   * @returns {Array} - массив отформатированных тестов.
   */
  [ONLY_TESTS]({ tests }) {
    const onlyTests = [];
    const formattedTests = JSON.parse(JSON.stringify({ tests }));
    for (const group in formattedTests?.tests) {
      for (const status of Object.keys(TEST_STATUS_KEY_MAP)) {
        formattedTests.tests[group][status]?.length &&
          onlyTests.push(...formattedTests.tests[group][status]);
      }
    }
    return onlyTests;
  },
  /**
   * Геттер для получения тестов по статусам.
   *
   * @param {object} tests - список групп с тестами и их статусами.
   * @returns {object} - объект со статусами и тестами внутри
   */
  [TESTS_BY_STATUS]({ tests }) {
    const formattedTests = JSON.parse(JSON.stringify({ tests }));
    const readyTests = {
      started: [],
      not_started: [],
      finished: [],
    };
    for (const group in formattedTests?.tests) {
      for (const status of Object.keys(TEST_STATUS_KEY_MAP)) {
        formattedTests.tests[group][status]?.length &&
          readyTests[status].push(...formattedTests.tests[group][status]);
      }
    }
    return readyTests;
  },
  [KING_LEADERS](state, getters, rootState) {
    return rootState.games.king.leadersList.map(leader => {
      return {
        image: leader.user_photo,
        name: leader.user_fields.fio,
        itsYou: leader.id === rootState.auth.user.id,
        points: leader.game_king_points,
        place: leader.place,
      };
    });
  },
  [KING_USER_SCORE](state, getters, rootState) {
    const score = rootState.games.king;
    return score.points || score.points === 0
      ? `${score.points} ${i18nPlurals("point", score.points, rootState.common.interfaceLanguage)}`
      : null;
  },
  [INTERACTIVE_THEME](state) {
    return {
      styles:
        state.interactiveTheme === CUSTOM_THEME && state.interactiveThemeBg
          ? { backgroundImage: `url('${state.interactiveThemeBg}')` }
          : "",
      class: state.interactiveTheme ? `interactive--bg-${state.interactiveTheme}` : "",
    };
  },
};
