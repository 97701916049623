<template>
  <span v-if="append && dictKey" data-testid="timer" v-html="text"></span>
  <span v-else data-testid="timer">{{ time }}</span>
</template>

<script>
import EasyTimer from "easytimer";

/**
 * Таймер
 *
 * @vue-prop {number} [seconds=0] - время в секундах
 * @vue-prop {boolean} [append=false] - вставляем таймер в текст или нет
 * @vue-prop {string} [dictKey=""] - ключ для получения выражения из словаря
 * @vue-data {object|null} [timer=null] - объект EasyTimer
 * @vue-data {string} [time=""] - время в заданном формате
 * @vue-event time-is-out - событие истечения времени таймера
 */
export default {
  name: "WsTimer",
  props: {
    seconds: {
      type: Number,
      default: 0,
    },
    append: {
      type: Boolean,
      default: false,
    },
    dictKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      timer: null,
      time: "",
    };
  },
  computed: {
    text() {
      return this.$t(this.dictKey, { time: this.time });
    },
  },
  created() {
    this.timer = new EasyTimer();
    this.timer.addEventListener("secondsUpdated", this.secondsUpdatedHandler);
    this.timer.addEventListener("targetAchieved", this.targetAchievedHandler);
  },
  mounted() {
    this.timer.start({
      countdown: true,
      startValues: {
        seconds: this.seconds,
      },
    });
  },
  beforeDestroy() {
    this.timer.removeEventListener("secondsUpdated", this.secondsUpdatedHandler);
    this.timer.removeEventListener("targetAchieved", this.targetAchievedHandler);
    this.timer = null;
  },
  methods: {
    secondsUpdatedHandler() {
      this.time = this.timer.getTimeValues().toString(["minutes", "seconds"]);
    },
    targetAchievedHandler() {
      this.$emit("time-is-out");
    },
  },
};
</script>
