import BaseLogger from "@/services/logger/BaseLogger";

class UsersPresenceLogger extends BaseLogger {
  constructor() {
    super("userPresenceLogger", "user-activity-actions");
    this.Events = {
      Stop: 0,
      Start: 1,
      Ping: 2,
    };
  }

  getPayload(payload, event) {
    return {
      login: payload.login,
      fio: payload.fio,
      userId: payload.userId,
      roomNumber: payload.roomNumber,
      roomId: payload.roomId,
      audienceId: payload.audienceId,
      status: event,
    };
  }
}

export default new UsersPresenceLogger();
