export const SET_USER = "SET_USER";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const RESET_USER = "RESET_USER";
export const SET_USER_SLIDE_LIKES = "SET_USER_SLIDE_LIKES";
export const SET_USER_PRESENTATION_LIKES = "SET_USER_PRESENTATION_LIKES";
export const SET_USER_PRESENTATION_LIKE = "SET_USER_PRESENTATION_LIKE";
export const SET_USER_SLIDE_LIKE = "SET_USER_SLIDE_LIKE";
export const SET_USER_IS_MAIN = "SET_USER_IS_MAIN";
export const SET_USER_FIO = "SET_USER_FIO";
export const SET_USER_PHOTO = "SET_USER_PHOTO";
export const SET_USER_DESCRIPTION = "SET_USER_DESCRIPTION";




