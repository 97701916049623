/* eslint-disable no-useless-escape */
const IMAGE_TYPE = {
  default: "default",
  hqdefault: "hqdefault",
  mqdefault: "mqdefault",
  sddefault: "sddefault",
  maxresdefault: "maxresdefault",
};
/**
 * Match input url contain ID and check it for right length
 * @param {String} url
 * @returns string
 */
function matchYt(url) {
  const match = url.match(regExpYt);
  if (match && match[2].length === 11) {
    return match[2];
  }
  console.error("this type video is not supported or your use bad url");
}


function matchVimeo(url) {
  const matchV = url.match(regExpVimeo);
  if (matchV && (matchV[4].length > 0 || matchV[2].length > 0)) {
    return matchV[4].length > 0 ? matchV[4] : matchV[2];
  }
  console.error("this type video is not supported or your use bad url");
}
/** parser for youtube url
 *  - youtube.com/watch
 *  - youtu.be
 *  - youtube.com/embed
 */
const regExpYt = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
const regExpVimeo = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

/**
 * Парсер для подготовки ссылок на видео с ютуба и получения превью видео
 */
class YoutubeParser {
  /**
   * parser for youtube url
   *  - youtube.com/watch
   *  - youtu.be
   *  - youtube.com/embed
   *
   * @param {String} url
   * @returns {String} youtube video id
   */
  static getYoutubeId(url) {
    const id = matchYt(url);
    return id;
  }

  /**
   * parser for youtube url
   *  - youtube.com/watch
   *  - youtu.be
   *  - youtube.com/embed
   *
   * @param {String} url
   * @returns {String} youtube video embed url
   */
  static getYoutubeEmbedUrl(url) {
    const id = matchYt(url);
    return `https://www.youtube.com/embed/${id}?controls=0&fs=0&iv_load_policy=3`;
  }
  static getVimeoEmbedUrl(url) {
    const id = matchVimeo(url);
    console.log(url)
    return `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0`;
  }

  static get IMAGE_TYPE() {
    return IMAGE_TYPE;
  }

  /**
   * parser for youtube url
   *  - youtube.com/watch
   *  - youtu.be
   *  - youtube.com/embed
   * @param {String} url
   * @param {String} type - default | hqdefault | mqdefault | sddefault | maxresdefault
   * @returns {String} youtube video embed url
   */
  static getYoutubeImgPreviewUrl(url, type = "maxresdefault") {
    const id = matchYt(url);
    if (id) {
      return `https://img.youtube.com/vi/${id}/${type}.jpg`;
    }
    return null;
  }

  static async getVimeoImgPreviewUrl(url) {
    const id = matchVimeo(url);
    if (id) {
      let vimeoInfo = await fetch(`https://vimeo.com/api/oembed.json?url=${url}`,
        {
        method: 'get',
        cache: 'no-cache',
        mode: 'cors',
      });
      vimeoInfo = await vimeoInfo.json();
      return vimeoInfo.thumbnail_url;
    }
    return null;
  }
}

export default YoutubeParser;
