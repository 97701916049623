import { hidePreloader } from "@/utils";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import Auth from "@/services/AuthService";
import { brainstorm, broadcast, landing, room } from "@/store/modules/store.namespaces";
import router from "@/routes/router";
import { wsErrorType, WSCustomError } from "@/utils/errors";
import { LAST_QUERY_PARAMS } from "@/constants/user/auth-const";

import { i18n } from "@/utils/i18n-setup";
import { SET_USER_TABLE_ID } from "@/store/modules/brainstorm/mutation-types";
import { RESET_ROOM_STATE, SET_ROOM_NUMBER } from "@/store/modules/common/mutation-types";
import { TEMPLATE } from "@/store/modules/landing/getter-types";
import { IS_BROADCAST_ENABLED, IS_CONFERENCE_TYPE } from "@/store/modules/broadcast/getter-types";
import { USER_IS_LOGGED_IN } from "@/store/modules/auth/getter-types";
import { DELETE_LEADING } from "@/store/modules/broadcast/action-types";
import { BROADCAST_RESET_STATE } from "@/store/modules/broadcast/mutation-types";
import { RESET_USER, SET_USER } from "./mutation-types";
import {
  LOGIN,
  LOGIN_BY_SURNAME,
  LOGIN_BY_TOKEN,
  LOGOUT,
  REGISTRATION,
  RESTORE_PASSWORD,
  UPDATE_BY_TOKEN,
  RESET,
} from "./action-types";

const waitLanding = async rootState => {
  return new Promise((resolve, reject) => {
    let iteration = 0;
    const check = () => {
      iteration++;
      if (rootState.landing.template) {
        return resolve();
      }
      if (iteration > 20) {
        return reject(new WSCustomError(wsErrorType.AUTH_ERROR_TIMEOUT_WAIT_LANDING));
      }
      setTimeout(check, 200);
    };
    check();
  });
};

const getUserDataHandler = async (sessionToken, commit) => {
  try {
    const userResponse = await AdminWebHttpService.get(`getUserDataByToken/${sessionToken}`);
    commit(SET_USER, userResponse.data);
    commit(`${brainstorm}/${SET_USER_TABLE_ID}`, userResponse.data.user_table_id, { root: true });
    return userResponse.data;
  } catch (error) {
    throw new WSCustomError(wsErrorType.AUTH_ERROR_FAIL_GET_USER_DATA, error);
  }
};

export default {
  async [LOGIN_BY_SURNAME]({ commit }, { login, pass, fio }) {
    try {
      const { sessionToken } = await Auth.loginBySurname(login, pass, fio);
      await getUserDataHandler(sessionToken, commit);
    } catch (error) {
      if (error instanceof WSCustomError) {
        error.setParentType(wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_SURNAME);
        throw error;
      } else {
        const errorName =
          error.response?.data?.name === "Conflict" ? error.response?.data?.name : "";
        const errorType = errorName
          ? wsErrorType.REGISTER_ERROR_FAIL_BY_SURNAME
          : wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_SURNAME;
        throw new WSCustomError(errorType, {
          ...error,
          subtype: wsErrorType.UNKNOWN_ERROR,
          server: {
            status: error.response?.status ?? 0,
            error_code: error.response?.code ?? -1,
            error: error.response?.data?.message ?? "",
          },
        });
      }
    }
  },
  async [LOGOUT](
    { commit, dispatch, getters, rootState, rootGetters },
    { redirect, tokenExpired } = {},
  ) {
    if (rootGetters[`${broadcast}/${IS_CONFERENCE_TYPE}`] && !tokenExpired) {
      await dispatch(`${broadcast}/${DELETE_LEADING}`, null, { root: true });
    }
    commit(`${room}/${SET_ROOM_NUMBER}`, "", { root: true });
    const { hide_landing, corp_landing } = rootGetters[`${landing}/${TEMPLATE}`];
    if (getters[USER_IS_LOGGED_IN] && hide_landing && !corp_landing) {
      return router.replace({ name: "lobby" });
    }
    Auth.removeToken();

    if (rootGetters[`${broadcast}/${IS_BROADCAST_ENABLED}`]) {
      commit(`${broadcast}/${BROADCAST_RESET_STATE}`, true, { root: true });
    }
    commit(`${room}/${RESET_ROOM_STATE}`, null, { root: true });
    commit(RESET_USER);
    localStorage.removeItem(LAST_QUERY_PARAMS);

    if (rootState.route.fullPath !== "/login") {
      const route = {
        path: "/login",
      };
      if (redirect) {
        route.query = { redirect };
      }
      await router.replace(route);
    }
  },
  async [LOGIN]({ commit }, { login, pass }) {
    try {
      const authResponse = await Auth.login(login, pass);
      if (!authResponse || authResponse.error) {
        throw new WSCustomError(wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_PASS, {
          server: authResponse,
        });
      }

      if (login === "guest") {
        Auth.setGuestToken(authResponse.sessionToken);
      } else {
        Auth.removeGuestToken();
      }

      await getUserDataHandler(authResponse.sessionToken, commit);
      setTimeout(hidePreloader, 100);
    } catch (error) {
      if (error instanceof WSCustomError) {
        error.setParentType(wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_PASS);
        throw error;
      } else {
        throw new WSCustomError(wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_PASS, {
          ...error,
          subtype: wsErrorType.UNKNOWN_ERROR,
        });
      }
    }
  },
  async [LOGIN_BY_TOKEN]({ commit, rootState }, token) {
    try {
      const checkedToken = token || Auth.getToken();

      let expiresDatetime = +localStorage.getItem("wsUserTokenExpiresDatetime");
      if (!expiresDatetime) {
        expiresDatetime = Date.now() + 48 * 3600 * 1000;
      }
      const expiresMs = expiresDatetime - Date.now();
      const expiresDays = expiresMs / (24 * 3600 * 1000);

      if (expiresDays > 0) {
        const user = await getUserDataHandler(checkedToken, commit);
        localStorage.setItem("wsUserTokenExpiresDatetime", expiresDatetime);
        Auth.setToken(checkedToken, { expires: expiresDays, SameSite: "Lax" });
        /* There was a precedent:
          LOGIN_BY_TOKEN may be requested when landing data has not yet arrived.
        */
        await waitLanding(rootState);
        setTimeout(hidePreloader, 100);
        return user;
      }
      throw new WSCustomError(wsErrorType.AUTH_ERROR_EXPIRED_TOKEN);
    } catch (error) {
      if (error instanceof WSCustomError) {
        error.setParentType(wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_TOKEN);
        throw error;
      } else {
        throw new WSCustomError(wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_TOKEN, {
          ...error,
          subtype: wsErrorType.UNKNOWN_ERROR,
        });
      }
    }
  },
  async [REGISTRATION]({ dispatch }, data) {
    const response = await Auth.reg(data);
    const userData = {
      login: response.email,
      pass: response.password,
    };
    if (data.loginOnComplete) {
      await dispatch(LOGIN, userData);
    }
  },
  async [RESTORE_PASSWORD](context, { login }) {
    try {
      const { data } = await Auth.restorePassword(login);
      if (data.expire) {
        return i18n.t("Login.restorePasswordRestriction", [data.expire]);
      }
      await router.push("/login");
    } catch (e) {
      throw new Error("Error in restore password action");
    }
  },
  async [UPDATE_BY_TOKEN]({ commit, dispatch }) {
    try {
      const userResponse = await getUserDataHandler(Auth.getToken(), commit);
      commit(`${room}/${SET_ROOM_NUMBER}`, userResponse.room_number, {
        root: true,
      });
    } catch (error) {
      dispatch(LOGOUT);
      throw new WSCustomError(wsErrorType.AUTH_ERROR_FAIL_UPDATE_BY_TOKEN, error);
    }
  },
  [RESET]({ dispatch }) {
    try {
      if (typeof window !== "undefined") {
        if (window.localStorage) {
          window.localStorage.clear();
        }
      }
      if (typeof document !== "undefined") {
        (document.cookie || "").split(";").forEach(c => {
          document.cookie = `${c.trim().split("=")[0]}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        });
      }
      dispatch(LOGOUT);
    } catch (error) {
      dispatch(LOGOUT);
      throw new WSCustomError(wsErrorType.AUTH_ERROR_FAIL_RESET_USER_DATA, error);
    }
  },
};
