<template>
  <div class="form-faq">
    <a
      class="form-faq__btn"
      :href="faqUrl"
      target="_blank"
    >
      faq
    </a>
  </div>
</template>

<script>
export default {
  name: "LandingFaqButton",
  props: {
    faqUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="less">
.form-faq {
  display: inline-block;
  margin: 5px 0 10px;
  /* todo make base-button for it*/
  &__btn {
    display: block;
    position: relative;
    width: 55px;
    height: 30px;
    padding: 4px 5px 6px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    background-size: cover;
    background-position: center center;
    border: 1px solid var(--login-button-color);
    border-radius: 5px;
    cursor: pointer;
    color: var(--login-button-color);

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
