import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    isComplete: false,
    posts: [],
    lastPostId: null,
  },
  actions,
  mutations,
};
