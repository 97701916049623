import BaseHttpService from "@/services/http/BaseHttpService";
import config from "@/settings/config";

const baseURL = `${config.controllServerUrl}/api/`;

class ControlHttpService extends BaseHttpService {
  constructor() {
    super({ baseURL });
  }

  changeSlide({ roomNum, presId, slideOrd }) {
    return this.get(`changed/${roomNum}/${presId}/${slideOrd}`);
  }
}

export default new ControlHttpService();
