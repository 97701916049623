export const TRANSLATION_SET_PROP = "TRANSLATION_SET_PROP";
export const TRANSLATION_RESET_STATE = "TRANSLATION_RESET_STATE";
export const TOGGLE_STREAM_IS_ACTIVE = "TOGGLE_STREAM_IS_ACTIVE";
export const SET_STREAM_SAFEMODE = "SET_STREAM_SAFEMODE";
export const SET_STREAM_AIR = "SET_STREAM_AIR";
export const SET_STREAM_IS_ACTIVE = "SET_STREAM_IS_ACTIVE";
export const SET_STREAM_LOW_LATENCY = "SET_STREAM_LOW_LATENCY";
export const SET_STREAM_RESOLUTION = "SET_STREAM_RESOLUTION";

export const SET_AVAILABLE_DEVICES = "SET_AVAILABLE_DEVICES";
