import { isDesktop } from "@/utils/browser-check";
import {
  IS_CONFERENCE_TYPE,
  IS_FACECAST_TYPE,
  IS_MP4_TYPE,
  IS_TYPICAL_TYPE,
  IS_ONE_2_MANY_TYPE,
  IS_YOUTUBE_TYPE,
  YT_BROADCAST_URL,
  BROADCAST_URL,
  FACECAST_URL,
  ROOM_BROADCAST_TYPE,
  EXTERNAL_STREAM_URL,
  IS_NEED_MEDIA_DEVICES,
  IS_VIDEO_TYPE,
  IS_BROADCAST_URL_MISSING,
  IS_BROADCAST_ENABLED,
  SPEAKER_PLAYER_CONSTRAINTS,
  HIGH_LATENCY_BROADCAST_ON_AIR,
  BROADCAST_PLAYER_TYPE,
  IS_EXTERNAL_STREAM,
  SCREEN_SAVER_SRC,
  BROADCAST_SERVERS,
  IS_VIMEO_TYPE,
  VIMEO_BROADCAST_URL,
} from "@/store/modules/broadcast/getter-types";
import {
  BROADCAST_TYPE,
  NGENIX_URL_POSTPART,
  NGENIX_URL_PREPART,
  OPTION_EXTERNAL_STREAM,
  PLAYER_TYPE,
} from "@/constants/broadcast/broadcast-const";
// import UserRoles from "@/constants/user/roles-const";
import { room } from "@/store/modules/store.namespaces";
import config from "@/settings/config";

export default {
  [ROOM_BROADCAST_TYPE](_, _2, rootState) {
    return (rootState.common.roomInfo.type_broadcast_type || "").toLowerCase();
  },
  [BROADCAST_PLAYER_TYPE](state, getters, rootState) {
    const playerType =
      (getters[IS_CONFERENCE_TYPE] && PLAYER_TYPE.CONFERENCE) ||
      (getters[IS_VIDEO_TYPE] && PLAYER_TYPE.EXTERNAL) ||
      ((getters[IS_ONE_2_MANY_TYPE] || getters[IS_TYPICAL_TYPE]) && PLAYER_TYPE.ONE_TO_MANY);
    // if (rootState.auth.user.role !== UserRoles.Admin && getters[IS_TYPICAL_TYPE]) {
    //   playerType = PLAYER_TYPE.EXTERNAL;
    // }
    return playerType;
  },
  [IS_CONFERENCE_TYPE](_, getters) {
    return getters[ROOM_BROADCAST_TYPE] === BROADCAST_TYPE.MEETING;
  },
  [IS_TYPICAL_TYPE](state, getters) {
    return getters[ROOM_BROADCAST_TYPE] === BROADCAST_TYPE.TYPICAL && state.buffering;
  },
  [IS_ONE_2_MANY_TYPE](state, getters) {
    return getters[ROOM_BROADCAST_TYPE] === BROADCAST_TYPE.TYPICAL && !state.buffering;
  },
  [IS_EXTERNAL_STREAM]({ selectedVideo }, getters) {
    return (
      getters[ROOM_BROADCAST_TYPE] === BROADCAST_TYPE.TYPICAL &&
      selectedVideo?.deviceId === OPTION_EXTERNAL_STREAM
    );
  },
  [IS_YOUTUBE_TYPE](_, getters) {
    return getters[ROOM_BROADCAST_TYPE] === BROADCAST_TYPE.YOUTUBE;
  },
  [IS_VIMEO_TYPE](_, getters) {
    return getters[ROOM_BROADCAST_TYPE] === BROADCAST_TYPE.VIMEO;
  },
  [IS_MP4_TYPE](_, getters) {
    return getters[ROOM_BROADCAST_TYPE] === BROADCAST_TYPE.MP4;
  },
  [IS_FACECAST_TYPE](_, getters) {
    return getters[ROOM_BROADCAST_TYPE] === BROADCAST_TYPE.FACECAST;
  },
  [BROADCAST_URL](_, _2, rootState) {
    return (rootState.common.roomInfo.type_broadcast_url || "").trim();
  },
  [YT_BROADCAST_URL](_, getters) {
    return `${getters[BROADCAST_URL]}?playsinline=1&rel=0&showinfo=0`;
  },
  [VIMEO_BROADCAST_URL](_, getters) {
    return `${getters[BROADCAST_URL]}?playsinline=1&rel=0&showinfo=0`;
  },
  [FACECAST_URL](_, _2, rootState) {
    return (rootState.common.roomInfo.facecast_url || "").trim();
  },
  [IS_BROADCAST_ENABLED](_, getters) {
    return getters[ROOM_BROADCAST_TYPE] !== BROADCAST_TYPE.NONE;
  },
  [EXTERNAL_STREAM_URL](_, getters, rootState) {
    if (getters[IS_YOUTUBE_TYPE] || getters[IS_MP4_TYPE] || getters[IS_VIMEO_TYPE]) {
      return getters[BROADCAST_URL];
    }

    if (getters[IS_EXTERNAL_STREAM]) {
      if (config.flashphonerHlsUrl) {
        return `${config.flashphonerHlsUrl}/${rootState.common.roomNumber}/${rootState.common.roomNumber}.m3u8`;
      }

      return `${NGENIX_URL_PREPART}${rootState.common.roomNumber}${NGENIX_URL_POSTPART}`;
    }
    return "";
  },
  [IS_NEED_MEDIA_DEVICES](_, getters) {
    return getters[IS_TYPICAL_TYPE] || getters[IS_CONFERENCE_TYPE] || getters[IS_ONE_2_MANY_TYPE];
  },
  [IS_VIDEO_TYPE](_, getters) {
    return (
      getters[IS_YOUTUBE_TYPE] ||
      getters[IS_VIMEO_TYPE] ||
      getters[IS_MP4_TYPE] ||
      getters[IS_FACECAST_TYPE] ||
      getters[IS_EXTERNAL_STREAM]
    );
  },
  [IS_BROADCAST_URL_MISSING](_, getters) {
    return (
      (getters[IS_YOUTUBE_TYPE] || getters[IS_MP4_TYPE] || getters[IS_VIMEO_TYPE]) &&
      !getters[BROADCAST_URL]
    );
  },
  [SPEAKER_PLAYER_CONSTRAINTS](state) {
    const selectedVideo = Object.keys(state.selectedVideo).length ? state.selectedVideo : false;
    const selectedAudio = Object.keys(state.selectedAudio).length ? state.selectedAudio : false;

    const video = selectedVideo || state.devices.video[0] || false;
    const audio =
      selectedAudio || (state.devices.audio[0] && state.devices.audio[0].deviceId) || false;

    const constraints = {
      audio,
      video,
    };

    if (!isDesktop() && constraints.video !== false) {
      let facingModeValue = "";
      if (video.facingMode) {
        if (typeof video.facingMode === "string") {
          facingModeValue = video.facingMode;
        } else {
          facingModeValue = video.facingMode.exact;
        }
      }

      constraints.video.facingMode = {
        exact: facingModeValue,
      };
    }
    return constraints;
  },
  [HIGH_LATENCY_BROADCAST_ON_AIR](state, getters) {
    return state.onAir && (getters[IS_TYPICAL_TYPE] || getters[IS_EXTERNAL_STREAM]);
  },
  [SCREEN_SAVER_SRC](_, _2, rootState) {
    return rootState.common.roomInfo.broadcast_preview_img;
  },
  /**
   * Список серверов для стриминга
   *
   * @param {*} state
   * @param {*} getters
   * @param {*} rootState
   * @returns {Array} Массив серверов
   */
  [BROADCAST_SERVERS](state, getters, rootState) {
    return rootState[room].roomInfo.stream?.servers || [];
  },
};
