import { auth } from "@/store/modules/store.namespaces";
import { RESET } from "@/store/modules/auth/action-types";

export default function checkReset({ to, next, store, nextMiddleware }) {
  if (to.name === "reset") {
    next(false);
    return store.dispatch(`${auth}/${RESET}`, null, { root: true });
  }

  return nextMiddleware();
}
