import {
  SET_ERROR,
  SET_LISTENER_PROFILE,
  SET_LISTENERS,
} from "@/store/modules/listeners/mutation-types";

export default {
  [SET_LISTENERS](state, data) {
    state.listeners = data;
  },
  /**
   * Сохраняем профиль юзера по ключу который является id для кеша
   * @param {Object} state
   * @param {Object} profile - профиль юзера
   */
  [SET_LISTENER_PROFILE](state, profile) {
    state.profiles = { ...state.profiles, [profile.id]: profile };
  },
  /**
   * Устанавливает код ошибки если загрузка данных не выполнилась
   * @param state
   * @param {Object} error - объект ошибки с кодом и сообщением
   */
  [SET_ERROR](state, error) {
    state.error = error;
  },
};
