import { makeUrl } from "@/utils";

export default class PhotoOnWall {
  constructor(photo) {
    this.id = "";
    this.roomId = "";
    this.src = "";
    this.srcPreview = "";
    this.dateCreated = new Date();
    this.dateUpdate = new Date();
    this.setServer(photo);
  }

  // Адаптер для серверных данных
  setServer(photo) {
    if (!photo) return;
    this.set({
      id: +photo.id,
      roomId: +photo.room_id || 0,
      src: makeUrl(photo.content),
      srcPreview: makeUrl(photo.preview),
      dateCreated: new Date(photo.created_at),
      dateUpdate: new Date(photo.updated_at),
    });
  }

  set(question) {
    Object.entries(question).forEach(([key, val]) => {
      this[key] = val;
    });
    return this;
  }
}
