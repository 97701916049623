import Vue from "vue";
import SentryLogger from "@/services/logger/SentryLogger";
import UserPresenceLogger from "@/services/logger/UserPresenceLogger";
import StreamLogger from "@/services/logger/StreamLogger";
import VideoLogger from "@/services/logger/VideoLogger";
import MaterialsLogger from "@/services/logger/MaterialsLogger";

const loggers = {
  install(instance) {
    instance.mixin({
      computed: {
        $loggers() {
          return {
            $sentry: new SentryLogger(),
            $userPresence: UserPresenceLogger,
            $stream: StreamLogger,
            $video: VideoLogger,
            $materials: MaterialsLogger,
          };
        },
        $sentryLoggerLevels() {
          return this.$loggers.$sentry.levels;
        },
      },
    });
  },
};

Vue.use(loggers);

export default loggers;
