<template>
  <div class="social-auth">
    <p class="social-description">
      {{ $t("Login.socialEnter") }}
    </p>
    <div class="social-list">
      <ws-button
        v-for="(btn, idx) in buttons"
        :key="idx"
        fab
        outlined
        color="primary"
        :title="$t(btn.dictKey)"
        @click.prevent="socialLinkClickHandler(btn.type)"
      >
        <ws-icon lg brands>{{ btn.icon }}</ws-icon>
      </ws-button>
    </div>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

/**
 * Компонента аунтификации через социальные сети,
 * при выборе социальной сети редиректит на страницу авторизации
 *
 * @vue-event {string} auth - Выбраная социальная сеть
 */
export default {
  name: "SocialAuth",
  components: {
    WsButton,
    WsIcon,
  },
  computed: {
    buttons() {
      return [
        this.makeBtn("authVk", "vk", "Vkontakte"),
        this.makeBtn("authFb", "facebook-f", "FaceBook"),
        this.makeBtn("authOk", "odnoklassniki", "Odnoklassniki"),
        this.makeBtn("authGmail", "google", "Gmail"),
      ];
    },
  },
  methods: {
    socialLinkClickHandler(socialName) {
      this.$emit("auth", socialName);
    },
    makeBtn(type, icon, dictKey) {
      return {
        type,
        icon,
        dictKey: `Login.social${dictKey}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.social-description {
  margin-top: 10px;
  font-size: 14px;
  color: var(--login-button-color);
  text-align: center;
  opacity: 0.7;
}

.social-list {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 0 0.5rem;
  justify-content: center;
  padding: 0;
  margin-top: 5px;
}
</style>
