import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import {
  GET_AGENDA_EVENT,
  GET_REWARDS,
  GET_REWARDS_LIST,
  GET_SCHEDULE,
  SEND_SETTED_REWARDS,
  SHOW_AGENDA_EVENT,
} from "@/store/modules/schedule/action-types";
import {
  SET_AGENDA_EVENT,
  SET_AGENDA_EVENT_DETAILS,
  SET_LOADING_STATUS,
  SET_REWARDS,
  SET_REWARDS_LIST,
  SET_SCHEDULE,
  SET_SHOW_REWARD_BUTTON,
} from "@/store/modules/schedule/mutation-types";

const langMap = {
  eng: "en",
  rus: "ru",
};

export default {
  [GET_AGENDA_EVENT]({ commit }, eventId) {
    return new Promise(resolve => {
      AdminWebHttpService.get(`getAgendaEvent/${eventId}`)
        .then(res => {
          commit(SET_AGENDA_EVENT, res.data);
          resolve(res.data);
        })
        .catch(err => console.error(err));
    });
  },
  [SHOW_AGENDA_EVENT]({ commit, dispatch }, eventId) {
    return new Promise(resolve => {
      dispatch(GET_AGENDA_EVENT, eventId)
        .then(eventData => {
          commit(SET_AGENDA_EVENT_DETAILS, {
            event: eventData,
            id: eventId,
          });
          resolve();
        })
        .catch(() => resolve());
    });
  },
  [GET_REWARDS_LIST]({ commit }) {
    AdminWebHttpService.post("getRewardsList", "").then(res => {
      const presentsIndex = res.data.Reward_types.findIndex(item => item.name === "Присутствие");
      res.data.Reward_types.unshift(
        res.data.Reward_types.splice(presentsIndex, presentsIndex + 1)[0],
      );

      commit(SET_REWARDS_LIST, res.data);
      commit(SET_REWARDS, false);
    });
  },
  [SEND_SETTED_REWARDS]({ commit }, { submittedAnswers, eventId }) {
    const ans = JSON.stringify(submittedAnswers);
    return new Promise((resolve, reject) => {
      AdminWebHttpService.post(`setRewards/${eventId}`, ans)
        .then(res => {
          if (res.status === 200 && res.data === false) {
            // throw new Error("Вы уже поставили оценку");
            commit(SET_REWARDS, true);
            commit(SET_SHOW_REWARD_BUTTON, {
              id: eventId,
              status: true,
            });
            reject("Вы уже поставили оценку");
          } else {
            commit(SET_REWARDS, true);
            commit(SET_SHOW_REWARD_BUTTON, {
              id: eventId,
              status: true,
            });
            resolve("Ваши изменения успешно внесены");
          }
        })
        .catch(() => {
          commit(SET_REWARDS, true);
          reject(
            "Возникла проблема при соединении с сервером. Пожалуйста, перезагрузите страницу через несколько минут.",
          );
        });
    });
  },
  [GET_REWARDS]({ commit }, eventId) {
    AdminWebHttpService.post(`getRewards/${eventId}`).then(res => {
      if (res.status === 200 && res.data === false) {
        const result = {
          id: eventId,
          status: false,
        };
        commit(SET_SHOW_REWARD_BUTTON, result);
      } else {
        const result = {
          id: eventId,
          status: true,
        };
        commit(SET_SHOW_REWARD_BUTTON, result);
      }
    });
  },
  [GET_SCHEDULE]({ commit, rootState }) {
    if (rootState.common.roomNumber) {
      commit(SET_LOADING_STATUS, true);
      return new Promise(resolve => {
        const language = langMap[rootState.common.interfaceLanguage];
        AdminWebHttpService.get(`getScheduleByHalls/${rootState.common.roomNumber}/${language}`)
          .then(res => {
            if (res) {
              commit(SET_SCHEDULE, res.data);
              commit(SET_LOADING_STATUS, false);
            }
            resolve();
          })
          .catch(err => {
            commit(SET_LOADING_STATUS, true);
            console.error(err);
          });
      });
    }
    return Promise.resolve();
  },
};
