
import getters from "./getters";
import mutations from "./mutations";

export default {
  state: {
    channelId: null,
    lastMsgId: null,
    countMsgs: null,
    userId: null,
    avatar: null,
  },
  getters,
  mutations,
};
