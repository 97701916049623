import { registerModules } from "@/store/modules/registerModules";
import { speaker } from "@/store/modules/store.namespaces";
import actions from "./actions";
import mutations from "./mutations";

const modules = registerModules(true, speaker);
export default {
  state: {
    rooms: [],
    burgerMenuOpened: false,
  },
  actions,
  mutations,
  modules,
};
