/**
 * Константы используемые для аунтификации
 * @namespace AuthConsts
 */
export const LAST_QUERY_PARAMS = "queryParams";
export const GUEST_TOKEN_KEY = "guestToken";
export const USER_TOKEN_KEY = "_ws_user_token";
export const FROM_GUEST = "FROM_GUEST";
/**
 * Типы форм авторизации зависит от настройки лендинга
 * @memberof AuthConsts
 * @const
 * @type {Object}
 * @default
 */
export const LOGIN_FORM_TYPE = {
  EMAIL: "email",
  STANDARD: "standard",
  SURNAME: "surname",
  ANONYMOUS: "anonymous",
  OAUTH2: "oauth2",
};