<template>
  <div :class="$style.header">
    <div :class="$style.logo" />
  </div>
</template>

<script>
/**
 * Хедер для страниц аутентификации и корпоративного лендинга
 */
export default {
  name: "AuthHeader",
};
</script>

<style lang="scss" module>
.header {
  box-sizing: border-box;
  height: 100%;
  max-height: 300px;
  padding: 20px;
  background-color: var(--login-header-bg-color);
  background-image: var(--login-header-image);
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  height: 100%;
  max-height: 110px;
  background-image: var(--login-header-logo-image);
  background-repeat: no-repeat;
  background-position-x: var(--login-header-logo-position);
  background-size: contain;
}
</style>
