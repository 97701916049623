import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import ControlHttpService from "@/services/http/ControlHttpService";
import {
  GET_POLLS,
  GET_SPEAKER_TEST,
  SHOW_QUIZ_RESULT,
  START_SPEAKER_TEST,
  TOGGLE_QUIZ,
  START_ABOUT_WS_QUIZ,
  STOP_ABOUT_WS_QUIZ,
  GET_ABOUT_WS_QUIZ,
  RESET_INTERACTIVE,
} from "@/store/modules/speaker/modules/polls/action-types";
import AuthService from "@/services/AuthService";
import {
  SET_ABOUT_WS_ID,
  SET_POLLS,
  SET_TESTS,
} from "@/store/modules/speaker/modules/polls/mutation-types";
import { SET_START_PRESENTATION } from "@/store/modules/presentation/mutation-types";
import { polls, presentation, room, socket } from "@/store/modules/store.namespaces";
import {
  SET_ROOMINFO_QUIZ,
  SET_ROOMINFO_SHOW_RESULT,
  SET_ROOMINFO_TEST_ID,
} from "@/store/modules/common/mutation-types";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import {
  ACTIVE_POLL_ID,
  ACTIVE_RESULTS_ID,
  ACTIVE_TEST_ID,
  AVAILABLE_ROOM_MODULES,
} from "@/store/modules/common/getter-types";
import config from "@/settings/config";
import { SET_POLLCHAIN_SCORE } from "@/store/modules/polls/mutation-types";
import { test } from "@/constants/polls/polls-module-types";
import axios from "axios";

export default {
  /**
   * Мето запуска/остановки теста.
   *
   * TODO: Объединить метод с START_SPEAKER_TEST, они практически идентичны.
   *
   * @param {*} param0
   * @param {*} data
   */
  [TOGGLE_QUIZ]({ commit }, data) {
    data.room &&
      data.voting &&
      axios
        .get(`${config.serverUrl}/questionnaire/api/publish-status-test`, {
          headers: {
            "x-token": AuthService.getToken(),
          },
          params: {
            room_number: data.room,
            test_id: data.voting,
          },
        })
        .then(res => {
          // Закончен ли опрос
          const { isStarted } = JSON.parse(res?.data);
          commit(`${presentation}/${SET_START_PRESENTATION}`, isStarted ? 1 : 0, { root: true });
          // todo from global
          commit(`${room}/${SET_ROOMINFO_QUIZ}`, isStarted ? data.voting : null, { root: true });
        })
        .catch(res => console.warn(res));
  },

  [SHOW_QUIZ_RESULT]({ commit }, data) {
    commit(`${room}/${SET_ROOMINFO_SHOW_RESULT}`, JSON.stringify(data), {
      root: true,
    });
  },
  /**
   * Запрашиваем список опросов и подготавливаем данные для отображения
   *
   * @param {*} context
   * @param {*} context.commit
   * @param {*} context.rootGetters
   * @param {object} presentation
   * @returns {Promise<void>}
   */
  async [GET_POLLS]({ commit, rootGetters }, presentation) {
    try {
      // const { data } = await AdminWebHttpService.get(
      //   `getPresentationQuizes/${presentation.pres}/${AuthService.getToken()}`,
      // );

      const presentation_id = presentation?.pres;

      const { data } = await axios.get(`${config.serverUrl}/questionnaire/api/get-surveys`, {
        headers: {
          "x-token": AuthService.getToken(),
        },
        params: {
          presentation_id,
        },
      });

      const { pdfReportModule } = rootGetters[`${room}/${AVAILABLE_ROOM_MODULES}`];
      const polls = data.map(poll => ({
        ...poll,
        id: +poll.id,
        availableDownloadReport:
          pdfReportModule && poll.is_availavle_pdf_report && poll.count_answers > 0,
        pdfLink: `${config.serverUrl}/api/v2/reports/pdf/${poll.id}`,
      }));
      commit(SET_POLLS, polls);
      return polls;
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  /**
   * Метод получения тестов презентации.
   *
   * @param {*} param0
   * @param {number} presentation_id - id презентации
   */
  [GET_SPEAKER_TEST]({ commit }, presentation_id) {
    axios
      .get(`${config.serverUrl}/questionnaire/api/get-tests`, {
        headers: {
          "x-token": AuthService.getToken(),
        },
        params: {
          presentation_id,
        },
      })
      .then(res => commit(SET_TESTS, res.data))
      .catch(res => console.warn(res));
  },

  /**
   * Метод запуска теста у спикера.
   * Event: Спикер нажимает на кнопку Запустить тест/Остановить тест.
   * Вызывается в методе toggleTestHandler (src/views/speaker/ViewTestsAndPolls.vue)
   *
   * @param {*} param0
   * @param {*} idTest
   * @returns {object} Возвращаем показатель конца теста/опроса (закончен ли он)
   */
  async [START_SPEAKER_TEST]({ rootState, commit }, idTest = 0) {
    if (!idTest) {
      return {};
    }

    const response = await axios
      .get(`${config.serverUrl}/questionnaire/api/publish-status-test`, {
        headers: {
          "x-token": AuthService.getToken(),
        },
        params: {
          room_number: rootState.common.roomNumber,
          test_id: idTest,
        },
      })
      .then(res => {
        const { isStarted } = JSON.parse(res?.data);
        // Установка активного теста у спикера
        commit(`${room}/${SET_ROOMINFO_TEST_ID}`, isStarted ? idTest : 0, { root: true });
        return { isStarted };
      })
      .catch(res => {
        console.warn(res);
        return {};
      });

    return response;
  },

  async [START_ABOUT_WS_QUIZ]({ rootState, commit, dispatch }) {
    try {
      const { roomNumber } = rootState.common;
      const test = await dispatch(GET_ABOUT_WS_QUIZ);
      const tick = {
        type: "runQuiz",
        isShowCounter: false,
        obj: test,
      };
      commit(`${socket}/${SET_SOCKET_OBJ}`, tick, { root: true });
      dispatch(TOGGLE_QUIZ, { room: roomNumber, voting: test.test_id });
    } catch (e) {
      throw new Error(e);
    }
  },
  async [STOP_ABOUT_WS_QUIZ]({ rootState, commit, dispatch }) {
    try {
      const { roomNumber } = rootState.common;
      const { data: test } = await ControlHttpService.get(`stopPolling/${roomNumber}`);
      const tick = {
        type: "closeQuiz",
        obj: { id: 0 },
        id: 0,
      };
      commit(`${socket}/${SET_SOCKET_OBJ}`, tick, { root: true });
      dispatch(TOGGLE_QUIZ, { room: roomNumber, voting: test.test_id });
    } catch (e) {
      throw new Error(e);
    }
  },
  async [GET_ABOUT_WS_QUIZ]({ rootState, commit }) {
    const { roomNumber } = rootState.common;
    const { data: test } = await AdminWebHttpService.get(`getAboutWSQuiz/${roomNumber}`);
    commit(SET_ABOUT_WS_ID, test.test_id);
    return test;
  },
  /**
   * Отключается запущенный опрос, результаты или тест, так же сбрасываются результаты для раздела опросы
   *
   * @param {*} context
   * @param {object} context.rootGetters
   * @param {Function} context.dispatch
   * @param {Function} context.commit
   * @param {object} context.rootState
   * @returns {Promise<void>}
   */
  async [RESET_INTERACTIVE]({ rootGetters, dispatch, commit, rootState }) {
    if (rootGetters[`${room}/${ACTIVE_POLL_ID}`]) {
      commit(
        `${socket}/${SET_SOCKET_OBJ}`,
        {
          type: "closeQuiz",
          obj: { id: 0 },
          id: 0,
        },
        { root: true },
      );
      await dispatch(TOGGLE_QUIZ, {
        room: rootState[room].roomNumber,
        voting: rootGetters[`${room}/${ACTIVE_POLL_ID}`],
      });
    }
    if (rootGetters[`${room}/${ACTIVE_TEST_ID}`]) {
      commit(
        `${socket}/${SET_SOCKET_OBJ}`,
        {
          type: "currentTest",
          obj: { currentTest: 0 },
        },
        { root: true },
      );
      // TODO: Поправить тут 0 посылаться больше не может
      await dispatch(START_SPEAKER_TEST, 0);
    }
    if (rootGetters[`${room}/${ACTIVE_RESULTS_ID}`]) {
      const obj = {
        type: "showQuizResult",
        obj: { event: "hide" },
      };
      commit(`${socket}/${SET_SOCKET_OBJ}`, obj, { root: true });
      await dispatch(SHOW_QUIZ_RESULT, obj);
    }
    commit(`${polls}/${SET_POLLCHAIN_SCORE}`, { type: test, payload: {} }, { root: true });
  },
};
