<template>
  <label :class="$style.label">
    <input
      :class="[
        $style.input,
        { [$style.input_error]: error || !!errorMessage },
      ]"
      v-bind="$attrs"
      v-on="$listeners"
      @input="$emit('model-input', $event.target.value)"
    />
    <span v-show="errorMessage" :class="$style.errorMessage">
      {{ errorMessage }}
    </span>
  </label>
</template>

<script>
/**
 * Хранит в себе общую стилистику инпутов для аунтификации
 *
 * @vue-prop {boolean} error - Есть ли ошибка
 * @vue-prop {string} errorMessage - Причина ошибки если она есть
 * @vue-event {boolean} "model-input" - Введеный текст в инпут, нужно для v-model
 */
export default {
  name: "AuthInput",
  inheritAttrs: false,
  model: {
    event: "model-input",
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" module>
.label {
  position: relative;
}

.input {
  width: 100%;
  padding: 10px 2px;
  font-size: 16px;
  line-height: normal;
  color: var(--login-input-color);
  background: none;
  border: none;
  border-bottom: 1px solid $black-light;
  border-radius: 0;
  outline: none;
  transition: background-color 5000s ease-in-out 0s;

  &_error {
    border-bottom: 1px solid $danger;
  }

  /* Скрывает стрелочки на инпут с типом число */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.errorMessage {
  position: absolute;
  bottom: -12px;
  left: 2px;
  font-size: 10px;
  color: red;
}
</style>