import { LISTENER_WITH_PROFILE } from "@/store/modules/listeners/getter-types";
import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    listeners: [],
    profiles: {},
    error: {
      code: 0,
      message: "",
    },
  },
  getters: {
    [LISTENER_WITH_PROFILE](state, _getters, rootState) {
      const id = rootState.route.params.listenerId;
      const listener = state.listeners.find(listener => listener.id === +id);
      const profile = state.profiles[rootState.route.params.listenerId];
      return profile ? Object.assign({ ...listener }, profile) : {};
    },
  },
  actions,
  mutations,
};
