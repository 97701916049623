import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import AuthService from "@/services/AuthService";

export default {
  getQuizPolls(data) {
    return new Promise(resolve => {
      AdminWebHttpService
        .get(
          `getQuizPolls/${data.obj.id ||
            data.obj.test_id}/${AuthService.getToken()}`,
        )
        .then(response => {
          let pollchain = {
            id: data.obj.id,
            title: data.obj.title,
            type: "quiz",
            timer: data.obj.timer,
            polls: response.data,
            step: 0,
          };
          if (response.data.length > 0) {
            resolve(pollchain);
          } else {
            resolve(false);
          }
        });
    });
  },
  getWsQuizPolls({ common }) {
    return new Promise(resolve => {
      AdminWebHttpService
        .get("getAboutWSQuiz/" + common.roomNumber)
        .then(resp => {
          let response = resp.data;
          let pollchain = {
            id: response.test_id,
            title: "",
            type: "quiz",
            timer: 0,
            polls: response.questions,
            step: 0,
          };
          if (response.questions) {
            resolve(pollchain);
          } else {
            resolve(false);
          }
        })
        .catch(err => {});
    });
  },
  postQuizAnswer(data, { poll, pollChain }) {
    const pollIds = {
      poll_id: poll && poll.id,
      test_id: pollChain && pollChain.id,
    };
    switch (poll.type) {
      case "open":
        return {
          answer: [data],
          type: "open",
          variants: [],
          ...pollIds,
        };
      case "word-cloud":
        return {
          answer: data,
          type: "word-cloud",
          variants: [],
          ...pollIds,
        };
      case "relation":
        return {
          questionId: poll.id,
          type: "relation",
          variants: data,
          ...pollIds,
        };
      case "relation-images":
        return {
          questionId: poll.id,
          type: "relation-images",
          variants: data,
          ...pollIds,
        };
      default:
        return {
          answer: "",
          type: "closed",
          variants: data,
          ...pollIds,
        };
    }
  },
  postTestAnswer(data, { poll, pollChain }) {
    switch (poll.type) {
      case "open":
        return {
          answer: [data],
          poll_id: poll.id,
          test_id: pollChain.id,
          type: "open",
          variants: [],
        };
      case "word-cloud":
        return {
          answer: data,
          poll_id: poll.id,
          test_id: pollChain.id,
          type: "word-cloud",
          variants: [],
        };
      case "relation-images":
        return {
          questionId: poll.id,
          poll_id: poll.id,
          test_id: pollChain.id,
          type: "relation-images",
          variants: data,
        };
      case "relation":
        return {
          questionId: poll.id,
          poll_id: poll.id,
          test_id: pollChain.id,
          type: "relation",
          variants: data,
        };
      default:
        return {
          answer: "",
          poll_id: poll.id,
          test_id: pollChain.id,
          type: "closed",
          variants: data,
        };
    }
  },
};
