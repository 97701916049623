import { SpeakerRouteName } from "@/constants/router/router-const";

const Speaker = () => import(/* webpackChunkName="ViewSpeaker" */ "@/views/speaker/ViewSpeaker");
const SpeakerPresentation = () =>
  import(/* webpackChunkName="SpeakerPresentation" */ "@/components/speaker/SpeakerPresentation");

const PresentationControl = () =>
  import(
    /* webpackChunkName="SpeakerPresentationControl" */ "@/components/speaker/PresentationControl/PresentationControl"
  );
const Questions = () =>
  import(/* webpackChunkName="SpeakerQuestions" */ "@/views/speaker/ViewQuestions");
const Agenda = () =>
  import(/* webpackChunkName="SpeakerAgenda" */ "@/components/speaker/Agenda/Agenda");
const BrainstormControl = () =>
  import(
    /* webpackChunkName="SpeakerBrainstormControl" */ "@/components/speaker/Brainstorm/BrainstormControl"
  );
const Miro = () =>
  import(/* webpackChunkName="Miro" */ "@/components/event/room/Presentation/components/miro/Miro");

export default [
  {
    path: "/speaker",
    name: "speaker",
    component: Speaker,
    children: [
      {
        path: "rooms",
        name: SpeakerRouteName.Rooms,
        meta: {
          isSpeaker: true,
          requiresAuth: true,
        },
        props: route => ({
          status: route.query?.status !== undefined ? +route.query.status : -1,
        }),
        component: () =>
          import(/* webpackChunkName="ViewSpeakerRooms" */ "@/views/speaker/ViewRooms"),
      },
      {
        path: ":id",
        name: SpeakerRouteName.Room,
        props: true,
        meta: {
          isSpeaker: true,
          requiresAuth: true,
        },
        component: SpeakerPresentation,
        redirect: {
          name: SpeakerRouteName.Presentations,
        },
        children: [
          {
            path: "presentations-list",
            name: SpeakerRouteName.Presentations,
            component: () =>
              import(
                /* webpackChunkName="ViewSpeakerPresentations" */ "@/views/speaker/ViewPresentations"
              ),
          },
          {
            path: "questions-in-room",
            name: "questions-in-room",
            component: Questions,
          },
          {
            path: "order-paper",
            name: "order-paper",
            component: Agenda,
          },
          {
            path: "brainwave",
            name: "sp-brainwave",
            component: BrainstormControl,
          },
          {
            path: "presentation/:presId",
            name: SpeakerRouteName.Presentation,
            component: SpeakerPresentation,
            redirect: { name: "presentation-control" },
            props: true,
            children: [
              {
                path: "presentation-control",
                name: "presentation-control",
                component: PresentationControl,
              },
              {
                path: "question",
                name: "question",
                component: Questions,
              },
              {
                path: "brainwave",
                name: "brainwave",
                component: BrainstormControl,
              },
              {
                path: "agendas",
                name: "agendas",
                component: Agenda,
              },
              {
                path: "polls",
                name: "polls",
                component: () =>
                  import(
                    /* webpackChunkName="ViewTestAndPolls" */ "@/views/speaker/ViewTestsAndPolls"
                  ),
              },
              {
                path: "miro",
                name: "miro",
                component: Miro,
              },
            ],
          },
        ],
      },
    ],
  },
];
