import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { auth, presentation } from "@/store/modules/store.namespaces";
import {
  SET_USER_PHOTO,
  SET_USER_PRESENTATION_LIKE,
  SET_USER_PRESENTATION_LIKES,
  SET_USER_SLIDE_LIKE,
  SET_USER_SLIDE_LIKES,
} from "@/store/modules/auth/mutation-types";
import * as type from "@/store/mutations/mutation-types";
import { CURRENT_PRESENTATION_SLIDE_ID } from "@/store/modules/presentation/getter-types";
import {
  GET_PRESENTATION_LIKES,
  GET_SLIDE_LIKES,
  LIKE_PRESENTATION,
  LIKE_SLIDE,
  UPLOAD_AVATAR,
} from "@/store/modules/user/action-types";
import { ErrorWS, wsErrorType } from "@/utils/errors";
import AdminApiHttpService from "@/services/http/AdminApiHttpService";
import { makeUrl } from "@/utils";

export default {
  async [GET_SLIDE_LIKES]({ commit }) {
    try {
      const { data } = await AdminWebHttpService.get(`getslidelikes/${AdminWebHttpService.token}`);
      if (!data) {
        commit(`${auth}/${SET_USER_SLIDE_LIKES}`, {}, { root: true });
        return;
      }
      commit(`${auth}/${SET_USER_SLIDE_LIKES}`, data, { root: true });
    } catch (e) {
      throw new ErrorWS(wsErrorType.UNKNOWN_ERROR, e).getCustomErrorMessage(GET_SLIDE_LIKES);
    }
  },
  async [GET_PRESENTATION_LIKES]({ commit }) {
    try {
      const { data } = await AdminWebHttpService.get(
        `getpresentationslikes/${AdminWebHttpService.token}`,
      );

      if (!data) {
        commit(`${auth}/${SET_USER_PRESENTATION_LIKES}`, {}, { root: true });
        return;
      }
      commit(`${auth}/${SET_USER_PRESENTATION_LIKES}`, data, { root: true });
    } catch (e) {
      throw new ErrorWS(wsErrorType.UNKNOWN_ERROR, e).getCustomErrorMessage(GET_PRESENTATION_LIKES);
    }
  },
  [LIKE_PRESENTATION]({ commit, rootState }, likeType) {
    const { id } = rootState.presentation.currentPresentationInfo;
    commit(
      `${auth}/${SET_USER_PRESENTATION_LIKE}`,
      {
        type: likeType,
        id,
      },
      { root: true },
    );

    AdminWebHttpService.post(
      `likepresentations/${rootState.presentation.currentPresentationInfo.id}/${AdminWebHttpService.token}`,
      {
        type: likeType,
      },
    ).catch(err => {
      console.error(err);
      commit(
        `${auth}/${SET_USER_PRESENTATION_LIKE}`,
        {
          type,
          id,
        },
        { root: true },
      );
    });
  },
  [LIKE_SLIDE]({ commit, rootGetters }, likeType) {
    const id = rootGetters[`${presentation}/${CURRENT_PRESENTATION_SLIDE_ID}`];

    commit(
      `${auth}/${SET_USER_SLIDE_LIKE}`,
      {
        type: likeType,
        id,
      },
      { root: true },
    );

    AdminWebHttpService.post(`likeslide/${id}/${AdminWebHttpService.token}`, {
      type: likeType,
    }).catch(err => {
      console.error(err);
      commit(
        `${auth}/${SET_USER_SLIDE_LIKE}`,
        {
          type,
          id,
        },
        { root: true },
      );
    });
  },
  /**
   * Загрузка аватара или его обновление
   *
   * @param context
   * @param context.commit
   * @param {Blob} avatar - загружаемый аватар
   */
  async [UPLOAD_AVATAR]({ commit }, avatar) {
    if (!avatar) return;
    const formData = new FormData();
    formData.append("User[photo]", avatar, avatar.name);
    const { data } = await AdminApiHttpService.post(
      `saveUserPhoto/${AdminApiHttpService.token}`,
      formData,
    );
    commit(`${auth}/${SET_USER_PHOTO}`, makeUrl(data.photoUrl), { root: true });
  },
};
