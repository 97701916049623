import Vue from "vue";
import {
  RESET_USER,
  SET_LOGGED_IN,
  SET_USER,
  SET_USER_DESCRIPTION,
  SET_USER_FIO,
  SET_USER_IS_MAIN,
  SET_USER_PHOTO,
  SET_USER_PRESENTATION_LIKE,
  SET_USER_PRESENTATION_LIKES,
  SET_USER_SLIDE_LIKE,
  SET_USER_SLIDE_LIKES,
} from "@/store/modules/auth/mutation-types";
import AuthService from "@/services/AuthService";

const token = AuthService.getToken();

export default {
  [SET_USER](state, data) {
    const user = {
      id: data.user_id,
      is_guest: data.is_guest,
      is_allow_event_record: data.is_screen_recording,
      login: data.user_login,
      role: data.user_role,
      photoWithUrl: data.user_photo,
      fio: data.user_fio.trim(),
      city: data.user_city,
      description: "",
      fields: data.user_fields,
      customRole: data.user_custom_role,
      room: data.user_rooms,
      token,
      group: data.user_group,
      email: data.user_email,
      registered: !data.is_guest,
      groupChatId: data.user_group_external_chat_id,
      buisnessUnits: data.user_business_unit
        ? data.user_business_unit.toLowerCase().split(",")
        : [],
      scheduleMainFilter: data.schedule_main_filter,
      lastRoom: data.room_number,
      defaultRoom: data.default_room,
    };
    state.user = {
      ...state.user,
      ...user,
    };
  },
  [SET_LOGGED_IN](state) {
    state.loggedIn = true;
  },
  [RESET_USER](state) {
    state.user = {
      id: "",
      login: "",
      email: "",
      role: "",
      photoWithUrl: "",
      fio: "",
      fields: {},
      customRole: "",
      token: "",
      likedSlides: {},
      likedPresentations: {},
      firstTimeUpload: null,
    };
  },
  [SET_USER_SLIDE_LIKES](state, data) {
    state.user.likedSlides = data;
  },
  [SET_USER_PRESENTATION_LIKES](state, data) {
    state.user.likedPresentations = data;
  },
  [SET_USER_PRESENTATION_LIKE]({ user }, { type, id }) {
    if (!user.likedPresentations[id]) {
      Vue.set(user.likedPresentations, id, [type]);
      return;
    }
    const index = user.likedPresentations[id].indexOf(type);
    if (index !== -1) {
      Vue.set(user.likedPresentations, id, []);
      return;
    }
    Vue.set(user.likedPresentations, id, [type]);
  },
  [SET_USER_SLIDE_LIKE]({ user }, { type, id }) {
    if (!user.likedSlides[id]) {
      Vue.set(user.likedSlides, id, [type]);
      return;
    }
    const index = user.likedSlides[id].indexOf(type);
    if (index !== -1) {
      Vue.set(user.likedSlides, id, []);
      return;
    }
    Vue.set(user.likedSlides, id, [type]);
  },
  [SET_USER_IS_MAIN](state, data) {
    state.user = {
      ...state.user,
      isMain: data.isMain,
    };
  },
  [SET_USER_FIO](state, name) {
    state.user.fio = name;
  },
  [SET_USER_PHOTO](state, avatar) {
    state.user.photoWithUrl = avatar;
  },
  [SET_USER_DESCRIPTION](state, description) {
    state.user.description = description;
  },
};
