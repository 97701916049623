<template>
  <div v-if="$slots.default" :class="className">
    <div class="page-message__wrapper">
      <slot name="default">{{ $t("messages.common.please_wait") }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageMessage",
  props: {
    info: {
      type: Boolean,
      default: () => false,
    },
    error: {
      type: Boolean,
      default: () => false,
    },
    warning: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    className() {
      return {
        "page-message": true,
        "page-message--info": this.info,
        "page-message--error": this.error,
        "page-message--warning": this.warning,
      };
    },
  },
};
</script>
<style lang="less" scoped>
.page-message {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  overflow: hidden;
  text-overflow: ellipsis;

  &__wrapper {
    padding: 10px;
    font-size: 24px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      font-size: 18px;
    }
  }

  &--info,
  &--error,
  &--warning {
    .page-message__wrapper {
      border-radius: 5px;
      font-size: 16px;
    }
  }
  &--error .page-message__wrapper {
    color: var(--base-button-active-color);
    background-color: var(--negative-color);
  }
}
</style>
