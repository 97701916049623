import Vue from "vue";

const detectTouchPlugin = {
  install(Vue) {
    Vue.mixin({
      computed: {
        $isTouch() {
          return window.matchMedia("(pointer: coarse)").matches;
        },
      },
    });
  },
};

Vue.use(detectTouchPlugin);
