export const SHOW_SUPPORT_MODAL = "SHOW_SUPPORT_MODAL";
export const CLOSE_SUPPORT_MODAL = "CLOSE_SUPPORT_MODAL";
export const SET_BASE_ROUTE = "SET_BASE_ROUTE";
export const RESET_STATE = "RESET_STATE";
export const ROLE_SET_MODE = "ROLE_SET_MODE";
export const SET_PINBAR = "SET_PINBAR";
export const SET_LOGO_ROUTE = "SET_LOGO_ROUTE";
export const SET_TEST_SLIDE_ANSWERED = "SET_TEST_SLIDE_ANSWERED";
export const SET_CURRENT_MEDIA_QUERY_KEY = "SET_CURRENT_MEDIA_QUERY_KEY";
export const SET_PIN_STATUS = "SET_PIN_STATUS";
