export const NAVIGATION_ELEMENTS = "NAVIGATION_ELEMENTS";
export const AVAILABLE_ROOM_MODULES = "AVAILABLE_ROOM_MODULES";
export const MOSCOW_TIME = "MOSCOW_TIME";
export const ROOM_TOP_LOGO_SRC = "ROOM_TOP_LOGO_SRC";
export const ROOM_LOGO = "ROOM_LOGO";
export const LAST_ROOM_NUMBER = "LAST_ROOM_NUMBER";
export const ROOM_NUMBER = "ROOM_NUMBER";
export const ACTIVE_POLL_ID = "ACTIVE_POLL_ID";
export const ACTIVE_RESULTS_ID = "ACTIVE_RESULTS_ID";
export const ACTIVE_TEST_ID = "ACTIVE_TEST_ID";
