import Vue from "vue";
import Router from "vue-router";
import config from "@/settings/config";
import eventRoutes from "./event.routes";
import rootRoutes from "./root.routes";
import screenRoutes from "./screen.routes";
import speakerRoutes from "./speaker.routes";

import store from "../store/store";
import middlewaresPipeline from "../middlewares/pipeline";
import routerMiddlewares from "../middlewares/router/index";

const ViewNotFound = () => import("@/views/ViewNotFound");

Vue.use(Router);
const router = new Router({
  mode: config.routerMode || "history",
  routes: [
    ...rootRoutes,
    ...screenRoutes,
    ...speakerRoutes,
    ...eventRoutes,
    {
      name: "reset",
      path: "/reset/",
    },
    {
      path: "*",
      name: "404",
      component: ViewNotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const context = {
    to,
    from,
    next,
    store,
  };

  return routerMiddlewares[0]({
    ...context,
    nextMiddleware: middlewaresPipeline(context, routerMiddlewares, 1),
  });
});

export default router;
