import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    user: {
      id: "",
      login: "",
      email: "",
      role: "",
      photoWithUrl: "",
      fio: "",
      fields: {},
      customRole: "",
      token: "",
      likedSlides: {},
      likedPresentations: {},
    },
    loggedIn: false,
  },
  actions,
  mutations,
  getters,
};
