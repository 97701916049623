export const DARK_THEME = "dark";
export const CUSTOM_THEME = "custom";

export const ICON_SIZES = ["xs", "sm", "md", "lg", "xl"];
export const BUTTON_SIZES = ["sm", "md", "lg", "xl"];
export const ICON_COLORS = ["default", "primary", "accent", "green", "white", "black", "danger"];
export const BUTTON_COLORS = ["default", "primary", "primary-light", "accent", "danger"];

/**
 * Размеры радио кнопок
 *
 * @constant
 * @type {object}
 * @default
 */
export const RADIO_SIZES = {
  sm: 12,
  md: 20,
};