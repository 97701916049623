import * as type from "./mutation-types";

const pinStatus = localStorage.getItem("currentPinStatus") === "true";

const defaultState = () => ({
  appMode: "",
  pinbar: {
    visible: false,
  },
  pinRequest: {
    visible: false,
    roomNumber: null,
  },
  neuroStatus: false,
  baseRoute: false,
  speakerIsActive: null,
  showSupportModal: false,
  modalView: false,
  wsPollModalOpen: false,
  logoRoute: "presentation",
  fromGuestRoute: false,
  pinStatus,
});

export default {
  [type.SHOW_SUPPORT_MODAL](state) {
    state.showSupportModal = true;
  },
  [type.CLOSE_SUPPORT_MODAL](state) {
    state.showSupportModal = false;
  },
  [type.SET_BASE_ROUTE](state, route) {
    state.baseRoute = route;
  },
  [type.RESET_STATE](state) {
    const newState = defaultState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  [type.ROLE_SET_MODE](state, mode) {
    state.appMode = mode;
  },
  [type.SET_PINBAR](state, data) {
    state.pinbar.visible = data && data.state;
  },
  [type.SET_LOGO_ROUTE](state, route) {
    state.logoRoute = route;
  },
  [type.SET_CURRENT_MEDIA_QUERY_KEY](state, mq) {
    state.currentMediaQuery = mq;
  },
  [type.SET_PIN_STATUS](state, data = !state.pinStatus) {
    state.pinStatus = data;
    localStorage.setItem("currentPinStatus", JSON.stringify(data));
  },
};
