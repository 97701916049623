import Color from "color";
import {
  BASE_FADE_COLOR,
  BASE_SHADOW_COLOR,
  CORP_LANDING_PARAMS,
  HAS_CORP_LANDING,
  LANDING_LOGO,
  LIGHTER_BASE_COLOR,
  LOGIN_FORM_COLOR,
  MANAGER_LOGIN_AVAILABLE,
  SYSTEM_FADE_COLOR,
  TEMPLATE,
  DEFAULT_ROOM,
} from "@/store/modules/landing/getter-types";
import actions from "./actions";
import mutations from "./mutations";

const DEFAULT_LOGO = "/images/logo.png";

function isDefaultLogo(logo) {
  return logo === DEFAULT_LOGO;
}

function getLogo(logoMain, logoBackup) {
  if (isDefaultLogo(logoMain) && !isDefaultLogo(logoBackup)) {
    return logoBackup || logoMain;
  }
  return logoMain || logoBackup;
}

export default {
  state: {
    template: null,
  },
  getters: {
    [TEMPLATE](state) {
      return state.template || {};
    },
    [DEFAULT_ROOM](state) {
      return state.template?.room_number_default || "";
    },
    [LANDING_LOGO](state, getters, rootState) {
      if (!state.template) return "";
      const { logo, logo_en } = state.template;
      if (rootState.common.interfaceLanguage === "rus") {
        return getLogo(logo, logo_en);
      }
      return getLogo(logo_en, logo);
    },
    [LIGHTER_BASE_COLOR](state, getters, rootState) {
      const baseColor = Color(rootState.common.styleColors.baseColor);
      return baseColor.lighten(0.1).string();
    },
    [BASE_SHADOW_COLOR](state, getters, rootState) {
      const baseColor = Color(rootState.common.styleColors.baseColor);
      return baseColor.alpha(0.2).string();
    },
    [BASE_FADE_COLOR](state, getters, rootState) {
      const baseColor = Color(rootState.common.styleColors.baseColor);
      return baseColor.alpha(0.8).string();
    },
    [SYSTEM_FADE_COLOR](state, getters, rootState) {
      const baseColor = Color(rootState.common.styleColors.systemBgNotify);
      return baseColor.alpha(0.8).string();
    },
    [LOGIN_FORM_COLOR](state) {
      return state.template?.form_background_color ?? "";
    },
    [CORP_LANDING_PARAMS](state) {
      return state.template?.corp_params ?? {};
    },
    [HAS_CORP_LANDING](state) {
      return state.template?.corp_landing;
    },
    [MANAGER_LOGIN_AVAILABLE](state) {
      return state.template?.corp_params?.login_manager;
    },
  },
  actions,
  mutations,
};
