import Vue from "vue";
import { MqName } from "@/constants/media-query/media-query";

/**
 * Плагин который инкапсулирует хелперы для работы с vue-mq что бы по коду не размывалось использование сторонего<br>
 * плагина и проще было найти его зависимости.<br>
 * Определения:<br>
 * Для обычных проверок используем $mq<MqNamespace><br>
 * Для проверки до определенного значения используем to - $mqToLaptop будет включать все кроме laptop значения.<br>
 * Для проверки с определенного значения и выше используем from - $mqFromTablet будет включать все начиная с tablet<br>
 *
 * @mixin mediaQueryHelpers
 * @vue-computed {Array<string>} $mqMobileKeys - Ключи которые включают в себя все мобильные брейкпоинты
 * @vue-computed {boolean} $mqMobile - Текущее значение $mq является мобильным
 * @vue-computed {boolean} $mqMobileS - Текущее значение $mq не более 375px
 * @vue-computed {boolean} $mqToMobileL - Текущее значение $mq является от mobile-s до mobile-m+
 * @vue-computed {boolean} $mqToMobileM - Текущее значение $mq является от mobile-s до mobile-m
 * @vue-computed {boolean} $mqTablet - Текущее значение $mq является планшетом
 * @vue-computed {boolean} $mqFromTablet - Текущее значение $mq является от планшета до Infinity
 * @vue-computed {boolean} $mqToLaptop - Текущее значение $mq является от мобильных до планшета
 * @vue-computed {boolean} $mqLaptop - Текущее значение $mq является лаптопом
 */
const mediaQueryHelpers = {
  install(instance) {
    instance.mixin({
      computed: {
        $mqMobileKeys() {
          return [MqName.MobileS, MqName.MobileM, MqName.MobileMPlus, MqName.MobileL];
        },
        $mqMobile() {
          return this.$mqMobileKeys.includes(this.$mq);
        },
        $mqMobileS() {
          return this.$mq === MqName.MobileS;
        },
        $mqToMobileL() {
          return [MqName.MobileS, MqName.MobileM, MqName.MobileMPlus].includes(this.$mq);
        },
        $mqToMobileM() {
          return [MqName.MobileS, MqName.MobileM].includes(this.$mq);
        },
        $mqTablet() {
          return this.$mq === MqName.Tablet;
        },
        $mqFromTablet() {
          return !this.$mqMobile;
        },
        $mqToLaptop() {
          return this.$mqMobile || this.$mqTablet;
        },
        $mqLaptop() {
          return this.$mq === MqName.Laptop;
        },
      },
    });
  },
};

Vue.use(mediaQueryHelpers);

export default mediaQueryHelpers;
