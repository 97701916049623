export const SOCKET_CONNECT = "SOCKET_CONNECT";
export const SLIDE = "SLIDE";
export const PRESENTATION = "PRESENTATION";
export const RUN_QUIZ = "RUN_QUIZ";
export const CLOSE_QUIZ = "CLOSE_QUIZ";
export const CHANGE_SPEAKER_IS_MAIN = "CHANGE_SPEAKER_IS_MAIN";
export const TIMER = "TIMER";
export const QUESTION = "QUESTION";
export const NOTIFICATION = "NOTIFICATION";
export const SHOW_QUIZ_RESULT = "SHOW_QUIZ_RESULT";
export const BRAINSTORM_IDEA = "BRAINSTORM_IDEA";
export const BRAINSTORM_IDEA_LIKE = "BRAINSTORM_IDEA_LIKE";
export const BRAINSTORM_STATUS = "BRAINSTORM_STATUS";
export const CURRENT_TEST = "CURRENT_TEST";
export const SET_BRAINSTORM = "SET_BRAINSTORM";
export const CHANGE_BRAINSTORM_STATUS = "CHANGE_BRAINSTORM_STATUS";
export const YOUTUBE_SLIDE_START_STOP = "YOUTUBE_SLIDE_START_STOP";
export const ANSWER = "ANSWER";
export const PIN = "PIN";
export const QUESTION_MARK = "QUESTION_MARK";
export const QUESTION_DISLIKE = "QUESTION_DISLIKE";
export const STREAM = "STREAM";
export const TOGGLE_PREMODERATION = "TOGGLE_PREMODERATION";
export const ADD_LEADING = "ADD_LEADING";
export const UPDATE_LEADING = "UPDATE_LEADING";
export const USER_RAISE_HAND = "USER_RAISE_HAND";
export const DELETE_LEADING = "DELETE_LEADING";
export const RESET_STATE = "RESET_STATE";
export const REFRESH_QUESTIONS_LIKES = "REFRESH_QUESTIONS_LIKES";
export const REFRESH_QUESTIONS_DISLIKES = "REFRESH_QUESTIONS_DISLIKES";
export const CLEAR_LAST_NOTIFICATION = "CLEAR_LAST_NOTIFICATION";
