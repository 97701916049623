import config from "@/settings/config";
import { landing, room } from "@/store/modules/store.namespaces";
import { mapGetters, mapState } from "vuex";
import { CORP_LANDING_PARAMS, TEMPLATE } from "@/store/modules/landing/getter-types";
import { LOGIN_FORM_TYPE } from "@/constants/user/auth-const";

/* TODO: Создал миксин который предназначен что бы заменить LandingStylesProvider
 *  Будет удобен в будущем
 * */

const AlignmentToCss = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

export default {
  computed: {
    ...mapGetters(landing, {
      template: TEMPLATE,
      corpParams: CORP_LANDING_PARAMS,
    }),
    ...mapState(room, ["interfaceLanguage"]),
    corpContentAlignment() {
      return AlignmentToCss[this.corpParams.html_position];
    },
    landingLangSelector() {
      return this.template.show_language;
    },
    landingFaqUrl() {
      const { options } = this.template;
      return options?.faq && !!options.faq_url ? options.faq_url : "";
    },
    welcomeText() {
      if (["corpLogin", "Login"].includes(this.$route.name)) {
        return this.template[`content_text_${this.interfaceLanguage}`];
      }
      return "";
    },
    disableGuestLogin() {
      return this.corpParams.disable_login_guest;
    },
    supportButtonEnabled() {
      return this.corpParams.button_support;
    },
    supportPhone() {
      return this.corpParams.support_number;
    },
    corpContent() {
      return this.corpParams.html;
    },
    loginType() {
      if (this.owner) {
        return LOGIN_FORM_TYPE.STANDARD;
      }
      return this.template.login_form_type || LOGIN_FORM_TYPE.STANDARD;
    },
    isOAuth2Type() {
      return this.loginType === LOGIN_FORM_TYPE.OAUTH2;
    },
    isEmailType() {
      return this.loginType === LOGIN_FORM_TYPE.EMAIL;
    },
    isStandardType() {
      return this.loginType === LOGIN_FORM_TYPE.STANDARD;
    },
    isSurnameType() {
      return this.loginType === LOGIN_FORM_TYPE.SURNAME;
    },
    isAnonymousType() {
      return this.loginType === LOGIN_FORM_TYPE.ANONYMOUS;
    },
  },
  methods: {
    oauth2Auth() {
      const landingId = this.template.id;
      const redirectUri = window.location.origin;
      window.location.replace(
        `${config.serverUrl}/api/auth?landingId=${landingId}&redirectUri=${redirectUri}`,
      );
    },
    socialAuth(socialName) {
      window.location.replace(`${config.serverUrl}/api/${socialName}`);
    },
  },
};
