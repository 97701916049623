import {
  RESET_ROOM_STATE,
  SET_BRAINSTORM_STATUS,
  SET_CLOCK_TIMESTAMP,
  SET_FAQ_ANSWERS,
  SET_INTERFACE_LANGUAGE,
  SET_NEUROSTATUS,
  SET_PREMODERATION,
  SET_ROOM_ID,
  SET_ROOM_INFO,
  SET_ROOM_INFO_BRAINSTORM,
  SET_ROOM_NUMBER,
  SET_ROOM_THEME,
  SET_ROOM_TIMER,
  SET_ROOMINFO_QUIZ,
  SET_ROOMINFO_SHOW_RESULT,
  SET_ROOMINFO_TEST_ID,
  TOGGLE_SIDEBAR_FULLSCREEN,
  TOGGLE_SIDEBAR_EXPANDED,
  TOGGLE_USER_POPUP_VISIBLE,
  SET_ROOM_MODULES_STATE,
  SET_ROOM_PENDING,
  SET_SELECTED_STREAM_LANG,
} from "@/store/modules/common/mutation-types";
import { BROADCAST_TYPE } from "@/constants/broadcast/broadcast-const";
import { ROOM_NUMBER } from "@/constants/room/room-const";

const defaultState = () => ({
  // Параметр, содержащий основную информацию о комнате
  roomInfo: {
    miro: {
      boardId: "",
    },
  },
  roomNumber: "",
  roomId: "",
  userPopupVisible: false,
  hasTimer: "",
  streamLang: "RUS",
  styleColors: {
    defaultColor: "#f5f5f5",
    baseColor: "#f79843",
    extraColor: "#f9ac44",
    baseAccentColor: "#f05735",
    secondaryColor: "#B5E4F5",
    positiveColor: "#7CDE10",
    negativeColor: "#e90e42",
    baseTextColor: "#353535",
    muteTextColor: "#898989",
    baseBorderColor: "#D5D5D5",
    scrollBar: "#ebebeb",
    scrollThumb: "#a4a4a4",
    buttonTextColor: "#373737",
    buttonActiveTextColor: "#fff",
    buttonDefaultTextColor: "#acacac",
    baseBgColor: "#ffe4bf",
    systemBgNotify: "#0670b8",
  },
  modules: {
    pdfReportModule: false,
    downloadPhotoWallModule: false,
    sortPresentationModule: false,
    downloadMaterialsModule: false,
  },
});

export default {
  [SET_FAQ_ANSWERS](state, payload) {
    // todo for abbott later remove it
    state.faq = payload;
  },
  [TOGGLE_SIDEBAR_FULLSCREEN](state) {
    state.sidebar.fullscreen = !state.sidebar.fullscreen;
  },
  [TOGGLE_USER_POPUP_VISIBLE](state) {
    state.userPopupVisible = !state.userPopupVisible;
  },
  [TOGGLE_SIDEBAR_EXPANDED](state) {
    state.sidebar.expanded = !state.sidebar.expanded;
  },
  [SET_ROOM_TIMER](state, timer) {
    const { timer_seconds = false } = timer;
    state.timer.seconds = timer_seconds || 0;
  },
  [SET_CLOCK_TIMESTAMP](state, timestamp) {
    state.clock.timestamp = timestamp || Math.floor(+Date.now() / 1000);
  },
  [SET_ROOM_ID](state, roomId) {
    state.roomId = roomId;
  },
  [SET_ROOM_INFO](state, roomInfo) {
    state.roomInfo = {
      ...state.roomInfo,
      ...roomInfo,
    };
    const pollRes = JSON.parse(roomInfo.Show_result);
    state.activeResultId =
      roomInfo.Show_result && pollRes.type && pollRes.obj.id ? Number(pollRes.obj.id) : 0;
    state.loggers = {
      materialsLogger: !!roomInfo.is_file_material_log,
      streamLogger: !!roomInfo.is_stream_log,
      userPresenceLogger: !!roomInfo.is_user_activity_log,
      videoLogger: !!roomInfo.is_video_log,
    };
    if (state.roomInfo.type_broadcast_type === BROADCAST_TYPE.MEETING) {
      state.roomInfo.Room_nav.top_nav.elements.translation = {
        label: {
          en: "",
          ru: "",
        },
        order: 999,
        visibility: 1,
      };
    }
  },
  [SET_INTERFACE_LANGUAGE](state, lang) {
    state.interfaceLanguage = lang;
  },
  [SET_ROOM_NUMBER](state, number) {
    state.roomNumber = number ? String(number) : number;
    localStorage.setItem(ROOM_NUMBER, number ?? "");
  },
  [SET_ROOM_THEME](state, theme) {
    const colors = JSON.parse(theme.colors);
    state.styleColors = {
      ...state.styleColors,
      ...colors,
    };
    state.styleUrls.roomTopLogo = theme.logo;
    state.theme = theme;
  },
  [SET_ROOM_INFO_BRAINSTORM](state, payload) {
    state.roomInfo.Brainstorm = payload;
  },
  [SET_BRAINSTORM_STATUS](state, status) {
    if (state.roomInfo.Brainstorm) {
      state.roomInfo.Brainstorm.status = status;
    }
  },
  [SET_PREMODERATION](state, payload) {
    state.roomInfo.Question_premoderation = payload;
  },
  [SET_ROOMINFO_QUIZ](state, data) {
    state.roomInfo.Quiz = data;
  },
  [SET_ROOMINFO_SHOW_RESULT](state, data) {
    state.roomInfo.Show_result = data;
    const pollRes = JSON.parse(state.roomInfo.Show_result);
    if (data && pollRes.type) {
      state.activeResultId = pollRes.obj.id ? Number(pollRes.obj.id) : 0;
    }
  },
  [SET_ROOMINFO_TEST_ID](state, id) {
    state.roomInfo.Test = id;
  },
  [SET_NEUROSTATUS](state) {
    state.neuroStatus = true;
  },
  [RESET_ROOM_STATE](state) {
    const newState = defaultState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  [SET_ROOM_MODULES_STATE](state, data) {
    Object.keys(state.modules).forEach(key => {
      state.modules[key] = data[key] || false;
    });
  },
  [SET_ROOM_PENDING](state, data) {
    state.roomPending = data;
  },
  [SET_SELECTED_STREAM_LANG](state, lang) {
    state.streamLang = lang;
  },
  /**
   * Метод установки id доски.
   *
   * @param {*} state
   * @param {string} boardId - id доски
   */
  setBoardId(state, boardId) {
    state.roomInfo.miro.boardId = boardId;
  },
};
