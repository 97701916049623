import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions/actions";
import mutations from "./mutations/mutations";
import state from "./state";
import modules from "./modules";
import plugins from "./plugins";

Vue.use(Vuex);

const VuexStore = new Vuex.Store({
  state,
  mutations,
  actions,
  modules,
  plugins,
});
window.store = VuexStore;

export default VuexStore;
