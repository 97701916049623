import { SET_TEMPLATE } from "@/store/modules/landing/mutation-types";

const DEFAULT_VALUES = {
  button_color: "#f28b00",
  button_text_color: "#ffffff",
};

function processTemplate(template) {
  const candidate = { ...template };
  Object.keys(candidate).forEach(key => {
    if (key in DEFAULT_VALUES && !candidate[key]) {
      candidate[key] = DEFAULT_VALUES[key];
    }
  });
  return candidate;
}

export default {
  [SET_TEMPLATE](state, template) {
    state.template = processTemplate(template);
  },
};
