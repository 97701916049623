import {
  CHATS_SET_PROP,
  CHATS_SET_PROPS,
  CHATS_RESET_STATE,
} from "./mutation-types";

const defaultState = {
  channelId: null,
  lastMsgId: null,
  countMsgs: null,
  userId: null,
};

export default {
  [CHATS_SET_PROP](state, { prop, value }) {
    state[prop] = value;
  },
  [CHATS_SET_PROPS](state, props) {
    Object.keys(props).forEach(key => {
      state[key] = props[key];
    });
  },
  [CHATS_RESET_STATE](state) {
    Object.keys(state).forEach(key => {
      state[key] = defaultState[key];
    });
  },
};
