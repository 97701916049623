import * as Sentry from "@sentry/browser";

/**
 * @typedef {object} Severity
 * @property {string} Fatal - fatal
 * @property {string} Error - error
 * @property {string} Warning - warning
 * @property {string} Log - log
 * @property {string} Info - info
 * @property {string} Debug - debug
 * @property {string} Critical - critical
 */
/**
 * @typedef {Severity | object} Options
 * @property {object} [user]
 * @property {Severity} level
 * @property {object} extra
 * @property {object} contexts
 * @property {object} tags
 * @property {string[]} fingerprint
 */
/**
 * Логгер инкапсулирующий в себе работу с sentry для отправки вручную логов в sentry
 *
 * @property {SentryLogger} logger - набор функций и методов доступных у sentry
 * @property {Severity} level - уровни логирования определяющие как будет отображен лог
 * @property {SentryLogger} instance - кешировный инстанс для синглтона,
 * при создании нового экземпляра он будет один и тот же
 */
class SentryLogger {
  /**
   * [Документация sentry]{@link https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/}
   *
   * @param sentry
   * @returns {SentryLogger}
   */
  constructor(sentry = Sentry) {
    if (typeof SentryLogger.instance === "object") {
      return SentryLogger.instance;
    }
    this.logger = sentry;
    this.levels = sentry.Severity;
    SentryLogger.instance = this;
    return this;
  }

  /**
   * Используется для логирования дебаг информации или любого другого служебного сообщения
   * необходимого для контроля за работой приложения и разбора инцидентов
   *
   * @param {string} message - Сообщение которое вы хотите отправить на сервер
   * @param {Options} options
   */
  sendMessage(message = "", options = {}) {
    this.logger.withScope(() => {
      this.logger.captureMessage(message, options);
    });
  }

  /**
   * Используется для отправки ошибки на сервер вместе со стектрейсом
   * Первый параметр обязательно должны быть ошибкой
   *
   * @param {Error} exception
   * @param {Options} options
   */
  sendException(exception = new Error(), options = {}) {
    this.logger.withScope(() => {
      this.logger.captureException(exception, options);
    });
  }

  /**
   * Устанавливает тег на область видимости в которой будут перехватываться ошибки
   * теги облегчают поиск в sentry
   *
   * @param {string} key
   * @param {string} value
   */
  setTag(key, value) {
    this.logger.setTag(key, value);
  }

  /**
   * Делает тоже самое что setTag, но во множественном числе
   *
   * @param {object} tags - набор тегов пары ключ значение
   */
  setTags(tags = {}) {
    this.logger.setTags(tags);
  }

  /**
   * Устанавливает в контекст юзера, чтобы по логам было видно у какого пользователя
   * возникла ошибка
   *
   * @param {object} user
   */
  setUser(user) {
    this.logger.setUser(user);
  }

  /**
   * Очищает область перехвата ошибок от установленых тегов, конеткстов, юзеров
   * и т.д
   * Кейс применения - выход или смена комнаты, разлогин. Возможны иные кейсы.
   */
  clear() {
    this.logger.configureScope(scope => scope.clear());
  }
}

export default SentryLogger;
