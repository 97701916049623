import axios from "axios";
import api from "@/store/api/pollsAdapter";
import router from "@/routes/router";
import {
  CHAIN_STEP_FORWARD,
  CHECK_IS_AVAILABLE_WS_POLL,
  CLOSE_INTERACTIVE,
  CLOSE_POLL,
  CLOSE_POLL_SCORE,
  GET_COMMON_TEST_RESULTS,
  GET_TEST_POLLS,
  GET_TEST_RESULTS,
  GET_TESTS,
  RUN_ABOUT_WS_POLL,
  RUN_INTERACTIVE,
  RUN_POLL,
  RUN_TEST_POLL,
  SEND_NEW_ANSWER,
  SEND_POLL_ANSWER,
  SEND_POLL_TEST_ANSWER,
  SET_TEST_TIMER,
  SHOW_POLL_SCORE,
  SHOW_POLL_TEST_RESULT,
  GET_NEXT_QUESTION,
  FINISH_TEST,
} from "@/store/modules/polls/action-types";
import {
  SET_ACTIVE_POLL,
  SET_POLL_CHAIN,
  SET_SENDING_STATUS,
  SET_INTERACTIVE_TYPE,
  SET_PREVIOUS_ROUTE,
  CLEAR_POLLCHAIN,
  SET_POLLCHAIN_SCORE,
  SET_IS_BLOCK_CLOSE_POLL,
  SET_CURRENT_RESULT_ID,
  SET_TEST_RESULTS,
  SET_WS_POLL_COMPLETE,
  SET_WS_POLL_AVAILABLE,
  SET_TESTS,
  SKIP_TEST_POLL,
} from "@/store/modules/polls/mutation-types";
import { interactive, test } from "@/constants/polls/polls-module-types";
import { POLL, POLL_SCORES, POLL_TEST } from "@/constants/polls/poll-interactive-types";
import AuthService from "@/services/AuthService";
import { wsErrorType, ErrorWS } from "@/utils/errors";
import UserRoles from "@/constants/user/roles-const";
import { socket } from "@/store/modules/store.namespaces";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import config from "@/settings/config";

export default {
  /**
   * Событие запуска опроса.
   *
   * @param {*} param0
   * @param {*} param1
   * @returns {void}
   */
  [RUN_POLL]({ commit, dispatch }, { type, payload, isScreen = false }) {
    // Установка лоадера
    commit(SET_SENDING_STATUS, {
      type,
      payload: true,
    });
    // Очистка активного вопроса
    commit(SET_ACTIVE_POLL, {
      type,
      payload: {},
    });

    const { id: test_id } = payload?.obj;

    dispatch("createTimeLog", test_id).then(response => {
      const { success } = response.data;

      if (!success || !test_id) {
        if (type === test && test_id) {
          dispatch(GET_TEST_RESULTS, {
            type,
            payload: test_id,
          });
          dispatch(SHOW_POLL_TEST_RESULT, { type, testId: test_id });
        }
        return;
      }

      axios
        .get(`${config.serverUrl}/questionnaire/api/get-test-questions`, {
          headers: {
            "x-token": AuthService.getToken(),
          },
          params: {
            test_id,
          },
        })
        .then(res => {
          const { testName, question, questionsNum } = res?.data;

          // TODO: Вынести в отдельную модель
          const pollchain = {
            id: payload.obj.id,
            title: payload.obj.title,
            testName,
            type: "quiz",
            timer: payload.obj.timer,
            time_started: payload.obj.time_started,
            polls: [question || res?.data],
            step: 0,
            questionsNum,
            // timerDelta: res.data?.timerDelta || payload.obj.timerDelta,
          };

          // Выставляем активный опрос
          commit(SET_POLL_CHAIN, { type, payload: pollchain });
          // Выставляем активный вопрос
          commit(SET_ACTIVE_POLL, {
            type,
            payload: question || res.data,
          });

          if (question?.show_projector_result && isScreen) {
            dispatch(SHOW_POLL_SCORE, {
              type,
              payload: payload.obj,
              isScreen,
            });
            return;
          }

          if (type === interactive) dispatch(RUN_INTERACTIVE, { isScreen, interactiveType: POLL });
        })
        .catch(res => console.warn(res));
    });

    commit(SET_SENDING_STATUS, {
      type,
      payload: false,
    });
  },

  /**
   * Метод закрытия теста/опроса.
   *
   * @param {*} root0
   * @param {object} type - объект, содержащий тип проходимого теста/опроса и экран ли это
   */
  [CLOSE_POLL]({ state, commit, dispatch }, { type, isScreen = false }) {
    if (!state.isBlockClosePoll) {
      commit(SET_SENDING_STATUS, { type, payload: false });
      commit(CLEAR_POLLCHAIN, { type });
      commit(SET_ACTIVE_POLL, { type, payload: {} });
    }
    if (type === interactive) {
      dispatch(CLOSE_INTERACTIVE, {
        isScreen,
      });
    }
  },

  /**
   * Метод получения результатов по тесту/опросу без баллов.
   *
   * @param {*} param0
   * @param {*} param1
   * @returns {void}
   */
  async [SHOW_POLL_SCORE]({ commit, rootState, dispatch }, { type, payload, isScreen = false }) {
    const currentRoom = rootState.common.roomNumber;
    if (!payload?.test_id && !payload?.id) return;

    // const response = await AdminWebHttpService.get(`getResults/${payload.id}`);

    const response = await axios.get(
      `${config.serverUrl}/questionnaire/api/get-all-users-test-result`,
      {
        headers: {
          "x-token": AuthService.getToken(),
        },
        params: {
          test_id: payload?.test_id || payload?.id,
        },
      },
    );

    if (response && response.data) {
      if (currentRoom === rootState.common.roomNumber) {
        if (type === interactive) {
          dispatch(RUN_INTERACTIVE, { isScreen, interactiveType: POLL_SCORES });
        }
        commit(SET_POLLCHAIN_SCORE, {
          type,
          payload: {
            ...payload,
            ...response.data,
          },
        });
      }
    }
  },
  [CLOSE_POLL_SCORE]({ commit, dispatch }, { type, isScreen = false }) {
    commit(SET_POLLCHAIN_SCORE, { type, payload: {} });
    if (type === interactive) {
      dispatch(CLOSE_INTERACTIVE, { isScreen });
    }
  },
  /**
   * Ответ на вопрос опроса.
   *
   * @param {*} param0
   * @param {*} param1
   */
  async [SEND_POLL_ANSWER]({ commit, dispatch, state }, { type, payload }) {
    commit(SET_SENDING_STATUS, { type, payload: true });
    await AdminWebHttpService.post(
      "setAnswer",
      api.postQuizAnswer(payload, {
        poll: state[type].activePoll,
        pollChain: state[type].chain,
      }),
    );
    commit(SET_IS_BLOCK_CLOSE_POLL, false);
    commit(SET_SENDING_STATUS, { type, payload: false });
    dispatch(CHAIN_STEP_FORWARD, {
      type,
    });
  },

  /**
   * Ответ на вопрос теста.
   *
   * @param {*} param0
   * @param {*} param1
   * @returns {void}
   */
  async [SEND_POLL_TEST_ANSWER]({ state, commit, dispatch }, { type, payload }) {
    const stateByType = state[type];
    commit(SET_SENDING_STATUS, {
      type,
      payload: true,
    });
    const response = await AdminWebHttpService.post(
      `setAnswer`,
      api.postTestAnswer(payload, {
        poll: stateByType.activePoll,
        pollChain: stateByType.chain,
      }),
    );
    commit(SET_SENDING_STATUS, {
      type,
      payload: false,
    });

    if (response.data && response.data.is_last) {
      commit(SET_WS_POLL_COMPLETE, true);
    }
    dispatch(CHAIN_STEP_FORWARD, { type });
    return Promise.resolve();
  },

  /**
   * Новый метод отправки ответа на вопрос.
   *
   * @param {*} type
   * @param {boolean} is_last_question - Последний ли был вопрос
   * @param {number} user_test_answer_time - Время ответа на вопрос пользователем (в секундах)
   * @param {number} poll_id - ID вопроса
   * @param {number} test_id - ID теста/опроса
   * @param {Array} variants - Массив ответов
   */
  [SEND_NEW_ANSWER](
    { commit, dispatch, state, rootState },
    {
      type,
      is_last_question,
      user_test_answer_time,
      poll_id = state?.interactive?.activePoll?.id || state?.test?.activePoll?.id,
      test_id = state?.interactive?.chain?.id ||
      state[type]?.chain?.id ||
      +rootState.route.params.testId,
      variants,
      skipQuestion,
      moduleType,
    },
  ) {
    // ! TODO: Сказать Максу чтобы он сюда добавлял событие показа модалки об окончании, а в форме просто цеплялся на его state !!!
    // TODO: Костыль, свести все ответы к единой структуре и вынести все в модель ответа
    // TODO: Обсудить с Владом единую модель данных ответа на вопрос либо небольшой костыль так как данные не особо разные
    if (state.sendingStatus) return;

    // Установка ограничения на отправку новых ответов на вопрос.
    commit("changeSendingStatus", true);

    const { activePoll: poll } = state[moduleType];

    // Ответ на вопрос
    let data;

    switch (poll.type) {
      // Входные данные для вопросов с открытым ответом
      case "open_answer":
        data = {
          is_last_question,
          user_test_answer_time,
          answer: [variants],
          poll_id,
          test_id,
          variants: [],
          skipQuestion,
        };
        break;
      case "analog_scale":
        data = {
          is_last_question,
          user_test_answer_time,
          answer: "",
          poll_id,
          test_id,
          variants,
          skipQuestion,
        };
        break;
      // Дефолтный вариант
      default:
        data = {
          is_last_question,
          user_test_answer_time,
          poll_id,
          test_id,
          variants,
          skipQuestion,
        };
        break;
    }
    // Костыль END
    const payload = state[type]?.chain;
    const missing = payload?.missing || [];
    missing.push(state[type]?.activePoll.id);
    axios
      .post(`${config.serverUrl}/questionnaire/api/create-test-answer`, data, {
        headers: {
          "x-token": AuthService.getToken(),
        },
        // params: {
        //   last_question_ids: missing,
        // },
      })
      .then(async res => {
        const pollchain = state[type]?.chain;
        const pollsArray = pollchain?.polls;
        pollsArray.push(res?.data?.question);
        pollchain.polls = pollsArray;
        missing.push(res.data?.question.id);
        payload.missing = missing;
        const questionsNum = res?.data?.questionsNum;
        pollchain.timerDelta = res?.data?.timerDelta || 0;
        pollchain.isRepeated = res?.data?.isRepeated;

        if (pollchain.timerDelta && pollchain.isRepeated) {
          pollchain.timerOut += pollchain.timerDelta;
          pollchain.timer += pollchain.timerDelta;
        }

        commit(SET_POLL_CHAIN, {
          type,
          payload: pollchain,
        });
        dispatch(CHAIN_STEP_FORWARD, {
          type,
          questionsNum,
          skipQuestion,
        });
        // Если был последним вопрос или пришел флаг конца прохождения теста, то заканчиваем тест
        // TODO: Перепроверить, что-то не работает
        res?.data?.finish && commit(SET_WS_POLL_COMPLETE, true);
      })
      .catch(res => console.warn(res))
      // Снятие ограничения на отправку новых ответов на вопрос.
      .finally(() => commit("changeSendingStatus", false));
  },

  async [CHAIN_STEP_FORWARD](
    { state, commit, dispatch },
    { type, questionsNum = null, skipQuestion },
  ) {
    commit(CHAIN_STEP_FORWARD, { type, skipQuestion });
    // Обновление кол-ва вопросов. Нужно если админ поменял кол-во вопросов
    if (questionsNum !== null) {
      const pollchain = state[type]?.chain;
      pollchain.questionsNum = questionsNum + state[type]?.chain?.step;
      commit(SET_POLL_CHAIN, {
        type,
        payload: pollchain,
      });
    }

    const nextQuestion = state[type].chain.polls[state[type].chain.step];

    if (state[type].chain.step === state[type].chain.questionsNum && Array.isArray(nextQuestion)) {
      const { id } = state[type].chain;
      const chainType = state[type].chain.type;

      if (chainType !== "test") {
        commit(
          `${socket}/${SET_SOCKET_OBJ}`,
          {
            type: "quiz",
            obj: {
              event: "endQuiz",
              id,
            },
          },
          { root: true },
        );
        dispatch(CLOSE_POLL, { type });
        return;
      }

      commit(CLEAR_POLLCHAIN, { type });
      commit(SET_ACTIVE_POLL, { type, payload: {} });

      if (state[type].showResultAsCommon) {
        dispatch(GET_COMMON_TEST_RESULTS, {
          type,
          payload: id,
        }).then(() => {
          dispatch(SHOW_POLL_TEST_RESULT, {
            type,
            testId: id,
            isCommon: true,
          });
        });
      } else {
        dispatch(GET_TEST_RESULTS, {
          type,
          payload: id,
        });
        dispatch(SHOW_POLL_TEST_RESULT, { type, testId: id });
      }
    } else {
      commit(SET_ACTIVE_POLL, {
        type,
        payload: state[type].chain.polls[state[type].chain.step],
      });
    }
  },

  /**
   * Метод запуска теста.
   *
   * TODO: Протестировать и переписать метод.
   *
   * @param {*} param0
   * @param {*} param1
   * @returns {Promise} Возвращает промис на получение списка вопросов.
   */
  async [RUN_TEST_POLL]({ dispatch }, { type, payload, isScreen }) {
    const id = payload?.id || payload;
    let response;

    /**
     * Запрос на создание точки начала прохождения теста на бэке.
     */
    id &&
      dispatch("createTimeLog", id)
        .then(async res => {
          const { success, question, isRepeated, timerDelta, questionsNum } = res?.data;

          if (!success) {
            if (type === test) {
              dispatch(GET_TEST_RESULTS, {
                type,
                payload: id,
              });
              dispatch(SHOW_POLL_TEST_RESULT, { type, testId: id });
            }

            return;
          }

          // TODO: Переделать этот момент.
          response = await dispatch(GET_TEST_POLLS, {
            poll: question,
            questionsNum,
            type,
            isScreen,
            payload: {
              isRepeated,
              timerDelta,
              ...payload,
              id,
            },
          });
        })
        .catch(res => console.warn(res));

    return response;
  },

  /**
   * Метод получения вопросов теста.
   *
   * @param {*} param0
   * @param {*} param1
   * @returns {*} Возвращает статус выполнения метода.
   */
  async [GET_TEST_POLLS](
    { commit, dispatch, rootState, state },
    { poll, questionsNum, type, payload, isScreen = false },
  ) {
    commit(SET_SENDING_STATUS, {
      type,
      payload: false,
    });

    /* const response = await axios
      .get(`${config.serverUrl}/questionnaire/api/get-test-questions`, {
        headers: {
          "x-token": AuthService.getToken(),
        },
        params: {
          test_id: payload?.id,
        },
      })
      .then(res => {
        return res;
      })
      .catch(res => console.warn(res)); */

    // заменить на новый метод получения теста
    // const response = await AdminWebHttpService.get(
    //   `getTestPolls/${payload.id}/${AuthService.getToken()}`,
    // );
    if (!poll) {
      return false;
    }

    // const poll = response.data[0];

    if (poll.show_projector_result && isScreen) {
      dispatch(SHOW_POLL_SCORE, {
        type: interactive,
        payload: {
          id: payload.id,
        },
        isScreen,
      });
      return false;
    }
    const pollsArray = state[type]?.chain?.polls ?? [];
    pollsArray.push(poll);
    const { isRepeated } = payload;
    const pollchain = {
      id: payload.id,
      polls: pollsArray,
      step: 0,
      timer: payload.timer || "0:00:00",
      title: payload.title,
      type: "test",
      time_started: payload.time_started,
      commonResult: payload.commonResult,
      isRepeated,
      testIsEndless: payload.endless,
      timerDelta: payload.timerDelta,
      questionsNum,
    };

    if (pollchain?.timerDelta && isRepeated) {
      pollchain.timerOut = pollchain.timer + pollchain.timerDelta;
      pollchain.timer += pollchain.timerDelta;
    }

    commit(SET_POLL_CHAIN, {
      type,
      payload: pollchain,
    });
    commit(SET_ACTIVE_POLL, {
      type,
      payload: poll,
    });
    if (type === interactive) {
      dispatch(RUN_INTERACTIVE, { interactiveType: POLL_TEST });
    }
    if (!isScreen && rootState.auth.user.role !== UserRoles.Admin) {
      router.push({
        name: "test",
        params: {
          moduleType: type,
          testId: payload.id,
        },
        query: {
          moduleType: type,
        },
      });
    }

    return "";
  },

  /**
   * Метод запуска таймера теста.
   *
   * @param {*} _context
   * @param {*} payload
   * @returns {Promise} Возвращает промис на запуск таймера.
   */
  async [SET_TEST_TIMER](_context, payload) {
    return AdminWebHttpService.post(`setTestTimer/${payload.id}`, {
      time_started: payload.timer,
      is_finished: payload.finish || false,
    });
  },

  /**
   * Метод получения результатов теста с балами.
   *
   * @param {*} param0
   * @param {*} param1
   * @returns {object} объект с результатами теста
   */
  async [GET_TEST_RESULTS]({ commit }, { type, payload }) {
    try {
      // const response = await AdminWebHttpService.get(`getTestResults/${payload}`);
      // TODO: может перенести это все в геттеры (так будет логичнее и правильнее)
      // TODO: думаю, надо перенести
      const response = await axios
        .get(`${config.serverUrl}/questionnaire/api/get-test-result`, {
          headers: {
            "x-token": AuthService.getToken(),
          },
          params: {
            test_id: payload,
          },
        })
        .finally(res => {
          return res;
        });

      /**
       * При success: false ответе на данный запрос - мы должны отправлять пользователя на страницу со смайлом.
       */
      if (response?.data?.success) {
        commit(SET_TEST_RESULTS, {
          type,
          payload: {
            id: payload,
            data: response.data.result,
          },
        });
      } else {
        commit(SET_TEST_RESULTS, {
          type,
          payload: {
            id: payload,
            data: {
              error: true,
              message: response?.data?.message,
            },
          },
        });
      }

      commit(SET_CURRENT_RESULT_ID, { type, payload });

      if (response?.data) {
        return response;
      }
    } catch (e) {
      commit(SET_TEST_RESULTS, {
        type,
        payload: {
          id: payload,
          data: {
            error: true,
          },
        },
      });
      commit(SET_CURRENT_RESULT_ID, { type, payload });
      throw new ErrorWS(wsErrorType.SERVER_ERROR, e);
    }
    // проверить возвращаемый тип
    return {};
  },

  async [GET_COMMON_TEST_RESULTS]({ commit }, { type, payload }) {
    try {
      // const response = await AdminWebHttpService.get(`getResults/${payload}`);

      const response = await axios.get(
        `${config.serverUrl}/questionnaire/api/get-all-users-test-result`,
        {
          headers: {
            "x-token": AuthService.getToken(),
          },
          params: {
            test_id: payload,
          },
        },
      );

      if (response && response.data) {
        commit(SET_POLLCHAIN_SCORE, {
          type,
          payload: {
            ...response.data,
            id: payload,
          },
        });
      }
    } catch (e) {
      throw new ErrorWS(wsErrorType.SERVER_ERROR, e);
    }
  },
  async [SHOW_POLL_TEST_RESULT]({ state, commit }, { type, isCommon = false, testId }) {
    if (!state[type].previousRoute && type === interactive) {
      commit(`${type}/${SET_PREVIOUS_ROUTE}`, router.currentRoute);
    }
    const routeName = isCommon ? "testCommonResult" : "testResult";
    await router.replace({ name: routeName, params: { moduleType: type, testId } });
    commit(SET_SENDING_STATUS, {
      type,
      payload: false,
    });
  },
  [RUN_INTERACTIVE]({ state, commit, rootState }, { route, isScreen, interactiveType }) {
    commit(`${interactive}/${SET_INTERACTIVE_TYPE}`, interactiveType);
    if (!isScreen) {
      if (!state.interactive.previousRoute && ![POLL, POLL_SCORES].includes(interactiveType)) {
        commit(`${interactive}/${SET_PREVIOUS_ROUTE}`, router.currentRoute);
      }
      if (route && rootState.auth.user.role !== UserRoles.Admin) {
        router.push(route);
      }
    }
  },

  /**
   * Метод закрытия интерактивного (перекрывает все) прохождения теста/опроса.
   *
   * @param {*} root0
   * @param {object} isScreen - экран ли это
   */
  [CLOSE_INTERACTIVE]({ state, commit }, { isScreen }) {
    commit(`${interactive}/${SET_INTERACTIVE_TYPE}`, null);
    if (!isScreen) {
      // TODO: Поправить данный костыль с переходом на одну и ту же страницу
      // Если мы перезагружаем страницу с тестом то предыдущей страницей становится сам тест
      // и после завершения он открывается снова.
      // Проблема была временно решена проверками state?.interactive?.previousRoute?.fullpath !== router?.currentRoute?.fullpath
      // и state?.interactive?.previousRoute?.fullpath === router?.currentRoute?.fullpath
      if (
        state?.interactive?.previousRoute &&
        state?.interactive?.previousRoute?.fullpath !== router?.currentRoute?.fullpath
      ) {
        router.push({
          name: state.interactive.previousRoute.name,
          params: state.interactive.previousRoute.params,
        });
        commit(`${interactive}/${SET_PREVIOUS_ROUTE}`, null);
      }
    }
  },
  async [RUN_ABOUT_WS_POLL]({ commit, rootState }) {
    commit(SET_WS_POLL_COMPLETE, false);
    const response = await api.getWsQuizPolls(rootState);
    if (response) {
      if (response.polls && response.polls.length > 0) {
        commit(SET_POLL_CHAIN, {
          type: test,
          payload: response,
        });
        commit(SET_ACTIVE_POLL, {
          type: test,
          payload: response.polls[0],
        });
      } else {
        commit(SET_WS_POLL_COMPLETE, true);
      }
    }
  },
  async [CHECK_IS_AVAILABLE_WS_POLL]({ commit, rootState }) {
    try {
      const res = await api.getWsQuizPolls(rootState);
      commit(SET_WS_POLL_AVAILABLE, !!res);
    } catch (e) {
      console.error(e);
    }
  },
  /**
   * Метод получения списка тестов пользователем.
   *
   * @param {*} param0
   * @param {number} roomNumber - Номер комнаты
   * @returns {void}
   */
  async [GET_TESTS]({ commit, rootState }, roomNumber) {
    const currentRoom = roomNumber || rootState.common.roomNumber;
    if (!currentRoom) {
      return;
    }

    await axios
      .get(`${config.serverUrl}/questionnaire/api/get-tests`, {
        headers: {
          "x-token": AuthService.getToken(),
        },
        params: {
          room_number: currentRoom,
        },
      })
      .then(res => {
        const { data } = res;
        if (!data.finished) data.finished = [];
        commit(SET_TESTS, data);
      })
      .catch(res => console.warn(res));
  },

  /**
   * Метод создания отметки о начале прохождения тестов/опросов.
   *
   * @param {number} testId - id теста/опроса
   *
   * @returns {Promise} Возвращает промис с отметкой о начале прохождения теста.
   */
  createTimeLog({ commit }, testId) {
    commit(CLEAR_POLLCHAIN, { type: "test" });
    return (
      testId &&
      axios
        .post(`${config.serverUrl}/questionnaire/api/create-user-test-time-log`, null, {
          headers: {
            "x-token": AuthService.getToken(),
          },
          params: {
            testId,
          },
        })
        .catch(res => console.warn(res))
    );
  },

  /**
   * Метод создания отметки о завершении прохождения тестов/опросов.
   * Так же происходит очистка теста/опроса, если указать его тип.
   *
   * @param {object} state
   * @param {Function} dispatch
   * @param {number} testId - id теста/опроса
   * @param {string} type - тип теста/опроса
   * @returns {Promise} Возвращает промис с отметкой о окончании прохождения теста.
   */
  [FINISH_TEST]({ dispatch }, { testId, type = "" }) {
    return (
      testId &&
      axios
        .post(`${config.serverUrl}/questionnaire/api/test-time-out`, null, {
          headers: {
            "x-token": AuthService.getToken(),
          },
          params: {
            testId,
          },
        })
        .then(() => {
          type && dispatch(CLOSE_POLL, { type });
        })
        .catch(res => console.warn(res))
    );
  },
  /**
   * Метод получения следующего вопроса.
   *
   * @param {*} commit
   * @param {*} state
   * @param {*} type - тип тест/опрос
   * @param {*} testId - id теста/опроса
   *
   * @returns {Promise} Возвращает промис с отметкой о начале прохождения теста.
   */
  [GET_NEXT_QUESTION]({ commit, state }, { type }) {
    const payload = state[type]?.chain;
    const test_id = state[type]?.chain.id;
    const missing = payload?.missing || [];
    missing.push(state[type]?.activePoll.id);
    if (payload.polls?.length <= payload.missing?.length) {
      commit(SKIP_TEST_POLL, type);
    } else {
      test_id &&
        missing &&
        axios
          .get(`${config.serverUrl}/questionnaire/api/get-test-questions`, {
            headers: {
              "x-token": AuthService.getToken(),
            },
            params: {
              test_id,
              last_question_ids: missing,
            },
          })
          .then(res => {
            // TODO: Вынести в отдельную модель
            const pollsArray = payload?.polls;
            pollsArray.push(res.data?.question);
            payload.polls = pollsArray;
            // баг. так как вопросы приходят по одному то хранение пропущеных вопросов приводит к дублированию
            // а если убрать дублирование то на бэке кончаются вопросы и завершается тест, хотя еще есть вопросы на фронте
            // TODO: пофиксить баг нормально. с таким фиксом вопросы можно будет пропускать бесконечно
            // missing.push(res.data?.question.id);
            // payload.missing = missing;
            commit(SET_POLL_CHAIN, {
              type,
              payload,
            });
            commit(SKIP_TEST_POLL, type);
          })
          .catch(res => console.warn(res));
    }
  },
};
