export const TEMPLATE = "TEMPLATE";
export const LANDING_LOGO = "LANDING_LOGO";
export const LIGHTER_BASE_COLOR = "LIGHTER_BASE_COLOR";
export const BASE_SHADOW_COLOR = "BASE_SHADOW_COLOR";
export const BASE_FADE_COLOR = "BASE_FADE_COLOR";
export const SYSTEM_FADE_COLOR = "SYSTEM_FADE_COLOR";
export const LOGIN_FORM_COLOR = "LOGIN_FORM_COLOR";
export const CORP_LANDING_PARAMS = "CORP_LANDING_PARAMS";
export const HAS_CORP_LANDING = "HAS_CORP_LANDING";
export const MANAGER_LOGIN_AVAILABLE = "MANAGER_LOGIN_AVAILABLE";
export const DEFAULT_ROOM = "DEFAULT_ROOM";
