export default {
  state: {
    chain: null,
    activePoll: null,
    sendingStatus: false,
    chainScore: null,
    showResultAsCommon: false,
    results: {},
    currentResultId: "",
  },
};
