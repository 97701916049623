import { RESET_TRANSLATION } from "@/store/modules/translation/action-types";
import {
  SET_STREAM_AIR,
  SET_STREAM_IS_ACTIVE,
  SET_STREAM_LOW_LATENCY,
  SET_STREAM_SAFEMODE,
} from "@/store/modules/translation/mutation-types";

export default {
  [RESET_TRANSLATION]({ commit, state }, force = false) {
    commit(SET_STREAM_LOW_LATENCY, false);
    commit(SET_STREAM_IS_ACTIVE, false);
    if (state.streamSafeMode && force) {
      commit(SET_STREAM_SAFEMODE, false);
    }
    commit(SET_STREAM_AIR, false);
  },
};
