export default class SpeakerQuestion {
  constructor(question) {
    this.id = "";
    this.date = "";
    this.isAnswered = false;
    this.isDisliked = false;
    this.isMarked = false;
    this.isModerated = false;
    this.countDislike = 0;
    this.countMark = 0;
    this.messages = [];
    this.stats = false;
    this.answer = "";
    this.admin_answer = "";
    this.setServer(question);
  }

  // Адаптер для серверных данных
  setServer(question) {
    if (!question) return;
    this.set({
      id: question.id,
      date: question.date || "",
      isAnswered: !!question.isAnswered,
      isDisliked: !!question.isDisliked,
      isMarked: !!question.isMarked,
      isModerated: !!question.moderated,
      countDislike: +question.dislike,
      countMark: +question.mark,
      messages: question.value?.split("\n").filter(item => item.length) || [],
      stats: !!question.stats,
      answer: question.answer || "",
      admin_answer: question.admin_answer || "",
    });
  }

  set(question) {
    Object.entries(question).forEach(([key, val]) => {
      this[key] = val;
    });
    return this;
  }
}
