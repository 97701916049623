import { polls } from "@/store/modules/store.namespaces";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import { registerModules } from "../registerModules";

const modules = registerModules(true, polls);
export default {
  state: {
    tests: [],
    isBlockClosePoll: false,
    wsPollAvailable: false,
    wsPollComplete: false,
    wsPollModalOpen: false,
    interactiveTheme: null,
    interactiveThemeBg: null,
    isCheatPoints: false,
    // Статус отправки нового ответа
    sendNewAnswerStatus: false,
  },
  modules,
  mutations,
  actions,
  getters,
};
