import BaseHttpService from "@/services/http/BaseHttpService";
import config from "@/settings/config";

const baseURL = config.serverUrl + "/api/v2/";

class AdminApi2HttpService extends BaseHttpService {
  constructor() {
    super({ baseURL });
  }
}

export default new AdminApi2HttpService();
