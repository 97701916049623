import { test } from "@/constants/polls/polls-module-types";

export default function checkReset({ to, next, nextMiddleware }) {
  if (["test", "testResult", "testCommonResult"].includes(to.name)) {
    if (!to.params.moduleType) {
      to.params.moduleType = to.query.moduleType || test;
      return next(to);
    }
  }

  return nextMiddleware();
}
