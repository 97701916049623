import { PlayerEvents } from "@/constants/logs/logs-const";
import BaseLogger from "./BaseLogger";

class StreamLogger extends BaseLogger {
  constructor() {
    super("streamLogger", "stream-actions");
    this.Events = {
      ...PlayerEvents,
      MainPlayer: 6,
      Ping: 7,
    };
  }

  getPayload(payload) {
    return {
      userId: payload.user.id,
      personalId: payload.user.personalId,
      login: payload.user.login,
      roomNumber: payload.roomNumber,
      presentationId: payload.presId,
      event: payload.event,
    };
  }
}

export default new StreamLogger();
