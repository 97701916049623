import {
  RESET_PRESENTATION_STATE,
  SET_PLAY_YOUTUBE_VIDEO,
  SET_PRESENTATION_INFO,
  SET_PRESENTATIONS,
  SET_SLIDE_BUSY,
  SET_SLIDE_VIDEO_TIME,
  SET_START_PRESENTATION,
  SET_TEST_SLIDE_ANSWERED,
  SET_USER_SLIDE_INDEX,
} from "@/store/modules/presentation/mutation-types";

const defaultState = () => ({
  presentations: [],
  slideVideoTime: [],
  currentPresentationInfo: {
    id: null,
    slideIndex: null,
    userSelectedSlideIndex: null,
    start: 0,
  },
});

export default {
  [SET_PLAY_YOUTUBE_VIDEO](state, payload) {
    state.playYoutubeVideo = payload;
  },
  [SET_PRESENTATIONS](state, payload) {
    state.presentations = payload;
  },
  [SET_USER_SLIDE_INDEX](state, index) {
    state.currentPresentationInfo = {
      ...state.currentPresentationInfo,
      userSelectedSlideIndex: index,
    };
  },
  [SET_SLIDE_VIDEO_TIME](state, time) {
    state.slideVideoTime = time;
  },
  [SET_PRESENTATION_INFO](state, data) {
    const { slideIndex, presentationId, userSelectedSlideIndex } = data;
    state.currentPresentationInfo = {
      ...state.currentPresentationInfo,
      id: presentationId || 0,
      slideIndex,
      userSelectedSlideIndex:
        userSelectedSlideIndex || state.currentPresentationInfo.userSelectedSlideIndex,
    };
  },
  [SET_START_PRESENTATION](state, start) {
    state.currentPresentationInfo.start = start;
  },
  [SET_TEST_SLIDE_ANSWERED](state, slideId) {
    const { id: presentationId } = state.currentPresentationInfo;
    const presentationIndex = state.presentations.findIndex(item => item.id === presentationId);
    const slideEntries = Object.entries(state.presentations[presentationIndex].slides).find(
      ([_key, slide]) => slide.id === slideId,
    );
    if (slideEntries.length) {
      state.presentations[presentationIndex].slides[slideEntries[0]] = {
        ...slideEntries[1],
        isTestAnswered: true,
      };
    }
  },
  [RESET_PRESENTATION_STATE](state) {
    const newState = defaultState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  [SET_SLIDE_BUSY](state, value) {
    state.changeSlideIsBusy = value;
  },
};
