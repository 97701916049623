/**
 * Константы используемые для трансляций
 *
 * @namespace BroadcastConsts
 */
import config from "@/settings/config";

/**
 *
 * @typedef  Resolution
 * @type {object}
 * @property {string} name
 * @property {number} width
 * @property {number} height
 * @property {boolean} disabled
 */
/**
 * Доступные разрешения
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {Readonly<object.<string, Resolution>>}
 * @default
 */
export const RESOLUTIONS = Object.freeze({
  "240p": {
    name: "240p",
    width: 320,
    height: 180,
    disabled: false,
  },
  "480p": {
    name: "480p",
    width: 640,
    height: 360,
    disabled: false,
  },
  "720p": {
    name: "720p",
    width: 1280,
    height: 720,
    disabled: false,
  },
  "1080p": {
    name: "1080p",
    width: 1920,
    height: 1080,
    disabled: false,
  },
});
/**
 * @typedef Bitrate
 * @type {object}
 * @property {number} minBitrate
 * @property {number} maxBitrate
 */
/**
 * Значения битрейта по разрешению
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {Readonly<object.<string, Bitrate>>}
 * @default
 */
export const BITRATE_BY_RESOLUTION = Object.freeze({
  "240p": {
    minBitrate: 300,
    maxBitrate: 700,
  },
  "480p": {
    minBitrate: 1500,
    maxBitrate: 3000,
  },
  "720p": {
    minBitrate: 2500,
    maxBitrate: 4000,
  },
  "1080p": {
    minBitrate: 3500,
    maxBitrate: 5000,
  },
});

/**
 * Типы трансляций
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{MP4: string, TYPICAL: string, MEETING: string, FACECAST: string, NONE: string, YOUTUBE: string}}
 * @default
 */
export const BROADCAST_TYPE = {
  TYPICAL: "typical",
  YOUTUBE: "youtube",
  VIMEO: "vimeo",
  MP4: "mp4_file",
  MEETING: "meeting_room",
  FACECAST: "facecast",
  NONE: "none",
};

/**
 * Тип плеера
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{RTMP: string, EXTERNAL: string, ONE_TO_MANY: string, CONFERENCE: string}}
 * @default
 */
export const PLAYER_TYPE = {
  EXTERNAL: "external",
  RTMP: "rtmp",
  CONFERENCE: "conference",
  ONE_TO_MANY: "one2Many",
};

/**
 * Ошибки медиа устройств
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {object}
 * @default
 */
export const MEDIA_ERROR = {
  NOT_ALLOWED_ERROR: "NotAllowedError",
  UNSUPPORTED_BROWSER_IOS: "UnsupportedBrowserIOS",
  UNSUPPORTED_BROWSER: "UnsupportedBrowser",
  FAILED_ALLOCATE: "Failed to allocate videosource",
  INVALID_CONSTRAINT: "Invalid constraint",
  DEVICE_NOT_FOUND: "Device not found",
  VIDEO_DEVICE_NOT_FOUND: "Video device not found",
  AUDIO_DEVICE_NOT_FOUND: "Audio device not found",
  NOT_AVAILABLE_DEVICES: "Error detecting available devices",
  NOT_FOUND_ERROR: "NotFoundError",
};

/**
 * Типы провайдеров для стримов
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{WS_PLAYER: string, WEBRTC: string, MSE: string}}
 * @default
 */
export const PROVIDERS_TYPES = {
  WS_PLAYER: "WSPlayer",
  WEBRTC: "WebRTC",
  MSE: "MSE",
};

/**
 * Ключей для определения того в каком режиме запущен плеер
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{VIEWER: string, PREVIEW: string, SPEAKER: string, LEADING: string}}
 * @default
 */
export const STREAM_TYPES = {
  PREVIEW: "PREVIEW",
  SPEAKER: "SPEAKER",
  VIEWER: "VIEWER",
  LEADING: "LEADING",
};

/**
 * Префиксы для имен стримов в зависимости от режима в котором запущен плеер
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {object}
 * @default
 */
export const USER_NAME_PREFIX = {};
Object.keys(STREAM_TYPES).forEach(key => {
  USER_NAME_PREFIX[key] = `${STREAM_TYPES[key]}__`;
});

/**
 * Тип плеера
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{ONE_TO_MANY: "CONFERENCE", CONFERENCE: "ONE_TO_MANY"}}
 * @default
 */
export const STRATEGY_TYPES = {
  CONFERENCE: "CONFERENCE",
  ONE_TO_MANY: "ONE_TO_MANY",
};

/**
 * Facing mode constraints
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{environment: "environment", user: "user"}}
 * @default
 */
export const FACING_MODE = {
  environment: "environment",
  user: "user",
};

/**
 * Адрес ртмп сервера ндженикс 1
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {string}
 * @default
 */
export const NGENIX_RTMP_URL =
  "rtmp://s38684:favjoafleud0@s38684-media-origin1.cdn.ngenix.net/live";
/**
 * Адрес ртмп сервера ндженикс 2
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {string}
 * @default
 */
export const NGENIX_RTMP_URL2 =
  "rtmp://s38684:favjoafleud0@s38684-media-origin2.cdn.ngenix.net/live";

/**
 * Часть адреса сервера ндженикс для проигрывания стрима
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {string}
 * @default
 */
export const NGENIX_URL_PREPART = "https://s35139.cdn.ngenix.net/s35139-media-origin/lvs/";
/**
 * Часть адреса сервера ндженикс для проигрывания стрима
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {string}
 * @default
 */
export const NGENIX_URL_POSTPART = "/index.m3u8";
/**
 * Строковая константа для определения внешний поток это или нет
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {string}
 * @default
 */
export const OPTION_EXTERNAL_STREAM = "externalStream";
/**
 * Количество мс до отправки следующего пульса
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {number}
 * @default
 */
export const SEND_PULSE_INTERVAL = 5000;
/**
 * Задержка листания слайда в мс при стриме с задержкой или внешнем потоке
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {number}
 * @default
 */
export const HIGH_LATENCY_ACTIONS_DEFFERS_MS = 8000;

export const RTMP_SERVERS = [
  {
    label: "Сервер ре-публикации 1",
    name: NGENIX_RTMP_URL,
  },
  {
    label: "Сервер ре-публикации 2",
    name: NGENIX_RTMP_URL2,
  },
];

/**
 * Постфикс для имени скриншаринга
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {string}
 * @default
 */
export const CAPTURE_STREAM_POSTFIX = "screenShare";

export const CAPTURE_STREAM_STATUSES = {
  PUBLISHED: "CAPTURE_PUBLISHED",
  STOPPED: "CAPTURE_STOPPED",
  FAILED: "CAPTURE_FAILED",
};

/**
 * Перечисление для определения выбраного типа записи
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{Event: 1, Stream: 2, Off: 3}}
 * @default
 */
export const RecordTypes = {
  Off: 1,
  Stream: 2,
  Event: 3,
};

export const RECORD_MESSAGES = {
  CLOSE_WINDOW: "CLOSE_WINDOW",
};

/**
 * Время до отключения заставки стрима в мс
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {number}
 * @default
 */
export const SCREEN_SAVER_TIMEOUT = 8000;

/**
 * Статусы качества для стрима
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{Unknown: "UNKNOWN", Bad: "BAD", OnAir: "ON_AIR", Good: "GOOD", Off: "OFF", Perfect: "PERFECT"}}
 * @default
 */
export const ConnectionQuality = {
  Off: "OFF",
  Unknown: "UNKNOWN",
  Bad: "BAD",
  Good: "GOOD",
  Perfect: "PERFECT",
  OnAir: "ON_AIR",
};
/**
 * Позиции провайдера плееров в комнате
 *
 * @memberof BroadcastConsts
 * @constant
 * @type {{ROOM_CONTAINER: "ROOM_CONTAINER", ROOM_SIDE: "ROOM_SIDE"}}
 * @default
 */
export const PROVIDER_POSITIONS = {
  ROOM_CONTAINER: "ROOM_CONTAINER",
  ROOM_SIDE: "ROOM_SIDE",
};
