<script>
import { ICON_COLORS } from "@/constants/themes/themes-const";

const ICON_TYPE = {
  solid: "fas fa-",
  regular: "far fa-",
  light: "fal fa-",
  brands: "fab fa-",
  duotone: "fad fa-",
};
/**
 * Мы используем fontawesome pro kit.<br>
 * По умолчанию иконка с типом solid.<br>
 * Компонент берет имя с дефолтного слота компонента.<br>
 * Примеры [WsIcon]{@link https://storybook.dev.whenspeak.ru/?path=/docs/ui-wsicon--styles}
 * @vue-prop {Boolean} [regular=false] - Тип иконки regular
 * @vue-prop {Boolean} [brands=false] - Тип иконки brands
 * @vue-prop {Boolean} [light=false] - Тип иконки light
 * @vue-prop {Boolean} [duotone=false] - Тип иконки duotone
 * @vue-prop {Boolean} [xs=false] - размер иконки 12px
 * @vue-prop {Boolean} [sm=false] - размер иконки 14px
 * @vue-prop {Boolean} [md=false] - размер иконки 16px
 * @vue-prop {Boolean} [lg=false] - размер иконки 18px
 * @vue-prop {Boolean} [xl=false] - размер иконки 20px
 * @vue-prop {Boolean} [xxl=false] - размер иконки 25px
 * @vue-prop {String} [color=""] - одно из значений из константы ICON_COLORS
 */
export default {
  name: "WsIcon",
  props: {
    regular: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    brands: {
      type: Boolean,
      default: false,
    },
    duotone: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
    xxl: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
      validator(value) {
        return !value || ICON_COLORS.includes(value);
      },
    },
  },
  /**
   * Посмотреть примеры: [WsIcon]{@link https://storybook.dev.whenspeak.ru/?path=/docs/ui-base-wsicon--styles}
   */
  render(createElement) {
    const iconName = this.$slots.default ? this.$slots.default[0]?.text?.trim() ?? "" : "";

    const iconType =
      (this.regular && ICON_TYPE.regular) ||
      (this.light && ICON_TYPE.light) ||
      (this.brands && ICON_TYPE.brands) ||
      (this.duotone && ICON_TYPE.duotone) ||
      ICON_TYPE.solid;
    const iconSize =
      (this.xs && this.$style.xs) ||
      (this.sm && this.$style.sm) ||
      (this.md && this.$style.md) ||
      (this.lg && this.$style.lg) ||
      (this.xl && this.$style.xl) ||
      (this.xxl && this.$style.xxl) ||
      "";
    const iconColor = ICON_COLORS.includes(this.color) ? this.$style[this.color] : "";
    const classList = [
      this.$style.wsIcon,
      iconName && `${iconType}${iconName}`,
      iconSize,
      iconColor,
    ].filter(className => className);

    return createElement("i", {
      class: classList,
      attrs: this.$attrs,
      on: this.$listeners,
    });
  },
};
</script>

<style lang="scss" module>
.wsIcon {
  --ws-icon-color-default: var(--default-button-color, #acacac);
  --ws-icon-color-primary: var(--ws-color-primary, #f79843);
  --ws-icon-color-accent: var(--ws-color-accent, #f05735);

  @include scssToCssVariable(
    (
      ws-icon-color-white: $white,
      ws-icon-color-black: $black,
      ws-icon-color-danger: $danger,
      ws-icon-color-green: $green,
    )
  );
}

$sizes: "xs", "sm", "md", "lg", "xl", "xxl";

@each $s in $sizes {
  @include generate-class-by-key("font-size", var(--ws-icon-size-#{$s}), $s);
}

$colors: "default", "primary", "accent", "green", "white", "black", "danger";

@each $c in $colors {
  @include generate-class-by-key("color", var(--ws-icon-color-#{$c}), $c);
}
</style>
