import axios from "axios";
import config from "@/settings/config";

export function subscribeNotifications({ roomId }) {
  window.plugins.OneSignal.setLogLevel({ logLevel: 5, visualLevel: 0 });
  const notificationOpenedCallback = jsonData => {
    console.log(`notificationOpenedCallback: ${JSON.stringify(jsonData)}`);
  };

  const notificationOpenedReceived = jsonData => {
    console.log(`notificationReceivedCallback: ${JSON.stringify(jsonData)}`);
  };

  const iosSettings = {};
  iosSettings.kOSSettingsKeyAutoPrompt = false;
  iosSettings.kOSSettingsKeyInAppLaunchURL = false;

  window.plugins.OneSignal.startInit(config.pushServerAppId)
    .handleNotificationReceived(notificationOpenedReceived)
    .handleNotificationOpened(notificationOpenedCallback)
    .iOSSettings(iosSettings)
    .inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.Notification)
    .endInit();

  window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
    console.log(`User accepted notifications: ${accepted}`);
  });

  window.plugins.OneSignal.sendTags({
    room: roomId,
    domain: config.domain,
  });
}

export function removeOneSignalSubscription(roomId) {
  window.plugins.OneSignal.deleteTags({
    room: roomId,
    domain: config.domain,
  });
}

export function pushNotification({ message, roomId }) {
  const headers = {
    Authorization: `Basic ${config.pushServerRestId}`,
  };
  const data = {
    app_id: config.pushServerAppId,
    contents: {
      ru: message,
      en: "default",
    },
    filters: [
      {
        field: "tag",
        key: "room",
        relation: "=",
        value: roomId,
      },
      {
        operator: "AND",
      },
      {
        field: "tag",
        key: "domain",
        relation: "=",
        value: config.domain,
      },
    ],
  };
  return axios.post(config.pushServerUrl, data, { headers });
}
