import {
  RESET_USER_QUESTIONS,
  SET_QUESTION_DISLIKE,
  SET_QUESTION_LIKE,
  SET_QUESTION_MESSAGE,
  SET_QUESTIONS_SORT,
  SET_USER_QUESTIONS,
} from "@/store/modules/questions/mutation-types";

const questionsReduceCb = fn => (accum, question) => {
  if (question && question.id) {
    accum.push(fn(question));
  }
  return accum;
};

/**
 * Метод добавляет новые вопросы в массив старых.
 *
 * @param {Array} oldQuestions - массив старых вопросов
 * @param {Array} newQuestions - измененный массив вопросов
 * @returns {Array}
 */
function findNewQuestions(oldQuestions, newQuestions) {
  const preparedNewQuestions = newQuestions.reduce(
    questionsReduceCb(question => [question.id, question]),
    [],
  );
  const questionMap = new Map(preparedNewQuestions);
  const preparedStateQuestions = oldQuestions.reduce(
    questionsReduceCb(question => [question.id]),
    [],
  );
  const stateQuestionsIdsMap = new Map(preparedStateQuestions);
  const questions = oldQuestions
    .reduce(
      questionsReduceCb(question => questionMap.get(question.id)),
      [],
    )
    .filter(q => !!q);
  const foundQuestions = [];
  for (const [id] of questionMap) {
    if (!stateQuestionsIdsMap.has(id)) {
      foundQuestions.push(questionMap.get(id));
    }
  }
  return [...questions, ...foundQuestions];
}

export default {
  [RESET_USER_QUESTIONS](state) {
    state.questions = [];
    state.sort = true;
  },
  [SET_QUESTIONS_SORT](state, payload) {
    state.sort = payload;
  },
  /**
   * Метод получения новых вопросов.
   *
   * @param {object} state
   * @param {Array} questions - массив вопросов.
   */
  [SET_USER_QUESTIONS](state, questions) {
    let newQuestions;
    if (!state.sort) {
      newQuestions = findNewQuestions(state.questions, questions);
    } else {
      newQuestions = questions;
    }
    // TODO: Упростить сортировку в одну.
    if (state.sort) {
      newQuestions
        .sort((a, b) => +a.id - +b.id)
        .sort((a, b) => b.mark - a.mark)
        .sort((a, b) => a.isAnswered - b.isAnswered);
    }

    state.questions = newQuestions;
  },
  [SET_QUESTION_MESSAGE](state, message) {
    state.questionMessage = message;
  },
  [SET_QUESTION_LIKE](state, payload) {
    const { markDelta, id, markStatus } = payload;
    const stateQuestion = state.questions.find(question => question.id === id);
    stateQuestion.isMarked = markStatus;
    stateQuestion.mark += markDelta;
  },
  [SET_QUESTION_DISLIKE](state, payload) {
    const { markDelta, id, markStatus } = payload;
    const stateQuestion = state.questions.find(question => question.id === id);
    stateQuestion.isDisliked = markStatus;
    stateQuestion.dislike += markDelta;
  },
};
