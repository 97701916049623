export const SET_FAQ_ANSWERS = "SET_FAQ_ANSWERS";
export const TOGGLE_SIDEBAR_FULLSCREEN = "TOGGLE_SIDEBAR_FULLSCREEN";
export const TOGGLE_USER_POPUP_VISIBLE = "TOGGLE_USER_POPUP_VISIBLE";
export const TOGGLE_SIDEBAR_EXPANDED = "TOGGLE_SIDEBAR_EXPANDED";
export const SET_ROOM_TIMER = "SET_ROOM_TIMER";
export const SET_CLOCK_TIMESTAMP = "SET_CLOCK_TIMESTAMP";
export const SET_INTERFACE_LANGUAGE = "SET_INTERFACE_LANGUAGE";
export const SET_ROOM_ID = "SET_ROOM_ID";
export const SET_ROOM_INFO = "SET_ROOM_INFO";
export const SET_ROOM_NUMBER = "SET_ROOM_NUMBER";
export const SET_ROOM_THEME = "SET_ROOM_THEME";
export const SET_ROOM_INFO_BRAINSTORM = "SET_ROOM_INFO_BRAINSTORM";
export const SET_BRAINSTORM_STATUS = "SET_BRAINSTORM_STATUS";
export const SET_PREMODERATION = "SET_PREMODERATION";
export const SET_ROOMINFO_QUIZ = "SET_ROOMINFO_QUIZ";
export const SET_ROOMINFO_TEST_ID = "SET_ROOMINFO_TEST_ID";
export const SET_ROOMINFO_SHOW_RESULT = "SET_ROOMINFO_SHOW_RESULT";
export const SET_NEUROSTATUS = "SET_NEUROSTATUS";
export const RESET_ROOM_STATE = "RESET_ROOM_STATE";
export const SET_ROOM_MODULES_STATE = "SET_ROOM_MODULES_STATE";
export const SET_ROOM_PENDING = "SET_ROOM_PENDING";
export const SET_SELECTED_STREAM_LANG = "SET_SELECTED_STREAM_LANG";
