import Vue from "vue";
import * as browserCheck from "@/utils/browser-check";

const browserCheckPlugin = {
  install(instance) {
    instance.mixin({
      computed: {
        $browser: () => browserCheck.browser,
        $isSupportBrowser: browserCheck.isSupportBrowser,
        $isNotSupportBrowser: browserCheck.isNotSupportBrowser,
        $isNotSupportStream: browserCheck.isNotSupportStream,
        $isMobile: browserCheck.isMobile,
        $isTablet: browserCheck.isTablet,
        $isDesktop: browserCheck.isDesktop,
        $isAndroid: browserCheck.isAndroid,
        $isIOS: browserCheck.isIOS,
        $isMobileIOS: browserCheck.isMobileIOS,
        $isIPad: browserCheck.isIPad,
        $isSafari: browserCheck.isSafari,
        $isChrome: browserCheck.isChrome,
        $hasWebRTC: browserCheck.hasWebRTC,
        $hasScreenCapture: browserCheck.hasScreenCapture,
        $isSafariWebRTC: browserCheck.isSafariWebRTC,
        $isCordova: browserCheck.isCordova,
        $isCordovaIOS: () => window.device?.platform === "iOS",
        // костыль для мобильных сафари от 12 до 13 версии для решения проблемы скролла
        $isSafariIOS12() {
          const [major] = this.$getBrowserVersion();
          return this.$isSafari && this.$isIOS && parseInt(major, 10) === 12;
        },
        $isIOSNotSupportWebRTC() {
          // todo подключить функционал когда будем делать задачу https://jira.whenspeak.ru/browse/OLD-6282
          // if (this.$isIOS) {
          //   const [major, minor] = this.$browser.getOSVersion().split(".");
          //   const OSVersion = +`${major}.${minor}`;
          //   return !this.$isSafari && OSVersion < 14.3;
          // }
          // return false;
          return this.$isIOS && !this.$isSafari;
        },
      },
      methods: {
        $checkBrowser: browserCheck.checkBrowser,
        $getBrowserVersion: browserCheck.getVersion,
      },
    });
  },
};

Vue.use(browserCheckPlugin);
