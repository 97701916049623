/* eslint-disable max-len */
/**
 * Константы используемые в утилитах
 * @namespace UtilsConsts
 */
/**
 * @memberof UtilsConsts
 * @const
 * @type {RegExp}
 * @default
 */
export const VALID_MAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * @memberof UtilsConsts
 * @const
 * @type {RegExp}
 * @default
 */
export const GLOBAL_EMAIL_REGEXP = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
