export const wsErrorType = {
  /* Common errors */
  LOGIN_ERROR: "LOGIN_ERROR",
  SERVER_ERROR: "SERVER_ERROR",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  EXISTS_ERROR: "EXISTS_ERROR",
  SOCKET_ERROR: "SOCKET_ERROR",
  NETWORK_ERROR: "NETWORK_ERROR",
  /* Complex actions errors */
  // store/modules/common/actions
  ROOM_ENTER_ERROR: "ROOM_ENTER_ERROR",
  ROOM_ENTER_ERROR_NO_ACCESSIBILITY: "ROOM_ENTER_ERROR_NO_ACCESSIBILITY",
  ROOM_ENTER_ERROR_NO_CHANGED_FOR_USER: "ROOM_ENTER_ERROR_NO_CHANGED_FOR_USER",
  ROOM_ENTER_ERROR_NO_GUEST_ALLOWED: "ROOM_ENTER_ERROR_NO_GUEST_ALLOWED",
  ROOM_INFO_ERROR: "ROOM_INFO_ERROR",
  ROOM_INFO_ERROR_NO_NUMBER: "ROOM_INFO_ERROR_NO_NUMBER",
  ROOM_INFO_ERROR_NO_DATA: "ROOM_INFO_ERROR_NO_DATA",
  ROOM_INFO_ERROR_FAIL_GET_ROOM_TIMER: "ROOM_INFO_ERROR_FAIL_GET_ROOM_TIMER",
  ROOM_INFO_ERROR_FAIL_GET_MOSCOW_TIME: "ROOM_INFO_ERROR_FAIL_GET_MOSCOW_TIME",
  // store/modules/auth/actions
  AUTH_ERROR_FAIL_LOGIN_BY_PASS: "AUTH_ERROR_FAIL_LOGIN_BY_PASS",
  AUTH_ERROR_FAIL_LOGIN_BY_SURNAME: "AUTH_ERROR_FAIL_LOGIN_BY_SURNAME",
  AUTH_ERROR_FAIL_LOGIN_BY_TOKEN: "AUTH_ERROR_FAIL_LOGIN_BY_TOKEN",
  AUTH_ERROR_FAIL_GET_USER_DATA: "AUTH_ERROR_FAIL_GET_USER_DATA",
  AUTH_ERROR_EXPIRED_TOKEN: "AUTH_ERROR_EXPIRED_TOKEN",
  AUTH_ERROR_FAIL_UPDATE_BY_TOKEN: "AUTH_ERROR_FAIL_UPDATE_BY_TOKEN",
  AUTH_ERROR_FAIL_RESET_USER_DATA: "AUTH_ERROR_FAIL_RESET_USER_DATA",
  AUTH_ERROR_TIMEOUT_WAIT_LANDING: "AUTH_ERROR_TIMEOUT_WAIT_LANDING",
  REGISTER_ERROR_FAIL_BY_SURNAME: "AUTH_ERROR_FAIL_REGISTER_BY_SURNAME",
  // store/modules/brainstorm/actions
  BRAINSTORM_ERROR_FAIL_GET_ROUNDED_TABLES: "BRAINSTORM_ERROR_FAIL_GET_ROUNDED_TABLES",
  BRAINSTORM_ERROR_FAIL_SET_TABLE_FOR_USER: "BRAINSTORM_ERROR_FAIL_SET_TABLE_FOR_USER",
  BRAINSTORM_ERROR_FAIL_GET_BRAINSTORMS: "BRAINSTORM_ERROR_FAIL_GET_BRAINSTORMS",
  BRAINSTORM_ERROR_FAIL_UPDATE_IDEA: "BRAINSTORM_ERROR_FAIL_UPDATE_IDEA",
  BRAINSTORM_ERROR_FAIL_SWITCH_IDEA_LIKE: "BRAINSTORM_ERROR_FAIL_SWITCH_IDEA_LIKE",
  BRAINSTORM_ERROR_FAIL_SEND_IDEA: "BRAINSTORM_ERROR_FAIL_SEND_IDEA",
  BRAINSTORM_ERROR_FAIL_DELETE_IDEA: "BRAINSTORM_ERROR_FAIL_DELETE_IDEA",
};
const errorUserMessage = {
  [wsErrorType.LOGIN_ERROR]: "Данные введены неверно.",
  [wsErrorType.SERVER_ERROR]: "Произошла ошибка со стороны сервера.",
  [wsErrorType.UNKNOWN_ERROR]: "Произошла непредвиденная ошибка.",
  [wsErrorType.EXISTS_ERROR]: "Ошибка: значение уже используется.",
  [wsErrorType.SOCKET_ERROR]: "Произошла ошибка при обработке сообщения от сокета.",
  [wsErrorType.NETWORK_ERROR]: "Произошла ошибка во время выполнения запроса.",

  [wsErrorType.ROOM_ENTER_ERROR]: "Произошла ошибка при входе в комнату.",
  [wsErrorType.ROOM_ENTER_ERROR_NO_ACCESSIBILITY]: "Попытка входа в комнату, которая не доступна.",
  [wsErrorType.ROOM_ENTER_ERROR_NO_CHANGED_FOR_USER]:
    "Произошла ошибка при сохранении комнаты в профиле пользователя.",
  [wsErrorType.ROOM_INFO_ERROR]: "Произошла ошибка запроса информации по комнате.",
  [wsErrorType.ROOM_INFO_ERROR_NO_NUMBER]:
    "Произошла ошибка запроса информации по комнате: не обнаружен номер комнаты.",
  [wsErrorType.ROOM_INFO_ERROR_NO_DATA]:
    "Произошла ошибка запроса информации по комнате: нет данных.",
  [wsErrorType.ROOM_ENTER_ERROR_NO_GUEST_ALLOWED]: "Попытка входа в комнату с авторизацией гостем.",
  [wsErrorType.ROOM_INFO_ERROR_FAIL_GET_ROOM_TIMER]:
    "Произошла ошибка запроса данных таймера для комнаты.",
  [wsErrorType.ROOM_INFO_ERROR_FAIL_GET_MOSCOW_TIME]: "Произошла ошибка запроса времени по МСК.",

  [wsErrorType.AUTH_ERROR]: "Произошла ошибка авторизации пользователя.",
  [wsErrorType.AUTH_ERROR_FAIL_GET_USER_DATA]:
    "Произошла ошибка при получении данных пользователя.",
  [wsErrorType.AUTH_ERROR_EXPIRED_TOKEN]:
    "Произошла ошибка авторизации пользователя: токен просрочен.",
  [wsErrorType.AUTH_ERROR_FAIL_UPDATE_BY_TOKEN]: "Произошла ошибка при обновлении токена.",
  [wsErrorType.AUTH_ERROR_FAIL_RESET_USER_DATA]:
    "Произошла ошибка при очистке данных пользователя.",
  [wsErrorType.AUTH_ERROR_TIMEOUT_WAIT_LANDING]:
    "Произошла ошибка авторизации пользователя: нет данных лендинга.",
  [wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_PASS]:
    "Произошла ошибка авторизации пользователя по паролю.",
  [wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_SURNAME]:
    "Произошла ошибка авторизации пользователя по фамилии.",
  [wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_TOKEN]:
    "Произошла ошибка авторизации пользователя по токену.",
  [wsErrorType.REGISTER_ERROR_FAIL_BY_SURNAME]:
    "Произошла ошибка регистрации пользователя по Фамилии и Имени",

  [wsErrorType.BRAINSTORM_ERROR_FAIL_GET_ROUNDED_TABLES]:
    "Брейншторм: ошибка загрузки данных по столам.",
  [wsErrorType.BRAINSTORM_ERROR_FAIL_SET_TABLE_FOR_USER]:
    "Брейншторм: ошибка размещения пользователя за столом.",
  [wsErrorType.BRAINSTORM_ERROR_FAIL_GET_BRAINSTORMS]: "Брейншторм: ошибка получения данных.",
  [wsErrorType.BRAINSTORM_ERROR_FAIL_UPDATE_IDEA]: "Брейншторм: ошибка обновлении идеи.",
  [wsErrorType.BRAINSTORM_ERROR_FAIL_SWITCH_IDEA_LIKE]:
    "Брейншторм: ошибка переключения like для идеи.",
  [wsErrorType.BRAINSTORM_ERROR_FAIL_SEND_IDEA]: "Брейншторм: ошибка отправки идеи.",
  [wsErrorType.BRAINSTORM_ERROR_FAIL_DELETE_IDEA]: "Брейншторм: ошибка удаления идеи.",
};

export class ErrorWS {
  constructor(type, data) {
    if (!wsErrorType[type]) {
      console.error(
        `this ${type} error type doesn't exist,
            please create new or choose one from available types:`.trim(),
        Object.keys(wsErrorType),
      );
    }
    this.type = type;
    this.data = data;
    this.userMessage = errorUserMessage[type];
  }

  getCommonUserMessage() {
    return this.userMessage;
  }

  getCustomErrorMessage(message) {
    return `${this.userMessage}: ${message}, ${this.data}`;
  }
}

const dev = process.env.NODE_ENV !== "production";

export class WSCustomError extends Error {
  constructor(type, data, notThrow) {
    super(errorUserMessage[type] || errorUserMessage[wsErrorType.UNKNOWN_ERROR]);
    this.name = "WSCustomError";
    this.type = type;

    if (data) {
      /**
       * We can simply override the default message if this is not a native error,
       * and there is only a message.
       */
      if (typeof data === "string") {
        this.message = data;
      }

      if (typeof data === "object") {
        /**
         * We use an array for "subType" to save / track the error chain
         * to the final handler with the type in "type" field.
         */
        if (data.subType) {
          this.subType = Array.isArray(data.subType) ? data.subType : [data.subType];
        }

        /* Save native browser error. */
        if (data.name) {
          this.original = {
            type: data.name, // ReferenceError, SyntaxError,....
            message: data.message,
            stack: data.stack,
          };
        }

        /* Save server error. */
        if (data.server) {
          this.server = {
            code: +data.server.error_code, // number: 0, 1, 2,...
            message: data.server.error,
            status: data.server.status,
          };
        }
      }
    }

    if (!wsErrorType[type]) {
      console.error(
        `WSCustomError: this "${type}" error type doesn't exist,
        please create new or choose one from available types:`.trim(),
        Object.keys(wsErrorType),
      );
    } else if (!notThrow && dev) {
      console.error(this.toString());
    }
  }

  /**
   * We use -1 as the default value, because 0 is a valid code value
   * and further codes will most likely be used to get a text label-code from an array,
   * where 0 is also valid.
   */
  get serverCode() {
    return this?.server.code ?? -1;
  }

  get status() {
    return this?.server.status ?? 0;
  }

  setParentType(type) {
    if (type !== this.type) {
      this.subType = this.subType ? [...this.subType, this.type] : [this.type];
      this.type = type;
      this.message = `${this.name}: ${errorUserMessage[type] ||
        errorUserMessage[wsErrorType.UNKNOWN_ERROR]}`;
    }
  }

  hasType(type) {
    return this.type === type || (this.subType && this.subType.includes(type));
  }

  toString() {
    return [
      `${this.name}::${this.type} - ${this.message}`,
      this.original ? `\n[original error]\n${this.original.stack}` : "",
      this.server ? `\n[server error]\n${JSON.stringify(this.server)}` : "",
    ].join("");
  }
}
