import {
  SET_MESSENGER_ANONYMOUS,
  SET_MESSENGER_TAB,
} from "@/store/modules/messenger/mutation-types";

export default {
  state: {
    anonymous: true,
    messengerTab: "MainChatList",
  },
  mutations: {
    [SET_MESSENGER_ANONYMOUS](state, payload) {
      state.anonymous = payload;
    },
    [SET_MESSENGER_TAB](state, tab) {
      state.messengerTab = tab;
    },
  },
};
