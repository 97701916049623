import {
  ADD_ANSWERED_QUESTION,
  ADD_SKIPPED_QUESTION,
  CLEAR_CURRENT_ACTIVE_GAME,
  INCREMENT_CURRENT_QUESTION_INDEX,
  REMOVE_SKIPPED_QUESTION,
  SET_AVAILABLE_GAMES,
  SET_CURRENT_ACTIVE_GAME,
  SET_CURRENT_QUESTION_INDEX,
  SET_KING_LEADERS_LIST,
  SET_KING_POINTS,
  SET_LAST_GAME_QUESTION_TIME,
  SET_POST_GAME_SCREEN_VISIBLE,
  SET_KING_RESULT,
} from "@/store/modules/games/mutation-types";
import { GET_SCORES } from "@/store/modules/games/action-types";

export default {
  [SET_AVAILABLE_GAMES](state, data) {
    state.availableGames = data;
  },
  [SET_KING_LEADERS_LIST](state, data) {
    state.king.leadersList = data;
  },
  [SET_CURRENT_ACTIVE_GAME](state, data) {
    state.currentActiveGame = {
      name: "king",
      pollsList: data,
      currentQuestionIndex: 0,
      type: "closed",
      postGameScreen: true,
      postGameScreenVisible: false,
      postGameScreenAction: GET_SCORES,
      answeredQuestions: [],
      skippedQuestions: [],
      gameTime:
        (state.availableGames &&
          state.availableGames.king &&
          state.availableGames.king.timer * 60) ||
        0,
      lastQuestionTime: null,
    };
  },
  [INCREMENT_CURRENT_QUESTION_INDEX](state) {
    state.currentActiveGame.currentQuestionIndex++;
  },
  [SET_CURRENT_QUESTION_INDEX](state, index) {
    state.currentActiveGame.currentQuestionIndex = index;
  },
  [SET_POST_GAME_SCREEN_VISIBLE](state, visible = true) {
    state.currentActiveGame.postGameScreenVisible = visible;
  },
  [SET_LAST_GAME_QUESTION_TIME](state, time) {
    state.currentActiveGame.lastQuestionTime = time || null;
  },
  [SET_KING_POINTS](state, points) {
    state.king.points = points;
  },
  [ADD_ANSWERED_QUESTION](state, index) {
    let newArray = Object.assign([], state.currentActiveGame.answeredQuestions);
    if (newArray.indexOf(index) === -1) {
      newArray.push(index);
      state.currentActiveGame.answeredQuestions = newArray;
    }
  },
  [ADD_SKIPPED_QUESTION](state, index) {
    let newArray = Object.assign([], state.currentActiveGame.skippedQuestions);
    if (newArray.indexOf(index) === -1) {
      newArray.push(index);
      state.currentActiveGame.skippedQuestions = newArray;
    }
  },
  [REMOVE_SKIPPED_QUESTION](state, index) {
    let newArray = Object.assign([], state.currentActiveGame.skippedQuestions);
    let indexOfindex = newArray.indexOf(index);
    if (indexOfindex > -1) {
      newArray.splice(indexOfindex, 1);
    }
    state.currentActiveGame.skippedQuestions = newArray;
  },
  [CLEAR_CURRENT_ACTIVE_GAME](state) {
    state.currentActiveGame = {
      name: "",
      pollsList: [],
      currentQuestionIndex: 0,
      pollType: "",
      postGameScreen: false,
      postGameScreenVisible: false,
      postGameScreenAction: "",
      answeredQuestions: [],
      skippedQuestions: [],
      gameTime: 0,
      lastQuestionTime: null,
    };
  },
  [SET_KING_RESULT](state, data) {
    state.gameKingResult = data;
  },
};
