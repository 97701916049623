import {
  SET_POSTS,
  UPDATE_POSTS,
  ADD_POST,
  CLEAR_POSTS,
  SET_COMPLETE,
} from "./mutation-types";

export default {
  [SET_POSTS](state, posts) {
    if (posts.length > 0) {
      state.lastPostId = posts[posts.length - 1].id;
      state.posts = [...state.posts, ...posts];
    }
  },
  [UPDATE_POSTS](state, post) {
    const index = state.posts.findIndex(item => {
      return item.id.toString() === post.id.toString();
    });
    const newPostsList = [...state.posts];
    if (index > -1) {
      newPostsList.splice(index, 1, post);
    } else {
      newPostsList.push(post);
    }
    state.posts = [...newPostsList];
  },
  [ADD_POST](state, post) {
    state.posts = [...state.posts, post];
    state.lastPostId = post.id;
  },
  [CLEAR_POSTS](state) {
    state.posts = [];
  },
  [SET_COMPLETE](state, val) {
    state.isComplete = val;
  },
};
