<template>
  <div class="agreement">
    <label for="processingPersonData" class="agreement__box">
      <input
        id="processingPersonData"
        data-testid="processingPersonData"
        :checked="agreement"
        class="agreement__input"
        type="checkbox"
        @change="agreementClickHandler"
      />
      <span class="agreement__text">
        {{ $t("Login.processingPersonData") }}
        <router-link class="agreement__link" target="_blank" to="/personal-data-agreement">{{
          $t("Login.processingPersonDataLink")
        }}</router-link>
      </span>
    </label>
  </div>
</template>

<script>
/**
 * Agreement component for personal data agreement checkbox on login or corp login page.
 * Color of agreement text depends of landing theme
 */
export default {
  name: "Agreement",
  model: {
    prop: "agreement",
    event: "input",
  },
  props: {
    /**
     * Value for checkbox input
     */
    agreement: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /**
     * Upgrade agreement by click on checkbox
     */
    agreementClickHandler() {
      /**
       * Event for upgrade v-model binding
       *
       * @event input
       * @type {boolean}
       * @property {boolean} agreement value
       */
      this.$emit("input", !this.agreement);
    },
  },
};
</script>

<style lang="less" scoped>
.agreement {
  font-size: 10px;

  &__box {
    display: flex;
    margin-top: 10px;
  }

  &__input {
    margin: 0 3px 0 0;
  }

  &__text {
    font-size: inherit;
    color: var(--login-input-color);
  }

  &__link {
    color: #07a5dc;
  }
}
</style>
