/* eslint-disable */
import bowser from "bowser";

const SUPPORT_BROWSER = {
  Chrome: ">=65",
  Firefox: ">=65",
  safari: ">=11",
  "Microsoft Edge": ">14",
  "Yandex Browser": ">13",
  Opera: ">59",
  "Android Browser": ">5",
  "Samsung Internet": ">8.2",
};
const NOT_SUPPORT_BROWSER = [
  "Opera Mini",
  "Opera Mobile",
  "Blackberry Browser",
  "Internet Explorer",
  "Internet Explorer Mobile",
];

const OS = {
  ANDROID: "Android",
  IOS: "iOS",
};
const PLATFORMS = {
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop",
};

export const browser = bowser.getParser(window.navigator.userAgent);

export const isSupportBrowser = () => browser.satisfies(SUPPORT_BROWSER);
export const isNotSupportBrowser = () => NOT_SUPPORT_BROWSER.includes(browser.getBrowserName());

export const isMobile = () => browser.isPlatform(PLATFORMS.MOBILE);
export const isTablet = () => browser.isPlatform(PLATFORMS.TABLET);
export const isDesktop = () => browser.isPlatform(PLATFORMS.DESKTOP);

export const isAndroid = () => browser.isOS(OS.ANDROID);
export const isIOS = () => browser.isOS(OS.IOS);

export const isMobileIOS = () => isMobile() && isIOS();
export const isIPad = () => browser.test(/iPad/);

export const checkBrowser = browserName => browser.isBrowser(browserName);

export const isSafari = () => checkBrowser("Safari");
export const isChrome = () => checkBrowser("Chrome");
export const isEdge = () => checkBrowser("Microsoft Edge");
export const isFirefox = () => checkBrowser("Firefox");

export const hasWebRTC = () => !!navigator.mediaDevices;
export const isSafariWebRTC = () => isSafari() && hasWebRTC();

export const hasScreenCapture = () => isDesktop() && (isChrome() || isEdge() || isFirefox()) && (browserVersion() >= 74);

export const isNotSupportStream = () => {
  const [major, minor, build] = getVersion();
  const notSupportVer = major >= 14 && minor>=0 && build >= 1;
  return !isIOS() && isDesktop() && isSafari() && notSupportVer;
};

export const browserVersion = () => {
  const [majorVersion] = browser.getBrowserVersion().split(".");
  return parseInt(majorVersion, 10) || 0;
}

export const isCordova = () => !!window.cordova;

export const getVersion = () => browser.getBrowserVersion().split(".");
