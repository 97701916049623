import {
  polls,
  presentation,
  room,
  socket,
} from "@/store/modules/store.namespaces";
import { GET_ROOM_INFO } from "@/store/modules/common/action-types";
import {
  CLEAR_POLLCHAIN,
  SET_INTERACTIVE_TYPE,
} from "@/store/modules/polls/mutation-types";
import { interactive } from "@/constants/polls/polls-module-types";
import {
  GET_TEST_POLLS,
  RUN_POLL,
  SHOW_POLL_SCORE,
} from "@/store/modules/polls/action-types";
import {
  CHANGE_BRAINSTORM_STATUS,
  CLOSE_QUIZ,
  CURRENT_TEST,
  PIN,
  PRESENTATION,
  QUESTION,
  QUESTION_DISLIKE,
  QUESTION_MARK,
  RUN_QUIZ,
  SET_BRAINSTORM,
  SHOW_QUIZ_RESULT,
  SLIDE,
} from "@/store/modules/socket/action-types";
import { SET_PINBAR } from "@/store/mutations/mutation-types";
import {
  GET_PRESENTATIONS,
  SET_SLIDE,
  SET_SLIDE_TIME,
} from "@/store/modules/presentation/action-types";
import {
  CURRENT_PRESENTATION_SLIDE,
  CURRENT_PRESENTATION_SLIDE_ID,
} from "@/store/modules/presentation/getter-types";

export default {
  actions: {
    async [SLIDE]({ dispatch, rootState, rootGetters }, data) {
      const { Slide = 1, Presentation = null } = data.obj;
      if (!rootState.common.roomInfo.Online_room || !Presentation) {
        return false;
      }
      const slideIndex = Slide;
      if (
        rootState.presentation.currentPresentationInfo.slideIndex === slideIndex
      ) {
        const isTimerSlide =
          rootGetters[`${presentation}/${CURRENT_PRESENTATION_SLIDE}`].type ===
          "timer";
        if (isTimerSlide) {
          const slideId =
            rootGetters[`${presentation}/${CURRENT_PRESENTATION_SLIDE_ID}`];
          await dispatch(`${presentation}/${SET_SLIDE_TIME}`, slideId, {
            root: true,
          });
          await dispatch(`${presentation}/${GET_PRESENTATIONS}`, undefined, {
            root: true,
          });
        }
        return;
      }
      dispatch(
        `${presentation}/${SET_SLIDE}`,
        {
          presentationId: Presentation,
          slideIndex,
        },
        { root: true },
      );
    },
    [PRESENTATION]({ dispatch, commit, rootState }, data) {
      const { Slide = 1, Presentation = null } = data.obj;
      if (!Presentation || !rootState.common.roomInfo.Online_room) {
        return;
      }
      dispatch(
        `${presentation}/${SET_SLIDE}`,
        {
          presentationId: Presentation,
          slideIndex: Slide,
        },
        { root: true },
      );
      commit(
        `${polls}/${CLEAR_POLLCHAIN}`,
        {
          type: interactive,
        },
        { root: true },
      );
      commit(`${polls}/${interactive}/${SET_INTERACTIVE_TYPE}`, null, {
        root: true,
      });
    },
    [QUESTION_MARK]({ dispatch }) {
      dispatch(`${socket}/${QUESTION}`, null, { root: true });
    },
    [QUESTION_DISLIKE]({ dispatch }) {
      dispatch(`${socket}/${QUESTION}`, null, { root: true });
    },
    [RUN_QUIZ]({ dispatch }, data) {
      if (data.obj && data.obj.show_projector_result) {
        dispatch(
          `${polls}/${SHOW_POLL_SCORE}`,
          {
            type: interactive,
            payload: data.obj,
            isScreen: true,
          },
          { root: true },
        );
      } else {
        dispatch(
          `${polls}/${RUN_POLL}`,
          {
            type: interactive,
            payload: data,
            isScreen: true,
          },
          { root: true },
        );
      }
    },
    [CLOSE_QUIZ]({ dispatch }) {
      dispatch(`${socket}/${CLOSE_QUIZ}`, { isScreen: true }, { root: true });
    },
    [SHOW_QUIZ_RESULT]({ dispatch }, data) {
      dispatch(
        `${socket}/${SHOW_QUIZ_RESULT}`,
        { ...data, isScreen: true },
        { root: true },
      );
    },
    [PIN]({ commit }, data) {
      commit(SET_PINBAR, data, { root: true });
    },
    [SET_BRAINSTORM]({ dispatch }) {
      dispatch(`${socket}/${SET_BRAINSTORM}`, null, { root: true });
      dispatch(`${room}/${GET_ROOM_INFO}`, null, { root: true });
    },
    [CHANGE_BRAINSTORM_STATUS]({ dispatch }) {
      dispatch(SET_BRAINSTORM);
    },
    async [CURRENT_TEST]({ dispatch }, data) {
      if (data.obj.currentTest === 0) {
        await dispatch(CLOSE_QUIZ);
      } else {
        await dispatch(
          `${polls}/${GET_TEST_POLLS}`,
          {
            type: interactive,
            payload: {
              id: data.obj.currentTest,
            },
            isScreen: true,
          },
          { root: true },
        );
      }
    },
  },
};
