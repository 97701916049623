import axios from "axios";
import config from "@/settings/config";

export default {
  /**
   * Метод создания доски.
   *
   * @param {object} data - объект содержащий информацию о номере комнаты/id презентации/доске миры
   */
  createBoard({ dispatch }, data) {
    const options = {
      method: "POST",
      url: "/miro/v1/boards",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer WRN6rf5cjvExWF7b0kBYq5eIogA",
      },
      data: data?.boardParams,
    };

    axios
      .request(options)
      .then(response => dispatch("setBoard", { ...data, boardId: response?.data?.id }))
      .catch(error => console.error(error));
  },

  /**
   * Метод записи доски на бэк
   *
   * @param {object} payload - объект содержащий информацию о номере комнаты/id презентации/id доски миры
   */
  setBoard({ commit }, payload) {
    axios
      .post(`${config.serverUrl}/web/api/set-miro-board`, payload)
      .then(() => commit("common/setBoardId", payload.boardId, { root: true }))
      .catch(error => console.warn(error));
  },
};
