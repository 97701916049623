<template>
  <form :class="$style.container" v-on="$listeners">
    <template v-if="!hideForm">
      <div v-if="!!promoText" :class="$style.promo">
        {{ promoText }}
      </div>
      <div :class="$style.form">
        <slot />
      </div>
      <div v-if="$slots.additions" :class="$style.additions">
        <slot name="additions" />
      </div>
    </template>
    <div :class="$style.footer">
      <div v-if="$slots.error" :class="$style.error">
        <slot name="error" />
      </div>
      <loader v-else-if="loading" />
    </div>
  </form>
</template>

<script>
import Loader from "@/components/common/elements/Loader";

/**
 * Представление для компонент логина, регистрации и входа в комнату
 * @vue-prop {Boolean} loading - Показать лоадер под формой
 * @vue-prop {Boolean} hideForm - Скрывает содержимое формы
 * @vue-prop {String} promoText - Промо текст внутри формы
 */

export default {
  name: "LayoutAuthForm",
  components: { Loader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hideForm: {
      type: Boolean,
      default: false,
    },
    promoText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" module>
.container,
.form {
  display: grid;
  grid-gap: 10px;
}

.promo {
  color: var(--login-text-color);
  text-align: center;
}

.additions {
  font-size: 10px;
  text-align: center;

  & a {
    color: var(--login-button-color);
  }
}

.footer {
  position: relative;
}

.error {
  margin-bottom: 15px;
  color: $danger;
  text-align: center;
}
</style>