import moment from "moment";
import { i18n } from "@/utils/i18n-setup";
import { DATES_SORT, EVENTS, HALLS_SORT } from "@/store/modules/schedule/getter-types";

const ALL_HALLS = "all";
const prepareEvents = (events, hall) =>
  events.map(event => ({
    ...event,
    hall_name: hall.name,
    hall_id: hall.id,
  }));
const makeDatesSort = dates =>
  dates.map(date => ({
    label: moment(new Date(date.date_utc)).format("DD MMMM"),
    id: date.id,
    date: date.date_utc,
  }));
export default {
  [DATES_SORT](state) {
    return hallId => {
      if (hallId === ALL_HALLS) {
        return makeDatesSort(
          state.schedule.halls?.reduce((accum, hall) => {
            hall.data.forEach(date => {
              if (!accum.some(item => item.id === date.id))
                accum.push({ date_utc: date.date_utc, id: date.id });
            });
            return accum;
          }, []) ?? [],
        );
      }
      return makeDatesSort(state.schedule.halls?.find(item => item.id === hallId).data ?? []);
    };
  },
  [HALLS_SORT](state) {
    return [
      { label: i18n.t("SpeakerAgenda.allHalls"), id: ALL_HALLS },
      ...(state.schedule.halls?.map(value => ({
        label: value.name,
        id: value.id,
      })) ?? []),
    ];
  },
  [EVENTS](state) {
    return (currentDateId, hallId) => {
      if (hallId === ALL_HALLS) {
        return (
          state.schedule.halls?.reduce((accum, current) => {
            const events = current.data
              .filter(item => item.id === currentDateId)
              .reduce((accum, date) => [...accum, ...prepareEvents(date.events, current)], []);
            return [...accum, ...events];
          }, []) ?? []
        );
      }
      const hallsById =
        state.schedule.halls?.reduce((accum, current) => {
          if (!accum[current.id]) {
            accum[current.id] = current;
          }
          return accum;
        }, {}) ?? {};
      const hall = hallsById[hallId];
      return (
        hall?.data
          .filter(item => item.id === currentDateId)
          .reduce((accum, date) => [...accum, ...prepareEvents(date.events, hall)], []) ?? []
      );
    };
  },
};
