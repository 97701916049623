import pluralsDictionary from "@/languages/plurals";
import config from "@/settings/config";
import { GLOBAL_EMAIL_REGEXP } from "@/constants/utils/utils-const";

const HTTP_REGEX = new RegExp("^(?:[a-z]+:)?//", "i");

/**
 * Функции используемые в утилитах
 *
 * @namespace UtilsFunctions
 */
/**
 * Подготавливает ссылку исходя из того ссылка на сторадж это или ссылка на админку
 * У ссылки на админку нет https
 *
 * @param {string} [url=""] - исходный урл
 * @returns {string} - подготовленная ссылка
 */
export const makeUrl = (url = "") =>
  (url && (HTTP_REGEX.test(url) ? url : `${config.serverUrl}${url}`)) || "";

const onPlay = function(event) {
  const medias = Array.prototype.slice.apply(document.querySelectorAll("audio,video"));
  medias.forEach(function(media) {
    const isStreamMedia = !!(
      media.closest("#conferencePlayer") || media.parentElement.id === "broadcastPlayer"
    );
    if (event.target !== media && !isStreamMedia) {
      media.pause();
    }
  });
};

export const setPlayEvents = () => {
  const medias = Array.prototype.slice.apply(document.querySelectorAll("audio,video"));
  medias.forEach(function(media) {
    const isStreamMedia = !!(
      media.closest("#conferencePlayer") || media.parentElement.id === "broadcastPlayer"
    );
    if (!isStreamMedia) {
      media.removeEventListener("play", onPlay);
      media.addEventListener("play", onPlay);
    }
  });
};

/**
 * @param func
 * @param ms
 */
export function throttle(func, ms) {
  let isThrottled = false;
  let savedArgs;
  let self;

  /**
   * @param {...any} args
   */
  function wrapper(...args) {
    if (isThrottled) {
      // (2)
      savedArgs = args;
      self = this;
      return;
    }

    func.apply(this, args); // (1)

    isThrottled = true;

    setTimeout(function() {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(self, savedArgs);
        savedArgs = null;
        self = null;
      }
    }, ms);
  }

  return wrapper;
}

/**
 * @param item
 */
export function encodeStringify(item) {
  return encodeURIComponent(JSON.stringify(item));
}

/**
 * @param item
 */
export function parseDecode(item) {
  return JSON.parse(decodeURIComponent(item));
}

/**
 * @param key
 * @param num
 * @param locale
 */
export function i18nPlurals(key, num, locale) {
  const wordsArray = (pluralsDictionary[locale] && pluralsDictionary[locale][key]) || ["", "", ""];
  if (num % 1 !== 0) {
    return wordsArray[1];
  }
  num %= 100;
  if (num > 10 && num < 20) return wordsArray[2];
  num %= 10;
  return wordsArray[num > 1 && num < 5 ? 1 : num === 1 ? 0 : 2];
}

/**
 * @param url
 */
export function checkImage(url) {
  return new Promise((resolve, reject) => {
    if (!url) reject();
    const img = new Image();
    img.src = makeUrl(url);
    img.addEventListener("load", resolve.bind(this, img.src));
    img.addEventListener("error", resolve.bind(this, ""));
  });
}

/**
 * Скрывает самый главный прелоадер
 *
 * @memberof UtilsFunctions
 */
export function hidePreloader() {
  const preloader = document.getElementById("preloader");
  preloader.style.display = "none";
  preloader.style.zIndex = "0";
}

export const copyObj = obj => JSON.parse(JSON.stringify(obj));

/**
 * Преобразует ссылки которые содержаться в строке в html ссылки
 *
 * @param {string} [string=""] - входная строка которая содержит ссылки для преобразованя
 * @returns {string} - строка как html
 */
export const stringToHtmlLink = (string = "") =>
  (string &&
    string
      .replace(/(https?:\/\/[^ |\n|\r"]+)/gi, str => `<a href="${str}" target="_blank">${str}</a>`)
      .replace(GLOBAL_EMAIL_REGEXP, str => `<a href="mailto:${str}">${str}</a>`)) ||
  "";
