import ViewAuthLogin from "@/views/auth/ViewAuthLogin";
import ViewAuth from "@/views/auth/ViewAuth";

const ViewAuthLobby = () =>
  import(/* webpackChunkName:"ViewAuthLobby" */ "../views/auth/ViewAuthLobby");
const ViewAuthRestorePassword = () =>
  import(/* webpackChunkName:"RootRestorePassword" */ "../views/auth/ViewAuthRestorePassword");
const ViewAuthRegistration = () =>
  import(/* webpackChunkName:"RootRegistration" */ "../views/auth/ViewAuthRegistration");
const PDAgreement = () =>
  import(/* webpackChunkName:"RootPDAgreement" */ "../components/event/PDAgreement");
const UserSystemTest = () =>
  import(/* webpackChunkName:"RootUserSystemTest" */ "../components/event/UserSystemTest");
const RoomLimit = () => import(/* webpackChunkName:"RootRoomLimit" */ "@/components/RoomLimit");
const ViewCorpLogin = () => import(/* webpackChunkName:"ViewCorpLogin" */ "@/views/ViewCorpLogin");

export default [
  {
    path: "/",
    name: "auth",
    redirect: { name: "Login" },
    component: ViewAuth,
    children: [
      {
        path: "(|login|auth)",
        name: "Login",
        component: ViewAuthLogin,
        props: route => ({
          token: route.query.token,
          redirect: route.query.redirect,
          owner: route.query.owner && JSON.parse(route.query.owner),
        }),
      },
      {
        path: "/registration",
        name: "Registration",
        component: ViewAuthRegistration,
      },
      {
        path: "lobby",
        name: "lobby",
        component: ViewAuthLobby,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/restorePassword",
        name: "restorePassword",
        component: ViewAuthRestorePassword,
      },
    ],
  },
  {
    path: "/(guest|qr)/:guestRoom",
    name: "Guest",
  },
  {
    path: "/room-limit",
    name: "roomLimit",
    component: RoomLimit,
    props: route => ({
      status: route.params.status,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test-system",
    name: "TestUserSystem",
    component: UserSystemTest,
  },
  {
    path: "/personal-data-agreement",
    name: "pdAgreement",
    component: PDAgreement,
  },
  {
    path: "/corp-login",
    name: "corpLogin",
    component: ViewCorpLogin,
  },
];
