export const SET_ROUNDED_TABLES = "SET_ROUNDED_TABLES";
export const SET_BRAINSTORM_IDEAS = "SET_BRAINSTORM_IDEAS";
export const SET_BRAINSTORM_IDEAS_IS_LOADING =
  "SET_BRAINSTORM_IDEAS_IS_LOADING";
export const SET_ROOM_BRAINSTORMS = "SET_ROOM_BRAINSTORMS";
export const REMOVE_BRAINSTORM_IDEA = "REMOVE_BRAINSTORM_IDEA";
export const UPDATE_BRAINSTORM_IDEA = "UPDATE_BRAINSTORM_IDEA";
export const SET_CURRENT_BRAINSTORM_TAB = "SET_CURRENT_BRAINSTORM_TAB";
export const CLEAR_USER_TABLE = "CLEAR_USER_TABLE";
export const SET_BRAINSTORM_IDEA_ID = "SET_BRAINSTORM_IDEA_ID";
export const SET_BRAINSTORM_FILTER = "SET_BRAINSTORM_FILTER";
export const SET_USER_TABLE_ID = "SET_USER_TABLE_ID";
