import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { SET_VIDEO_LOG } from "@/store/actions/action-types";

const actions = {
  [SET_VIDEO_LOG]({ state }) {
    AdminWebHttpService.post(
      `SetVideoStreamLog/${state.common.roomNumber}/video/${
        state.presentation.currentPresentationInfo.id
      }`,
    );
  },
};
export default actions;
