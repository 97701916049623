/* eslint-disable import/prefer-default-export */
export const ROOM_NUMBER = "_ws_room_number";

export const DASHBOARD_COLORS = {
  online: "#7cde10",
  presence: "#f44336",
  question: "#2196f3",
  quiz: "#00c853",
  test: "#e38908",
  game_king: "#7f0964",
  achieve: "#f44336",
  rewards: "#7f0964",
  additional: "#2196f3",
  training: "#9c27b0",
  sum: "#ff00c8",
  def: "#c0c0c0",
  brainstorm: "#6612dc",
};

export const ROOM_LIMIT_MESSAGE_KEYS = [
  "roomIsInaccessible",
  "wrongToken",
  "roomIsNotAvailableNow",
  "eventEnds",
  "roomIsNotExisted",
  "roomIsNotAttachedToEvent",
  "eventIsNotExisted",
  "roomOvercrowded",
  "eventIsRemoved",
  "roomIsRemoved",
  "connectionLimitForCompany",
];
