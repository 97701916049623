import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    unreadNotifs: 0,
    notifications: [],
    page: 0,
    pagesCount: 0,
    lastNotification: {},
    lastSystemNotification: {},
  },
  actions,
  mutations,
};
