import Vue from "vue";

const reactorComponent = new Vue({
  data: () => ({
    isLandscape: true,
  }),
});

const mqLandscape = window.matchMedia("(orientation: landscape)");
reactorComponent.isLandscape = mqLandscape.matches;
mqLandscape.addListener(m => {
  reactorComponent.isLandscape = m.matches;
});

const detectOrientationPlugin = {
  install(Vue) {
    Vue.mixin({
      computed: {
        $isLandscape() {
          if (this.$isAndroid) {
            const orientation = window.orientation || screen.orientation.angle;
            return reactorComponent.isLandscape && (orientation === 90 || orientation === -90);
          }
          return reactorComponent.isLandscape;
        },
        $isMobileLandscape() {
          return this.$isLandscape && this.$isMobile;
        },
      },
    });
  },
};

Vue.use(detectOrientationPlugin);
