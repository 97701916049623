const ScPresentation = () =>
  import(
    /* webpackChunkName:"ScreenPresentation" */ "@/components/screen/room/Presentation/ScreenPresentation"
  );
const ScMessenger = () =>
  import(/* webpackChunkName:"ScreenMessenger" */ "@/components/screen/room/Messenger");
const RoundedTable = () =>
  import(
    /* webpackChunkName:"ScreenRoundedTable" */ "@/components/screen/room/RoundedTable/RoundedTable"
  );
const ScPhotowall = () =>
  import(/*webpackChunkName:"ScreenPhotowall"*/ "@/components/screen/room/Photowall/Photowall");
const ViewScreenQuestions = () =>
  import(/*webpackChunkName:"ViewScreenQuestions"*/ "@/views/screen/ViewScreenQuestions");
const Screen = () =>
  import(/*webpackChunkName:"ScreenScreen"*/ "@/views/screen/ViewScreen");

export default [
  {
    path: "/screen",
    name: "screen",
    component: Screen,
    meta: {
      isScreen: true,
      requiresAuth: true,
    },
  },
  {
    path: "/screen/:id",
    name: "screenRoom",
    component: Screen,
    props: true,
    meta: {
      isScreen: true,
      requiresAuth: true,
    },
    children: [
      {
        path: "brainstorm",
        name: "screenBrainstorm",
        component: RoundedTable,
        meta: {
          isScreen: true,
        },
      },
      {
        path: "presentation",
        name: "scpresentation",
        component: ScPresentation,
        meta: {
          isScreen: true,
        },
      },
      {
        path: "messenger",
        name: "scmessenger",
        component: ScMessenger,
        meta: {
          isScreen: true,
        },
      },

      {
        path: "questions",
        name: "scquestions",
        component: ViewScreenQuestions,
        meta: {
          isScreen: true,
        },
      },
      {
        path: "photowall",
        name: "scphotowall",
        component: ScPhotowall,
        meta: {
          isScreen: true,
        },
      },
    ],
  },
];
