import axios from "axios";
import { auth } from "@/store/modules/store.namespaces";
import { LOGOUT } from "@/store/modules/auth/action-types";

/**
 * Базовый класс для работы с http запросами
 */
class BaseHttpService {
  /**
   * Создает инстанс axios передает в него конфигурацию<br>
   * Добавляет interceptors
   *
   * @param {object} config - конфигурация для сервиса
   */
  constructor(config = {}) {
    this.setConfig(config);
    this.store = null;
    this.httpInstance = axios.create({
      baseURL: this.config.baseURL,
    });
    this.addInterceptors();
  }

  /**
   * Выполняет подготовку и установку настроек инстанса
   *
   * @param {string} baseURL - Базовый урл инстанса
   * @param {function} getToken - Функция получения токена
   */
  setConfig({ baseURL = "", getToken = () => "" }) {
    this.config = { baseURL, getToken };
  }

  /**
   * Выполняет GET запрос
   *
   * @param {string} api - адрес запроса
   * @param {object | undefined} config - дополнительная конфигурация запроса
   * @returns {Promise<any>} - ответ от сервера
   */
  get(api, config) {
    return this.httpInstance.get(api, config);
  }

  /**
   *
   * @param {string} api - адрес запроса
   * @param {any} data - данный для отправки на сервер
   * @param {object | undefined} config - дополнительная конфигурация запроса
   * @returns {Promise<any>} - ответ от сервера
   */
  post(api, data, config) {
    return this.httpInstance.post(api, data, config);
  }

  /**
   * Выполняет PUT запрос
   *
   * @param {string} api - адрес запроса
   * @param {any | undefined} data - данный для отправки на сервер
   * @param {object | undefined} config - дополнительная конфигурация запроса
   * @returns {Promise<any>} - ответ от сервера
   */
  put(api, data, config) {
    return this.httpInstance.put(api, data, config);
  }

  /**
   * Выполняет DELETE запрос
   *
   * @param {string} api - адрес запроса
   * @param {object | undefined} config - дополнительная конфигурация запроса
   * @returns {Promise<any>} - ответ от сервера
   */
  delete(api, config) {
    return this.httpInstance.delete(api, config);
  }

  /**
   * Авторизационный токен
   *
   * @type {string}
   */
  get token() {
    return this.config.getToken();
  }

  /**
   * Установить подготовленный VuexStore
   *
   * @param {object} store - vuex store
   */
  setStore(store) {
    this.store = store;
  }

  /**
   * Добавляет interceptors к инстансу http клиента для обработки ошибок сервера и ответов сервера
   */
  addInterceptors() {
    this.httpInstance.interceptors.request.use(config => {
      if (this.token) {
        config.headers["x-token"] = this.token;
      }
      return config;
    });
    this.httpInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response?.status && this[`error${error.response.status}`]) {
          this[`error${error.response.status}`]();
        }
        return Promise.reject(error);
      },
    );
  }

  /**
   * Обработка ошибки 404 по умолчанию
   */
  error401() {
    if (this.store) {
      this.store.dispatch(`${auth}/${LOGOUT}`, { tokenExpired: true });
    }
  }
}

export default BaseHttpService;
