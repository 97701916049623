import commonConfig from "./common.config";

const ENV_VAR_REGEX = /VUE_APP_?([A-Z_]+)/;
const WSS_PREFIX = "wss://";
const HTTPS_PREFIX = "https://";

function configMapPropFactory(envKey, prefix = HTTPS_PREFIX, postfix = "") {
  return {
    envKey,
    prefix,
    postfix,
  };
}

const CONFIG_PROPS_MAP = {
  domain: configMapPropFactory("SERVER_URL", ""),
  serverUrl: configMapPropFactory("SERVER_URL"),
  controllServerUrl: configMapPropFactory("CONTROLL_SERVER_URL"),
  WEB_SOCKET_SERVER_URL: configMapPropFactory("CONTROLL_SERVER_URL", WSS_PREFIX),
  pushServerUrl: configMapPropFactory("PUSH_SERVER_URL"),
  pushServerAppId: configMapPropFactory("PUSH_SERVER_APP_ID", ""),
  pushServerRestId: configMapPropFactory("PUSH_SERVER_REST_ID", ""),
  chatServerUrl: configMapPropFactory("CHAT_SERVER_URL"),
  chatSocketUrl: configMapPropFactory("CHAT_SERVER_URL", WSS_PREFIX, "/graphql"),
  storageUploadServerUrl: configMapPropFactory("STORAGE_SERVER_URL", HTTPS_PREFIX, "/upload"),
  storageServerUrl: configMapPropFactory("STORAGE_SERVER_URL", HTTPS_PREFIX),
  msgServerUrl: configMapPropFactory("CHAT_SERVER_URL"),
  audioServerUrl: configMapPropFactory("AUDIO_SERVER_URL"),
  streamingServer: configMapPropFactory("STREAMING_SERVER", WSS_PREFIX, ":8443"),
  omnideskUrl: configMapPropFactory("OMNIDESK_URL"),
  signalServer: configMapPropFactory("SIGNAL_SERVER", WSS_PREFIX, "/one2many"),
  routerMode: configMapPropFactory("ROUTER_MODE", ""),
  landingDomain: configMapPropFactory("LANDING_DOMAIN", ""),
  sentryDsn: configMapPropFactory("SENTRY_DSN", ""),
  flashphonerHlsUrl: configMapPropFactory("VUE_APP_FLASHPHONER_HLS_URL", ""),
  wcsLoadBalancerUrl: configMapPropFactory("VUE_APP_WCS_LOAD_BALANCER_URL", ""),
};

function getConfigPropsByEnvKey(variableName) {
  return Object.entries(CONFIG_PROPS_MAP).filter(
    // eslint-disable-next-line no-unused-vars
    ([_key, value]) => value.envKey === variableName,
  );
}

function setConfigProperty(configVarsByEnvKey, env, variable) {
  const configPropertiesByEnvVar = {};
  configVarsByEnvKey.forEach(([key, value]) => {
    configPropertiesByEnvVar[key] = [value.prefix, env[variable], value.postfix].join("");
  });
  return configPropertiesByEnvVar;
}

const processEnvVariables = env => {
  const filteredEnvVars = Object.keys(env).filter(variable => ENV_VAR_REGEX.test(variable));
  return filteredEnvVars.reduce((accum, variable) => {
    const [, variableName] = variable.match(ENV_VAR_REGEX);
    const configVarsByEnvKey = getConfigPropsByEnvKey(variableName);
    const configPropertiesByEnvVar = setConfigProperty(configVarsByEnvKey, env, variable);
    return { ...accum, ...configPropertiesByEnvVar };
  }, {});
};

export const getConfig = () => {
  const additionalConfig = processEnvVariables(process.env);
  return { ...commonConfig, ...additionalConfig };
};

const makeConfig = () => {
  if (process.env.NODE_ENV === "production" && !process.env.VUE_APP_SERVER_URL) {
    return {
      ...window.conf,
      ...commonConfig,
    };
  }
  return getConfig();
};
const config = Object.freeze(makeConfig());
export default config;
