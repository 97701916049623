<template>
  <transition :name="animated ? 'throbber-fade' : 'none'">
    <div
      class="throbber"
      :class="{
        'absolute-center': center,
      }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50px"
        height="50px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <rect x="0" y="0" width="100" height="100" fill="none" />
        <circle
          class="throbber__second-circle"
          cx="50"
          cy="50"
          r="40"
          fill="none"
          stroke-width="10"
          stroke-linecap="round"
        />
        <circle
          :class="{ 'throbber__main-circle': !mainStrokeColor }"
          cx="50"
          cy="50"
          r="40"
          :stroke="mainStrokeColor"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
        >
          <animate
            attributeName="stroke-dashoffset"
            dur="2s"
            repeatCount="indefinite"
            from="502"
            to="0"
          />
          <animate
            attributeName="stroke-dasharray"
            dur="2s"
            repeatCount="indefinite"
            values="150.6 100.4;1 250;150.6 100.4"
          />
        </circle>
      </svg>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Loader",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: true,
    },
    mainStrokeColor: {
      type: String,
      default: "",
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="less">
.throbber {
  z-index: 100;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  svg {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  &__second-circle {
    stroke: var(--base-bg-color);
  }

  &__main-circle {
    stroke: var(--base-color);
  }
}

.throbber-fade-enter-active,
.throbber-fade-leave-active {
  transition: opacity 0.2s;
}

.throbber-fade-enter,
.throbber-fade-leave-to {
  opacity: 0;
}
</style>
