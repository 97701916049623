import { SLIDE_TYPE } from "@/constants/presentation/slide-const";
import {
  CURRENT_PRESENTATION,
  CURRENT_PRESENTATION_INDEX,
  CURRENT_PRESENTATION_LEADER,
  CURRENT_PRESENTATION_SLIDE,
  CURRENT_PRESENTATION_SLIDE_ID,
  CURRENT_PRESENTATION_SLIDE_SRC,
  CURRENT_PRESENTATION_SLIDES,
  SELECTED_SLIDE_ORD,
  CURRENT_PRESENTATION_NAME,
  CURRENT_PRESENTATION_SELECTED_SLIDE_POS,
  CURRENT_PRESENTATION_SLIDES_COUNT,
} from "@/store/modules/presentation/getter-types";
import { makeUrl } from "@/utils";

export default {
  [CURRENT_PRESENTATION]({ presentations, currentPresentationInfo }) {
    if (!presentations?.length) return null;
    const { id } = currentPresentationInfo;
    return presentations.find(item => id === item.id) || null;
  },
  [CURRENT_PRESENTATION_LEADER](state, getters) {
    return getters[CURRENT_PRESENTATION]?.leader ?? null;
  },

  [CURRENT_PRESENTATION_SLIDES](state, getters) {
    if (!getters[CURRENT_PRESENTATION]) return [];
    return getters[CURRENT_PRESENTATION].slidesArray;
  },

  /**
   * Метод получения активного слайда.
   *
   * @param {*} state
   * @param {*} getters
   * @param {*} rootState
   * @returns {object} - возвращает активный слайд
   */
  [CURRENT_PRESENTATION_SLIDE](state, getters, rootState) {
    const slides = getters[CURRENT_PRESENTATION_SLIDES];
    const slideOrd = getters[SELECTED_SLIDE_ORD];

    if (!slides.length || (!slideOrd && slideOrd !== 0)) {
      return null;
    }

    const isParticipant = rootState.route.path.includes("room");

    if (isParticipant && slides[slideOrd - 1]?.type === "timer") {
      if (slideOrd === 0) {
        return slides[slideOrd + 1];
      }
      return slides[slideOrd - 1];
    }

    return slides.find(slide => slide.ord === slideOrd) || slides[0];
  },

  [CURRENT_PRESENTATION_SLIDE_ID](state, getters) {
    return getters[CURRENT_PRESENTATION_SLIDE]?.id ?? null;
  },
  [CURRENT_PRESENTATION_SLIDE_SRC](state, getters) {
    const currentPresentationSlide = getters[CURRENT_PRESENTATION_SLIDE];
    if (!currentPresentationSlide || currentPresentationSlide.type === SLIDE_TYPE.test) {
      return "";
    }
    if (currentPresentationSlide.type === SLIDE_TYPE.extimg) {
      return currentPresentationSlide.path;
    }
    return makeUrl(currentPresentationSlide.path);
  },
  [SELECTED_SLIDE_ORD]({ currentPresentationInfo: { userSelectedSlideIndex, slideIndex } }) {
    return userSelectedSlideIndex || slideIndex;
  },
  [CURRENT_PRESENTATION_INDEX](state) {
    return state.presentations.findIndex(
      presentation => presentation.id === state.currentPresentationInfo.id,
    );
  },

  /*
    Current presentation name|title.
  */
  [CURRENT_PRESENTATION_NAME](state, getters) {
    return getters[CURRENT_PRESENTATION]?.name ?? "";
  },
  /*
    Current presentation selected|active slide position in slides list started with 1.
  */
  [CURRENT_PRESENTATION_SELECTED_SLIDE_POS]({ currentPresentationInfo }) {
    return currentPresentationInfo.slideIndex;
  },
  /*
    Current presentation slides count.
  */
  [CURRENT_PRESENTATION_SLIDES_COUNT](state, getters) {
    return getters[CURRENT_PRESENTATION_SLIDES].length;
  },
};
