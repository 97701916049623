import { auth, landing, room } from "@/store/modules/store.namespaces";
import {
  USER_IS_SPEAKER,
  USER_IS_SCREEN,
  USER_IS_PARTICIPANT,
  USER_IS_ALLOW_EVENT_RECORD,
} from "@/store/modules/auth/getter-types";
import { LAST_ROOM_NUMBER } from "@/store/modules/common/getter-types";
import { DEFAULT_ROOM } from "@/store/modules/landing/getter-types";
import { SpeakerRouteName } from "@/constants/router/router-const";

export default function checkRole({ to, next, store, nextMiddleware }) {
  const userIsScreen = store.getters[`${auth}/${USER_IS_SCREEN}`];
  const userIsSpeaker = store.getters[`${auth}/${USER_IS_SPEAKER}`];
  const userIsParticipant = store.getters[`${auth}/${USER_IS_PARTICIPANT}`];
  const userIsAllowEventRecord = store.getters[`${auth}/${USER_IS_ALLOW_EVENT_RECORD}`];
  const sectionIsSpeaker = to.matched.some(record => record.meta.isSpeaker);
  const sectionIsScreen = to.matched.some(record => record.meta.isScreen);
  const isLobbyRoute = to.name === "lobby";
  const isAuthRoute =
    !to.matched.some(({ name }) => name === "lobby") &&
    to.matched.some(({ name }) => name === "auth");

  const lastRoom = to.params.id || store.getters[`${room}/${LAST_ROOM_NUMBER}`];
  const targetRoom = userIsSpeaker
    ? lastRoom
    : lastRoom || store.getters[`${landing}/${DEFAULT_ROOM}`] || store.state.auth.user.defaultRoom;

  if (to.meta.allowEventRecord && !userIsAllowEventRecord) {
    next({ name: "room", params: { id: targetRoom } });
  }

  if (userIsSpeaker && !sectionIsSpeaker) {
    return targetRoom
      ? next({ name: SpeakerRouteName.Room, params: { id: targetRoom } })
      : next({ name: SpeakerRouteName.Rooms });
  }

  if (userIsScreen && !sectionIsScreen && !isLobbyRoute) {
    return targetRoom
      ? next({ name: "screenRoom", params: { id: targetRoom } })
      : next({ name: "lobby" });
  }

  if (userIsParticipant && (isAuthRoute || sectionIsSpeaker || sectionIsScreen)) {
    return targetRoom
      ? next({ name: "room", params: { id: targetRoom } })
      : next({ name: "lobby" });
  }

  return nextMiddleware();
}
