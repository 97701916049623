import { RTMP_SERVERS } from "@/constants/broadcast/broadcast-const";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    prepared: false,
    onAir: false,
    safeMode: false,
    buffering: false,
    onAirAsLeading: false,
    screenSaver: false,
    displayScreenSaver: false,
    devices: {
      audio: [],
      video: [],
    },
    selectedVideo: {},
    selectedAudio: {},
    permissions: {
      canPresent: false,
      audio: true,
      video: true,
      presentationControl: false,
    },
    resolution: {
      name: "1080p",
      width: 1920,
      height: 1080,
      disabled: false,
    },
    constraints: {
      audio: true,
      video: true,
    },
    streamName: "",
    streamCaptureName: "",
    streamServer: "",
    rtmpServer: RTMP_SERVERS[0],
    participants: [],
    speakerState: {
      avatar: "",
      videoMuted: false,
      resolution: {
        name: "1080p",
        width: 1920,
        height: 1080,
        disabled: false,
      },
    },
    requestUserName: false,
    // part of conference state
    onAirIds: [],
    raiseHandIds: [],
    // users which only watch stream
    viewers: [],
    showBroadcastSettingsPopup: false,
    handsUpBtnDisabled: localStorage.getItem("handsUpBtnDisabled"),
    showWarning: false,
    userPlayerType: "",
  },
  getters,
  actions,
  mutations,
};
