import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { GET_WEBEX_USERS, WEBEX_LOG } from "@/store/modules/webex/action-types";
import { SET_WEBEX_USERS } from "@/store/modules/webex/mutation-types";

export default {
  state: {
    webexUsers: [],
  },
  actions: {
    async [WEBEX_LOG]() {
      await AdminWebHttpService.post(`SetPracticeLog`);
    },
    async [GET_WEBEX_USERS]({ commit }) {
      try {
        const { data } = await AdminWebHttpService.get(`UserWebex`);
        if (Array.isArray(data)) {
          commit(SET_WEBEX_USERS, data);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    [SET_WEBEX_USERS](state, users) {
      state.webexUsers = users;
    },
  },
};
