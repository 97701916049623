import mutations from "./mutations";

export default {
  state: {
    user: {},
    unreadMessages: 0,
    roomChatId: false,
    teamChatId: false,
    messages: {},
  },
  mutations,
}