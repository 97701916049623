export const RUN_POLL = "RUN_POLL";
export const SHOW_POLL_SCORE = "SHOW_POLL_SCORE";
export const CLOSE_POLL = "CLOSE_POLL";
export const CLOSE_POLL_SCORE = "CLOSE_POLL_SCORE";
export const SEND_POLL_ANSWER = "SEND_POLL_ANSWER";
export const SEND_NEW_ANSWER = "SEND_NEW_ANSWER";
export const CHAIN_STEP_FORWARD = "CHAIN_STEP_FORWARD";
export const RUN_TEST_POLL = "RUN_TEST_POLL";
export const SET_TEST_TIMER = "SET_TEST_TIMER";
export const GET_TEST_POLLS = "GET_TEST_POLLS";
export const SEND_POLL_TEST_ANSWER = "SEND_POLL_TEST_ANSWER";
export const GET_TEST_RESULTS = "GET_TEST_RESULTS";
export const GET_COMMON_TEST_RESULTS = "GET_COMMON_TEST_RESULTS";
export const SHOW_POLL_TEST_RESULT = "SHOW_POLL_TEST_RESULT";
export const RUN_INTERACTIVE = "RUN_INTERACTIVE";
export const CLOSE_INTERACTIVE = "CLOSE_INTERACTIVE";
export const RUN_ABOUT_WS_POLL = "RUN_ABOUT_WS_POLL";
export const CHECK_IS_AVAILABLE_WS_POLL = "CHECK_IS_AVAILABLE_WS_POLL";
export const GET_TESTS = "GET_TESTS";
export const GET_NEXT_QUESTION = "GET_NEXT_QUESTION";
export const FINISH_TEST = "FINISH_TEST";