import {
  BROADCAST_RESET_STATE,
  BROADCAST_SET_STATE_PROP,
} from "@/store/modules/broadcast/mutation-types";
import { copyObj } from "@/utils";

const defaultState = {
  prepared: false,
  onAir: false,
  safeMode: false,
  onAirAsLeading: false,
  screenSaver: false,
  displayScreenSaver: false,
  screenSaverTimestamp: 0,
  speakerState: {
    avatar: "",
    videoMuted: false,
    resolution: {
      name: "1080p",
      width: 1920,
      height: 1080,
      disabled: false,
    },
  },
  permissions: {
    canPresent: false,
    audio: true,
    video: true,
    presentationControl: false,
  },
  participants: [],
  onAirIds: [],
  raiseHandIds: [],
  viewers: [],
  requestUserName: false,
  viewersCount: 0,
  showBroadcastSettingsPopup: false,
  handsUpBtnDisabled: false,
  streamName: "",
  streamCaptureName: "",
  showWarning: false,
};

export default {
  [BROADCAST_SET_STATE_PROP](state, data) {
    Object.keys(data).forEach(key => {
      state[key] = data[key];
    });
  },
  [BROADCAST_RESET_STATE](state, onlyState = false) {
    const newState = copyObj(defaultState);
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });

    if (onlyState) {
      localStorage.removeItem("handsUpBtnDisabled");
    }
  },
};
