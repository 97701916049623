import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";
import AuthService from "@/services/AuthService";
import SpeakerQuestion from "@/models/SpeakerQuestion";
import {
  ANSWER_ON_QUESTION,
  GET_QUESTIONS,
  MODERATE_QUESTION,
  TOGGLE_PREMODERATION,
} from "@/store/modules/speaker/modules/questions/action-types";
import { SET_QUESTIONS } from "@/store/modules/speaker/modules/questions/mutation-types";
import { room, socket } from "@/store/modules/store.namespaces";
import { SET_PREMODERATION } from "@/store/modules/common/mutation-types";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";

export default {
  state: {
    questions: [],
  },
  actions: {
    async [GET_QUESTIONS]({ rootState, commit }, sort = "mark") {
      const roomNum = rootState.common.roomNumber;
      if (!roomNum) return;
      const { data } = await AdminWebHttpService.get(
        `getAdminQuestions/${roomNum}/${AuthService.getToken()}?sort=${sort}`,
      );
      const questions = data.map(q => new SpeakerQuestion(q));
      commit(SET_QUESTIONS, questions);
    },
    async [ANSWER_ON_QUESTION]({ dispatch, commit }, data) {
      await AdminWebHttpService.get(`answerQuestion/${data.id}`);
      // Проверить тут
      const payload = { obj: data, type: "answer" };
      commit(`${socket}/${SET_SOCKET_OBJ}`, payload, { root: true });
      await dispatch(GET_QUESTIONS);
    },
    async [MODERATE_QUESTION]({ dispatch }, { id }) {
      await AdminWebHttpService.get(`moderateQuestion/${id}`);
      await dispatch(GET_QUESTIONS);
    },
    async [TOGGLE_PREMODERATION]({ commit, rootState }) {
      const { data } = await AdminApi2HttpService.put(
        `rooms/${rootState.common.roomId}/speakerQuestions/toggleModeration`,
      );
      commit(`${room}/${SET_PREMODERATION}`, data, { root: true });
      const socketObj = {
        type: "togglePremoderation",
        state: data,
      };
      commit(`${socket}/${SET_SOCKET_OBJ}`, socketObj, { root: true });
    },
  },
  mutations: {
    [SET_QUESTIONS](state, payload) {
      state.questions = payload;
    },
  },
};
