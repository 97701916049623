import {
  CLEAR_USER_TABLE,
  REMOVE_BRAINSTORM_IDEA,
  SET_BRAINSTORM_FILTER,
  SET_BRAINSTORM_IDEA_ID,
  SET_BRAINSTORM_IDEAS,
  SET_BRAINSTORM_IDEAS_IS_LOADING,
  SET_CURRENT_BRAINSTORM_TAB,
  SET_ROOM_BRAINSTORMS,
  SET_ROUNDED_TABLES,
  SET_USER_TABLE_ID,
  UPDATE_BRAINSTORM_IDEA,
} from "@/store/modules/brainstorm/mutation-types";

export default {
  [SET_ROUNDED_TABLES](state, tables) {
    state.roundedTables = tables;
  },
  [SET_ROOM_BRAINSTORMS](state, brainstorms) {
    state.roomBrainstorms = [...brainstorms];
  },
  [SET_BRAINSTORM_IDEAS](state, ideas) {
    state.roundedTableIdeas = [...ideas].sort((a, b) => {
      return b.likes - a.likes;
    });
  },
  [SET_BRAINSTORM_IDEAS_IS_LOADING](state, status) {
    state.brainstormIdeasIsLoading = status;
  },
  [UPDATE_BRAINSTORM_IDEA](state, idea) {
    if (
      state.currentBrainstormTab === "group" &&
      state.userTableId !== idea.table.id
    ) {
      return;
    }
    let index = state.roundedTableIdeas.findIndex(item => {
      return item.id.toString() === idea.id.toString();
    });
    let newIdeasList = [...state.roundedTableIdeas];
    if (index > -1) {
      newIdeasList.splice(index, 1, idea);
    } else {
      newIdeasList.push(idea);
    }
    state.roundedTableIdeas = [...newIdeasList];
  },
  [REMOVE_BRAINSTORM_IDEA](state, id) {
    state.roundedTableIdeas = state.roundedTableIdeas.filter(
      item => item.id !== id,
    );
  },
  [SET_CURRENT_BRAINSTORM_TAB](state, tab) {
    state.currentBrainstormTab = tab;
  },
  [CLEAR_USER_TABLE](state) {
    state.userTableId = null;
  },
  [SET_USER_TABLE_ID](state, data) {
    state.userTableId = data;
  },
  [SET_BRAINSTORM_IDEA_ID](state, id) {
    state.idBrainstorm = id;
  },
  [SET_BRAINSTORM_FILTER](state, val) {
    state.brainstormFilter = val;
  },
};
