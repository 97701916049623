import {
  CLEAR_READ_NOTIFS,
  SET_LAST_NOTIFICATION,
  SET_LAST_SYSTEM_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_PAGE,
  SET_PAGES_COUNT,
  SET_UNREAD_NOTIFICATIONS,
} from "@/store/modules/notifications/mutation-types";

export default {
  [SET_UNREAD_NOTIFICATIONS](state, data) {
    state.unreadNotifs = data;
  },
  [CLEAR_READ_NOTIFS](state) {
    let readNotifs = [];
    if (state.notifications) {
      readNotifs = state.notifications.map(notifs => {
        return {
          ...notifs,
          readed: true,
        };
      });
    }

    state.notifications = readNotifs;
  },
  [SET_NOTIFICATIONS](state, data) {
    state.notifications = data;
  },
  [SET_PAGE](state, page) {
    state.page = page;
  },
  [SET_PAGES_COUNT](state, pagesCount) {
    state.pagesCount = pagesCount;
  },
  [SET_LAST_NOTIFICATION](state, data) {
    state.lastNotification = data;
  },
  [SET_LAST_SYSTEM_NOTIFICATION](state, data) {
    state.lastSystemNotification = data;
  },
};
