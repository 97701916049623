import { SET_ROOMS, TOGGLE_BURGER_MENU } from "@/store/modules/speaker/mutation-types";

export default {
  [SET_ROOMS](state, rooms) {
    state.rooms = rooms;
  },
  [TOGGLE_BURGER_MENU](state) {
    state.burgerMenuOpened = !state.burgerMenuOpened;
  },
};
