import {
  GET_LAST_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
} from "@/store/modules/notifications/action-types";
import {
  SET_LAST_NOTIFICATION,
  SET_LAST_SYSTEM_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_PAGE,
  SET_PAGES_COUNT,
  SET_UNREAD_NOTIFICATIONS,
} from "@/store/modules/notifications/mutation-types";
import AdminWsHttpService from "@/services/http/AdminWsHttpService";

export default {
  [GET_UNREAD_NOTIFICATIONS]({ commit, rootState }) {
    if (!rootState.common.roomNumber) {
      return;
    }
    AdminWsHttpService.get(`getUnreadNotifications/${rootState.common.roomNumber}`).then(res => {
      commit(SET_UNREAD_NOTIFICATIONS, res.data.count);
    });
  },
  [GET_NOTIFICATIONS]({ commit, rootState }, { currentPage, pageCount }) {
    if (!rootState.common.roomNumber) {
      return;
    }
    return new Promise((resolve, reject) => {
      AdminWsHttpService.get(
        `getUserNotifications/${rootState.common.roomNumber}?page=${currentPage}&count=${pageCount}`,
      )
        .then(res => {
          commit(SET_NOTIFICATIONS, res.data.notifications);
          commit(SET_PAGE, +res.data.page);
          commit(SET_PAGES_COUNT, +res.data.pages_count);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_LAST_NOTIFICATION]({ commit, rootState }) {
    if (!rootState.common.roomNumber) {
      return;
    }
    AdminWsHttpService.get(`getLastNotification/${rootState.common.roomNumber}`).then(res => {
      if ((!res.data[0] || !res.data[0].text) && !res.data[1]) {
        return;
      }

      if (!res.data[0].type) {
        commit(SET_LAST_NOTIFICATION, res.data[0]);
      }

      if (res.data[0].id > res.data[1].id) {
        commit(SET_LAST_NOTIFICATION, res.data[0]);
        return;
      }

      if (Array.isArray(res.data[1])) {
        return;
      }
      commit(SET_LAST_SYSTEM_NOTIFICATION, res.data[1]);
    });
  },
};
