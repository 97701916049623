import { ACTIVE_ABOUT_WS, TESTS } from "@/store/modules/speaker/modules/getter-types";
import { ACTIVE_POLL_ID } from "@/store/modules/common/getter-types";
import { room } from "@/store/modules/store.namespaces";
import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    polls: [],
    tests: [],
    aboutWsTestId: 0,
  },
  getters: {
    /**
     * Фильтрует список полученных групп.
     * Убирает из списка группы, в которых нет тестов.
     *
     * @param {Array} tests - список тестов
     * @returns {Array} Возвращает список тестов доступных для показа.
     */
    [TESTS]({ tests }) {
      let allTests = JSON.parse(JSON.stringify(tests));
      allTests = allTests.filter(
        _group => _group.not_started?.length || _group.started?.length || _group.finished?.length,
      );
      return allTests;
    },
    /**
     * Запущен опрос о венспик или нет
     *
     * @param {number} aboutWsId - ид опроса о венспик
     * @param _getters
     * @param _rootState
     * @param {object} rootGetters
     * @returns {boolean}
     */
    [ACTIVE_ABOUT_WS]({ aboutWsTestId }, _getters, _rootState, rootGetters) {
      return rootGetters[`${room}/${ACTIVE_POLL_ID}`] === aboutWsTestId && !!aboutWsTestId;
    },
  },
  actions,
  mutations,
};
