import UserRoles from "@/constants/user/roles-const";
import {
  USER_IS_ADMIN,
  USER_IS_SCREEN,
  USER_IS_SPEAKER,
  USER_IS_LOGGED_IN,
  USER_IS_PARTICIPANT,
  USER_IS_ALLOW_EVENT_RECORD,
  USER_PROFILE,
} from "./getter-types.js";

export default {
  [USER_IS_LOGGED_IN](state) {
    return !!state.user.id;
  },
  [USER_IS_ADMIN](state) {
    return +state.user.role === UserRoles.SuperAdmin;
  },
  [USER_IS_SPEAKER](state) {
    return +state.user.role === UserRoles.Admin;
  },
  [USER_IS_SCREEN](state) {
    return [UserRoles.Manager, UserRoles.Screen].includes(+state.user.role);
  },
  [USER_IS_PARTICIPANT](state) {
    // @todo: check this after the backend checks and cleans up unused roles
    return [UserRoles.Leading, UserRoles.Guest, UserRoles.Photowall].includes(+state.user.role);
  },
  [USER_IS_ALLOW_EVENT_RECORD](state) {
    return !!state.user.is_allow_event_record;
  },
  [USER_PROFILE]({ user }) {
    return {
      userId: user.id,
      photo: !!user.photoWithUrl,
      avatar: user.photoWithUrl,
      fio: user.fio,
      name: [user.fio],
      position: [user.fields.position],
      description: user.description,
      email: user.fields.email,
      phone: user.fields.phone,
      location: [user.city, user.fields.country].filter(Boolean).join(", "),
      login: user.login,
    };
  },
};
