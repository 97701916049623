import { mapGetters } from "vuex";
import { landing } from "@/store/modules/store.namespaces";
import Color from "color";
import { LANDING_LOGO, TEMPLATE } from "@/store/modules/landing/getter-types";
import { makeUrl } from "@/utils/index";

/**
 * Распределяет какой стиль использовать в зависимости он позиции
 *
 * @memberof FormPosition
 * @constant
 * @type {object}
 * @default
 */
export const FORM_POSITION_TO_ALIGN = {
  left: "flex-start",
  right: "flex-end",
  default: "center",
};
/**
 * @mixin Используется для темификации лендинга
 *
 * @vue-computed {object} template - Все настройки лендинга
 * @vue-computed {string} landingLogo - Url логотипа лендинга
 * @vue-computed {object} theme - Содержит в себе все css переменые для лендинга
 * @vue-computed {object} themeColors - Цвета лендинга переменные css
 * @vue-computed {object} hemeBackground - Стили для основного фона страницы
 * @vue-computed {object} themeHeader - Стили для хедера и логотипа страницы
 * @vue-computed {object} themeForm - Центрирование формы входа для лендинга
 * @vue-computed {object} themePromo - Стили для текста приветствия
 */
export default {
  name: "useLandingTheme",
  computed: {
    ...mapGetters(landing, {
      template: TEMPLATE,
      landingLogo: LANDING_LOGO,
    }),
    theme() {
      return {
        ...this.themeColors,
        ...this.themeBackground,
        ...this.themeHeader,
        ...this.themeForm,
        ...this.themePromo,
      };
    },
    themeColors() {
      const baseColorHSL = Color(this.template.button_color || "#f28b00").hsl();
      return {
        "--login-header-color": this.template.header_color || "#ffffff",
        "--login-form-color": this.template.form_background_color || "",
        "--login-content-color": this.template.content_color || "#ffffff",
        "--login-input-color": this.template.button_color || "#f79843",
        "--login-text-color": this.template.text_color || "#000000",
        "--login-button-color": this.template.button_color || "#f79843",
        "--login-button-text-color": this.template.button_text_color || "#ffffff",

        "--ws-color-primary": baseColorHSL.string(),
        "--ws-color-primary--active": baseColorHSL.darken(0.06).string(),
      };
    },
    themeBackground() {
      return {
        "--login-background-image": `url("${makeUrl(this.template.page_logo)}")`,
        "--login-background-position": this.template.background_position || "center",
        "--login-background-size":
          this.template.view_background === "stretch" ? "cover" : "contain",
      };
    },
    themeHeader() {
      return {
        "--login-header-image": `url("${makeUrl(this.template.header_logo)}")`,
        "--login-header-bg-color": this.template.header_color || "#ffffff",
        "--login-header-logo-image": `url("${makeUrl(this.landingLogo)}")`,
        "--login-header-logo-position": this.template.logo_position || "center",
      };
    },
    themeForm() {
      const alignItems =
        FORM_POSITION_TO_ALIGN[this.template.login_form_position] || FORM_POSITION_TO_ALIGN.default;
      return {
        "--login-form-align": alignItems,
      };
    },
    themePromo() {
      return {
        "--login-promo-align": this.template.content_text_align || "center",
      };
    },
  },
};
