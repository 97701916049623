import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { GET_MATERIALS } from "./actions-types";
import { SET_MATERIALS } from "./mutation-types";

export default {
  async [GET_MATERIALS]({ commit, rootState }, { folderId = "", from }) {
    const isFolder = false;
    try {
      const url = `getfilesinfinite/${rootState.common.roomNumber}/${
        folderId ? `${folderId}/` : ""
      }${from || 0}`;
      const response = await AdminWebHttpService.get(url);
      if (response && response.data) {
        if (response.data.length || (response.data.files && response.data.files.length > 0)) {
          commit(SET_MATERIALS, {
            data: response.data,
            isFolder,
          });
          return null;
        }
        if (response.data.folders && response.data.folders.length > 0) {
          commit(SET_MATERIALS, {
            data: response.data,
            isFolder,
          });
        }
      }
      return "complete";
    } catch (e) {
      console.log(e);
      return "complete";
    }
  },
};
