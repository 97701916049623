export const MqName = {
  MobileS: "mobile-s",
  MobileM: "mobile-m",
  MobileMPlus: "mobile-m+",
  MobileL: "mobile-l",
  Tablet: "tablet",
  Laptop: "laptop",
};

export const BREAKPOINTS = {
  [MqName.MobileS]: 375,
  [MqName.MobileM]: 425,
  [MqName.MobileMPlus]: 480,
  [MqName.MobileL]: 768,
  [MqName.Tablet]: 1024,
  [MqName.Laptop]: Infinity,
};

export const TO_LAPTOP_MQ_KEYS = [
  MqName.MobileS,
  MqName.MobileM,
  MqName.MobileMPlus,
  MqName.MobileL,
  MqName.Tablet,
];
