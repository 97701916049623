import { GET_INFO_SECTION } from "@/store/modules/information/action-types";
import { SET_INFORMATION } from "@/store/modules/information/mutation-types";
import AdminWebHttpService from "@/services/http/AdminWebHttpService";

export default {
  state: {
    information: null,
  },
  actions: {
    [GET_INFO_SECTION]({ commit, rootState }, id) {
      return AdminWebHttpService.get(
        `getInfoSectionById/${rootState.common.roomNumber}/${id}`,
      ).then(data => {
        if (data) {
          const title = data.data.title || "";
          const res = {
            ...data.data,
          };
          const reg = /\{\{name\}\}/;
          const name = rootState.auth.user.is_guest === 1 ? "Гость" : rootState.auth.user.fio;
          if (title && reg.test(title)) {
            res.title = title.replace(reg, name);
          }
          commit(SET_INFORMATION, res);
          return res;
        }
        commit(SET_INFORMATION, null);
        return null;
      });
    },
  },
  mutations: {
    [SET_INFORMATION](state, data) {
      state.information = data;
    },
  },
};
